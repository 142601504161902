import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';
import { regionOptions } from '../utils/helpers';

export const apiGetTransactionCount = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/count_txns_monthly`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      const formattedData = response.data.data.reduce((accumulator, currentItem) => {
        regionOptions.forEach((option) => {
          if (!accumulator[option.value]) {
            accumulator[option.value] = [];
          }

          if (currentItem[option.value] !== undefined) {
            accumulator[option.value].push({
              timestamp: currentItem.month_and_year,
              value: currentItem[option.value],
            });
          }
        });

        return accumulator;
      }, {});

      return formattedData;
    } else {
      throw new Error('get transaction count failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
