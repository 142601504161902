import { ReactComponent as ChangeDown } from 'icons/custom/change-down.svg';
import { ReactComponent as ChangeUp } from 'icons/custom/change-up.svg';
import { ReactComponent as ChangeNeutral } from 'icons/custom/arrow-right.svg';

import numeral from 'numeral';
import 'numeral/locales/en-gb';
import React from 'react';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PillRenderer = ({ value }) => {
  const positive = value > 0;
  const neutral = value === 0;

  return !isNaN(value) && value !== undefined ? (
    <div
      className="font-thin text-xs"
      style={{
        width: '71px',
        height: '22px',
        background: neutral ? 'rgba(116, 123, 138, 0.1)' : positive ? '#F5FBF3' : 'rgba(208, 65, 59, 0.1)',
        border: neutral
          ? '1px solid rgba(116, 123, 138, 0.5)'
          : positive
            ? '1px solid rgba(98, 164, 97, 0.15)'
            : '1px solid rgba(208, 65, 59, 0.15)',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '8px',
        color: neutral ? 'rgba(116, 123, 138, 0.8)' : positive ? '#62A461' : '#D0413B',
      }}
    >
      {numeral(value / 100).format('0.00%')}&nbsp;
      {neutral ? (
        <ChangeNeutral style={{ fill: '#747b8acc', height: 12, paddingLeft: 2 }} />
      ) : positive ? (
        <ChangeUp />
      ) : (
        <ChangeDown />
      )}
    </div>
  ) : (
    '-'
  );
};

export default React.memo(PillRenderer);
