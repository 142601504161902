import { post } from 'api';
import type { ApiPaginatedDataResponse, DataResponse } from 'api/types';
import { Mutex } from 'async-mutex';
import {
  superIndexRequestAdaptor,
  superIndexResponseAdaptor,
} from './adaptors';
import type {
  SuperIndexRecord,
  SuperIndexRequestOptions,
  SuperIndexResponse,
} from './types';

export const SUPER_INDEX_QUERY_KEY = ['super-index'];

const superIndexMutex = new Mutex();

export const getSuperIndex = async (
  options?: SuperIndexRequestOptions,
  signal?: AbortSignal,
): Promise<DataResponse<SuperIndexRecord>> => {
  const response = await superIndexMutex.runExclusive(async () => {
    return await post<ApiPaginatedDataResponse<{ data: SuperIndexResponse[] }>>(
      'superindices',
      superIndexRequestAdaptor(options),
      { signal },
    );
  });

  return {
    data: superIndexResponseAdaptor(response.data.data),
    pageCount: options
      ? Math.ceil(response.data.total_count / options.pagination.pageSize)
      : 1,
  };
};
