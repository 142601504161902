import Select from 'react-select';

import BaseBarChart from '../../Dashboard/Charts/BaseBarChart';
import { calculateYearlyChange, seriesObjectToArray } from '../../../../utils/helpers';
import ItemVsIndexChart from '../../Dashboard/Charts/ItemVsIndexChart';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { useState } from 'react';

const RentalCharts = ({ targetTime, regionIndexData }) => {
  const [selectedIndexA, setSelectedIndexA] = useState(regionIndexData[0]);
  const [selectedIndexB, setSelectedIndexB] = useState(regionIndexData[1]);

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="pb-0 m-3 flex justify-start items-center">
              <Select
                className="w-1/3 mx-2"
                key="selectedIndexA"
                value={selectedIndexA}
                onChange={(item) => setSelectedIndexA(item)}
                options={regionIndexData}
                isSearchable={true}
              />

              <div className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                vs
              </div>
              <Select
                className="w-1/3 mx-2"
                key="selectedIndexB"
                value={selectedIndexB}
                onChange={(item) => setSelectedIndexB(item)}
                options={regionIndexData}
                isSearchable={true}
              />
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {selectedIndexA?.forecast ? (
                  <ItemVsIndexChart
                    address={selectedIndexA?.label}
                    index={selectedIndexB?.label}
                    itemGraphSeriesData={seriesObjectToArray(selectedIndexA?.forecast || {}, targetTime)}
                    benchmarkGraphSeriesData={seriesObjectToArray(selectedIndexB?.forecast || {}, targetTime)}
                    secondaryAxisButtonEnabled={true}
                  />
                ) : (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3 flex justify-between">
              <h4 className="fs-20 mb-0"> Year on year rent price change </h4>
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                <BaseBarChart
                  address={selectedIndexA?.label}
                  index={selectedIndexB?.label}
                  itemGraphSeriesData={calculateYearlyChange(selectedIndexA?.forecast || {}, targetTime)}
                  benchmarkGraphSeriesData={calculateYearlyChange(selectedIndexB?.forecast || {}, targetTime)}
                  dataType={'rate'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalCharts;
