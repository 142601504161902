import { useEffect, useRef, useState } from "react";

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });

  useEffect(() => {
    const updateMousePosition = ({ clientX, clientY }: MouseEvent) => {
      setMousePosition({ x: clientX, y: clientY });
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};

export const useOriginalValue = <T>(value: T): T => {
  const [originalValue] = useState<T>(value);
  return originalValue;
};

interface MapZoomState {
  isZooming: boolean;
  zoomStarted: boolean;
  currentZoom: number | undefined;
}

export const useMapZoomInteraction = (
  map: google.maps.Map | null,
  timeout = 500,
): MapZoomState => {
  const [state, setState] = useState<MapZoomState>({
    isZooming: false,
    zoomStarted: false,
    currentZoom: map?.getZoom(),
  });

  const timeoutRef = useRef<NodeJS.Timeout>();
  const lastZoomRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!map) return;

    const handleUserZoom = () => {
      const newZoom = map.getZoom();

      if (newZoom !== lastZoomRef.current) {
        lastZoomRef.current = newZoom;

        setState((prev) => ({
          isZooming: true,
          zoomStarted: !prev.isZooming,
          currentZoom: newZoom,
        }));

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setState((prev) => ({
            isZooming: false,
            zoomStarted: false,
            currentZoom: prev.currentZoom,
          }));
        }, timeout);
      }
    };

    const div = map.getDiv();
    div.addEventListener('wheel', handleUserZoom, { passive: true });
    div.addEventListener('click', (event) => {
      const { ariaLabel } = event.target as HTMLElement;
      if (ariaLabel === 'Zoom in' || ariaLabel === 'Zoom out') {
        handleUserZoom();
      }
    });
    map.addListener('dblclick', handleUserZoom);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      div.removeEventListener('wheel', handleUserZoom);
      google.maps.event.clearListeners(map, 'dblclick');
    };
  }, [map, timeout]);

  return state;
};
