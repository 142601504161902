import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as SuccessSVG } from 'icons/custom/circle-check-regular.svg';
import { ReactComponent as ExclamationSVG } from 'icons/custom/exclamation.svg';
import Select from 'react-select';
import { customSelectStyles, numberOfBedroomsOptionsOnly, propertyTypeOptionsOnly } from '../../../../utils/constants';
import { createProperty } from '../../../../api/property';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

const PropertyModelRunModal = ({ showModal, setShowModal, propertyParams }) => {
  const [modalState, setModalState] = useState('');
  const [tryAgainButtonClicked, setTryAgainButtonClicked] = useState(false);

  const [formData, setFormData] = useState({
    num_rooms: 1,
    property_type: 'F',
  });

  const queryClient = useQueryClient();

  const generateData = async (additionalParams) => {
    try {
      let createRequestParams = { ...propertyParams, custom: true };
      if (additionalParams) {
        setTryAgainButtonClicked(true);
        createRequestParams.num_rooms = parseInt(formData.num_rooms);
        createRequestParams.property_type = formData.property_type;
        createRequestParams.user_entered_params = '1';
      }
      await createProperty(createRequestParams);
      queryClient.invalidateQueries(['rawProperties']);
      queryClient.invalidateQueries(['rawProperties', 'in-progress']);
      const queryState = queryClient.getQueryState(['properties']);
      if (queryState) {
        queryClient.refetchQueries(['properties']);
      }
      setModalState('generating');
    } catch (error) {
      if (
        error?.response?.data &&
        (error.response.data.message === 'sales data is missing' ||
          error.response.data.message === 'params are missing')
      ) {
        setModalState('parametersMissing');
      } else {
        setModalState('generationFailed');
      }
    }
  };

  const handleDropdownFilterFormChange = (item, selectField) => {
    const fieldName = selectField;
    const fieldValue = item.value;
    const currentFormData = { ...formData };
    if (fieldValue !== formData[fieldName]) {
      currentFormData[fieldName] = fieldValue;
      setFormData(currentFormData);
    }
  };

  useEffect(() => {
    setTryAgainButtonClicked(false);
    setFormData({
      num_rooms: 1,
      property_type: 'F',
    });
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      setModalState('');
      if (propertyParams.num_rooms !== null && propertyParams.property_type) {
        generateData();
      } else {
        setFormData({
          num_rooms: propertyParams.num_rooms !== null ? propertyParams.num_rooms : 1,
          property_type: propertyParams.property_type ? propertyParams.property_type : 'F',
        });
        setModalState('parametersMissing');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyParams, showModal]);

  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center	justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-2xl font-medium"></div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />

        {!modalState && (
          <div className="flex flex-col py-20">
            <LoaderSvg className="animate-spin h-8 w-8 text-white" />
          </div>
        )}

        {modalState === 'generating' && (
          <>
            <div className="p-2">
              <div
                className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
                style={{ background: '#F5F8FE' }}
              >
                <SuccessSVG className="h-16 w-16 text-white" />
              </div>
            </div>

            <div className="text-black text-center font-medium mb-12 px-4">
              <ul className="list-disc text-left">
                <li className="list-disc"> Your valuation report is being generated </li>
                <li className="list-disc"> It will be ready in max. 2 minutes </li>
                <li className="list-disc"> You will get an email when it's ready </li>
              </ul>
            </div>

            <div className="flex flex-row w-full justify-between px-4 mb-4">
              <Link to={`/properties/`}>
                <PrimaryButton>View Properties</PrimaryButton>
              </Link>

              <SecondaryButton className={'w-auto px-3'} onClick={() => setShowModal(false)}>
                Add another property
              </SecondaryButton>
            </div>
          </>
        )}

        {modalState === 'generationFailed' && (
          <>
            <div className="p-2">
              <div
                className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
                style={{ background: '#F5F8FE' }}
              >
                <ExclamationSVG />
              </div>
            </div>

            <div className="text-black text-center font-medium mb-12 px-4">
              Unfortunately, we've not been able to provide a forecast for this property at this time. We're constantly
              improving our model so please check back at a later date.
            </div>
            <div className="flex flex-row w-full justify-end px-4 mb-4">
              <SecondaryButton onClick={() => setShowModal(false)}>Close</SecondaryButton>
            </div>
          </>
        )}

        {modalState === 'parametersMissing' && (
          <>
            <div className="p-2">
              <div
                className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
                style={{ background: '#F5F8FE' }}
              >
                <ExclamationSVG />
              </div>
            </div>

            <div className="text-black text-center font-medium mb-6 px-4">
              Unfortunately, we've don't have parameters to provide a forecast for this property at this time. Please
              provide information about the property type and number of bedrooms and try again
            </div>

            <div className="flex w-full px-4 mb-6">
              <div className="w-1/2 flex flex-column">
                <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
                <Select
                  className="mr-1.5 h-10 text-xs"
                  placeholder="Number of Bedrooms"
                  value={
                    numberOfBedroomsOptionsOnly.find(({ value }) => value === formData.num_rooms) ||
                    numberOfBedroomsOptionsOnly[0]
                  }
                  onChange={(item) => handleDropdownFilterFormChange(item, 'num_rooms')}
                  styles={customSelectStyles}
                  options={numberOfBedroomsOptionsOnly}
                  isSearchable={false}
                  name="num_rooms"
                />
              </div>
              <div className="w-1/2 flex flex-column">
                <label className="font-medium text-black text-xs"> Property Type </label>
                <Select
                  className="ml-1.5 h-10 text-xs"
                  placeholder="Property Type"
                  value={
                    propertyTypeOptionsOnly.find(({ value }) => value === formData.property_type) ||
                    propertyTypeOptionsOnly[0]
                  }
                  onChange={(item) => handleDropdownFilterFormChange(item, 'property_type')}
                  styles={customSelectStyles}
                  options={propertyTypeOptionsOnly}
                  isSearchable={true}
                  name="property_type"
                />
              </div>
            </div>

            <div className="flex flex-row w-full justify-between px-4 mb-4">
              <PrimaryButton disabled={tryAgainButtonClicked} onClick={() => generateData(true)}>
                Try Again
              </PrimaryButton>
              <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PropertyModelRunModal;
