import { useQueries } from '@tanstack/react-query';
import RegionalChart from './RegionalChart';
import RegionsMapData from './RegionsMapData';
import { apiSearchCityExactIndex } from '../../../../api/cityIndex';
import { regionIndexKeys } from '../../../../utils/constants';
import { useMemo } from 'react';
import moment from 'moment';
import RegionCard from './RegionCard';

const RegionalData = ({ targetTime }) => {
  const regionIndexQueries = useQueries({
    queries: regionIndexKeys.map((index) => {
      return {
        queryKey: ['cityIndexQueries', index.key],
        queryFn: ({ signal }) => apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: index.key }, signal),
      };
    }),
  });

  const regionIndexData = useMemo(() => {
    const aggregatedIndexData = regionIndexQueries.map((query, index) => {
      const targetDate = moment(targetTime).format('YYYY-MM-DD');
      const formattedLastMonth = moment(targetTime).subtract(1, 'months').format('YYYY-MM-DD');
      const formattedLastYear = moment(targetTime).subtract(12, 'months').format('YYYY-MM-DD');
      const targetValue = query?.data?.forecast[targetDate];
      const changeMom = targetValue / query?.data?.forecast[formattedLastMonth] - 1;
      const changeYoy = targetValue / query?.data?.forecast[formattedLastYear] - 1;
      const indexMeta = regionIndexKeys.find((element) => element.key === query?.data?.index_name);

      return {
        ...indexMeta,
        id: query?.data?.index_name,
        changeMom,
        changeYoy,
        currentPrice: targetValue,
      };
    });

    return aggregatedIndexData.filter((item) => item.currentPrice).sort((a, b) => b.currentPrice - a.currentPrice);
  }, [regionIndexQueries, targetTime]);

  return (
    <>
      {regionIndexData?.length ? (
        <>
          <h4 className="mb-2.5 ml-2 fs-20 text-black"> House prices by region in [UK] </h4>
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <RegionsMapData regionIndexData={regionIndexData} />
            </div>
            <div className="col-xl-6 col-sm-12">
              <RegionalChart regionIndexData={regionIndexData} />
            </div>
          </div>
          <h4 className="mb-2.5 ml-2 fs-20 text-black"> Housing price index</h4>
          <div className="row">
            {regionIndexData?.map((region, index) => (
              <div className="col-xl-3 col-md-4 col-sm-6 col-xs-12" key={index}>
                <RegionCard regionData={region} />
              </div>
            ))}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default RegionalData;
