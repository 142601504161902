import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export function getPropertyIndicesbyUPRN(uprn) {
  return axiosInstance.get(`propertyindices/${uprn}`, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export const getTargetTime = async (signal) => {
  try {
    const response = await axiosInstance.get(`propertyindices/targettime`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });

    if (response.status === 200) {
      return response.data.data.value;
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
