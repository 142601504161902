import React, { createRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import DeletePropertyModal from '../Dashboard/Modals/DeletePropertyModal';
import InvalidCSVModal from '../Dashboard/Modals/InvalidCSVModal';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';
import View from './Components/View';
import { ViewModes } from '../../../utils/constants';
import GridView from './Components/GridView';
import ListView from './Components/ListView';
import { apiGetUserPortfolios } from '../../../api/portfolio';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as HeartSolid } from 'icons/custom/heart-solid.svg';
import { ReactComponent as HeartRegular } from 'icons/custom/heart-regular.svg';
import InfoAsterisk from '../Dashboard/Components/InfoAsterisk';
import ValidCSVModal from './Components/ValidCSVModal';
import UploadCsvModal from './Components/UploadCsvModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Properties = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInvalidSCVModal, setShowInvalidSCVModal] = useState(false);
  const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);

  const [showValidCSVModal, setShowValidCSVModal] = useState(false);
  const [propertiesFromCsv, setPropertiesFromCsv] = useState([]);
  const [propertyToDelete, setPropertyToDelete] = useState('');
  const [deletedPropertyIds, setDeletedPropertyIds] = useState([]);
  const [viewMode, setViewMode] = useState(ViewModes.GRID);
  const [favouritesfilterActive, setFavouritesFilterActive] = useState(false);

  const fileRef = createRef();
  const queryClient = useQueryClient();

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appraisal = queryParams.get('appraisal');

  const csvPropertyIds = location?.state?.csvPropertyIds;

  useEffect(() => {
    if (csvPropertyIds?.length) {
      setPropertiesFromCsv(csvPropertyIds);
      setShowValidCSVModal(true);
      history.replace('/properties', { csvPropertyIds: [] });
    }
  }, [csvPropertyIds, csvPropertyIds?.length, history]);

  useEffect(() => {
    setViewMode(ViewModes.GRID);
  }, [appraisal]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: portfolios = [], isLoading: portfoliosLoading } = useQuery(['portfolios'], ({ signal }) =>
    apiGetUserPortfolios(signal),
  );

  const createPropertiesFromFile = (properties) => {
    const propertyIds = properties.map((property) => property.id);
    setPropertiesFromCsv(propertyIds);
    setShowValidCSVModal(true);
    queryClient.invalidateQueries(['dashboardData']);
    queryClient.invalidateQueries(['rawProperties']);
    queryClient.invalidateQueries(['rawProperties', 'in-progress']);
    const queryState = queryClient.getQueryState(['properties']);
    if (queryState) {
      queryClient.refetchQueries(['properties']);
    }
  };

  const readPropertiesFile = (event) => {
    const [theFile] = event.target.files;
    createPropertiesFromFile({ attachment: theFile });
  };

  const handleClickDelete = (propertyId) => {
    setShowDeleteModal(true);
    setPropertyToDelete(propertyId);
  };

  const handleRetryCSVUplooad = () => {
    setShowInvalidSCVModal(false);
    setShowUploadCsvModal(true);
  };

  const changeViewMode = (viewMode) => {
    setViewMode(viewMode);
    setDeletedPropertyIds([]);
  };

  const handleDeletedProperty = (propertyId) => {
    queryClient.invalidateQueries(['dashboardData']);
    queryClient.invalidateQueries(['rawProperties']);
    queryClient.invalidateQueries(['rawProperties', 'in-progress']);
    const queryState = queryClient.getQueryState(['properties']);
    if (queryState) {
      queryClient.refetchQueries(['properties']);
    }
    setDeletedPropertyIds((prevIds) => [...prevIds, propertyId]);
  };

  return (
    <>
      <DeletePropertyModal
        setShowModal={setShowDeleteModal}
        showModal={showDeleteModal}
        propertyId={propertyToDelete}
        handleSuccess={(propertyId) => handleDeletedProperty(propertyId)}
      />
      <InvalidCSVModal
        setShowModal={setShowInvalidSCVModal}
        showModal={showInvalidSCVModal}
        handleRetry={handleRetryCSVUplooad}
      />

      <UploadCsvModal
        setShowModal={setShowUploadCsvModal}
        showModal={showUploadCsvModal}
        createPropertiesFromFile={createPropertiesFromFile}
        setShowInvalidSCVModal={setShowInvalidSCVModal}
        setShowValidCSVModal={setShowValidCSVModal}
      />

      <ValidCSVModal
        setShowModal={setShowValidCSVModal}
        showModal={showValidCSVModal}
        newProperties={propertiesFromCsv}
        portfolios={portfolios}
      />

      <input
        style={{ display: 'none' }}
        onChange={(e) => readPropertiesFile(e)}
        onClick={(e) => (e.target.value = null)}
        type="file"
        accept=".csv"
        ref={fileRef}
        id="properties-file"
      />

      <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head justify-between">
        {appraisal ? (
          <div></div>
        ) : (
          <div className="flex">
            <Link to={`/new-property/`}>
              <PrimaryButton className={'mr-2 px-3 w-auto'}>+ New Property</PrimaryButton>
            </Link>
            <PrimaryButton className={'px-3 w-auto'} onClick={() => setShowUploadCsvModal(true)}>
              CSV upload
            </PrimaryButton>
          </div>
        )}

        <div className="d-flex flex-wrap justify-between align-items-center">
          {viewMode === ViewModes.LIST ? (
            <div className="mr-5 cursor-pointer" onClick={(e) => setFavouritesFilterActive(!favouritesfilterActive)}>
              {favouritesfilterActive ? (
                <InfoAsterisk text={`Show all`} placement="left" icon={<HeartSolid className="w-5" />} />
              ) : (
                <InfoAsterisk text={`Show favourites only`} placement="left" icon={<HeartRegular className="w-5" />} />
              )}
            </div>
          ) : (
            ''
          )}

          <div
            className={`h-10 px-2 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer`}
            style={{
              backgroundColor: '#FFFFFF',
              border: '1px solid rgba(53, 113, 229, 0.6)',
            }}
          >
            <div
              className="cursor-pointer mx-1 p-1 hover:opacity-50"
              style={{ color: `${viewMode === ViewModes.GRID ? '#3571E5' : ''}` }}
              onClick={() => changeViewMode(ViewModes.GRID)}
            >
              <i className="fa fa-th"></i>
            </div>
            <div
              className="cursor-pointer mx-1 p-1 hover:opacity-50"
              style={{ color: `${viewMode === ViewModes.LIST ? '#3571E5' : ''}` }}
              onClick={() => changeViewMode(ViewModes.LIST)}
            >
              <i className="fa fa-list"></i>
            </div>
          </div>
        </div>
      </div>

      {portfoliosLoading ? (
        <LoaderSvg className="animate-spin h-16 w-16 mx-auto mt-16 text-white" />
      ) : (
        <View
          currentMode={viewMode}
          renderers={{
            [ViewModes.GRID]: () => (
              <GridView
                handleClickDelete={(data) => handleClickDelete(data)}
                deletedPropertyIds={deletedPropertyIds}
                appraisal={appraisal}
              />
            ),
            [ViewModes.LIST]: () => (
              <ListView
                handleClickDelete={(data) => handleClickDelete(data)}
                portfolios={portfolios}
                appraisal={appraisal}
                deletedPropertyIds={deletedPropertyIds}
                favouritesfilterActive={favouritesfilterActive}
              />
            ),
          }}
        />
      )}
    </>
  );
};

export default Properties;
