import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as CheckSVG } from 'icons/custom/check-circle.svg';

import moment from 'moment';

const formatData = (originalData) => {
  const formattedData = [];

  const dataByYearMonth = originalData.reduce((acc, entry) => {
    const date = moment(entry.timestamp);
    const year = date.year();
    const month = date.month();
    if (!acc[year]) {
      acc[year] = {};
    }
    acc[year][month] = entry;
    return acc;
  }, {});

  for (const year in dataByYearMonth) {
    const yearData = [];
    for (let month = 0; month < 12; month++) {
      const entry = dataByYearMonth[year][month]
        ? { ...dataByYearMonth[year][month], inputValue: Math.floor(parseFloat(dataByYearMonth[year][month].value)) }
        : {
            timestamp: moment({ year, month, day: 1 }).format('YYYY-MM-DD'),
            value: null,
            omitPrams: true,
          };
      yearData.push(entry);
    }
    formattedData.push(yearData);
  }

  return formattedData;
};

const reverseFormatData = (formattedData) => {
  const originalData = {};

  formattedData.forEach((yearData) => {
    yearData.forEach((monthData) => {
      if (monthData.value !== null) {
        originalData[monthData.timestamp] = monthData.value ? monthData.value : 0;
      }
    });
  });

  return originalData;
};

const OverrideRentsModal = ({ showModal, setShowModal, handleSuccess, overriddenData }) => {
  const [editedData, setEditedData] = useState([]);
  const [activeInput, setActiveInput] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleSubmit = () => {
    const originalData = reverseFormatData(editedData);
    handleSuccess(originalData);
    setShowModal(false);
  };

  const handleInputChange = (yearIndex, monthIndex, value) => {
    const updatedData = [...editedData];
    updatedData[yearIndex][monthIndex].inputValue = parseFloat(value);
    setEditedData(updatedData);
    setActiveInput({ yearIndex, monthIndex });
  };

  const handleButtonClick = (yearIndex, monthIndex) => {
    const updatedData = [...editedData];
    const enteredValue = parseFloat(updatedData[yearIndex][monthIndex].inputValue);
    for (let currentMonthIndex = monthIndex; currentMonthIndex < 12; currentMonthIndex++) {
      updatedData[yearIndex][currentMonthIndex].value = enteredValue;
      updatedData[yearIndex][currentMonthIndex].inputValue = enteredValue;
    }
    setEditedData(updatedData);
    setButtonClicked(true);
    setActiveInput(null);
  };

  const handleInputBlur = (yearIndex, monthIndex) => {
    setTimeout(() => {
      if (!buttonClicked) {
        const updatedData = [...editedData];
        updatedData[yearIndex][monthIndex].inputValue = updatedData[yearIndex][monthIndex].value;
        setEditedData(updatedData);
        setActiveInput(null);
      }
      setButtonClicked(false);
    }, 200);
  };

  const handleInputKeyDown = (e, yearIndex, monthIndex) => {
    if (e.key === 'Enter') {
      if (editedData[yearIndex][monthIndex].inputValue) {
        handleButtonClick(yearIndex, monthIndex);
      }
    }
  };

  useEffect(() => {
    const data = overriddenData.map((a) => ({ ...a }));
    setEditedData(formatData(data));
    setActiveInput(null);
  }, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="modal"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="xl"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-2xl font-medium">Override Rents</div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
        <div>
          <table className="table-fixed border m-4 text-xs font-medium">
            <thead>
              <tr>
                <td className="border w-20 h-10 py-1 px-2"></td>
                <td className="border w-20 h-10 py-1 px-2">January</td>
                <td className="border w-20 h-10 py-1 px-2">February</td>
                <td className="border w-20 h-10 py-1 px-2">March</td>
                <td className="border w-20 h-10 py-1 px-2">April</td>
                <td className="border w-20 h-10 py-1 px-2">May</td>
                <td className="border w-20 h-10 py-1 px-2">June</td>
                <td className="border w-20 h-10 py-1 px-2">July</td>
                <td className="border w-20 h-10 py-1 px-2">August</td>
                <td className="border w-20 h-10 py-1 px-2">September</td>
                <td className="border w-20 h-10 py-1 px-2">October</td>
                <td className="border w-20 h-10 py-1 px-2">November</td>
                <td className="border w-20 h-10 py-1 px-2">December</td>
              </tr>
            </thead>
            <tbody>
              {editedData &&
                editedData.map((yearData, yearIndex) => (
                  <tr key={yearIndex}>
                    <td className="w-20 py-1 px-2 border">{yearData[0].timestamp.substring(0, 4)}</td>
                    {yearData.map((monthData, monthIndex) => (
                      <td key={monthIndex} className="border p-1.5">
                        {monthData?.omitPrams ? (
                          ''
                        ) : (
                          <div className="flex relative">
                            <span className="absolute inset-y-0 left-0 pl-1 flex items-center text-black">£</span>
                            <input
                              className="w-12 py-1 pl-3 pr-1 rounded-sm focus:ring-1 focus:ring-blue-600 text-black focus:outline-none focus:text-gray number-input"
                              type="number"
                              value={Number(monthData.inputValue).toString()}
                              onChange={(e) => handleInputChange(yearIndex, monthIndex, e.target.value)}
                              onKeyDown={(e) => handleInputKeyDown(e, yearIndex, monthIndex)}
                              onBlur={() => handleInputBlur(yearIndex, monthIndex)}
                            />
                            <button
                              className={`w-4 ml-1 ${
                                activeInput?.yearIndex === yearIndex && activeInput?.monthIndex === monthIndex
                                  ? 'visible'
                                  : 'invisible'
                              }`}
                              onClick={() => handleButtonClick(yearIndex, monthIndex)}
                            >
                              <CheckSVG className="w-3 h-3" />
                            </button>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row w-full justify-between px-4 mb-4">
          <PrimaryButton onClick={() => handleSubmit()}>Confirm</PrimaryButton>
          <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default OverrideRentsModal;
