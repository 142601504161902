import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import axios from 'axios';
import 'numeral/locales/en-gb';
import { DebounceInput } from 'react-debounce-input';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import {
  customSelectStyles,
  numberOfBedroomsOptionsWithAll,
  propertyTypeOptionsWithAll,
} from '../../../../utils/constants';
import { searchCityIndices } from '../../../../api/cityIndex';

const DetailedCityIndexSearchModal = ({ showModal, setShowModal, handleSuccess }) => {
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [superIndices, setSuperIndices] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedNumberOfBedrooms, setSelectedNumberOfBedrooms] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);

  const handleFilterFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const formData = { ...filterData };
    const fieldValueTrimmed = fieldValue.trim();
    if (fieldValueTrimmed !== filterData[fieldName]?.trim()) {
      formData[fieldName] = fieldValue;
      updateFilter(formData);
    }
  };

  const handleDropdownFilterFormChange = (selectedItems, selectField) => {
    const fieldName = selectField;
    let fieldValue = [];

    if (selectedItems.some((item) => item.value === 'all')) {
      if (selectField === 'num_of_bedrooms') {
        setSelectedNumberOfBedrooms(numberOfBedroomsOptionsWithAll.slice(1));
        fieldValue = numberOfBedroomsOptionsWithAll.slice(1).map((item) => parseInt(item.value));
      } else {
        setSelectedPropertyTypes(propertyTypeOptionsWithAll.slice(1));
        fieldValue = propertyTypeOptionsWithAll.slice(1).map((item) => item.value);
      }
    } else {
      selectField === 'num_of_bedrooms'
        ? setSelectedNumberOfBedrooms(selectedItems)
        : setSelectedPropertyTypes(selectedItems);

      fieldValue = selectedItems.map((item) => (selectField === 'num_of_bedrooms' ? parseInt(item.value) : item.value));
    }

    const formData = { ...filterData };
    formData[fieldName] = fieldValue;
    updateFilter(formData);
  };

  const updateFilter = (formData) => {
    setSuperIndices([]);
    setSelectedIndices([]);
    setSelectAll(false);
    setFilterData(formData);
    debouncedChangeHandler(formData);
  };

  let cancelToken;
  const getSearchData = async (filter) => {
    setLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      const searchResponse = await searchCityIndices(
        {
          offset: 0,
          limit: 24,
          property_type: filter?.property_type ? filter?.property_type : undefined,
          num_of_bedrooms: filter?.num_of_bedrooms ? filter?.num_of_bedrooms : undefined,
          post_town: filter?.post_town?.toUpperCase(),
        },
        { cancelToken: cancelToken.token },
      );

      if (searchResponse && searchResponse.data) {
        const searchResponseData = searchResponse?.data?.data;
        searchResponseData.sort((a, b) => a.index_name.localeCompare(b.index_name));

        setSuperIndices(searchResponseData);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleCheckboxEvent = (superIndex) => {
    if (selectedIndices.includes(superIndex.index_name)) {
      setSelectedIndices(selectedIndices.filter((index) => index !== superIndex.index_name));
      setSelectAll(false);
    } else {
      setSelectedIndices([...selectedIndices, superIndex.index_name]);
      if (selectedIndices.length + 1 === superIndices.length) {
        setSelectAll(true);
      }
    }
  };

  const handleSelectAll = () => {
    const allSelected = !selectAll;
    setSelectAll(allSelected);
    if (allSelected) {
      setSelectedIndices(superIndices.map((superIndex) => superIndex.index_name));
    } else {
      setSelectedIndices([]);
    }
  };

  const debouncedChangeHandler = useCallback(debounce(getSearchData, 200), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickSave = () => {
    const finalArray = superIndices.filter((array) => selectedIndices.some((filter) => filter === array.index_name));
    handleSuccess && handleSuccess(finalArray);
    setShowModal(false);
  };

  useEffect(() => {
    setFilterData({});
    setSuperIndices([]);
    setSelectedIndices([]);
    setSelectedNumberOfBedrooms([]);
    setSelectedPropertyTypes([]);
    setSelectAll(false);
  }, [showModal]);

  return (
    <Modal className="fade" show={showModal} onHide={setShowModal} size="lg" centered enforceFocus={false}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20"> Search Filters </h4>
              <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              <div>
                <div className="flex w-full flex-row pb-2">
                  <div className="form-group mb-2 mx-2 w-full flex flex-col">
                    <label className="font-medium text-black text-xs"> Post Town </label>
                    <DebounceInput
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="Location"
                      onChange={handleFilterFormChange}
                      value={filterData.post_town || ''}
                      debounceTimeout={500}
                      name="post_town"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-row pb-2">
                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
                    <Select
                      className="text-xs min-h-10"
                      placeholder="Select"
                      isMulti
                      styles={customSelectStyles}
                      onChange={(item) => handleDropdownFilterFormChange(item, 'num_of_bedrooms')}
                      options={numberOfBedroomsOptionsWithAll}
                      value={selectedNumberOfBedrooms}
                      isSearchable={false}
                      name="num_of_bedrooms"
                    />
                  </div>

                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Property Type </label>
                    <Select
                      className="text-xs min-h-10"
                      placeholder="Select"
                      isMulti
                      styles={customSelectStyles}
                      onChange={(item) => handleDropdownFilterFormChange(item, 'property_type')}
                      options={propertyTypeOptionsWithAll}
                      value={selectedPropertyTypes}
                      isSearchable={true}
                      name="property_type"
                    />
                  </div>
                </div>

                <div className="form-group mb-2 mx-2 w-8 flex flex-col">
                  {loading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
                </div>

                {superIndices?.length > 0 && (
                  <div className="p-2">
                    <div className="border-b mb-3">
                      <div className="fs-16 text-black my-2"> Select Preferred Result </div>
                      <div className="flex justify-start align-center pb-3">
                        <input type="checkbox" id="select-all" onChange={() => handleSelectAll()} checked={selectAll} />
                        <label className="mb-0 ml-4" htmlFor="select-all">
                          Select All
                        </label>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                      <div className="grid grid-cols-1 gap-x-4 gap-y-2">
                        {superIndices.slice(0, Math.ceil(superIndices.length / 2)).map((superIndex, index) => (
                          <div key={index} className="flex justify-start align-center">
                            <input
                              type="checkbox"
                              id={superIndex.index_name}
                              onChange={(event) => {
                                handleCheckboxEvent(superIndex);
                              }}
                              checked={selectedIndices?.includes(superIndex.index_name)}
                            />
                            <label className="mb-0 ml-4" htmlFor={superIndex.index_name}>
                              {`${superIndex.post_town}
                              ${superIndex?.property_type && superIndex.property_type !== 'nan' ? ', Type: ' + superIndex?.property_type : ''}
                              ${
                                superIndex?.number_of_bedrooms && superIndex.number_of_bedrooms !== 'NaN'
                                  ? ', Rooms: ' + superIndex?.number_of_bedrooms
                                  : ''
                              }`}
                            </label>
                          </div>
                        ))}
                      </div>

                      <div className="grid grid-cols-1 gap-x-4 gap-y-2">
                        {superIndices.slice(Math.ceil(superIndices.length / 2)).map((superIndex, index) => (
                          <div key={index} className="flex justify-start align-center">
                            <input
                              type="checkbox"
                              id={superIndex.index_name}
                              onChange={(event) => {
                                handleCheckboxEvent(superIndex);
                              }}
                              checked={selectedIndices?.includes(superIndex.index_name)}
                            />
                            <label className="mb-0 ml-4" htmlFor={superIndex.index_name}>
                              {`${superIndex.post_town},
                              ${superIndex?.property_type && superIndex.property_type !== 'nan' ? ' Type: ' + superIndex?.property_type : ''}
                              ${
                                superIndex?.number_of_bedrooms && superIndex.number_of_bedrooms !== 'NaN'
                                  ? ', Rooms: ' + superIndex?.number_of_bedrooms
                                  : ''
                              }`}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="flex w-100 justify-end">
                <div className="flex">
                  <SecondaryButton className="m-1" onClick={() => setShowModal(false)} type="button">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    className="m-1"
                    onClick={() => handleClickSave()}
                    type="button"
                    disabled={!selectedIndices.length}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DetailedCityIndexSearchModal;
