import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const keyMapping = {
  Studio: ['Flat', 'Studio'],
  '1 Bed Flat': ['Flat', '1 Bed'],
  '2 Bed Flat': ['Flat', '2 Bed'],
  '3+ Bed Flat': ['Flat', '3 Bed'],
  '2 Bed House': ['House', '2 Bed'],
  '3 Bed House': ['House', '3 Bed'],
  '4+ Bed House': ['House', '4 Bed'],
};

const quartileKeyMapping = {
  'Lower Quartile': 'lowerQuartile',
  'Median Quartile': 'medianQuartile',
  'Upper Quartile': 'upperQuartile',
};

const resultMapping = {
  'Lower Quartile': { district: {}, area: {} },
  'Median Quartile': { district: {}, area: {} },
  'Upper Quartile': { district: {}, area: {} },
};

const quartileOptions = Object.keys(resultMapping);

const RentsPaidChartWrapper = ({ combinedData, property }) => {
  for (const [newKey, [category, subCategory]] of Object.entries(keyMapping)) {
    for (const [quartile, quartileKey] of Object.entries(quartileKeyMapping)) {
      resultMapping[quartile].district[newKey] = combinedData[category][subCategory][quartileKey].district;
      resultMapping[quartile].area[newKey] = combinedData[category][subCategory][quartileKey].area;
    }
  }

  const categories = Object.keys(keyMapping);

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: { show: true },
      animations: { enabled: false },
    },
    fill: {
      type: 'solid',
      colors: ['#C3D4F7', '#FFE2E7'],
    },
    dataLabels: { enabled: false },
    legend: { show: true, showForSingleSeries: true },
    stroke: { width: 1, colors: ['#3571E5', '#FF4560'], show: true },
    grid: {
      show: true,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    xaxis: {
      categories,
      labels: { rotate: -90, rotateAlways: true },
    },
    yaxis: {
      labels: {
        formatter: (val) => numeral(val).format(),
      },
      forceNiceScale: true,
    },
    colors: ['#3571E5', '#FF4560'],
  };

  return (
    <>
      {quartileOptions.map((quartile) => {
        const series = [
          {
            name: `District (${property?.property_index_data?.district_code})`,
            data: Object.values(resultMapping[quartile].district),
          },
          {
            name: `Area (${property?.property_index_data?.area_code})`,
            data: Object.values(resultMapping[quartile].area),
          },
        ];
        return (
          <div key={quartile} className="bar-chart base-bar-chart pb-3 w-full">
            <h2 className="font-medium text-black text-lg mb-2">{quartile}</h2>
            <ReactApexChart options={options} series={series} type="bar" height={350} />
          </div>
        );
      })}
    </>
  );
};

export default RentsPaidChartWrapper;
