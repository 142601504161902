import React from 'react';
import Card from './Card';

const Dashboard = ({ targetTime }) => {
  return (
    <>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Sales </h4>

      <div className="row">
        <div className="col-xl-4 col-sm-6 col-xs-12">
          <Card targetTime={targetTime} index={'UK'} />
        </div>
        <div className="col-xl-4 col-sm-6 col-xs-12">
          <Card targetTime={targetTime} index={'LONDON'} />
        </div>
        <div className="col-xl-4 col-sm-6 col-xs-12">
          <Card targetTime={targetTime} index={'MANCHESTER'} />
        </div>
        <div className="col-xl-4 col-sm-6 col-xs-12">
          <Card targetTime={targetTime} index={'BIRMINGHAM'} />
        </div>
        <div className="col-xl-4 col-sm-6 col-xs-12">
          <Card targetTime={targetTime} index={'GLASGOW'} />
        </div>
        <div className="col-xl-4 col-sm-6 col-xs-12">
          <Card targetTime={targetTime} index={'NEWCASTLE'} />
        </div>
      </div>
    </>
  );
};

export default React.memo(Dashboard);
