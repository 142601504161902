import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import moment from 'moment';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

/* eslint-disable no-extend-native */
Array.prototype.first = function () {
  return this[0];
};
Array.prototype.second = function () {
  return this[1];
};
/* eslint-enable no-extend-native */

const PropertyVsCPIHChart = (props) => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [yAxisConfig, setYaxisConfig] = useState([]);

  useEffect(() => {
    const propertyGraphSeriesData = props.propertyGraphSeriesData;
    const indexData = props.indexGraphSeriesData
      .sort((a, b) => {
        const da = new Date(a.timestamp);
        const db = new Date(b.timestamp);
        return da - db;
      })
      .filter((item) => {
        return moment(item.timestamp).diff(moment(props.targetTime)) <= 0;
      });

    const filledIndexData = [...propertyGraphSeriesData];
    while (filledIndexData.length < indexData.length) {
      filledIndexData.push({ timestamp: null, value: null });
    }

    const reducedData = indexData.filter((data) => {
      return propertyGraphSeriesData.some((propertyData) => {
        return propertyData.timestamp === data.timestamp;
      });
    });

    const filledReducedData = [...reducedData];
    while (filledReducedData.length < propertyGraphSeriesData.length) {
      filledReducedData.push({ timestamp: null, value: null });
    }

    const series = [
      {
        name: props.address,
        data: propertyGraphSeriesData.map((eachValue) => (eachValue?.value ? parseInt(eachValue.value) : null)),
      },
      {
        name: props.index,
        data: filledReducedData.map((eachValue) => (eachValue?.value ? parseFloat(eachValue.value).toFixed(2) : null)),
      },
    ];

    const categories = propertyGraphSeriesData?.length ? propertyGraphSeriesData.map((item) => item.timestamp) : [];

    const propertyMinValue = propertyGraphSeriesData.length
      ? Math.min(...propertyGraphSeriesData.map((item) => item?.value))
      : null;
    const propertyMaxValue = propertyGraphSeriesData.length
      ? Math.max(...propertyGraphSeriesData.map((item) => item?.value))
      : null;
    const propertyRange = propertyMaxValue - propertyMinValue;
    const propertyAdjustedMin = propertyMinValue !== null ? propertyMinValue - propertyRange * 0.05 : null;
    const propertyAdjustedMax = propertyMaxValue !== null ? propertyMaxValue + propertyRange * 0.05 : null;

    const indexMinValue = reducedData.length ? Math.min(...reducedData.map((item) => item?.value)) : null;
    const indexMaxValue = reducedData.length ? Math.max(...reducedData.map((item) => item?.value)) : null;
    const indexRange = indexMaxValue - indexMinValue;
    const indexAdjustedMin = indexMinValue !== null ? indexMinValue - indexRange * 0.05 : null;
    const indexAdjustedMax = indexMaxValue !== null ? indexMaxValue + indexRange * 0.05 : null;

    const yAxisConfig = [
      {
        title: {
          text: props.address,
        },
        showAlways: true,
        min: propertyAdjustedMin,
        max: propertyAdjustedMax,
        labels: {
          formatter: function (val, index) {
            return val == null ? ' ' : numeral(val == null ? ' ' : parseInt(val.toString())).format();
          },
        },
      },
      {
        opposite: true,
        showAlways: true,
        min: indexAdjustedMin,
        max: indexAdjustedMax,
        title: {
          text: props.index,
        },
      },
    ];

    setSeries(series);
    setCategories(categories);
    setYaxisConfig(yAxisConfig);
  }, [props.address, props.index, props.indexGraphSeriesData, props.propertyGraphSeriesData, props.targetTime]);

  const options = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: true,
      },
      animations: {
        enabled: false,
      },
      events: {},
    },
    forecastDataPoints: {
      count: 23,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'text',
      tickAmount: 30,
      categories: categories,
      labels: {
        rotate: -90,
        rotateAlways: true,
        show: true,
      },
      lines: {
        show: false,
      },
    },
    yaxis: yAxisConfig,
    colors: ['#3571E5', '#747B8A', '#705fd3'],
  };

  return (
    <div id="chart2" className="bar-chart pb-3">
      <ReactApexChart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default PropertyVsCPIHChart;
