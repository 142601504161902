import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function InfoAsterisk({ text, icon, color = 'text-blue-500', placement = 'top' }) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip className={color}>
          <div className="text-center"> {text} </div>
        </Tooltip>
      }
    >
      {icon ? (
        icon
      ) : (
        <span className={`inline-block px-1 cursor-pointer ${color}`}>
          <i className="fa fa-info-circle" aria-hidden="true"></i>
        </span>
      )}
    </OverlayTrigger>
  );
}

export default InfoAsterisk;
