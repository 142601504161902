import { GoogleMap, MarkerF, InfoWindowF, OverlayViewF, OverlayView } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import activeIcon from 'icons/custom/active-marker-new.svg';
import inactiveIcon from 'icons/custom/inactive-marker-new.svg';
import mainHomeIcon from 'icons/custom/home-marker.svg';
import favouriteMarker from 'icons/custom/fav-pin-new.svg';

import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { getMapCarouselProps } from '../../../utils/helpers';
import { Carousel } from 'react-responsive-carousel';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const ImageCarousel = ({ images }) => {
  return (
    <Carousel {...getMapCarouselProps({ showThumbs: false })}>
      {images?.map((image, index) => {
        return (
          <div key={index} className="h-52">
            <img
              src={image}
              alt="carousel"
              className="h-full object-cover"
              style={{ maxHeight: 'calc(100vh - 100px)' }}
            />
          </div>
        );
      })}
    </Carousel>
  );
};

const CustomMarkerInfo = ({ property, toggleMarker, filteredIds, selectedPropertyIds, loading, listingMode }) => {
  return (
    <div className="w-80">
      <div className="flex mb-2 align-center">
        <input
          type="checkbox"
          onChange={() => toggleMarker(listingMode ? property.property_id : property.id)}
          id={listingMode ? property.property_id : property.id}
          className="mr-2"
          checked={
            listingMode ? selectedPropertyIds.includes(property.property_id) : !filteredIds?.includes(property.id)
          }
        />
        <label
          htmlFor={listingMode ? property.property_id : property.id}
          className="fs-12 font-medium mb-0 mr-3"
          style={{ color: '#3571E5' }}
        >
          {listingMode ? 'Select for Valuation' : 'Add to Comparables'}
        </label>
        {loading && <LoaderSvg className="animate-spin h-4 w-4 text-white" />}
      </div>
      <ImageCarousel
        images={
          listingMode
            ? [...(property?.images || []), ...(property?.floorplans || [])]
            : [...(property?.property_images || []), ...(property?.floor_plans || [])]
        }
      />
      <h3 className="text-black font-medium fs-16 truncate mt-2">{property?.address}</h3>
      <div className="grid gap-x-4 gap-y-2 my-2 grid-cols-4 grid-rows-3 font-normal text-xs">
        <div> Size: </div>
        <div className="text-black font-medium"> {property?.size ? property?.size : '-'} </div>
        <div> Bathrooms: </div>
        <div className="text-black font-medium">{property?.bathrooms ? parseInt(property?.bathrooms) : '-'}</div>
        <div> Price: </div>
        <div className="text-black font-medium">{property?.price ? numeral(property?.price).format() : '-'}</div>
        <div> Tenure: </div>
        <div className="text-black font-medium">
          {property?.freehold_leasehold ? (property?.freehold_leasehold === 'F' ? 'Freehold' : 'Leasehold') : '-'}
        </div>
        <div> New build: </div>
        <div className="text-black font-medium">
          {property?.old_new_lr ? (property?.old_new_lr === 'Y' ? 'Yes' : 'No') : '-'}
        </div>
        <div> EPC rating: </div>
        <div className="text-black font-medium">
          {property?.current_energy_rating_epc && property?.current_energy_rating_epc !== 'None'
            ? property?.current_energy_rating_epc
            : '-'}
        </div>
      </div>
    </div>
  );
};

const MapView = ({
  mainProperty,
  properties,
  optimalComparables = [],
  filteredIds,
  toggleProperty,
  loading,
  selectedProperties = [],
  listingMode = false,
  mapFavouritesActive = false,
  mapCompsActive = true,
}) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeProperty, setActiveProperty] = useState(null);
  const [map, setMap] = useState(null);
  const [updatedMarker, setUpdatedMarker] = useState(null);
  const selectedPropertyIds = selectedProperties.map((item) => item.property_id);

  const onLoad = useCallback((map) => {
    setMap(map);
    recalculateBounds(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recalculateBounds = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();

      const propertyData = [
        mainProperty,
        ...(mapFavouritesActive ? optimalComparables : []),
        ...(mapCompsActive ? properties : []),
      ];

      for (let i = 0; i < propertyData.length; i++) {
        const marker = propertyData[i];

        if (marker?.latitude && marker?.longitude) {
          const newPoint = new window.google.maps.LatLng(marker.latitude, marker.longitude);
          bounds.extend(newPoint);
        }
      }

      map.fitBounds(bounds);
      if (map.getZoom() > 17) {
        map.setZoom(17);
      }
    },
    [mainProperty, mapFavouritesActive, mapCompsActive, optimalComparables, properties],
  );

  const handleActiveMarker = (property) => {
    if (listingMode) {
      if (property.property_id === activeMarker) {
        return;
      }
      setActiveProperty(property);
      setActiveMarker(property.property_id);
    } else {
      if (property.id === activeMarker) {
        return;
      }
      setActiveProperty(property);
      setActiveMarker(property.id);
    }
  };

  const toggleMarker = (propertyId) => {
    setUpdatedMarker(propertyId);
    toggleProperty(propertyId);
  };

  useEffect(() => {
    if (map) {
      const foundProperty = listingMode
        ? properties.find((item) => item.property_id === updatedMarker)
        : properties.find((item) => item.id === updatedMarker);
      if (!foundProperty) {
        setUpdatedMarker(null);
        setActiveMarker(null);
        onLoad(map);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingMode, properties, updatedMarker]);

  useEffect(() => {
    if (map) {
      recalculateBounds(map);
    }
  }, [map, mapFavouritesActive, mapCompsActive, optimalComparables, properties, recalculateBounds]);

  return (
    <div className="mt-4">
      {properties?.length ? (
        <GoogleMap
          mapContainerClassName="map-container w-100"
          mapContainerStyle={{
            height: '550px',
          }}
          onLoad={onLoad}
        >
          <MarkerF
            position={{ lat: parseFloat(mainProperty?.latitude), lng: parseFloat(mainProperty?.longitude) }}
            options={{ icon: mainHomeIcon }}
          ></MarkerF>

          {mapFavouritesActive &&
            optimalComparables?.map((property, index) => (
              <div key={index}>
                <MarkerF
                  position={{ lat: parseFloat(property?.latitude), lng: parseFloat(property?.longitude) }}
                  onClick={() => handleActiveMarker(property)}
                  options={{ icon: favouriteMarker }}
                ></MarkerF>
                <OverlayViewF
                  position={{ lat: parseFloat(property?.latitude), lng: parseFloat(property?.longitude) }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2 - 45),
                    y: -(height / 2 + 20),
                  })}
                >
                  <div
                    className="bg-white py-1.5 px-1.5 relative"
                    style={{
                      boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.1)',
                      borderRadius: '3px',
                      color: 'rgba(53, 113, 229, 1)',
                    }}
                  >
                    <div
                      className="absolute -left-2 -top-4 w-6 h-6 rounded font-bold fs-16 flex items-center justify-center text-white border border-white"
                      style={{ backgroundColor: 'rgba(53, 113, 229, 1)' }}
                    >
                      {index + 1}
                    </div>
                    {property?.price ? numeral(property?.price).format() : numeral(property?.price_lr).format()}
                  </div>
                </OverlayViewF>
              </div>
            ))}

          {mapCompsActive &&
            properties.map((property, index) => (
              <div key={index}>
                <MarkerF
                  position={{ lat: parseFloat(property?.latitude), lng: parseFloat(property?.longitude) }}
                  onClick={() => handleActiveMarker(property)}
                  options={{
                    icon: listingMode
                      ? selectedPropertyIds?.includes(property.property_id)
                        ? activeIcon
                        : inactiveIcon
                      : filteredIds?.includes(property.id)
                        ? inactiveIcon
                        : activeIcon,
                  }}
                ></MarkerF>
                <OverlayViewF
                  position={{ lat: parseFloat(property?.latitude), lng: parseFloat(property?.longitude) }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2 - 45),
                    y: -(height / 2 + 20),
                  })}
                >
                  <div
                    className="bg-white py-1.5 px-1.5 relative"
                    style={{
                      boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.1)',
                      borderRadius: '3px',
                      color: 'rgba(53, 113, 229, 1)',
                    }}
                  >
                    {property?.price ? numeral(property?.price).format() : numeral(property?.price_lr).format()}
                  </div>
                </OverlayViewF>
              </div>
            ))}

          {activeMarker && (
            <InfoWindowF
              onCloseClick={() => setActiveMarker(null)}
              position={{ lat: parseFloat(activeProperty?.latitude), lng: parseFloat(activeProperty?.longitude) }}
              options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
            >
              <CustomMarkerInfo
                property={activeProperty}
                toggleMarker={toggleMarker}
                filteredIds={filteredIds}
                selectedPropertyIds={selectedPropertyIds}
                loading={loading}
                listingMode={listingMode}
              />
            </InfoWindowF>
          )}
        </GoogleMap>
      ) : (
        ''
      )}
    </div>
  );
};

export default MapView;
