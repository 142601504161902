import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const getDistrictPlanningApplications = async ({ district }, signal) => {
  try {
    const response = await axiosInstance.post(
      `planning/district`,
      { district },
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data.data[0];
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
