import React from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { ReactComponent as DeleteSVG } from 'icons/custom/delete-property.svg';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';

import 'numeral/locales/en-gb';

const DeleteOptimalComparableModal = ({ showModal, setShowModal, handleSuccess, property }) => {
  const handleClickDelete = async () => {
    try {
      handleSuccess && handleSuccess(property);
      setShowModal(false);
    } catch (error) {
      setShowModal(false);
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        centered
        enforceFocus={false}
      >
        <div className="flex flex-column items-center	justify-center mt-4">
          <div className="flex w-full items-center justify-between px-4">
            <div className="text-black text-2xl font-medium">Remove favourite comparable</div>
            <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
          </div>
          <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
          <div
            className="w-12	h-12 rounded-full flex items-center	justify-center mb-4 mt-4"
            style={{ background: '#F5F8FE' }}
          >
            <DeleteSVG />
          </div>
          <div className="text-black text-base font-medium mb-8 px-4">
            Are you sure you want to remove this comparable from your favourites?
          </div>
          <div className="flex flex-row w-full justify-between px-4 mb-4 ">
            <PrimaryButton onClick={handleClickDelete}>Confirm</PrimaryButton>
            <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteOptimalComparableModal;
