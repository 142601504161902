import { StyleSheet, View, Text } from '@react-pdf/renderer';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { propertySVGs } from '../../../../utils/helpers';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  table: {
    width: '100%',
    border: 'none',
  },
  header: {
    fontSize: 12,
    marginBottom: 8,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    borderBottomStyle: 'solid',
  },
  cell: {
    flex: 1,
    padding: 4,
    fontSize: 8,
  },
});

const PdfBenchmarkTable = ({ searchTableData }) => {
  return (
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.cell}>Location</Text>
        <Text style={styles.cell}>Last 5yr Return</Text>
        <Text style={styles.cell}>Number Of Bedrooms</Text>
        <Text style={styles.cell}>Property Type</Text>
        <Text style={styles.cell}>District code</Text>
        <Text style={styles.cell}>Estimated price change for the next 12 months</Text>
        <Text style={styles.cell}>Estimated price change for the next 24 months</Text>
        <Text style={styles.cell}>Price</Text>
        <Text style={styles.cell}>Monthly Rent Price</Text>
        <Text style={styles.cell}>Rental Yield</Text>
      </View>
      {searchTableData.map((row, index) => (
        <View key={index} style={styles.row}>
          <Text style={styles.cell}>{row.post_town}</Text>
          <Text style={styles.cell}>{row.Chg_5Y}</Text>
          <Text style={styles.cell}>{row.number_of_bedrooms}</Text>
          <Text style={styles.cell}>{row.property_type ? propertySVGs[row.property_type]?.name : ''}</Text>
          <Text style={styles.cell}>{row.district}</Text>
          <Text style={styles.cell}>{row.est_chg_fy1y}</Text>
          <Text style={styles.cell}>{row.est_chg_fy2y}</Text>
          <Text style={styles.cell}>{row.price ? numeral(row.price).format() : '-'}</Text>
          <Text style={styles.cell}>{row.rental_price ? numeral(row.rental_price).format() : '-'}</Text>
          <Text style={styles.cell}>
            {row.rental_price ? numeral((row.rental_price / row.price) * 12).format('0.000%') : '-'}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default PdfBenchmarkTable;
