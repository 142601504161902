import { StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';
import { getPropertyDashboardParams } from '../Helpers/Helpers';

const styles = StyleSheet.create({
  card: {
    fontSize: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  col: {
    width: '33.3%',
    paddingHorizontal: 4,
  },
  cardData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 8,
  },
  title: {
    marginBottom: 8,
    fontSize: 10,
    color: '#737b8b',
  },
  value: {
    fontSize: 16,
    fontWeight: '900',
    color: '#000',
  },
  pdfInfo: {
    fontSize: 8,
    fontWeight: '400',
    color: '#737b8b',
  },
});

const PdfDashboardSection = ({ property, premiumValue }) => {
  const propertyDashboardParams = getPropertyDashboardParams(property, premiumValue);

  return (
    <View style={styles.card}>
      {propertyDashboardParams.map((param, index) => (
        <View key={index} style={styles.col}>
          <View style={styles.cardData}>
            <Text style={styles.title}>{param.title}</Text>
            <Text style={styles.value}>{param.value ? param.value : '-'}</Text>
            <Text style={styles.pdfInfo}>{param.pdfInfo ? param.pdfInfo : ''}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export default PdfDashboardSection;
