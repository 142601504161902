import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as SuccessSVG } from 'icons/custom/circle-check-regular.svg';

const PropertyGenerationStatusModal = ({ showModal, setShowModal, selectedProperties = 0 }) => {
  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center	justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-2xl font-medium"></div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
        <div className="p-2">
          <div
            className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
            style={{ background: '#F5F8FE' }}
          >
            <SuccessSVG className="h-16 w-16 text-white" />
          </div>
        </div>
        <div className="text-black text-center font-medium mb-12 px-4">
          Valuation data for
          {!selectedProperties
            ? ' this property '
            : ' your selected ' + (selectedProperties > 1 ? 'properties ' : 'property ')}
          is now being generated. You'll receive an email notification when it's available. This process normally takes
          1-2 minutes but may sometimes take a little longer.
        </div>
        <div className="flex flex-row w-full justify-between px-4 mb-4">
          <Link to={`/properties/`}>
            <PrimaryButton>View Properties</PrimaryButton>
          </Link>
          <SecondaryButton onClick={() => setShowModal(false)}>Close</SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default PropertyGenerationStatusModal;
