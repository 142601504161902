import { useQuery } from '@tanstack/react-query';
import { apiSearchCityExactIndex } from '../../../../api/cityIndex';
import { useMemo } from 'react';
import { seriesObjectToArray } from '../../../../utils/helpers';
import BaseChart from '../../Dashboard/Charts/BaseChart';

function TypesPriceChart({ city = 'UK', targetTime }) {
  const { data: dTypeData = [], isSuccess: dTypeDataLoaded } = useQuery([city, 'D'], ({ signal }) =>
    apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: `${city} D` }, signal),
  );
  const { data: sTypeData = [], isSuccess: sTypeDataLoaded } = useQuery([city, 'S'], ({ signal }) =>
    apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: `${city} S` }, signal),
  );
  const { data: tTypeData = [], isSuccess: tTypeDataLoaded } = useQuery([city, 'T'], ({ signal }) =>
    apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: `${city} T` }, signal),
  );
  const { data: fTypeData = [], isSuccess: fTypeDataLoaded } = useQuery([city, 'F'], ({ signal }) =>
    apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: `${city} F` }, signal),
  );

  const chartData = useMemo(() => {
    if (dTypeDataLoaded && sTypeDataLoaded && tTypeDataLoaded && fTypeDataLoaded) {
      let result = { series: [], categories: [] };

      result.series.push({
        name: `Detached`,
        data: seriesObjectToArray(dTypeData?.forecast || {}, targetTime).length
          ? seriesObjectToArray(dTypeData?.forecast || {}, targetTime).map((item) => parseInt(item.value))
          : [],
      });

      result.series.push({
        name: `Semi-Detached`,
        data: seriesObjectToArray(sTypeData?.forecast || {}, targetTime).length
          ? seriesObjectToArray(sTypeData?.forecast || {}, targetTime).map((item) => parseInt(item.value))
          : [],
      });

      result.series.push({
        name: `Terraced`,
        data: seriesObjectToArray(tTypeData?.forecast || {}, targetTime).length
          ? seriesObjectToArray(tTypeData?.forecast || {}, targetTime).map((item) => parseInt(item.value))
          : [],
      });

      result.series.push({
        name: `Flat`,
        data: seriesObjectToArray(fTypeData?.forecast || {}, targetTime).length
          ? seriesObjectToArray(fTypeData?.forecast || {}, targetTime).map((item) => parseInt(item.value))
          : [],
      });

      const categoriesData = seriesObjectToArray(dTypeData?.forecast || {}, targetTime);
      result.categories = categoriesData.map((item) => item.timestamp);

      return result;
    } else {
      return { series: [], categories: [] };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dTypeDataLoaded, sTypeDataLoaded, tTypeDataLoaded, fTypeDataLoaded, city]);

  return <BaseChart series={chartData.series} categories={chartData.categories} />;
}

export default TypesPriceChart;
