import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as IncreaseSVG } from 'icons/custom/increase.svg';
import { ReactComponent as DecreaseSVG } from 'icons/custom/decrease.svg';
import { ReactComponent as UnchangedSVG } from 'icons/custom/unchanged.svg';
import { ReactComponent as ArrowSVG } from 'icons/custom/arrow-left.svg';
import { Link } from 'react-router-dom';

import { useMemo } from 'react';
import moment from 'moment';

import numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const calculateParams = (forecast, targetTime) => {
  const targetDate = moment(targetTime).format('YYYY-MM-DD');
  const formattedLastMonth = moment(targetTime).subtract(1, 'months').format('YYYY-MM-DD');
  const formattedLastYear = moment(targetTime).subtract(12, 'months').format('YYYY-MM-DD');
  const targetValue = forecast[targetDate];
  const changeMom = targetValue / forecast[formattedLastMonth] - 1;
  const changeYoy = targetValue / forecast[formattedLastYear] - 1;

  return { targetValue, changeMom, changeYoy };
};

const InfoIndicator = ({ value, text }) => {
  return (
    <div className="d-flex align-items-center">
      {value > 0 ? (
        <IncreaseSVG className="ml-5 mr-3 mt-3 h-6" />
      ) : value < 0 ? (
        <DecreaseSVG className="ml-5 mr-3 mt-3 h-6" />
      ) : (
        <UnchangedSVG className="ml-5 mr-3 mt-3 h-6" />
      )}
      <div className="mt-1.5">
        <div
          className="text-lt font-semibold"
          style={{ color: value > 0 ? '#32D16D' : value < 0 ? '#FF4015' : '#FFA500' }}
        >
          {numeral(value).format('0.00%')}
        </div>
        <div className="font-light text-xs text-gray-500">{text}</div>
      </div>
    </div>
  );
};

const MarketInsightCard = ({ targetTime, title, isLoading, indexData }) => {
  const formattedIndexData = useMemo(() => {
    if (indexData?.forecast && targetTime) {
      return calculateParams(indexData.forecast, targetTime);
    } else {
      return {};
    }
  }, [indexData, targetTime]);

  return (
    <div className="card ">
      <div className="card-body p-3">
        <h5 className="font-normal fs-16" style={{ color: '#747B8A' }}>
          {title}
        </h5>
        <div className="d-flex align-items-center justify-between">
          <div className="card-data flex">
            <div>
              <div className="text-2xl font-semibold text-black">
                {isLoading && !formattedIndexData?.targetValue ? (
                  <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
                ) : (
                  <div> {numeral(formattedIndexData?.targetValue).format()} </div>
                )}
              </div>
            </div>
          </div>

          {!isLoading && formattedIndexData?.changeMom ? (
            <div>
              <InfoIndicator value={formattedIndexData?.changeMom} text={'Than last month'} />
              <InfoIndicator value={formattedIndexData?.changeYoy} text={'Than last Year'} />
            </div>
          ) : (
            ''
          )}
          <div className="ml-2">
            <Link className="cursor-pointer" to={`/insights/`}>
              <ArrowSVG />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketInsightCard;
