import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const apiGetUserPortfolios = async (signal, appraisal = undefined) => {
  try {
    const response = await axiosInstance.get(`portfolio?has_appraisal=${appraisal ? 'True' : undefined}`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data.sort((a, b) => b.id - a.id);
    } else {
      throw new Error('get user portfolios failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetPortfolioAnalyticsById = async (id, signal) => {
  try {
    const response = await axiosInstance.get(`portfolio/${id}/analytics`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0];
    } else {
      throw new Error('get portfolio analytics failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export function createPortfolio(data) {
  return axiosInstance.post(
    `portfolio/`,
    { has_appraisal: false, ...data },
    {
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    },
  );
}

export function deletePortfolioByID(id) {
  return axiosInstance.delete(`portfolio/${id}`, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export function updatePortfolio(id, data) {
  return axiosInstance.patch(
    `portfolio/${id}`,
    { has_appraisal: false, ...data },
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('REalysepmt-token')}`,
      },
    },
  );
}
