import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const getPaginatedUserProperties = async (params, cancelToken = {}) => {
  try {
    const response = await axiosInstance.post(`property/search`, params, {
      ...cancelToken,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserPropertiesWithLimits = async (params, cancelToken = {}) => {
  try {
    const response = await axiosInstance.get(
      `property?limit=${params.limit}&offset=${params.offset}&has_appraisal=${params.appraisal}`,
      {
        ...cancelToken,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      const properties = response.data.data.map((item) => {
        return {
          ...item,
          property_type: item?.property_index_data?.property_type?.charAt(0).toUpperCase(),
          num_rooms: item?.changed_number_of_bedrooms
            ? item?.changed_number_of_bedrooms
            : item?.property_index_data
              ? item?.property_index_data.number_of_bedrooms
              : item?.num_rooms,
        };
      });

      return { data: properties, total_count: response.data.total_count };
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetUserProperties = async (signal) => {
  try {
    const response = await axiosInstance.get(`property/`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      const properties = response.data.data.filter((item) => item && item.property_index_data);
      return properties
        .sort((a, b) => b.id - a.id)
        .map((item) => {
          return {
            ...item,
            property_type: item?.property_index_data?.property_type,
            num_rooms: item?.changed_number_of_bedrooms
              ? item?.changed_number_of_bedrooms
              : item?.property_index_data
                ? item?.property_index_data.number_of_bedrooms
                : item?.num_rooms,
          };
        });
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetRawUserProperties = async (signal, inProgress = false, completed = false) => {
  try {
    const params = { raw: 'True' };

    if (inProgress) {
      params.status = 'In progress';
    } else if (completed) {
      params.status = 'Completed';
    }

    const response = await axiosInstance.get('property', {
      params,
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });

    if (response.status === 200) {
      return response.data.data
        .sort((a, b) => b.id - a.id)
        .map((item) => {
          return {
            ...item,
            property_type: item?.property_type?.charAt(0).toUpperCase(),
            num_rooms: item?.changed_number_of_bedrooms
              ? item?.changed_number_of_bedrooms
              : item?.property_index_data
                ? item?.property_index_data.number_of_bedrooms
                : item?.num_rooms,
          };
        });
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetPropertyAnalytics = async (propertyId, signal) => {
  try {
    const response = await axiosInstance.get(`property/${propertyId}/analytics`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      const property = response.data.data[0];
      return {
        ...property,
        property_type: property?.property_index_data?.property_type?.charAt(0).toUpperCase(),
        num_rooms: property?.changed_number_of_bedrooms
          ? property.changed_number_of_bedrooms
          : property?.property_index_data
            ? property.property_index_data.number_of_bedrooms
            : property?.num_rooms,
      };
    } else {
      throw new Error('get property analytics failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiUpdateProperty = async (data) => {
  try {
    const response = await axiosInstance.patch(`property/${data.id}`, data, {
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to update property');
  }
};

export function createProperty(data) {
  return axiosInstance.post(`property/`, data, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export function createPropertyByCoordinates(data) {
  return axiosInstance.post(`property/`, data, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export const getPropertyByID = async (propertyId) => {
  try {
    const response = await axiosInstance.get(`property/${propertyId}`, {
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0];
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export function deletePropertyByID(propertyId) {
  return axiosInstance.delete(`property/${propertyId}`, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export function createPropertiesFromCSV(data) {
  return axiosInstance.post(`property/csv/`, data, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}`, 'Content-Type': 'multipart/form-data' },
  });
}
