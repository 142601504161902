import BarChart from './BarChart';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const propertyTypeMapping = {
  F: 'Flat',
  T: 'House',
  S: 'House',
  D: 'House',
};

const DepthOfMarketTable = ({ currentProperty, district, area }) => {
  const { property_type, number_of_bedrooms } = currentProperty.property_index_data;
  const mappedType = propertyTypeMapping[property_type] || property_type;

  let adjustedBedrooms = Number(number_of_bedrooms);

  if (mappedType === 'Flat' && number_of_bedrooms > 3) {
    adjustedBedrooms = 3;
  } else if (mappedType === 'House' && number_of_bedrooms > 4) {
    adjustedBedrooms = 4;
  }

  const districtData = JSON.parse(
    district
      .find((item) => item.type === mappedType && item.number_of_bedrooms === adjustedBedrooms)
      ?.depth_of_market.replace(/'/g, '"'),
  );

  const areaData = JSON.parse(
    area
      .find((item) => item.type === mappedType && item.number_of_bedrooms === adjustedBedrooms)
      ?.depth_of_market.replace(/'/g, '"'),
  );

  const ranges = Object.keys(districtData);

  const formattedRanges = ranges.map((range, index) => {
    if (index === 0) {
      return 'Under £500';
    } else if (index === ranges.length - 1) {
      return `£3750+`;
    } else {
      const [start, end] = range.split('-');
      return `£${start}-£${end}`;
    }
  });

  const splitBands = (bands) => {
    const mid = Math.ceil(bands.length / 2);
    return [bands.slice(0, mid), bands.slice(mid)];
  };

  const [leftBands, rightBands] = splitBands(ranges);

  return (
    <div className="row w-100">
      <div className="col-12 col-lg-6">
        <div className="flex p-4">
          <div>
            <table className="border-collapse ">
              <thead>
                <tr className="text-left">
                  <th className="font-medium p-2 text-md text-black">Range</th>
                  <th className="font-medium p-2 text-md text-black border-l">District (%)</th>
                  <th className="font-medium p-2 text-md text-black border-l">Area (%)</th>
                </tr>
              </thead>
              <tbody>
                {leftBands.map((range, index) => (
                  <tr key={index}>
                    <td className="p-2">{formattedRanges[ranges.indexOf(range)]}</td>
                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#3571E5' }}>
                      {(districtData[range] * 100).toFixed(0)}%
                    </td>
                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#FF4560' }}>
                      {(areaData[range] * 100).toFixed(0)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <table className="border-collapse">
              <thead>
                <tr className="text-left p-2 text-md">
                  <th className="font-medium p-2 text-md text-black border-l">Range</th>
                  <th className="font-medium p-2 text-md text-black border-l">District (%)</th>
                  <th className="font-medium p-2 text-md text-black border-l">Area (%)</th>
                </tr>
              </thead>
              <tbody>
                {rightBands.map((range, index) => (
                  <tr key={index}>
                    <td className="p-2 border-l">{formattedRanges[ranges.indexOf(range)]}</td>
                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#3571E5' }}>
                      {(districtData[range] * 100).toFixed(0)}%
                    </td>
                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#FF4560' }}>
                      {(areaData[range] * 100).toFixed(0)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="p-4">
          <BarChart
            categories={formattedRanges}
            districtData={districtData}
            areaData={areaData}
            property={currentProperty}
          ></BarChart>
        </div>
      </div>
    </div>
  );
};

export default DepthOfMarketTable;
