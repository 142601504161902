import React from 'react';
import Table from '../../Dashboard/Tables/BaseTable';
import { compareNumericString, generateCsvByTableData, propertySVGs } from '../../../../utils/helpers';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import _ from 'lodash';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { ReactComponent as DownloadSvg } from 'icons/custom/download.svg';
import { ReactComponent as AnalyticsIcon } from 'icons/custom/analytics.svg';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';
import { Link } from 'react-router-dom';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const areEqual = (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
};

const PortfolioByPropertyTableMemoized = React.memo(({ sampleStatsDataGroupedAndSorted, portfolioValue }) => {
  const columnsMetadata = {
    location: {
      Header: 'Location',
      headerStyle: { width: '300' },
      filterable: false,
      showTitle: true,
      accessor: (x) => {
        return (
          <Link className="cursor-pointer flex" title={x?.location} to={`/property-analytics/${x.id}`}>
            <AnalyticsIcon className="mr-2" />
            <div
              className="truncate hover:opacity-50"
              style={{ color: '#000000', width: '275', maxWidth: '275px', minWidth: '275px', paddingRight: '10px' }}
            >
              {x?.location}
            </div>
          </Link>
        );
      },
    },
    uprn: {
      Header: 'UPRN',
      accessor: 'uprn',
      headerStyle: { width: '120px' },
      style: {
        color: '#000000',
        width: '120px',
        minWidth: '120px',
        paddingRight: '5px',
      },
      filterable: false,
    },
    value: {
      Header: 'Index AVM Value',
      accessor: 'value',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: {
        color: '#000000',
        width: '120px',
        maxWidth: '120px',
        minWidth: '120px',
      },
      sortType: compareNumericString,
      renderer: (cell) => {
        return (
          <div className="flex justify-between">
            {cell?.value}
            {cell?.row?.original?.highAccuracy ? (
              <InfoAsterisk color="text-green-500" text="High accuracy: between 1% - 5% of actual price" />
            ) : (
              <InfoAsterisk color="text-yellow-500" text="Medium accuracy: between 5% - 10% of actual price" />
            )}
          </div>
        );
      },
    },
    premiumValue: {
      Header: 'Premium Value',
      accessor: 'premiumValue',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000' },
      sortType: compareNumericString,
    },
    benchmarkValue: {
      Header: 'Benchmark Value',
      accessor: 'benchmarkValue',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000' },
      sortType: compareNumericString,
    },
    indexRentEstimated: {
      Header: 'Index Rental Estimate',
      accessor: 'indexRentEstimated',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000' },
      sortType: compareNumericString,
    },
    rentalYield: {
      Header: 'Rental Yield',
      accessor: 'rentalYield',
      headerStyle: { width: '90px', maxWidth: '90px', minWidth: '90px' },
      style: { color: '#000000' },
      sortType: compareNumericString,
    },
    actualRent: {
      Header: 'Actual Rent',
      accessor: 'actualRent',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000' },
      sortType: compareNumericString,
    },
    actualRentalYield: {
      Header: 'Actual Rental Yield',
      accessor: 'actualRentalYield',
      headerStyle: { width: '90px', maxWidth: '90px', minWidth: '90px' },
      style: { color: '#000000' },
      sortType: compareNumericString,
    },
    propertyType: {
      Header: 'Property Type',
      accessor: 'propertyType',
      headerStyle: { width: '100px', maxWidth: '100px', minWidth: '100px' },
      style: { color: '#000000' },
    },
    number_of_bedrooms: {
      Header: 'Number of Bedrooms',
      accessor: 'number_of_bedrooms',
      headerStyle: { width: '106px' },
      style: { width: '110px', maxWidth: '110px', minWidth: '110px' },
      filterable: true,
      filterClassName: 'w-52	h-9	px-2.5 py-2 bg-white rounded-lg mr-3',
      filterStyle: { border: '1px solid #F0F0F0' },
      filterPlaceholder: 'Number of Bedrooms',
    },
    districtCode: {
      Header: 'District Code',
      accessor: 'districtCode',
      headerStyle: { width: '80px', maxWidth: '80px', minWidth: '80px' },
      style: { color: '#000000' },
    },
    chg1m: {
      Header: '%Chg 1M',
      accessor: 'chg1m',
      headerStyle: { width: '80px', maxWidth: '80px', minWidth: '80px' },
      sortType: compareNumericString,
    },
    chg3m: {
      Header: '%Chg 3M',
      accessor: 'chg3m',
      headerStyle: { width: '80px', maxWidth: '80px', minWidth: '80px' },
      sortType: compareNumericString,
    },
    chg6m: {
      Header: '%Chg 6M',
      accessor: 'chg6m',
      headerStyle: { width: '80px', maxWidth: '80px', minWidth: '80px' },
      sortType: compareNumericString,
    },
    chg1y: {
      Header: '%Chg 1Y',
      accessor: 'chg1y',
      headerStyle: { width: '80px', maxWidth: '80px', minWidth: '80px' },
      sortType: compareNumericString,
    },
    weightPercentage: {
      Header: 'Portfolio Weight Percentage',
      accessor: 'weightPercentage',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000', width: '120px', maxWidth: '120px', minWidth: '120px' },
      filterable: false,
      sortType: compareNumericString,
    },
    lastTransactionPrice: {
      Header: 'Last Transaction Price',
      accessor: 'lastTransactionPrice',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000', width: '120px', maxWidth: '120px', minWidth: '120px' },
      sortType: compareNumericString,
    },
    lastTransactionDate: {
      Header: 'Last Transaction Date',
      accessor: 'lastTransactionDate',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { color: '#000000', width: '120px', maxWidth: '120px', minWidth: '120px' },
    },
  };

  const downloadCSV = () => {
    const combinedData = Object.keys(sampleStatsDataGroupedAndSorted).reduce((accumulator, key) => {
      return accumulator.concat(sampleStatsDataGroupedAndSorted[key]);
    }, []);
    generateCsvByTableData(combinedData);
  };

  if (Object.keys(sampleStatsDataGroupedAndSorted).length === 0) return null;
  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          <div className="card-header w-100 border-0">
            <div className="flex w-100 items-center justify-between">
              <h4 className="mb-0 fs-20 text-black">Portfolio stats by Property Type</h4>
              <SecondaryButton className="ml-auto w-36" onClick={() => downloadCSV()}>
                <DownloadSvg className="mr-1.5" />
                Download CSV
              </SecondaryButton>
            </div>
          </div>
          <>
            {Object.keys(sampleStatsDataGroupedAndSorted)?.map((propertyType, idx) => {
              const propertyTypeText = propertySVGs[propertyType]?.name ?? '-';
              const sampleTableData = sampleStatsDataGroupedAndSorted[propertyType];
              return (
                <Table
                  key={idx}
                  columnsMetadata={columnsMetadata}
                  sampleTableData={sampleTableData}
                  title={propertyTypeText}
                  staticData={true}
                />
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
}, areEqual);

export default PortfolioByPropertyTableMemoized;
