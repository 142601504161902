import Select from 'react-select';
import { customSelectStyles } from '../../../../utils/constants';
import { useState } from 'react';
import TypesPriceChart from './TypesPriceChart';
import { postTownOptions } from '../../../../utils/helpers';

function CitiesByTypePriceChartWrapper({ targetTime }) {
  const [selectedCity, setSelectedCity] = useState('UK');

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3 flex justify-between">
              <h4 className="fs-20 mb-0">Property price changes by property type</h4>
              <Select
                className="w-1/4 mb-3"
                styles={customSelectStyles}
                defaultValue={postTownOptions[0]}
                onChange={(item) => setSelectedCity(item.value)}
                options={postTownOptions}
                isSearchable={false}
              />
            </div>

            <div className="card-body py-0 px-sm-3 px-0 ">
              <TypesPriceChart city={selectedCity} targetTime={targetTime} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CitiesByTypePriceChartWrapper;
