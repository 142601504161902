import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const apiGetRentalIndexByParams = async ({ districtCode, propertyType, numBedrooms, signal }) => {
  try {
    const response = await axiosInstance.get(`rentalindices/${districtCode}/${propertyType}/${numBedrooms}`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0] ? response.data.data[0] : {};
    } else {
      throw new Error('get user portfolios failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetRentalIndexByIndexName = async ({ indexName, signal }) => {
  try {
    const response = await axiosInstance.get(`rentalindices/${indexName}`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0] ? response.data.data[0] : {};
    } else {
      throw new Error('get user portfolios failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
