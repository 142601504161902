import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as UploadSVG } from 'icons/custom/upload-file.svg';
import { ReactComponent as DownloadSVG } from 'icons/custom/download-file.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { createRef, useState } from 'react';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import { createPropertiesFromCSV } from '../../../../api/property';

const UploadCsvModal = ({ showModal, setShowModal, createPropertiesFromFile, setShowInvalidSCVModal }) => {
  const [loading, setLoading] = useState(false);

  const fileRef = createRef();
  const history = useHistory();
  const readPropertiesFile = async (event) => {
    const [theFile] = event.target.files;
    if (theFile) {
      try {
        setLoading(true);
        const response = await createPropertiesFromCSV({ attachment: theFile });
        const csvResponse = response.data.data;

        setLoading(false);
        if (Array.isArray(csvResponse)) {
          createPropertiesFromFile(csvResponse);
          setShowModal(false);
        } else if (typeof csvResponse === 'object') {
          Papa.parse(theFile, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              const csvData = results.data;
              history.push('/csv-properties-review', { csvData, errors: csvResponse });
            },
            error: (error) => {
              console.error('Error parsing CSV:', error);
            },
          });
        }
      } catch (error) {
        setLoading(false);
        if (error) {
          setShowModal(false);
          setShowInvalidSCVModal(true);
        }
      }
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        centered
        enforceFocus={false}
      >
        <input
          style={{ display: 'none' }}
          onChange={(e) => readPropertiesFile(e)}
          onClick={(e) => (e.target.value = null)}
          type="file"
          accept=".csv"
          ref={fileRef}
          id="properties-file"
        />
        <div className="flex flex-column items-center justify-center mt-4">
          <div className="flex w-full items-center justify-between px-4">
            <div className="text-black text-2xl font-medium">Upload </div>
            <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
          </div>
          <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />

          <div className="px-4 pb-4 flex flex-column items-center justify-between">
            <div
              className="w-16	h-16 rounded-full flex items-center justify-center mb-3 mt-3"
              style={{ background: '#F5F8FE' }}
            >
              <UploadSVG className="w-7 h-7" />
            </div>
            <div className="text-left px-4 pb-8">
              <div className="text-black text-base font-medium mb-2">
                Batch upload properties to your account using a CSV file
              </div>
              <div className="text-sm mb-3 font-light">
                To ensure a smooth upload, download the CSV template below as a starting point. You can edit the file
                using any spreadsheet software, such as Excel or Google Sheets. Simply fill in your property details
                following the template format. <br /> Please note that the maximum number of properties allowed per
                upload is
                <span className="font-semibold"> 100</span>
              </div>
              <a
                className="flex items-center font-normal"
                href="/csv-upload-template.csv"
                style={{
                  color: '#3571E5',
                }}
              >
                Download CSV template
                <DownloadSVG className="w-4 h-4 ml-2" />
              </a>
            </div>

            {loading ? <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" /> : ''}

            <div className="flex items-center justify-between w-100">
              <SecondaryButton onClick={() => setShowModal(false)} disabled={loading}>
                Cancel
              </SecondaryButton>
              <PrimaryButton className={'w-auto px-4'} onClick={() => fileRef?.current?.click()} disabled={loading}>
                Continue with upload
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadCsvModal;
