import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import Select from 'react-select';
import LocationMap from '../Components/LocationMap';
import { numberOfBedroomsOptionsOnly, propertyTypeOptionsOnly } from '../../../../utils/constants';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import moment from 'moment';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const maxDate = moment().format('YYYY-MM-DD');

const SearchByCoordinatesModal = ({ showModal, setShowModal, handleSuccess }) => {
  const [errors, setErrors] = useState({ num_rooms: '', property_type: '' });

  const [selectedLocation, setSelectedLocation] = useState({
    address: '',
    post_code: '',
    post_town: '',
    district_code: '',
    property_type: '',
    num_rooms: '',
    last_transaction_price: undefined,
    last_transaction_date: '',
  });

  const handleDropdownChange = (item, selectField) => {
    setErrors({ num_rooms: '', property_type: '' });
    const fieldName = selectField;
    const fieldValue = selectField === 'num_rooms' ? parseInt(item.value) : item.value;
    const formData = { ...selectedLocation };
    formData[fieldName] = fieldValue;
    setSelectedLocation(formData);
  };

  const handleFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    let fieldValue = event.target.value;
    const formData = { ...selectedLocation };
    if (fieldName === 'last_transaction_price') {
      fieldValue = Math.trunc(numeral(event.target.value).value());
    }

    if (fieldName === 'post_code') {
      const postCodeRegex = /^([A-Za-z][A-Ha-hJ-Yj-y]?\d[A-Za-z\d]? ?\d[A-Za-z]{2})$/;
      if (postCodeRegex.test(fieldValue)) {
        const districtCodeMatch = fieldValue.match(/^([A-Za-z][A-Ha-hJ-Yj-y]?\d[A-Za-z\d]?)/);
        formData.district_code = districtCodeMatch ? districtCodeMatch[1] : '';
      } else {
        formData.district_code = '';
      }
    }

    formData[fieldName] = fieldValue;
    setSelectedLocation(formData);
  };

  const addProperty = () => {
    let error = false;
    const errorObject = { ...errors };
    if (!selectedLocation.num_rooms) {
      errorObject.num_rooms = 'Number of Rooms is Required';
      error = true;
    }

    if (!selectedLocation.property_type) {
      errorObject.property_type = 'Property Type is Required';
      error = true;
    }
    if (!selectedLocation.post_code) {
      errorObject.post_code = 'Post Code is Required';
      error = true;
    } else {
      const postCodeRegex = /^([A-Za-z][A-Ha-hJ-Yj-y]?\d[A-Za-z\d]? ?\d[A-Za-z]{2})$/;
      if (!postCodeRegex.test(selectedLocation.post_code)) {
        errorObject.post_code = 'Invalid Post Code';
        error = true;
      }
    }

    if (!selectedLocation.district_code) {
      errorObject.district_code = 'District Code is Required';
      error = true;
    } else {
      const districtCodeRegex = /^([A-Za-z][A-Ha-hJ-Yj-y]?\d[A-Za-z\d]?)$/;
      if (!districtCodeRegex.test(selectedLocation.district_code)) {
        errorObject.district_code = 'Invalid District Code';
        error = true;
      }
    }

    setErrors(errorObject);
    if (error) {
      return;
    }

    handleSuccess && handleSuccess(selectedLocation);
    setShowModal(false);
  };

  useEffect(() => {
    setErrors({ num_rooms: '', property_type: '', post_code: '', district_code: '' });
  }, [selectedLocation]);

  useEffect(() => {
    setSelectedLocation({
      address: '',
      post_code: '',
      post_town: '',
      district_code: '',
      property_type: '',
      num_rooms: '',
      last_transaction_price: undefined,
      last_transaction_date: '',
    });
  }, [showModal]);

  return (
    <Modal className="fade" show={showModal} onHide={setShowModal} size="lg" centered enforceFocus={false}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20"> Search Property By Coordinates </h4>
              <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
            </div>

            <div className="modal-body">
              <LocationMap setSelectedLocation={setSelectedLocation} />
              <div>
                <div className="flex w-full flex-row">
                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Address </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="Address"
                      type="text"
                      name="address"
                      value={selectedLocation.address}
                      readOnly
                    />
                  </div>
                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Post Code </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="Post Code"
                      type="text"
                      name="post_code"
                      value={selectedLocation.post_code}
                      onChange={handleFormChange}
                    />
                    {errors.post_code && <div className="text-danger fs-12">{errors.post_code}</div>}
                  </div>
                </div>

                <div className="flex w-full flex-row">
                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> District Code </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="District Code"
                      type="text"
                      name="district_code"
                      value={selectedLocation.district_code}
                      onChange={handleFormChange}
                      readOnly
                    />
                    {errors.district_code && <div className="text-danger fs-12">{errors.district_code}</div>}
                  </div>
                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> City </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="City"
                      type="text"
                      name="city"
                      value={selectedLocation.post_town}
                      readOnly
                    />
                  </div>
                </div>

                <div className="flex w-full flex-row">
                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Property Type </label>
                    <Select
                      className="w-100 mr-3 text-xs"
                      placeholder="Property Type"
                      defaultValue={''}
                      onChange={(item) => handleDropdownChange(item, 'property_type')}
                      options={propertyTypeOptionsOnly}
                      isSearchable={false}
                      name="property_type"
                    />
                    {errors.property_type && <div className="text-danger fs-12">{errors.property_type}</div>}
                  </div>

                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
                    <Select
                      className="w-100 mr-3 text-xs"
                      placeholder="Number of Bedrooms"
                      defaultValue={''}
                      onChange={(item) => handleDropdownChange(item, 'num_rooms')}
                      options={numberOfBedroomsOptionsOnly}
                      isSearchable={false}
                      name="num_bedrooms"
                    />
                    {errors.num_rooms && <div className="text-danger fs-12">{errors.num_rooms}</div>}
                  </div>
                </div>

                <div className="flex w-full flex-row">
                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Last Transaction Amount (Optional) </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="Last Transaction Amount"
                      name="last_transaction_price"
                      value={
                        selectedLocation?.last_transaction_price
                          ? numeral(selectedLocation.last_transaction_price).format()
                          : ''
                      }
                      onChange={handleFormChange}
                      type="text"
                    />
                  </div>

                  <div className="form-group mb-4 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Last Transaction Date (Optional) </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-lg mr-3 border text-xs"
                      placeholder="Last Transaction Date"
                      type="date"
                      value={selectedLocation?.last_transaction_date ?? ''}
                      name="last_transaction_date"
                      onChange={handleFormChange}
                      max={maxDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="flex w-100 justify-end">
                <SecondaryButton className="m-1" onClick={() => setShowModal(false)} type="button">
                  Cancel
                </SecondaryButton>
                <PrimaryButton className="m-1" type="button" onClick={() => addProperty()}>
                  Add Property
                </PrimaryButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SearchByCoordinatesModal;
