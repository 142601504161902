import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const Summary = ({ values }) => {
  return (
    <>
      <div className="row p-2" style={{ backgroundColor: '#FEFBF1' }}>
        <div className="col-12">
          <h4 className="my-2 fs-16 text-black"> Summary </h4>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> </div>
          <div className="col-4">
            <h4 className="my-2 fs-14 text-black"> For Seller </h4>
          </div>
          <div className="col-4">
            <h4 className="my-2 fs-14 text-black"> For Buyer </h4>
          </div>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> Total commitment threshold </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield,
                ).format()}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                %
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.acquisitionYield / 100).format('0.00%')}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield,
                ).format()}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.acquisitionYield / 100).format('0.00%')}
              </span>
            </div>
          </div>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> Acquisition and development outlay </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  values.sellerTotalAcquisitionCosts +
                    values.sellerTotalRefurbishmentCosts +
                    values.sellerTotalSalesCosts,
                ).format()}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                %
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) /
                    (values.sellerTotalAcquisitionCosts +
                      values.sellerTotalRefurbishmentCosts +
                      values.sellerTotalSalesCosts),
                ).format('0.00%')}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  values.buyerTotalAcquisitionCosts + values.buyerTotalRefurbishmentCosts + values.buyerTotalSalesCosts,
                ).format()}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                %
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) /
                    (values.buyerTotalAcquisitionCosts +
                      values.buyerTotalRefurbishmentCosts +
                      values.buyerTotalSalesCosts),
                ).format('0.00%')}
              </span>
            </div>
          </div>
        </div>
        <div className="row items-center py-2">
          <div className="col-4"> Provider profit fixed at 10% on spend </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.sellerTotalAcquisitionCosts +
                    values.sellerTotalRefurbishmentCosts +
                    values.sellerTotalSalesCosts) *
                    0.1,
                ).format()}
              </span>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.buyerTotalAcquisitionCosts +
                    values.buyerTotalRefurbishmentCosts +
                    values.buyerTotalSalesCosts) *
                    0.1,
                ).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="row items-center py-2">
          <div className="col-4 text-black"> Total spend </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.sellerTotalAcquisitionCosts +
                    values.sellerTotalRefurbishmentCosts +
                    values.sellerTotalSalesCosts) *
                    1.1,
                ).format()}
              </span>
            </div>
          </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                %
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) /
                    ((values.sellerTotalAcquisitionCosts +
                      values.sellerTotalRefurbishmentCosts +
                      values.sellerTotalSalesCosts) *
                      1.1),
                ).format('0.00%')}
              </span>
            </div>
          </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.buyerTotalAcquisitionCosts +
                    values.buyerTotalRefurbishmentCosts +
                    values.buyerTotalSalesCosts) *
                    1.1,
                ).format()}
              </span>
            </div>
          </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                %
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) /
                    ((values.buyerTotalAcquisitionCosts +
                      values.buyerTotalRefurbishmentCosts +
                      values.buyerTotalSalesCosts) *
                      1.1),
                ).format('0.00%')}
              </span>
            </div>
          </div>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> Remaining balance / contingency / bond costs </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield -
                    (values.sellerTotalAcquisitionCosts +
                      values.sellerTotalRefurbishmentCosts +
                      values.sellerTotalSalesCosts) *
                      1.1,
                ).format()}
              </span>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield -
                    (values.buyerTotalAcquisitionCosts +
                      values.buyerTotalRefurbishmentCosts +
                      values.buyerTotalSalesCosts) *
                      1.1,
                ).format()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
