import React from 'react';

const PrimaryButton = ({ onClick, children, className, disabled = false, type = 'button' }) => {
  return (
    <button
      className={`w-32 h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
      style={{
        background: 'rgba(53, 113, 229, 0.15)',
        color: '#3571E5',
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
