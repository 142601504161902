import React from 'react';

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright ©
          <a href="http://realyse.com/" target="_blank" rel="noreferrer">
            Treex Limited
          </a>{' '}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
