import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import { customSelectStyles } from '../../../../utils/constants';
import { ReactComponent as ResetFiltersSvg } from 'icons/custom/resetfilters.svg';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

export const numberOfBedroomsOptions = [
  { value: '', label: ' All ' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const propertyTypeOptions = [
  { value: '', label: ' All ' },
  { value: 'F', label: 'Flat' },
  { value: 'T', label: 'Terraced' },
  { value: 'S', label: 'Semi-Detached' },
  { value: 'D', label: 'Detached' },
];

const PropertiesListFilter = ({ updateFilter, loading }) => {
  const [filterData, setFilterData] = useState({
    address: '',
    property_type: '',
    number_of_bedrooms: '',
    offset: 0,
    limit: 10,
  });

  const handleFilterFormChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleDropdownFilterFormChange = (item, selectField) => {
    const fieldName = selectField;
    const fieldValue = item.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]) {
      formData[fieldName] = fieldValue;
      setFilterData(formData);
    }
  };

  const resetFilters = () => {
    setFilterData({
      address: '',
      property_type: '',
      number_of_bedrooms: '',
    });
  };

  useEffect(() => {
    debouncedChangeHandler({ ...filterData });
  }, [filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => updateFilter(params), 200),
    [],
  );

  return (
    <div className="flex flex-wrap items-center justify-between gap-y-3 px-4 pt-5">
      <div className="flex flex-wrap gap-y-3">
        <div className="w-52 flex flex-column ml-3">
          <label className="font-medium text-black text-xs"> Address </label>
          <input
            className="h-10 px-2.5 py-2 bg-white rounded-lg text-xs border"
            placeholder="e.g. 10 Downing Street"
            type="text"
            onChange={handleFilterFormChange}
            value={filterData?.address}
            name="address"
          />
        </div>

        <div className="w-40 flex flex-column ml-3">
          <label className="font-medium text-black text-xs"> Property Type </label>
          <Select
            className="mb-2 h-10 text-xs"
            placeholder="Property Type"
            onChange={(item) => handleDropdownFilterFormChange(item, 'property_type')}
            styles={customSelectStyles}
            options={propertyTypeOptions}
            value={propertyTypeOptions.find(({ value }) => value === filterData.property_type) || ''}
            isSearchable={false}
            name="property_type"
          />
        </div>

        <div className="w-40 flex flex-column ml-3">
          <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
          <Select
            className="mb-2 h-10 text-xs"
            placeholder="Number of Bedrooms"
            onChange={(item) => handleDropdownFilterFormChange(item, 'number_of_bedrooms')}
            styles={customSelectStyles}
            options={numberOfBedroomsOptions}
            value={numberOfBedroomsOptions.find(({ value }) => value === filterData.number_of_bedrooms) || ''}
            isSearchable={false}
            name="number_of_bedrooms"
          />
        </div>
      </div>

      <div className="flex flex-wrap ml-3 mt-3">
        {loading && <LoaderSvg className="animate-spin h-8 w-8 text-white mr-4" />}
        <SecondaryButton className="ml-auto w-36" onClick={resetFilters}>
          <ResetFiltersSvg className="mr-1.5" />
          Reset Filters
        </SecondaryButton>
      </div>
    </div>
  );
};

export default PropertiesListFilter;
