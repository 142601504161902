import { useCallback, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import axios from 'axios';
import {
  customSelectStyles,
  epcOptionsWithAll,
  numberOfBedroomsOptions,
  propertyTypeOptions,
} from '../../../../utils/constants';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as ResetFiltersSvg } from 'icons/custom/resetfilters.svg';
import { ReactComponent as FilterSvg } from 'icons/custom/filter.svg';

import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';

import numeral from 'numeral';
import 'numeral/locales/en-gb';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import RangeSliderItem from '../../Dashboard/Components/RangeSliderItem';
import { searchEpcData } from '../../../../api/epc';
import EpcDataTable from './EpcDataTable';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const EpcData = () => {
  const [searchTableData, setSearchTableData] = useState([]);
  const [filterData, setFilterData] = useState({
    offset: 0,
    limit: 10,
  });
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const [priceRange, setPriceRange] = useState([200000, 1000000]);

  let cancelToken;
  const getSearchData = async (filter) => {
    setLoading(true);

    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      const num_of_bedrooms = parseInt(filter?.num_of_bedrooms);

      const searchResponse = await searchEpcData(
        {
          district: filter?.district?.toUpperCase(),
          offset: filter?.offset,
          limit: 10,
          epc_rating: filter?.epc_rating,
          ascending: !filter?.desc,
          property_type: filter?.property_type ? [filter?.property_type] : undefined,
          num_of_bedrooms: num_of_bedrooms ? [num_of_bedrooms] : undefined,
          order_by: filter?.order_by,
          post_town: filter?.post_town?.toUpperCase(),
          ...filter.ranges,
        },
        { cancelToken: cancelToken.token },
      );

      if (searchResponse && searchResponse.data) {
        setSearchTableData(searchResponse?.data?.data);
        setPageCount(Math.ceil(searchResponse?.data?.total_count / 10));
      }

      setLoading(false);
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        setSearchTableData([]);
        setLoading(false);
      }
    }
  };

  const debouncedChangeHandler = useCallback(debounce(getSearchData, 200), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]) {
      formData[fieldName] = fieldValue;
      setFilterData(formData);
      setControlledPageIndex(0);
    }
  };

  const handleDropdownFilterFormChange = (item, selectField) => {
    const fieldName = selectField;
    const fieldValue = item.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]) {
      formData[fieldName] = fieldValue;
      setFilterData(formData);
      setControlledPageIndex(0);
    }
  };

  const resetTableFilters = function () {
    const formData = {
      order_by: 'group_name',
      offset: 0,
      desc: true,
      ranges: {
        min_price: 200000,
        max_price: 1000000,
      },
    };
    setPriceRange([200000, 1000000]);

    setFilterData(formData);
    debouncedChangeHandler(formData);
    setControlledPageIndex(0);
  };

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.desc = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }

      setControlledPageIndex(pageIndex);
      setFilterData(formData);
      debouncedChangeHandler(formData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterData],
  );

  const applyFilters = () => {
    let formData = { ...filterData };
    formData.ranges = {
      min_price: priceRange[0],
      max_price: priceRange[1],
    };

    debouncedChangeHandler(formData);
  };

  useEffect(() => {
    debouncedChangeHandler(filterData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <div className="card">
        <div className="card-header border-0 flex justify-between">
          <h4 className="mb-0 fs-20 text-black">EPC upgrade table</h4>
        </div>
        <div className="flex flex-wrap w-100 mt-3 pl-7 pr-7">
          <div className="w-52 flex flex-column mb-3">
            <label className="font-medium text-black text-xs"> Location </label>
            <DebounceInput
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs"
              placeholder="e.g. London"
              onChange={handleFilterFormChange}
              value={filterData.post_town || ''}
              debounceTimeout={500}
              name="post_town"
            />
          </div>
          <div className="w-52 flex flex-column mb-3">
            <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
            <Select
              className="mr-3 mb-2 h-10 text-xs"
              defaultValue={[numberOfBedroomsOptions[3]]}
              placeholder="Number of Bedrooms"
              value={
                numberOfBedroomsOptions.find(({ value }) => value === filterData.num_of_bedrooms) ||
                numberOfBedroomsOptions[0]
              }
              onChange={(item) => handleDropdownFilterFormChange(item, 'num_of_bedrooms')}
              styles={customSelectStyles}
              options={numberOfBedroomsOptions}
              isSearchable={false}
              name="num_of_bedrooms"
            />
          </div>
          <div className="w-52 flex flex-column mb-3">
            <label className="font-medium text-black text-xs"> Property Type </label>
            <Select
              className="mr-3 mb-2 h-10 text-xs"
              placeholder="Property Type"
              defaultValue={[propertyTypeOptions[2]]}
              value={
                propertyTypeOptions.find(({ value }) => value === filterData.property_type) || propertyTypeOptions[0]
              }
              onChange={(item) => handleDropdownFilterFormChange(item, 'property_type')}
              styles={customSelectStyles}
              options={propertyTypeOptions}
              isSearchable={true}
              name="property_type"
            />
          </div>

          <div className="w-52 flex flex-column mb-3">
            <label className="font-medium text-black text-xs"> EPC Rating </label>
            <Select
              className="mr-3 mb-2 h-10 text-xs"
              placeholder="EPC Rating"
              value={epcOptionsWithAll.find(({ value }) => value === filterData.epc_rating) || epcOptionsWithAll[0]}
              onChange={(item) => handleDropdownFilterFormChange(item, 'epc_rating')}
              styles={customSelectStyles}
              options={epcOptionsWithAll}
              isSearchable={true}
              name="epc_rating"
            />
          </div>

          <div className="w-52 flex flex-column mb-3">
            <label className="font-medium text-black text-xs"> District Code </label>
            <DebounceInput
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs"
              placeholder="e.g. SW6"
              onChange={handleFilterFormChange}
              value={filterData.district || ''}
              debounceTimeout={500}
              name="district"
            />
          </div>

          <div className="w-52 flex flex-column mr-5">
            <label className="font-medium text-black text-xs"> Price Range </label>
            <RangeSliderItem
              title={''}
              min={0}
              max={10000000}
              step={10000}
              range={priceRange}
              updateRange={setPriceRange}
              unit={'£'}
            ></RangeSliderItem>
          </div>
        </div>
        <div className="w-100 mb-2">
          <div className="flex flex-wrap mx-4 border-t">
            <div className="flex">
              <div className="w-12 mt-7">{loading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}</div>
            </div>
            <PrimaryButton className="ml-auto w-36 mt-3 mr-3 mb-2" onClick={applyFilters}>
              <FilterSvg className="mr-1.5" />
              Apply Filters
            </PrimaryButton>

            <SecondaryButton className="w-36 mt-3 mb-2" onClick={resetTableFilters}>
              <ResetFiltersSvg className="mr-1.5" />
              Reset Filters
            </SecondaryButton>
          </div>
        </div>

        <EpcDataTable
          pageCount={pageCount}
          pageIndex={controlledPageIndex}
          tableData={searchTableData}
          filtersActive={false}
          fetchData={fetchData}
          loading={loading}
        ></EpcDataTable>
      </div>
    </>
  );
};

export default EpcData;
