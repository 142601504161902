import { useEffect, useMemo, useState } from 'react';
import PropertyCard from './PropertyCard';
import axios from 'axios';
import { apiGetRawUserProperties, apiUpdateProperty, getUserPropertiesWithLimits } from '../../../../api/property';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as Error } from 'icons/custom/error.svg';
import InfiniteScroll from 'react-infinite-scroller';
import ErrorScreen from '../../SharedComponents/ErrorScreen';
import { useQuery } from '@tanstack/react-query';

function GridView({ handleClickDelete, deletedPropertyIds = [], appraisal }) {
  const [properties, setProperties] = useState([]);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [favouritePropertyIds, setFavouritePropertyIds] = useState([]);

  const { data: rawProperties = [] } = useQuery(['rawProperties', 'in-progress'], ({ signal }) =>
    apiGetRawUserProperties(signal, true),
  );

  let cancelToken;
  const fetchData = async () => {
    if (totalCount > 0 && properties.length >= totalCount) {
      return;
    }
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    if (!loading) {
      setLoading(true);
      try {
        const responseData = await getUserPropertiesWithLimits(
          {
            offset: offset || 0,
            limit: 10,
            appraisal: appraisal ? 'True' : undefined,
          },
          { cancelToken: cancelToken.token },
        );
        if (responseData && responseData?.data) {
          const newPageData = responseData.data;
          const newTotalCount = responseData.total_count;
          setProperties((prevData) => [...prevData, ...newPageData]);
          setTotalCount(newTotalCount);
          setOffset((prevValue) => prevValue + 10);

          const favoriteIds = newPageData.filter((item) => item.favourite === true).map((item) => item.id);
          setFavouritePropertyIds((prevData) => [...prevData, ...favoriteIds]);
        }
        setError(false);
        setLoading(false);
      } catch (error) {
        console.error(error);
        if (error.code !== 'ERR_CANCELED') {
          setError(true);
        }
        setLoading(false);
      }
    }
  };

  const toggleFavourite = async (property) => {
    const favourite = favouritePropertyIds.includes(property.id);
    try {
      await apiUpdateProperty({ id: property.id, favourite: !favourite });
      if (!favourite) {
        setFavouritePropertyIds((prevIds) => [...prevIds, property.id]);
      } else {
        const updatedFavourites = favouritePropertyIds.filter((id) => id !== property.id);
        setFavouritePropertyIds(updatedFavourites);
      }
    } catch (error) {
      throw new Error('Failed to update property');
    }
  };

  const [invalidate, setInvalidate] = useState(false);

  useEffect(() => {
    setOffset(0);
    setTotalCount(0);
    setProperties([]);
    setInvalidate(true);
    setLoading(false);
  }, [appraisal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invalidate) {
      fetchData();
      setInvalidate(false);
      window.scrollTo(0, 0);
    }
  }, [invalidate]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredRawProperties = useMemo(
    () => rawProperties.filter((rawProperty) => !properties.some((property) => property.id === rawProperty.id)),
    [rawProperties, properties],
  );

  return (
    <>
      {error ? (
        <div className="card h-auto">
          <ErrorScreen />
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchData}
          hasMore={properties.length < totalCount}
          loader={
            <div className="flex justify-center" key={0}>
              <LoaderSvg className="animate-spin h-8 w-8 text-white mr-4" />
            </div>
          }
        >
          <div className="row">
            {!appraisal &&
              filteredRawProperties.map((property) =>
                deletedPropertyIds.includes(property.id) ? (
                  ''
                ) : (
                  <PropertyCard
                    propertyData={property}
                    key={property.id}
                    handleClickDelete={handleClickDelete}
                    toggleFavourite={(data) => toggleFavourite(data)}
                    favouritePropertyIds={favouritePropertyIds}
                  />
                ),
              )}
            {properties.map((property) =>
              deletedPropertyIds.includes(property.id) ? (
                ''
              ) : (
                <PropertyCard
                  propertyData={property}
                  key={property.id}
                  handleClickDelete={handleClickDelete}
                  toggleFavourite={(data) => toggleFavourite(data)}
                  favouritePropertyIds={favouritePropertyIds}
                />
              ),
            )}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
}

export default GridView;
