import { ReactComponent as UkMapSvg } from 'icons/custom/uk-regions.svg';
import { ReactComponent as IncreaseSVG } from 'icons/custom/increase.svg';
import { ReactComponent as DecreaseSVG } from 'icons/custom/decrease.svg';
import { ReactComponent as UnchangedSVG } from 'icons/custom/unchanged.svg';

import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import 'numeral/locales/en-gb';
import { Tooltip } from 'react-tooltip';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const TooltipContent = ({ currentRegion }) => {
  return currentRegion?.name ? (
    <div className="bg-white rounded-md shadow-lg py-2 pl-1 pr-2">
      <div className="d-flex align-center">
        <span>
          {currentRegion?.changeYoy > 0 ? (
            <IncreaseSVG className="h-4" />
          ) : currentRegion?.changeYoy < 0 ? (
            <DecreaseSVG className="h-4" />
          ) : (
            <UnchangedSVG className="h-4" />
          )}
        </span>
        <span
          className="mr-1"
          style={{
            color: currentRegion?.changeYoy > 0 ? '#32D16D' : currentRegion?.changeYoy < 0 ? '#FF4015' : '#FFA500',
          }}
        >
          {numeral(currentRegion?.changeYoy).format('0.0%')}
        </span>
        <span style={{ color: '#94A2AB' }}> {currentRegion?.name} </span>
      </div>
      <div className="text-black font-medium fs-16 px-2"> {numeral(currentRegion?.currentPrice).format()} </div>
    </div>
  ) : (
    <></>
  );
};

const RegionsMapData = ({ regionIndexData }) => {
  const [currentRegionId, setCurrentRegionId] = useState();
  const [currentRegion, setCurrentRegion] = useState({});

  const handleHover = (e) => {
    setCurrentRegionId(e.target.id);
  };

  const clearContent = () => {
    setCurrentRegion();
  };

  useEffect(() => {
    const region = regionIndexData.find((region) => region.mapId === currentRegionId);
    if (region) {
      setCurrentRegion(region);
    } else {
      setCurrentRegion();
    }
  }, [currentRegionId, regionIndexData]);

  return (
    <div className="card p-4" onMouseLeave={clearContent}>
      <div className="d-flex justify-between">
        <div className="w-80">
          <UkMapSvg
            data-tip
            data-tooltip-id="my-tooltip"
            data-tooltip-hidden={!currentRegion?.name}
            className="w-full uk-regions-svg"
            onMouseOver={(e) => handleHover(e)}
            onMouseLeave={(e) => handleHover(e)}
          />

          <Tooltip
            id="my-tooltip"
            float="true"
            style={{ padding: 0, backgroundColor: '#fff' }}
            arrowColor="transparent"
            opacity={1}
            render={() => <TooltipContent currentRegion={currentRegion} />}
          />
        </div>

        <div className="pt-12">
          {regionIndexData.map((item, index) => (
            <div key={index} className="py-1.5">
              <span className="text-black"> {item.name} </span> - <span> {numeral(item?.currentPrice).format()} </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(RegionsMapData);
