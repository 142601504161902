import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const getPropertyListings = async (params, signal) => {
  try {
    const response = await axiosInstance.post(`/listings/search`, params, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export function searchListings(params, cancelToken = {}) {
  return axiosInstance.post(`/listings/search`, params, {
    ...cancelToken,
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export const getListingsCSV = async (propertyId, params, cancelToken = {}) => {
  try {
    const response = await axiosInstance.post(`/listings/search`, params, {
      ...cancelToken,
      headers: {
        Authorization: `Bearer ${Cookies.get('REalysepmt-token')}`,
        Accept: 'text/csv',
      },
    });

    if (response.status === 200) {
      if (response.data) {
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        a.click();
        URL.revokeObjectURL(url);
      }
      return response.data;
    } else {
      throw new Error('get comparables CSV failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
