import React from 'react';
import TransactionItem from './TransactionItem';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';

const PropertyTransactions = ({ property, transactions, showModal, modalView }) => {
  return (
    <>
      {!modalView ? <div className="font-medium	text-base	mb-6 text-black">Transaction History</div> : ''}
      {(modalView ? transactions : transactions.slice(0, 3))?.map((transaction, index) => (
        <div key={index}>
          <TransactionItem
            property={property}
            transaction={transaction}
            hideBorder={transactions.length - 1 === index}
          ></TransactionItem>
        </div>
      ))}
      {!modalView && transactions.length > 3 ? (
        <SecondaryButton className={'ml-4'} onClick={() => showModal()} styles={{ width: 80, height: 32 }}>
          See more
        </SecondaryButton>
      ) : (
        ''
      )}
    </>
  );
};

export default React.memo(PropertyTransactions);
