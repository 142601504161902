import { useCallback, useState } from 'react';
import PageHead from '../additionalModules/PageHead';
import Filters from './Components/Filters';
import debounce from 'lodash.debounce';
import { searchLhaData } from '../../../api/lha';
import LhaData from './Components/LhaData';
import axios from 'axios';

const Lha = () => {
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [lhaData, setLhaData] = useState([]);

  const updateFilter = (formData) => {
    setFilterData(formData);
    setPageIndex(0);
    debouncedChangeHandler(formData);
  };

  let cancelToken;
  const getLhaData = async (filter) => {
    try {
      setLoading(true);
      if (cancelToken) {
        cancelToken.cancel('Operation cancelled due to new Request');
      }
      cancelToken = axios.CancelToken.source();
      const searchResponse = await searchLhaData(
        {
          ...filter,
          offset: filter?.offset || 0,
          limit: 10,
        },
        { cancelToken: cancelToken.token },
      );

      if (searchResponse && searchResponse?.data) {
        setLhaData(searchResponse?.data?.data);
        setPageCount(Math.ceil(searchResponse?.data?.total_count / 10));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => getLhaData(params), 200),
    [filterData],
  );

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.ascending = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }

      setLoading(true);
      setPageIndex(pageIndex);
      setFilterData(formData);
      debouncedChangeHandler(formData);
    },
    [debouncedChangeHandler, filterData],
  );

  return (
    <>
      <PageHead activePage="Local Housing Allowance rates" />

      <div className="card p-2">
        <Filters updateFilter={(params) => updateFilter(params)} loading={loading}></Filters>
        <LhaData
          data={lhaData}
          pageCount={pageCount}
          pageIndex={pageIndex}
          fetchData={fetchData}
          loading={loading}
        ></LhaData>
      </div>
    </>
  );
};

export default Lha;
