import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortfolio, updatePortfolio } from '../../../../api/portfolio';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { useQueryClient } from '@tanstack/react-query';
import FilteredProperties from './FilteredProperties';
import StatusModal from '../../Dashboard/Modals/StatusModal';

const inputStyle = {
  border: '1px solid #DFE1E5',
  color: '#747B8A',
};

const PortfolioModal = ({ showModal, setShowModal, handleSuccess, portfolio, appraisal }) => {
  const [errors, setErrors] = useState({ name: '', description: '', properties: '' });
  const [portfolioData, setPortfolioData] = useState({
    name: portfolio.name || '',
    description: portfolio.description || '',
  });
  const [portfolioProperties, setPortfolioProperties] = useState([
    ...(portfolio?.properties && portfolio?.properties?.length ? portfolio?.properties : []),
  ]);

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const queryClient = useQueryClient();

  const handleAddFormChange = (event) => {
    event.preventDefault();
    setErrors({ name: '', description: '', properties: '' });
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...portfolioData };
    newFormData[fieldName] = fieldValue;
    setPortfolioData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    var error = false;

    const errorObj = { ...errors };
    if (portfolioData.name === '') {
      errorObj.name = 'Name is Required';
      error = true;
    }

    if (portfolioData.description === '') {
      errorObj.description = 'Description is Required';
      error = true;
    }

    if (portfolioProperties?.length === 0) {
      errorObj.properties = 'Please select at least one property';
      error = true;
    }

    setErrors(errorObj);

    if (!error) {
      modifyPortfolio({
        name: portfolioData.name,
        description: portfolioData.description,
        properties: portfolioProperties,
      });
      setShowModal(false);
    }
  };

  const modifyPortfolio = async (portfolioForm) => {
    try {
      const response = portfolio?.id
        ? await updatePortfolio(portfolio?.id, portfolioForm)
        : await createPortfolio({ ...portfolioForm, has_appraisal: appraisal ? true : undefined });

      const modifiedPortfolio = response?.data?.data[0];
      queryClient.invalidateQueries(['portfolioAnalyticsData', portfolio?.id]);

      if (modifiedPortfolio) {
        const successText = portfolio?.id ? 'Portfolio successfully updated' : 'Portfolio successfully added';
        setStatusModalContent({
          show: true,
          type: 'success',
          description: successText,
        });

        handleSuccess && handleSuccess();
      }
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
      console.log(error);
    }
  };

  useEffect(() => {
    setPortfolioData(portfolio);
  }, [portfolio]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectedPropertiesChange = useCallback(
    (selectedPropertyIds) => {
      setErrors({ name: '', description: '', properties: '' });
      setPortfolioProperties(selectedPropertyIds);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />

      <Modal
        className="fade"
        id="ele3"
        style={{ width: '100%', maxWidth: '100%' }}
        centered
        show={showModal}
        onHide={setShowModal}
        enforceFocus={false}
      >
        <div role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20"> {portfolio?.id ? 'Update' : 'Add New'} Portfolio </h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Portfolio Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      value={portfolioData?.name}
                      name="name"
                      maxLength={22}
                      required="required"
                      onChange={handleAddFormChange}
                      placeholder="Enter Portfolio Name"
                      style={inputStyle}
                    />
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label className="text-black font-w500">Portfolio Description</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      value={portfolioData?.description}
                      name="description"
                      required="required"
                      maxLength={35}
                      onChange={handleAddFormChange}
                      placeholder="Enter Portfolio Description"
                      style={inputStyle}
                    />
                    {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Select the Properties you wish to add to the portfolio</label>
                  <div className="row">
                    <FilteredProperties
                      portfolio={portfolio}
                      onSelectedPropertiesChange={onSelectedPropertiesChange}
                      appraisal={appraisal}
                    />
                  </div>
                </div>

                {errors.properties && <div className="text-danger text-right fs-12">{errors.properties}</div>}
              </div>
              <div className="modal-footer">
                <PrimaryButton onClick={handleAddFormSubmit} type="submit">
                  {portfolio?.id ? 'Update' : 'Add'}
                </PrimaryButton>
                <SecondaryButton onClick={() => setShowModal(false)}> Cancel </SecondaryButton>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PortfolioModal;
