import React from 'react';
import { lazy, Suspense, useEffect } from 'react';
import Index from './jsx';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { checkAutoLogin } from './services/AuthService';
import { getUser, isAuthenticated } from './store/selectors/AuthSelectors';
import { initGTM, initHotjar, initPendo, startHotjar, usersnap } from './utils/tracking';
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';
import './scss/index.css';

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));
const SetupPassword = lazy(() => import('./jsx/pages/SetupPassword'));
const Login = lazy(() => import('./jsx/pages/Login'));

const allowedPaths = ['/reset', '/welcome'];

const App = ({ history, isAuthenticated, user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allowedPaths.includes(history.location.pathname)) {
      checkAutoLogin(dispatch, history);
    }
  }, [dispatch, history]);

  useEffect(() => {
    initGTM();
    initHotjar();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      startHotjar(user);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      usersnap(user);
      initPendo(user);
    }
  }, [isAuthenticated, user]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset" component={ResetPassword} />
      <Route path="/welcome" component={SetupPassword} />
    </Switch>
  );

  if (isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  }
  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {routes}
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    user: getUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
