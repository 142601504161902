import { post } from 'api';
import type { ApiDataResponse } from 'api/types';
import { geometryResponseAdaptor } from './adaptors';
import type {
  GeometryRecord,
  GeometryRequestOptions,
  GeometryResponse,
} from './types';

export const GEOMETRY_QUERY_KEY = ['geometry'];

export const getGeometry = async (
  options: GeometryRequestOptions,
  signal?: AbortSignal,
): Promise<GeometryRecord[]> => {
  const response = await post<ApiDataResponse<{ data: GeometryResponse[] }>>(
    'geometry/addresses',
    options,
    { signal },
  );
  return geometryResponseAdaptor(response.data.data);
};