import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export function searchCityIndices(data, cancelToken = {}) {
  return axiosInstance.post(`/cityindices/`, data, {
    ...cancelToken,
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export const apiSearchCityIndices = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/cityindices/`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error('get cpih index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiSearchCityExactIndex = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/cityindices/`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0];
    } else {
      throw new Error('get cpih index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
