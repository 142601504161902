import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export function searchAddresses(data, cancelToken = {}, allAddresses) {
  return axiosInstance.post(`propertyaddresses/${allAddresses ? 'all' : ''}`, data, {
    ...cancelToken,
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export function getCoordinates(data) {
  return axiosInstance.get(`propertyaddresses/point`, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    params: data,
  });
}
