import React, { useState } from 'react';

import { ReactComponent as ChevronLeft } from 'icons/custom/chevron-left.svg';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import ImageCarouselModal from '../../Dashboard/Modals/ImagesModal';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PropertyVisuals = ({ property }) => {
  const [modalImages, setModalImages] = useState([]);
  const [showImageCarouselModal, setShowImageCarouselModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const getCarouselProps = {
    showArrows: true,
    showThumbs: true,
    infiniteLoop: true,
    dynamicHeight: true,
    showStatus: false,
    showIndicators: true,
    swipeable: true,
    emulateTouch: true,
    renderArrowPrev: (clickHandler, hasPrev) => {
      return (
        <div
          className={`${
            hasPrev ? 'absolute' : 'hidden'
          } inset-y-1/2 left-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-10 m-3 mt-0 h-10 w-10`}
          onClick={clickHandler}
        >
          <ChevronLeft className="w-6 h-6" />
        </div>
      );
    },
    renderArrowNext: (clickHandler, hasNext) => {
      return (
        <div
          className={`${
            hasNext ? 'absolute' : 'hidden'
          } inset-y-1/2 right-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-10 m-3 mt-0 h-10 w-10`}
          onClick={clickHandler}
        >
          <ChevronLeft className="w-6 h-6" style={{ transform: 'rotate(180deg)' }} />
        </div>
      );
    },
  };

  const openImagesModal = (images, index) => {
    setModalImages(images);
    setCurrentImageIndex(index);
    setShowImageCarouselModal(true);
  };

  return (
    <>
      <ImageCarouselModal
        setShowModal={setShowImageCarouselModal}
        showModal={showImageCarouselModal}
        currentIndex={currentImageIndex}
        images={modalImages}
      ></ImageCarouselModal>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Images & Floor plans </h4>
      <div className="card p-4">
        <div className="row h-full overflow-hidden">
          {property?.property_images?.length ? (
            <div className="col-md-6 p-6">
              <h4 className="mb-4 text-base text-black"> Property Images </h4>
              <Carousel {...getCarouselProps}>
                {property?.property_images?.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => openImagesModal(property?.property_images, index)}
                    >
                      <img src={image} alt="carousel" />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            ''
          )}

          {property?.floor_plans?.length ? (
            <div className="col-md-6 p-6">
              <h4 className="mb-4 text-base text-black "> Property Floor Plans </h4>
              <Carousel {...getCarouselProps} style={{ maxHeight: 300 }}>
                {property?.floor_plans?.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => openImagesModal(property?.floor_plans, index)}
                    >
                      <img src={image} alt="carousel" style={{ maxHeight: 300, width: 'auto' }} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(PropertyVisuals);
