import React, { useState, useCallback, useRef, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { ReactComponent as SearchSVG } from 'icons/custom/search.svg';
import { ReactComponent as CloseSVG } from 'icons/custom/close-blue.svg';
import axios from 'axios';
import { searchCompany } from '../../../../api/company';

const CompanySearchAutocomplete = ({ onSelect }) => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const container = useRef(null);

  let cancelToken;
  const handleOnSearch = async function (keyword) {
    setLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      const response = await searchCompany({ companyName: keyword }, { cancelToken: cancelToken.token });
      if (response?.data?.data.length > 0) {
        const result = response.data.data.map((item) => ({
          ...item,
        }));
        setItems(result);
      } else {
        setItems([]);
      }
      setLoading(false);
    } catch (error) {
      setItems([]);
    }
  };

  const debouncedChangeHandler = useCallback(debounce(handleOnSearch, 200), []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeHandler = (event) => {
    let keyword = event.target.value;
    setSearchQuery(keyword);
    const keywordTrimmed = keyword.trim();
    if (keywordTrimmed && keywordTrimmed.length > 2) {
      if (keywordTrimmed !== searchQuery?.trim()) {
        debouncedChangeHandler(keywordTrimmed);
      }
    } else {
      setItems([]);
      setLoading(false);
    }
  };

  const selectHandler = (item) => {
    setSearchQuery(item.proprietor_name);
    setItems([]);
    onSelect(item);
  };

  const handleOutsideClick = useCallback((event) => {
    if (!container?.current?.contains(event.target)) {
      setShowItems(false);
    } else {
      setShowItems(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [container]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={container} className="relative text-xs" style={{ height: 40, zIndex: 1 }}>
      <div className="absolute w-100 rounded-lg bg-white location-search" style={{ border: '1px solid #DFE1E5' }}>
        <div className="pt-3 pb-3 pl-3 pr-3 flex items-center">
          <div>
            <SearchSVG className={'mr-3 ml-1.5'} />
          </div>
          <input
            className="w-100 text-black"
            type="text"
            placeholder="Search for a company name"
            value={searchQuery}
            onChange={changeHandler}
          />
          {searchQuery && !loading && (
            <div className="cursor-pointer" onClick={() => setSearchQuery('')}>
              <CloseSVG className={'mr-2 ml-1.5'} />
            </div>
          )}
        </div>

        {showItems && (
          <>
            {loading && (
              <>
                <div className="border-t mr-3 ml-3"></div>
                <div className="pl-3 pr-3 pt-2 pb-2"> Loading... </div>
              </>
            )}

            {!loading && Number(items?.length) === 0 && searchQuery?.length > 2 && (
              <>
                <div className="border-t mr-3 ml-3"></div>
                <div className="flex items-center pt-2.5 pb-2.5 pl-3 pr-3 text-black">
                  <div>
                    <SearchSVG className={'mr-3 ml-1.5'} />
                  </div>
                  <div> No Results </div>
                </div>
              </>
            )}

            {!loading && Number(items?.length) > 0 && (
              <>
                <div className="border-t mr-3 ml-3"></div>
                <div className="pt-2 pb-2">
                  {items.map((item, index) => {
                    return (
                      <div
                        className="flex items-center cursor-pointer pt-1.5 pb-1.5 pl-3 pr-3 text-black hover:bg-gray-200"
                        key={index}
                        onClick={() => selectHandler(item)}
                      >
                        <div>
                          <SearchSVG className={'mr-3 ml-1.5'} />
                        </div>
                        <div> {item.proprietor_name} </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default CompanySearchAutocomplete;
