import { useQuery } from '@tanstack/react-query';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { apiGetUserPortfolios } from '../../../../api/portfolio';
import DashboardPortfolioItem from './DashboardPortfolioItem';
import SecondaryButton from '../Buttons/SecondaryButton';
import { Link } from 'react-router-dom';
import { ReactComponent as RedirectBlueSvg } from 'icons/custom/redirect-blue.svg';
import emptyImg from 'icons/custom/empty-portfolios.png';

const DashboardPortfolios = ({ targetTime }) => {
  const { data: portfolios = [], isLoading: portfoliosLoading } = useQuery(['portfolios'], ({ signal }) =>
    apiGetUserPortfolios(signal),
  );

  return (
    <div className="card">
      <div className="card-body d-flex align-items-center p-4">
        <div className="card-data w-100 flex flex-column h-100 justify-between">
          <div className="flex justify-between items-center w-100 mb-2">
            <div className="text-base font-normal text-black">My Portfolios </div>
            <div className="text-xs"> Total {portfolios.length}</div>
          </div>

          {portfoliosLoading ? (
            <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
          ) : portfolios?.length > 0 ? (
            <div className="flex flex-column justify-center items-center">
              {portfolios.slice(0, 3).map((portfolio, index) => (
                <DashboardPortfolioItem key={index} portfolio={portfolio} targetTime={targetTime} />
              ))}
            </div>
          ) : (
            <div className="flex mt-8 flex-column justify-center items-center">
              <img className="h-52" src={emptyImg} alt="error" />
              <div className="text-sm text-black align-center"> There’s not portfolios yet </div>
              <Link className="cursor-pointer" to={`/portfolios`}>
                <SecondaryButton className="w-auto px-3 mt-4">
                  <RedirectBlueSvg className="mr-2" />
                  Add a Portfolio
                </SecondaryButton>
              </Link>
            </div>
          )}

          <div>
            {portfolios?.length > 0 ? (
              <Link className="ml-auto cursor-pointer mt-4" to={`/portfolios`}>
                <SecondaryButton className="w-auto px-3 ml-auto">
                  <RedirectBlueSvg className="mr-2" />
                  See all portfolios
                </SecondaryButton>
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPortfolios;
