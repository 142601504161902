import Select from 'react-select';
import { customSelectStyles, propertyTypeOptions } from '../../../../utils/constants';

export default function SelectTypeColumnFilter({ column }) {
  return (
    <div className="w-40 flex flex-column ml-3">
      <label className="font-medium text-black text-xs"> Property Type </label>
      <Select
        className="h-9 text-xs"
        defaultValue={propertyTypeOptions[0]}
        value={propertyTypeOptions.find(({ value }) => value === column.filterValue) || propertyTypeOptions[0]}
        onChange={(newOptionSelected) => column.setFilter(newOptionSelected.value)}
        styles={customSelectStyles}
        options={propertyTypeOptions}
        isSearchable={false}
      />
    </div>
  );
}
