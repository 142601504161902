import { useCallback, useEffect, useState } from 'react';
import RangeSliderItem from '../../Dashboard/Components/RangeSliderItem';
import Select from 'react-select';
import { ReactComponent as ResetFiltersSvg } from 'icons/custom/resetfilters.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import debounce from 'lodash.debounce';
import {
  customSelectStyles,
  numberOfBedroomsOptionsWithAll,
  propertyTypeOptionsWithAll,
} from '../../../../utils/constants';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';

export const numberOfTenantsOptionsWithAll = [
  { value: 'all', label: ' All ' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
];

const Filters = ({ updateFilter, loading }) => {
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [selectedNumberOfBedrooms, setSelectedNumberOfBedrooms] = useState([]);
  const [selectedNumberOfTenants, setSelectedNumberOfTenants] = useState([]);
  const [lhaRange, setLhaRange] = useState([0, 2000]);
  const [rentalPriceRange, setRentalPriceRange] = useState([0, 5000]);
  const [percentageDiff, setPercentageDiff] = useState([-10, 10]);

  const [filterData, setFilterData] = useState({
    district_code: '',
    property_type: [],
    number_of_bedrooms: [],
    tenants: [],
    offset: 0,
    limit: 10,
  });

  const handleMultiDropdownFilterFormChange = (selectedItems, selectField) => {
    const fieldName = selectField;
    let fieldValue = [];

    if (selectedItems.some((item) => item.value === 'all')) {
      if (selectField === 'property_type') {
        setSelectedPropertyTypes(propertyTypeOptionsWithAll.slice(1));
        fieldValue = propertyTypeOptionsWithAll.slice(1).map((item) => item.value);
      } else if (selectField === 'number_of_bedrooms') {
        setSelectedNumberOfBedrooms(numberOfBedroomsOptionsWithAll.slice(1));
        fieldValue = numberOfBedroomsOptionsWithAll.slice(1).map((item) => item.value);
      } else {
        setSelectedNumberOfTenants(numberOfTenantsOptionsWithAll.slice(1));
        fieldValue = numberOfTenantsOptionsWithAll.slice(1).map((item) => item.value);
      }
    } else {
      selectField === 'property_type'
        ? setSelectedPropertyTypes(selectedItems)
        : selectField === 'number_of_bedrooms'
        ? setSelectedNumberOfBedrooms(selectedItems)
        : setSelectedNumberOfTenants(selectedItems);
      fieldValue = selectedItems.map((item) => item.value);
    }

    const formData = { ...filterData };
    formData[fieldName] = fieldValue;
    setFilterData(formData);
  };

  const handleFilterFormChange = (e) => {
    let { name, value } = e.target;
    setFilterData((prevFilterData) => {
      if (name === 'district_code') {
        value = value.toUpperCase();
      }

      return {
        ...prevFilterData,
        [name]: value,
      };
    });
  };

  const resetFilters = function () {
    const formData = {
      offset: 0,
      limit: 10,
      old_new_lr: undefined,
      freehold_leasehold: undefined,
      distance_from: undefined,
    };

    setLhaRange([0, 2000]);
    setRentalPriceRange([0, 5000]);
    setPercentageDiff([-10, 10]);
    setSelectedPropertyTypes([]);
    setSelectedNumberOfBedrooms([]);
    setSelectedNumberOfTenants([]);
    setFilterData(formData);
  };

  useEffect(() => {
    const ranges = {
      min_lha: lhaRange[0],
      max_lha: lhaRange[1],
      min_rental_price: rentalPriceRange[0],
      max_rental_price: rentalPriceRange[1],
      min_lha_percentage_diff: percentageDiff[0] / 100,
      max_lha_percentage_diff: percentageDiff[1] / 100,
    };
    debouncedChangeHandler({ ...filterData, ...ranges });
  }, [lhaRange, rentalPriceRange, percentageDiff, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => updateFilter(params), 200),
    [],
  );

  return (
    <div className="p-5 pb-2">
      <div className="row">
        <div className="col-md-3">
          <h3 className="mb-2"> District Code </h3>
          <input
            className="px-2.5 h-10 py-2 w-full bg-white rounded-md mr-3 mb-2 border text-xs"
            placeholder=""
            type="text"
            onChange={handleFilterFormChange}
            value={filterData?.district_code || filterData?.district_code === 0 ? filterData?.district_code : ''}
            name="district_code"
          />
        </div>

        <div className="col-md-3">
          <h3 className="mb-2"> Property Type </h3>
          <Select
            className="mr-3 mb-2 min-h-10 text-xs"
            placeholder="Property Type"
            isMulti
            onChange={(item) => handleMultiDropdownFilterFormChange(item, 'property_type')}
            styles={customSelectStyles}
            options={propertyTypeOptionsWithAll}
            value={selectedPropertyTypes}
            isSearchable={false}
            name="property_type"
          />
        </div>

        <div className="col-md-3">
          <h3 className="mb-2"> Number of Bedrooms </h3>
          <Select
            className="mr-3 mb-2 min-h-10 text-xs"
            placeholder="Number of Bedrooms"
            isMulti
            onChange={(item) => handleMultiDropdownFilterFormChange(item, 'number_of_bedrooms')}
            styles={customSelectStyles}
            options={numberOfBedroomsOptionsWithAll}
            value={selectedNumberOfBedrooms}
            isSearchable={false}
            name="number_of_bedrooms"
          />
        </div>

        <div className="col-md-3">
          <h3 className="mb-2"> Number of Tenants </h3>
          <Select
            className="mr-3 mb-2 min-h-10 text-xs"
            placeholder="Number of Tenants"
            isMulti
            onChange={(item) => handleMultiDropdownFilterFormChange(item, 'tenants')}
            styles={customSelectStyles}
            options={numberOfTenantsOptionsWithAll}
            value={selectedNumberOfTenants}
            isSearchable={false}
            name="tenants"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <h3 className="mb-2"> LHA </h3>
          <RangeSliderItem
            title={''}
            min={0}
            max={2000}
            step={100}
            range={lhaRange}
            updateRange={setLhaRange}
            unit={'£'}
          ></RangeSliderItem>
        </div>
        <div className="col-md-3">
          <h3 className="mb-2"> Rental Price </h3>
          <RangeSliderItem
            title={''}
            min={0}
            max={5000}
            step={100}
            range={rentalPriceRange}
            updateRange={setRentalPriceRange}
            unit={'£'}
          ></RangeSliderItem>
        </div>
        <div className="col-md-3">
          <h3 className="mb-2"> Percentage Difference </h3>
          <RangeSliderItem
            title={''}
            min={-10}
            max={10}
            step={0.5}
            range={percentageDiff}
            updateRange={setPercentageDiff}
            unit={'%'}
          ></RangeSliderItem>
        </div>

        <div className="col-md-3 flex justify-end items-center">
          <div className="flex px-3">{loading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}</div>
          <PrimaryButton className="w-36 mr-3 " onClick={resetFilters}>
            <ResetFiltersSvg className="mr-1.5" />
            Reset Filters
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Filters;
