import React from 'react';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';

const PropertyParameter = ({ parameter }) => {
  return (
    <div>
      <div className="font-medium	text-base	mb-2	" style={{ color: '#747B8A' }}>
        {parameter.name}
      </div>
      <div
        className="flex flex-row font-medium	text-base	text-black items-center  pb-2 mb-3"
        style={{ borderBottom: '1px solid #F0F0F0' }}
      >
        <div className="w-6	mr-2.5">{parameter.icon}</div>
        {parameter.value ? (
          <>
            {parameter.value}
            {parameter?.note ? <InfoAsterisk text={parameter?.note} /> : ''}
          </>
        ) : (
          <span className="italic opacity-50"> - </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(PropertyParameter);
