import { useCallback, useEffect, useState } from 'react';
import RangeSliderItem from '../../Dashboard/Components/RangeSliderItem';
import Select from 'react-select';
import { ReactComponent as ResetFiltersSvg } from 'icons/custom/resetfilters.svg';

import debounce from 'lodash.debounce';
import { customSelectStyles } from '../../../../utils/constants';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import CustomSlider from './CustomSlider';

export const numberOfBathroomOptions = [
  { value: 'all', label: ' All ' },
  { value: '1.0', label: '1' },
  { value: '2.0', label: '2' },
  { value: '3.0', label: '3' },
  { value: '4.0', label: '4' },
  { value: '5.0', label: '5' },
];

export const newBuildOptions = [
  { value: null, label: 'Both' },
  { value: 'Y', label: 'New build' },
  { value: 'N', label: 'Secondary stock' },
];

export const tenureOptions = [
  { value: null, label: 'Both' },
  { value: 'F', label: 'Freehold' },
  { value: 'L', label: 'Leasehold' },
];

export const epcRatingOptions = [
  { value: 'all', label: ' All ' },
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'G', label: 'G' },
];

const FilterComparables = ({ updateFilter, property }) => {
  const [sizeRange, setSizeRange] = useState([0, 2700]);
  const [priceRange, setPriceRange] = useState([
    property?.price ? Math.round(property?.price * 0.7) : 0,
    property?.price ? Math.round(property?.price * 1.3) : 10000000,
  ]);

  const [selectedNumberOfBathrooms, setSelectedNumberOfBathrooms] = useState([]);
  const [selectedEpc, setSelectedEpc] = useState([]);

  const [filterData, setFilterData] = useState({
    bathrooms: [],
    current_energy_rating_epc: [],
    old_new_lr: null,
    distance_from: null,
    freehold_leasehold: null,
    null_toggle: true,
    offset: 0,
    limit: 10,
  });

  const handleDropdownFilterFormChange = (item, selectField) => {
    const fieldName = selectField;
    const fieldValue = item.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]) {
      formData[fieldName] = fieldValue;
      setFilterData(formData);
    }
  };

  const handleCheckboxChange = (event) => {
    const newCheckboxValue = event.target.checked;
    setFilterData((prevData) => ({
      ...prevData,
      null_toggle: newCheckboxValue,
    }));
  };

  const handleMultiDropdownFilterFormChange = (selectedItems, selectField) => {
    const fieldName = selectField;
    let fieldValue = [];

    if (selectedItems.some((item) => item.value === 'all')) {
      if (selectField === 'bathrooms') {
        setSelectedNumberOfBathrooms(numberOfBathroomOptions.slice(1));
        fieldValue = numberOfBathroomOptions.slice(1).map((item) => item.value);
      } else {
        setSelectedEpc(epcRatingOptions.slice(1));
        fieldValue = epcRatingOptions.slice(1).map((item) => item.value);
      }
    } else {
      selectField === 'bathrooms' ? setSelectedNumberOfBathrooms(selectedItems) : setSelectedEpc(selectedItems);
      fieldValue = selectedItems.map((item) => item.value);
    }

    const formData = { ...filterData };
    formData[fieldName] = fieldValue;
    setFilterData(formData);
  };

  const updateDistance = (value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      distance_from: parseFloat(value),
    }));
  };

  const resetFilters = function () {
    const formData = {
      offset: 0,
      limit: 10,
      old_new_lr: null,
      freehold_leasehold: null,
      distance_from: null,
    };

    setSizeRange([0, 2700]);
    setPriceRange([0, 10000000]);
    setSelectedNumberOfBathrooms([]);
    setSelectedEpc([]);
    setFilterData(formData);
    debouncedChangeHandler(formData);
  };

  useEffect(() => {
    const ranges = {
      min_size: sizeRange[0],
      max_size: sizeRange[1],
      min_price: priceRange[0],
      max_price: priceRange[1],
    };
    debouncedChangeHandler({ ...filterData, ...ranges });
  }, [sizeRange, priceRange, filterData]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((params) => updateFilter(params), 200),
    [],
  );

  return (
    <>
      <div className="row p-2">
        <div className="col-md-3">
          <h3 className="mb-2"> Property Size (sq ft) </h3>
          <RangeSliderItem
            title={''}
            min={0}
            max={2700}
            step={100}
            range={sizeRange}
            updateRange={setSizeRange}
            unit={'sq ft'}
            unitPlacement={'right'}
          ></RangeSliderItem>
        </div>
        <div className="col-md-3">
          <h3 className="mb-2"> Bathrooms </h3>
          <Select
            className="mr-3 mb-2 min-h-10 text-xs"
            placeholder="Bathrooms"
            isMulti
            onChange={(item) => handleMultiDropdownFilterFormChange(item, 'bathrooms')}
            styles={customSelectStyles}
            options={numberOfBathroomOptions}
            value={selectedNumberOfBathrooms}
            isSearchable={false}
            name="bathrooms"
          />
        </div>
        <div className="col-md-3">
          <h3 className="mb-2"> Build Type </h3>
          <Select
            className="mr-3 mb-2 h-10 text-xs"
            placeholder="Both"
            onChange={(item) => handleDropdownFilterFormChange(item, 'old_new_lr')}
            styles={customSelectStyles}
            options={newBuildOptions}
            value={newBuildOptions.find(({ value }) => value === filterData.old_new_lr) || ''}
            isSearchable={false}
            name="old_new_lr"
          />
        </div>
        <div className="col-md-3">
          <h3 className="mb-2"> Tenure </h3>
          <Select
            className="mr-3 mb-2 h-10 text-xs"
            placeholder="Tenure"
            onChange={(item) => handleDropdownFilterFormChange(item, 'freehold_leasehold')}
            styles={customSelectStyles}
            options={tenureOptions}
            value={tenureOptions.find(({ value }) => value === filterData.freehold_leasehold) || ''}
            isSearchable={false}
            name="freehold_leasehold"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <h3 className="mb-2"> EPC Rating </h3>
          <Select
            className="mr-3 mb-2 min-h-10 text-xs"
            placeholder="EPC Rating"
            isMulti
            onChange={(item) => handleMultiDropdownFilterFormChange(item, 'current_energy_rating_epc')}
            styles={customSelectStyles}
            options={epcRatingOptions}
            value={selectedEpc}
            isSearchable={false}
            name="current_energy_rating_epc"
          />
        </div>
        <div className="col-md-3">
          <h3 className="mb-2"> Distance from property (miles) </h3>
          <CustomSlider
            min={0}
            max={10}
            step={0.1}
            value={filterData?.distance_from ? filterData?.distance_from : ''}
            unit={'miles'}
            updateValue={updateDistance}
            unitPlacement={'right'}
          ></CustomSlider>
        </div>
        <div className="col-md-3">
          <h3 className="mb-2 flex items-center">Listing Price</h3>
          <RangeSliderItem
            title={''}
            min={0}
            max={10000000}
            step={10000}
            range={priceRange}
            updateRange={setPriceRange}
            unit={'£'}
          ></RangeSliderItem>
        </div>

        <div className="col-md-3 flex justify-end">
          <PrimaryButton className="w-36 mt-6 mr-3 mb-2" onClick={resetFilters}>
            <ResetFiltersSvg className="mr-1.5" />
            Reset Filters
          </PrimaryButton>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12">
          <input type="checkbox" id="null_toggle" checked={filterData.null_toggle} onChange={handleCheckboxChange} />
          <label htmlFor="null_toggle" className="text-xs ml-2">
            Include results where the filtered parameter is missing
          </label>
        </div>
      </div>
    </>
  );
};

export default FilterComparables;
