import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as SuccessSVG } from 'icons/custom/check-solid.svg';

import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { customSelectStyles } from '../../../../utils/constants';
import { createPortfolio, updatePortfolio } from '../../../../api/portfolio';
import Select from 'react-select';
import { useQueryClient } from '@tanstack/react-query';
import StatusModal from '../../Dashboard/Modals/StatusModal';

const formatOptions = (elements) => {
  return elements.map((element) => ({
    value: element,
    label: element.name,
  }));
};

const ValidCSVModal = ({ showModal, setShowModal, newProperties, portfolios }) => {
  const [portfolioMode, setPortfolioMode] = useState('');
  const [errors, setErrors] = useState({ name: '', description: '', portfolio: '' });
  const [portfolioData, setPortfolioData] = useState({ name: '', description: '' });
  const [selectedPortfolio, setSelectedPortfolio] = useState({});
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
    title: '',
  });
  const queryClient = useQueryClient();

  const handleAddFormChange = (event) => {
    event.preventDefault();
    setErrors({ name: '', description: '', properties: '' });
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...portfolioData };
    newFormData[fieldName] = fieldValue;
    setPortfolioData(newFormData);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let error = false;
    const errorObj = {};
    if (portfolioMode === 'create') {
      if (portfolioData.name === '') {
        errorObj.name = 'Name is Required';
        error = true;
      }

      if (portfolioData.description === '') {
        errorObj.description = 'Description is Required';
        error = true;
      }
    }

    if (portfolioMode === 'update' && !selectedPortfolio?.id) {
      errorObj.portfolio = 'Please select portfolio';
      error = true;
    }

    setErrors(errorObj);
    if (!error) {
      modifyPortfolio();
    }
  };

  const modifyPortfolio = async () => {
    try {
      const response =
        portfolioMode === 'update'
          ? await updatePortfolio(selectedPortfolio?.id, {
              name: selectedPortfolio.name,
              description: selectedPortfolio.description,
              properties: [...selectedPortfolio.properties, ...newProperties],
            })
          : await createPortfolio({
              name: portfolioData.name,
              description: portfolioData.description,
              properties: newProperties,
            });

      setShowModal(false);
      const modifiedPortfolio = response?.data?.data[0];
      queryClient.invalidateQueries(['portfolios']);

      if (portfolioMode === 'update') {
        queryClient.invalidateQueries(['portfolioAnalyticsData', selectedPortfolio.id]);
      }

      if (modifiedPortfolio) {
        const successText =
          portfolioMode === 'update' ? 'Portfolio successfully updated' : 'Portfolio successfully added';
        setStatusModalContent({
          show: true,
          type: 'success',
          description: successText,
          title: 'Voila!',
        });
      }
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
        title: 'Oops',
      });
    }
  };

  useEffect(() => {
    setPortfolioMode('');
    setErrors({ name: '', description: '', portfolio: '' });
    setPortfolioData({ name: '', description: '' });
    setSelectedPortfolio({});
  }, [showModal]);

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <Modal
        className="modal fade"
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        centered
        enforceFocus={false}
      >
        <div className="flex flex-column items-center	justify-center mt-4">
          <div className="flex w-full items-center justify-between px-4">
            <div className="text-black text-2xl font-medium">Upload CSV</div>
            <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
          </div>
          <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />

          <div className="px-4 pb-4 flex flex-column items-center justify-between">
            <div
              className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
              style={{ background: '#F5F8FE' }}
            >
              <SuccessSVG className="w-5 h-5" />
            </div>
            <div className="text-black text-base font-medium mb-2">
              You have successfully uploaded {newProperties.length} propert{newProperties.length > 1 ? 'ies' : 'y'}
            </div>

            <div className="text-black text-sm text-center mb-2">
              You can create a new portfolio with new properties or update an existing portfolio
            </div>

            {portfolioMode === 'create' ? (
              <div className="w-100 pt-3">
                <div className="form-group mb-3">
                  <label className="font-medium text-black text-xs">Portfolio Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs w-100"
                      autoComplete="off"
                      value={portfolioData?.name}
                      name="name"
                      maxLength={22}
                      required="required"
                      onChange={handleAddFormChange}
                      placeholder="Enter Portfolio Name"
                    />
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label className="font-medium text-black text-xs">Portfolio Description</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs w-100"
                      autoComplete="off"
                      value={portfolioData?.description}
                      name="description"
                      required="required"
                      maxLength={35}
                      onChange={handleAddFormChange}
                      placeholder="Enter Portfolio Description"
                    />
                    {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                  </div>
                </div>
              </div>
            ) : portfolioMode === 'update' ? (
              <div className="w-100 flex flex-column pt-3">
                <label className="font-medium text-black text-xs"> Select Portfolio </label>
                <Select
                  className="mr-3 mb-2 h-10 text-xs"
                  placeholder="Select Portfolio"
                  onChange={(item) => setSelectedPortfolio(item.value)}
                  styles={customSelectStyles}
                  options={formatOptions(portfolios)}
                  isSearchable={false}
                  name="portfolio"
                />
                {errors.portfolio && <div className="text-danger fs-12">{errors.portfolio}</div>}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-row w-full justify-between px-4 mb-4">
            {portfolioMode && (
              <PrimaryButton onClick={(event) => handleFormSubmit(event)}>
                {portfolioMode === 'update' ? 'Update' : 'Create'} Portfolio
              </PrimaryButton>
            )}

            {!portfolioMode && (
              <div className="flex flex-row">
                <PrimaryButton onClick={() => setPortfolioMode('create')}> Create New </PrimaryButton>
                <PrimaryButton className="ml-2" onClick={() => setPortfolioMode('update')}>
                  Update Existing
                </PrimaryButton>
              </div>
            )}

            <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ValidCSVModal;
