import type { NumberOfBedroomsOption, PropertyTypeKey, PropertyTypeLabel, PropertyTypeOption } from "./types";

export const PropertyTypeMap: Record<PropertyTypeKey, PropertyTypeLabel> = {
  F: 'Flat',
  T: 'Terraced',
  S: 'Semi-Detached',
  D: 'Detached',
};

export const NumberOfBedroomsMap: Record<number, number> = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

export const getPropertyTypeOptions = (filter?: PropertyTypeKey[]) => {
  return Object.entries(PropertyTypeMap)
    .filter(([key]) => !filter || filter.includes(key as PropertyTypeKey))
    .map<PropertyTypeOption>(([key, value]) => ({
      value: key as PropertyTypeKey,
      label: value,
    }));
};

export const getNumberOfBedroomsOptions = () => {
  return Object.entries(NumberOfBedroomsMap).map<NumberOfBedroomsOption>(
    ([key, value]) => ({
      value: Number.parseInt(key),
      label: value,
    }),
  );
};