let direction = 'ltr';

export const dezThemeSet = [
  {
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    headerBg: 'color_1',
    navheaderBg: 'color_1',
    sidebarBg: 'color_1',
    sidebarStyle: 'full',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'full',
    direction: direction,
  },
];
