import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const BaseChart = ({ series, categories }) => {
  const options = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: true,
      },
      animations: {
        enabled: false,
      },
    },
    forecastDataPoints: {
      count: 23,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'text',
      tickAmount: 30,
      categories: categories,
      labels: {
        rotate: -90,
        rotateAlways: true,
      },
      lines: {
        show: false,
      },
    },
    yaxis: {
      showAlways: true,
      labels: {
        formatter: function (val, index) {
          return val == null ? ' ' : numeral(val == null ? ' ' : parseInt(val.toString())).format();
        },
      },
    },
    colors: ['#3571E5', '#705fd3', '#10a37f', '#ff9800', '#00BFFF', '8B008B'],
  };

  return (
    <>
      <div id="chart2" className="bar-chart pb-3">
        <ReactApexChart options={options} series={series} type="area" height={350} />
      </div>
    </>
  );
};

export default BaseChart;
