import React from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as ExclamationSVG } from 'icons/custom/exclamation.svg';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';

const InvalidCSVModal = ({ showModal, setShowModal, handleRetry }) => {
  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center	justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-2xl font-medium">Upload CSV</div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
        <div
          className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
          style={{ background: '#F5F8FE' }}
        >
          <ExclamationSVG />
        </div>
        <div className="text-black text-base font-medium mb-2"> CSV file format is incorrect </div>

        <div className="text-grey text-xs mb-4">*Please check the file format and the data, then try again</div>
        <div className="flex flex-row w-full justify-between px-4 mb-4">
          {handleRetry ? <PrimaryButton onClick={() => handleRetry && handleRetry()}>Try Again</PrimaryButton> : ''}

          <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default InvalidCSVModal;
