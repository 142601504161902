import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ReactComponent as Info } from 'icons/custom/info-icon.svg';
import { ReactComponent as InfoActive } from 'icons/custom/info-icon-active.svg';

function InfoPopover({ title, description, placement = 'bottom' }) {
  return (
    <>
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip className="custom-info-tooltip">
            <div className="p-2">
              <div className="text-left pb-1 fs-16 text-black"> {title} </div>
              <div className="text-left fs-12 font-normal"> {description} </div>
            </div>
          </Tooltip>
        }
      >
        <div className="w-5 h-5 relative group" title="">
          <Info className="w-5 block opacity-100 group-hover:opacity-0 transition-opacity" />
          <InfoActive className="w-5 absolute opacity-0 top-0 left-0 group-hover:opacity-100 transition-opacity" />
        </div>
      </OverlayTrigger>
    </>
  );
}

export default InfoPopover;
