import React, { useMemo } from 'react';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import RentsTable from './RentalMarketTables/RentsTable';
import MarketTable from './RentalMarketTables/MarketTable';
import SummaryStatistics from './RentalMarketTables/SummaryStatistics';
import DepthOfMarketTable from './RentalMarketTables/DepthOfMarketTable';
import RentsPaidChartWrapper from './RentalMarketTables/RentsPaidChartWrapper';

import { apiRentalMarketDistrict, apiRentalMarketArea } from 'api/avm';
import { useQuery } from '@tanstack/react-query';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

function formatData(data) {
  let result = { Flat: {}, House: {} };

  data.forEach((item) => {
    const { type, number_of_bedrooms } = item;

    const label = number_of_bedrooms === 0 ? 'Studio' : `${number_of_bedrooms} Bed`;

    if (!result[type]) result[type] = {};
    if (!result[type][label]) result[type][label] = {};

    result[type][label] = {
      avgRentPaid12m: item.avg_rent_paid_12m,
      avgRentTop25: item.avg_rent_paid_12m_top25,
      avgRentTop10: item.avg_rent_paid_12m_top10,
      lowerQuartile: item.lower_quartile,
      medianQuartile: item.median_quartile,
      upperQuartile: item.upper_quartile,
      mostActiveRentalBand: item.most_active_rental_band,
    };
  });

  return result;
}

function combineData(districtData, areaData) {
  const result = {};

  const propertyTypes = new Set([...Object.keys(districtData), ...Object.keys(areaData)]);

  propertyTypes.forEach((type) => {
    const districtTypeData = districtData[type] || {};
    const areaTypeData = areaData[type] || {};

    result[type] = {};

    const labels = new Set([...Object.keys(districtTypeData), ...Object.keys(areaTypeData)]);

    labels.forEach((label) => {
      const districtLabelData = districtTypeData[label] || {};
      const areaLabelData = areaTypeData[label] || {};

      result[type][label] = {
        ...Object.fromEntries(
          Object.entries(districtLabelData).map(([key, value]) => [
            key,
            { district: value, area: areaLabelData[key] || null },
          ]),
        ),
        ...Object.fromEntries(
          Object.entries(areaLabelData)
            .filter(([key]) => !districtLabelData[key])
            .map(([key, value]) => [key, { district: null, area: value }]),
        ),
      };
    });
  });

  return result;
}

const flatCategories = ['Studio', '1 Bed', '2 Bed', '3 Bed'];
const houseCategories = ['2 Bed', '3 Bed', '4 Bed'];

const RentalMarketReport = ({ property }) => {
  const districtQuery = {
    district: property?.property_index_data?.district_code,
    limit: 20,
    offset: 0,
  };
  const areaQuery = {
    area_code: property?.property_index_data?.area_code,
    limit: 20,
    offset: 0,
  };

  const {
    data: rentalMarketDistrict = [],
    isLoading: rentalMarketDistrictDataLoading,
    isSuccess: rentalMarketDistrictDataLoaded,
  } = useQuery(['rentalMarketDistrict', districtQuery], ({ signal }) => apiRentalMarketDistrict(districtQuery, signal));

  const {
    data: rentalMarketArea = [],
    isLoading: rentalMarketAreaDataLoading,
    isSuccess: rentalMarketAreaDataLoaded,
  } = useQuery(['rentalMarketArea', areaQuery], ({ signal }) => apiRentalMarketArea(areaQuery, signal));

  const combinedData = useMemo(() => {
    if (rentalMarketDistrictDataLoaded && rentalMarketAreaDataLoaded) {
      const districtFormattedData = formatData(rentalMarketDistrict);
      const areaFormattedData = formatData(rentalMarketArea);

      return combineData(districtFormattedData, areaFormattedData);
    } else {
      return { Flat: {}, House: {} };
    }
  }, [rentalMarketDistrict, rentalMarketArea, rentalMarketDistrictDataLoaded, rentalMarketAreaDataLoaded]);

  const isLoading = rentalMarketDistrictDataLoading || rentalMarketAreaDataLoading;

  return (
    <>
      <div>
        <h4 className="mb-2.5 ml-2 ">
          <div className="row">
            <div className="col-12 flex flex-col lg:flex-row items-center justify-between">
              <div className="fs-20 text-black">Rental Market Report</div>
              <div className="flex items-center justify-center">
                <div className="inline-flex items-center ml-4">
                  District ({property?.property_index_data?.district_code})
                  <div
                    className="w-8 ml-2 h-5 border-2 border-white"
                    style={{ backgroundColor: '#3571E5', borderRadius: '6px' }}
                  ></div>
                </div>
                <div className="inline-flex items-center ml-4">
                  Area ({property?.property_index_data?.area_code})
                  <div
                    className="w-8 ml-2 h-5 border-2 border-white"
                    style={{ backgroundColor: '#FF4560', borderRadius: '6px' }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </h4>

        <div className="card p-4">
          <div className="row w-100">
            <div className="col-12 col-lg-8 mb-8">
              {isLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <div className=" overflow-x-auto w-100">
                  <div className="flex" style={{ minWidth: '600px' }}>
                    <MarketTable data={combinedData.Flat} type="Flats" categories={flatCategories} />
                    <MarketTable data={combinedData.House} type="Houses" categories={houseCategories} />
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-lg-4 ">
              {combinedData && property ? (
                <SummaryStatistics currentProperty={property} combinedData={combinedData} />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h4 className="mb-2.5 ml-2 fs-20 text-black">Rents paid</h4>
        <div className="card p-2">
          <div className="w-100">
            {isLoading ? (
              <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
            ) : (
              <>
                <div className="mb-10">
                  <div className="flex flex-col lg:flex-row overflow-x-auto w-100">
                    <div>
                      <RentsTable data={combinedData.Flat} type="Flats" categories={flatCategories} />
                    </div>
                    <div>
                      <RentsTable data={combinedData.House} type="Houses" categories={houseCategories} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row w-full px-4">
                  <RentsPaidChartWrapper combinedData={combinedData} property={property} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <h4 className="mb-2.5 ml-2 fs-20 text-black"> Depth of the market </h4>
        <div className="card p-2">
          <div className="flex w-100">
            {isLoading ? (
              <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
            ) : (
              <DepthOfMarketTable currentProperty={property} district={rentalMarketDistrict} area={rentalMarketArea} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalMarketReport;
