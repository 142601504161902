import axios, { type AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

export const BASE_URL = `${process.env.REACT_APP_API_URL}/pmt/v1`;

export const HTTP = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const post = <T>(
  path: string,
  data: unknown,
  options: AxiosRequestConfig = {}
): Promise<T> => {
  return HTTP.post(`${path}`, data, 
    {
      ...options,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
};
