import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as HomeSvg } from 'icons/custom/home-icon.svg';

import { apiGetPortfolioAnalyticsById } from '../../../../api/portfolio';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import PillRenderer from './PillRenderer';
import { useMemo } from 'react';
import moment from 'moment';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const DashboardPortfolioItem = ({ portfolio, targetTime }) => {
  const {
    data: analyticsData = {},
    isLoading: loading,
    isSuccess: portfolioLoaded,
  } = useQuery(['portfolioAnalyticsData', portfolio.id], ({ signal }) =>
    apiGetPortfolioAnalyticsById(portfolio.id, signal),
  );

  const portfolioData = useMemo(() => {
    if (portfolioLoaded) {
      const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
      const formattedPrevDate = moment(targetTime).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

      let portfolioRentPrice = 0;
      let portfolioPrevRentPrice = 0;

      if (analyticsData.property_analytics && analyticsData.property_analytics.length) {
        for (const property of analyticsData.property_analytics) {
          const rentPrice = property.rental_index_data?.forecast
            ? property.rental_index_data?.forecast[formattedCurrentDate]
            : 0;
          const prevRentPrice = property.rental_index_data?.forecast
            ? property.rental_index_data?.forecast[formattedPrevDate]
            : 0;

          portfolioRentPrice = portfolioRentPrice + rentPrice;
          portfolioPrevRentPrice = portfolioPrevRentPrice + prevRentPrice;
        }
      }

      return {
        ...analyticsData,
        chgActual: !isNaN(parseFloat(analyticsData?.chg_1m)) ? parseFloat(analyticsData?.chg_1m) : 0,
        rentPrice: portfolioRentPrice,
        chgRentActual: portfolioPrevRentPrice
          ? ((portfolioRentPrice - portfolioPrevRentPrice) / portfolioPrevRentPrice) * 100
          : undefined,
      };
    } else {
      return {};
    }
  }, [analyticsData, portfolioLoaded, targetTime]);

  return (
    <Link
      className="cursor-pointer flex hover:text-black "
      title={portfolioData.name}
      to={`/portfolio-analytics/${portfolioData.id}`}
    >
      <div key={portfolioData.id} className="w-100 my-4 px-1" style={{ borderBottom: '1px solid #DFE1E5' }}>
        <h5 className="pb-2 text-sm font-normal text-black"> {portfolioData.name} </h5>

        <div className="text-xs grid grid-cols-4 gap-2">
          <div className="flex h-full flex-col justify-between py-2">
            <div className="pb-2 text-xs"> Properties </div>
            <div className="text-xs font-semibold text-black flex items-center">
              <HomeSvg className="h-4" />
              {portfolioData.properties?.length}
            </div>
          </div>
          <div className="flex h-full flex-col justify-between py-2">
            <div className="pb-2 text-xs"> Portfolio Value </div>
            <div className="text-xs font-semibold text-black">
              {loading ? (
                <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
              ) : portfolioData.portfolio_value ? (
                numeral(portfolioData.portfolio_value).format()
              ) : (
                '-'
              )}
            </div>
          </div>

          <div className="flex h-full flex-col justify-between py-2">
            <div className="pb-2 text-xs"> Last Month Price Change </div>
            <div className="text-xs font-semibold text-black">
              {loading ? (
                <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
              ) : portfolioData.chgActual ? (
                <PillRenderer value={portfolioData.chgActual} />
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="flex h-full flex-col justify-between py-2">
            <div className="pb-2 text-xs"> Last Month Rent Change </div>
            <div className="text-xs font-semibold text-black">
              {loading ? (
                <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
              ) : portfolioData.chgRentActual ? (
                <PillRenderer value={portfolioData.chgRentActual} />
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DashboardPortfolioItem;
