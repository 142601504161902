import React from 'react';
import { ReactComponent as BedSVG } from 'icons/custom/bed-sm.svg';
import { ReactComponent as TagSVG } from 'icons/custom/tag.svg';
import { ReactComponent as HouseSVG } from 'icons/custom/house.svg';
import { ReactComponent as LocationSVG } from 'icons/custom/location-blue.svg';
import { propertySVGs } from '../../../../utils/helpers';
import { ReactComponent as IncreaseSVG } from 'icons/custom/increase.svg';
import { ReactComponent as DecreaseSVG } from 'icons/custom/decrease.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const TransactionItem = ({ property, transaction, hideBorder }) => {
  const priceDifference = transaction.sold_price - transaction.asking_price;
  const percentageDifference = priceDifference / ((transaction.sold_price + transaction.asking_price) / 2);

  return (
    <div className="pb-8 relative">
      {!hideBorder ? (
        <div className="absolute top-0 left-8 h-full border-l" style={{ borderColor: '#A1ACC3' }}></div>
      ) : (
        ''
      )}
      <div
        style={{
          backgroundColor: transaction.type === 'transaction' ? '#F2F6FB' : '#FFFFFF',
          border: '1px solid #a1acc366',
        }}
        className="absolute top-0 left-8 transform -translate-x-1/2 rounded-xl px-2 py-1 text-sm"
      >
        {transaction.type === 'transaction' ? 'Sold' : transaction.type === 'listing' ? 'Listed' : ''}
      </div>
      <div
        className="relative rounded-tr-xl rounded-br-xl rounded-bl-xl rounded-tl-0 2 p-2 ml-16"
        style={{ backgroundColor: transaction.type === 'transaction' ? '#F2F6FB' : '#FFFFFF' }}
      >
        <div className="flex justify-between">
          <div style={{ color: '#747B8A' }}>{transaction.sortingDate}</div>

          <div className="flex flex-col	items-end">
            {transaction.type === 'transaction' ? (
              <>
                <div className="flex items-center font-medium text-base text-black">
                  <TagSVG className="h-6 w-6 mr-2" /> {numeral(transaction.sold_price).format()}
                </div>

                <div className="h-6">
                  {transaction.asking_price ? (
                    <div
                      className="flex items-baseline mt-0.5"
                      style={{ color: priceDifference > 0 ? '#32D16D' : '#FF4015' }}
                    >
                      <span className="text-xs mr-2">
                        {priceDifference > 0 ? '+' : ''}
                        {numeral(priceDifference).format()}
                      </span>
                      <span className="text-base font-medium flex items-center">
                        {numeral(percentageDifference).format('%0')}
                        {priceDifference > 0 ? <IncreaseSVG className="h-4" /> : <DecreaseSVG className="h-4" />}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <div className="flex items-center font-medium text-base text-black">
                <TagSVG className="h-6 w-6 mr-2" /> {numeral(transaction.asking_price).format()}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between flex-wrap gap-2 mt-3">
          <div className="text-left italic font-light">
            {transaction.modifiedRooms ? 'No. of bedrooms changed' : ''}
          </div>
          <div className="text-right"> {transaction.type === 'listing' ? 'Listed Price' : ''}</div>
        </div>

        <div className="flex justify-between text-xs mt-2 flex-wrap gap-2" style={{ color: '#7B8FB7' }}>
          <div
            className="flex items-center py-1 px-2 rounded-xl"
            style={{ backgroundColor: transaction.type === 'transaction' ? '#FFFFFF' : '#F2F6FB' }}
          >
            <LocationSVG className="h-4 w-4 mr-1" />
            District <span className="font-medium pl-1"> {property?.property_index_data?.district_code} </span>
          </div>

          {transaction.property_type ? (
            <div
              className="flex items-center py-1 px-2 rounded-xl"
              style={{ backgroundColor: transaction.type === 'transaction' ? '#FFFFFF' : '#F2F6FB' }}
            >
              <HouseSVG className="h-4 w-4 mr-1" />
              <span className="font-medium"> {propertySVGs[transaction.property_type]?.name} </span>
            </div>
          ) : (
            ''
          )}
          <div>
            {transaction.bedrooms !== undefined ? (
              <div
                className="flex items-center py-1 px-2 rounded-xl"
                style={{ backgroundColor: transaction.type === 'transaction' ? '#FFFFFF' : '#F2F6FB' }}
              >
                <BedSVG className="h-4 w-4 mr-1" /> Bedrooms
                <span className="font-medium pl-1"> {transaction.bedrooms} </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TransactionItem);
