import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import axios from 'axios';
import 'numeral/locales/en-gb';
import { DebounceInput } from 'react-debounce-input';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import { searchSuperIndicesData } from '../../../../api/superindexes';
import { customSelectStyles, numberOfBedroomsOptions, propertyTypeOptions } from '../../../../utils/constants';

const DetailedSearchModal = ({ showModal, setShowModal, handleSuccess }) => {
  const [filterData, setFilterData] = useState({
    order_by: 'Chg_5Y',
    offset: 0,
    desc: true,
  });
  const [loading, setLoading] = useState(false);
  const [superIndices, setSuperIndices] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);

  const handleFilterFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const formData = { ...filterData };
    const fieldValueTrimmed = fieldValue.trim();
    if (fieldValueTrimmed !== filterData[fieldName]?.trim()) {
      formData[fieldName] = fieldValue;
      updateFilter(formData);
    }
  };

  const handleDropdownFilterFormChange = (item, selectField) => {
    const fieldName = selectField;
    const fieldValue = item.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]?.trim()) {
      formData[fieldName] = fieldValue;
      updateFilter(formData);
    }
  };

  const updateFilter = (formData) => {
    setFilterData(formData);
    debouncedChangeHandler(formData);
  };

  let cancelToken;
  const getSearchData = async (filter) => {
    setLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      let num_of_bedrooms = parseInt(filter?.num_of_bedrooms);
      const searchResponse = await searchSuperIndicesData(
        {
          offset: filter?.offset,
          limit: 10,
          area_code: filter?.area_code?.toUpperCase(),
          district: filter?.district?.toUpperCase(),
          property_type: filter?.property_type ? [filter?.property_type] : undefined,
          num_of_bedrooms: num_of_bedrooms ? [num_of_bedrooms] : undefined,
          order_by: filter?.order_by,
          post_town: filter?.post_town?.toUpperCase(),
          ascending: !filter?.desc,
          ...filter.ranges,
        },
        { cancelToken: cancelToken.token },
      );

      if (searchResponse && searchResponse.data) {
        setSuperIndices(searchResponse?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleCheckboxEvent = (superIndex) => {
    const selectedIndicesTemp = [...selectedIndices];
    if (selectedIndicesTemp.includes(superIndex.super_index_name)) {
      selectedIndicesTemp.splice(selectedIndicesTemp.indexOf(superIndex.super_index_name), 1);
    } else {
      selectedIndicesTemp.push(superIndex.super_index_name);
    }
    setSelectedIndices(selectedIndicesTemp);
  };

  const debouncedChangeHandler = useCallback(debounce(getSearchData, 200), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickSave = () => {
    const finalArray = superIndices.filter((array) =>
      selectedIndices.some((filter) => filter === array.super_index_name),
    );
    handleSuccess && handleSuccess(finalArray);
    setShowModal(false);
  };

  const handleReset = () => {
    setFilterData({
      order_by: 'Chg_5Y',
      offset: 0,
      desc: true,
    });
    setSuperIndices([]);
    setSelectedIndices([]);
    handleSuccess && handleSuccess([], true);
    setShowModal(false);
  };

  return (
    <Modal className="fade" show={showModal} onHide={setShowModal} size="lg" centered enforceFocus={false}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20"> Search Filters </h4>
              <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              <div>
                <div className="flex w-full flex-row pb-2">
                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Location </label>
                    <DebounceInput
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-md mr-3 border text-xs"
                      placeholder="e.g. London"
                      onChange={handleFilterFormChange}
                      value={filterData.post_town || ''}
                      debounceTimeout={500}
                      name="post_town"
                    />
                  </div>

                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
                    <Select
                      className="text-xs h-10"
                      placeholder="Number of Bedrooms"
                      styles={customSelectStyles}
                      onChange={(item) => handleDropdownFilterFormChange(item, 'num_of_bedrooms')}
                      options={numberOfBedroomsOptions}
                      isSearchable={false}
                      name="num_of_bedrooms"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-row pb-2">
                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Property Type </label>
                    <Select
                      className="text-xs h-10"
                      placeholder="Property Type"
                      styles={customSelectStyles}
                      onChange={(item) => handleDropdownFilterFormChange(item, 'property_type')}
                      options={propertyTypeOptions}
                      isSearchable={true}
                      name="property_type"
                    />
                  </div>

                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> Area Code </label>
                    <DebounceInput
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-md mr-3 border text-xs"
                      placeholder="e.g. SW"
                      onChange={handleFilterFormChange}
                      value={filterData.area_code || ''}
                      debounceTimeout={500}
                      name="area_code"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-row pb-2">
                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    <label className="font-medium text-black text-xs"> District Code </label>
                    <DebounceInput
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-md mr-3 border text-xs"
                      placeholder="e.g. SW6"
                      onChange={handleFilterFormChange}
                      value={filterData.district || ''}
                      debounceTimeout={500}
                      name="district"
                    />
                  </div>
                  <div className="form-group mb-2 mx-2 w-1/2 flex flex-col">
                    {loading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
                  </div>
                </div>

                {superIndices?.length > 0 && (
                  <div className="p-2">
                    <div className="fs-16 text-black my-2"> Select the Search Result </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                      {superIndices.map((superIndex, index) => (
                        <div key={index} className="flex justify-start align-center">
                          <input
                            type="checkbox"
                            id={superIndex.super_index_name}
                            onChange={(event) => {
                              handleCheckboxEvent(superIndex);
                            }}
                            checked={selectedIndices?.includes(superIndex.super_index_name)}
                          />
                          <label className="mb-0 ml-4" htmlFor={superIndex.super_index_name}>
                            {`${superIndex.post_town}, Area: ${superIndex.area_code}, District: ${superIndex.district}, ${superIndex.property_type}, ${superIndex.number_of_bedrooms}`}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="flex w-100 justify-between">
                <SecondaryButton className="m-1" onClick={() => handleReset()} type="button">
                  Reset
                </SecondaryButton>
                <div className="flex">
                  <SecondaryButton className="m-1" onClick={() => setShowModal(false)} type="button">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    className="m-1"
                    onClick={() => handleClickSave()}
                    type="button"
                    disabled={!selectedIndices.length}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DetailedSearchModal;
