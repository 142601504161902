import Select from 'react-select';
import { customSelectStyles, numberOfBedroomsOptions } from '../../../../utils/constants';

export default function SelectBedroomsColumnFilter({ column }) {
  return (
    <div className="md:w-40 flex flex-column ml-3">
      <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
      <Select
        className="h-9 text-xs"
        defaultValue={numberOfBedroomsOptions[0]}
        value={numberOfBedroomsOptions.find(({ value }) => value === column.filterValue) || numberOfBedroomsOptions[0]}
        onChange={(newOptionSelected) => column.setFilter(newOptionSelected.value)}
        styles={customSelectStyles}
        options={numberOfBedroomsOptions}
        isSearchable={false}
      />
    </div>
  );
}
