import { cn } from '@/lib/utils';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { HomeIcon, MapPinIcon } from 'lucide-react';
import type { FC } from 'react';

interface HomeMapMarkerProps {
  latitude: number;
  longitude: number;
  pinColor?: string;
  className?: string;
}

export const HomeMapMarker: FC<HomeMapMarkerProps> = ({
  latitude,
  longitude,
  pinColor = 'rgb(239 68 68)',
  className,
}) => {
  return (
    <AdvancedMarker
      position={{
        lat: latitude,
        lng: longitude,
      }}
      className={cn('relative', className)}
    >
      <MapPinIcon
        className="drop-shadow-lg h-12 text-white w-12"
        fill={pinColor}
        strokeWidth={1}
      />
      <div
        className="absolute h-5 left-3.5 text-white top-2.5 w-5"
        style={{ backgroundColor: pinColor }}
      />
      <HomeIcon
        className="absolute h-5 left-3.5 text-white top-2.5 w-5"
        strokeWidth={2}
      />
    </AdvancedMarker>
  );
};

export default HomeMapMarker;
