import Table from '../Dashboard/Tables/BaseTable';
import { ReactComponent as ImagesIcon } from 'icons/custom/images-icon.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';
import { useEffect, useState } from 'react';
import ImageGalleryModal from '../PropertyComparables/Components/ImageGallery';
import { KILOMETERS_TO_MILES } from '../../../utils/constants';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const ListingTable = ({
  listings,
  onSelectedRowsChange,
  controlledSelectedRows,
  pageCount,
  pageIndex,
  fetchData,
  loading,
}) => {
  const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(false);

  const columnsMetadata = {
    index: {
      Header: '#',
      filterable: false,
      accessor: (_row, i) => i + 1,
      disableSortBy: true,
      hideSort: true,
    },
    address: {
      Header: 'Address',
      id: 'address',
      accessor: (d) => {
        return (
          <div className="flex align-center justify-between">
            <p className="overflow-ellipsis overflow-hidden whitespace-nowrap">{d?.address}</p>
            {d?.floorplans?.length || d?.images?.length ? (
              <OverlayTrigger
                bsPrefix="range-popover"
                trigger="click"
                rootClose={true}
                key="1"
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Body className="w-64">
                      <div>
                        <img
                          src={d?.images?.length ? d?.images[0] : d?.floorplans?.length ? d?.floorplans[0] : ''}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-end mt-2">
                        <PrimaryButton className="ml-auto px-3 py-2 w-auto h-auto" onClick={() => viewGalleryClick(d)}>
                          Open Gallery
                        </PrimaryButton>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className={`ml-2`}>
                  <ImagesIcon className="w-6 cursor-pointer" />
                </div>
              </OverlayTrigger>
            ) : (
              ''
            )}
          </div>
        );
      },
      headerStyle: {
        minWidth: '270px',
      },
      style: {
        color: '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '210px',
        paddingRight: '10px',
      },
      filterable: false,
    },
    size: {
      Header: 'Property Size (sq ft)',
      headerStyle: { width: '140px' },
      filterable: false,
      accessor: 'size',
      formatter: (x) => (x ? x : '-'),
    },
    price_lr: {
      Header: 'Price (£)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'price_lr',
      formatter: (x) => (x ? numeral(x).format() : '-'),
    },
    date: {
      Header: 'Listing date',
      headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      style: { width: '120px', maxWidth: '120px', minWidth: '120px' },
      filterable: false,
      accessor: 'date',
      formatter: (x) => (x ? x : '-'),
    },
    old_new_lr: {
      Header: 'New Build',
      headerStyle: { width: '140px' },
      filterable: false,
      accessor: 'old_new_lr',
      formatter: (x) => (x ? (x === 'N' ? 'No' : 'Yes') : '-'),
    },
    bathrooms: {
      Header: 'Bathrooms',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'bathrooms',
      formatter: (x) => (x ? parseInt(x) : '-'),
    },
    freehold_leasehold: {
      Header: 'Tenure',
      headerStyle: { width: '140px' },
      filterable: false,
      accessor: 'freehold_leasehold',
      formatter: (x) => (x ? (x === 'F' ? 'Freehold' : 'Leasehold') : '-'),
    },
    current_energy_rating_epc: {
      Header: 'EPC rating',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'current_energy_rating_epc',
      formatter: (x) => (x && x !== 'None' ? x : '-'),
    },
    distance: {
      Header: 'Distance Radius',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'distance',
      formatter: (x) => (x ? numeral(x * KILOMETERS_TO_MILES).format('0.00') : '-'),
    },
    rental_yield: {
      Header: 'Rental Yield',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'rental_yield',
      formatter: (x) => (x ? numeral(x).format('0.00%') : '-'),
    },
  };

  const viewGalleryClick = (item) => {
    setSelectedProperty({ ...item, property_images: item.images, floor_plans: item.floorplans });
    setShowImageGalleryModal(true);
  };

  useEffect(() => {}, [controlledSelectedRows?.length]);

  return (
    <>
      <ImageGalleryModal
        setShowModal={setShowImageGalleryModal}
        showModal={showImageGalleryModal}
        property={selectedProperty}
      ></ImageGalleryModal>

      <Table
        columnsMetadata={columnsMetadata}
        sampleTableData={listings}
        onSelectedRowsChange={onSelectedRowsChange}
        controlledSelectedRows={controlledSelectedRows}
        filtersActive={false}
        paginationActive={true}
        staticData={false}
        title=""
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </>
  );
};

export default ListingTable;
