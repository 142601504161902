import React from 'react';

const SecondaryButton = ({ onClick, children, className, disabled = false, type = 'button', styles }) => {
  return (
    <button
      className={`w-32 h-10 rounded-lg flex items-center justify-center font-medium text-xs cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
      style={{
        color: '#3571E5',
        border: '1px solid rgba(53, 113, 229, 0.6)',
        ...styles,
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
