import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { loadingToggleAction, loginAction } from '../../store/actions/AuthActions';
import AuthWrapper from '../layouts/AuthWrapper';

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);

  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }

  return (
    <AuthWrapper>
      <div className="auth-form-1">
        <div className="mb-4">
          <h3 className="dz-title mb-1" style={{ color: '#454B59' }}>
            Sign in using
          </h3>
          <p className="">your email credentials</p>
        </div>
        {props.errorMessage && (
          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">{props.errorMessage}</div>
        )}
        {props.successMessage && (
          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">{props.successMessage}</div>
        )}
        <form onSubmit={onLogin}>
          <div className="form-group">
            <label className="mb-2 ">
              <strong>Email</strong>
            </label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Type Your Email Address"
            />
            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
          </div>
          <div className="form-group">
            <label className="mb-2 ">
              <strong>Password</strong>
            </label>
            <input
              type="password"
              className="form-control"
              value={password}
              placeholder="Type Your Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary btn-block">
              Sign In
            </button>
          </div>
        </form>

        <div className="new-account mt-2">
          <p className="">
            Forgot Password?{' '}
            <Link className="text-primary" to="./forgot-password">
              Reset
            </Link>
          </p>
        </div>
      </div>
    </AuthWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
