import React, { useCallback, useMemo, useState } from 'react';
import CompanySearchAutocomplete from './Components/CompanySearchAutocomplete';
import { searchPropertiesByCompany } from '../../../api/company';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import PropertyGenerationStatusModal from '../Dashboard/Components/PropertyGenerationStatusModal';
import { useQuery } from '@tanstack/react-query';
import SearchResultsTable from './Components/SearchResultsTable';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const CompanySearch = () => {
  const [selectedCompany, setSelectedCompany] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [filterData, setFilterData] = useState({
    offset: 0,
    limit: 10,
  });

  const {
    data: searchResult,
    isLoading,
    isSuccess: searchResultLoaded,
  } = useQuery(['search-company', selectedCompany?.proprietor_name, filterData], ({ signal }) =>
    searchPropertiesByCompany(
      {
        ...filterData,
        proprietor_names: [selectedCompany?.proprietor_name],
      },
      signal,
    ),
  );

  const selectCompany = (selectedItem) => {
    setSelectedCompany(selectedItem);
    setFilterData({ offset: 0, limit: 10 });
    setPageIndex(0);
    setPageCount(0);
  };

  const searchResultMemoized = useMemo(() => {
    if (searchResultLoaded) {
      setPageCount(Math.ceil(searchResult?.total_count / 10));
      setSearchResultList(searchResult?.data);
      return searchResult?.data;
    } else {
      return [];
    }
  }, [searchResultLoaded, searchResult]);

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.order_dir = sortBy[0].desc ? 'desc' : 'asc';
        formData.order_by = sortBy[0].id;
      }

      setPageIndex(pageIndex);
      setFilterData(formData);
    },
    [filterData],
  );

  return (
    <>
      <PropertyGenerationStatusModal
        setShowModal={setShowStatusModal}
        showModal={showStatusModal}
        selectedProperties={searchResultMemoized.length}
      />
      <div className="card rounded-lg p-4">
        <div className="form-group mb-3 mx-2">
          <label className="text-black font-w500">Company Name</label>
          <div className="contact-name">
            <CompanySearchAutocomplete
              onSelect={(selectedItem) => {
                selectCompany(selectedItem);
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-5">
        {searchResultList.length ? (
          <div className="p-2">
            <div className="flex items-center mb-4">
              <h1 className="text-black font-w500 fs-20 mt-0">{selectedCompany?.proprietor_name}</h1>
            </div>

            <SearchResultsTable
              items={searchResultList}
              pageCount={pageCount}
              pageIndex={pageIndex}
              fetchData={fetchData}
              loading={isLoading}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default CompanySearch;
