import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const apiGetDashboardData = async () => {
  try {
    const response = await axiosInstance.get(`dashboard`, {
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0];
    } else {
      throw new Error('get user portfolios failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
