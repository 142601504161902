import numeral from 'numeral';
import 'numeral/locales/en-gb';
import { Link } from 'react-router-dom';
import { compareNumericString, propertySVGs } from './helpers';
import SelectTypeColumnFilter from '../jsx/components/Dashboard/Components/SelectTypeColumnFilter';
import SelectBedroomsColumnFilter from '../jsx/components/Dashboard/Components/SelectBedroomsColumnFilter';
import PillRenderer from '../jsx/components/Dashboard/Components/PillRenderer';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

export const columnsMetadataPortfolio = {
  portfolioName: {
    Header: 'Portfolio name',
    accessor: 'portfolioName',
    headerStyle: { minWidth: '270px' },
    filterable: true,
    filterClassName: 'px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs w-72',
    filterStyle: { border: '1px solid #F0F0F0' },
    filterPlaceholder: 'Portfolio Name',
    filterTitle: 'Portfolio Name',
    style: {
      cursor: 'pointer',
      textDecoration: 'underline',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    renderer: (cell) => {
      return (
        <Link className="flex items-center mt-2 mb-2" to={`/portfolio-analytics/${cell?.row?.original?.id}`}>
          {cell.value}
        </Link>
      );
    },
  },
  numberOfProperties: {
    Header: 'Number Of Properties',
    accessor: 'numberOfProperties',
    headerStyle: { width: '171px' },
    style: {},
  },
  value: {
    Header: 'Value',
    accessor: 'value',
    headerStyle: { width: '151px' },
    style: { color: '#000000' },
    formatter: (x) => {
      return numeral(x).format();
    },
  },
  rentPrice: {
    Header: 'Monthly Rent',
    accessor: 'rentPrice',
    headerStyle: { width: '151px' },
    style: { color: '#000000' },
    formatter: (x) => {
      return x ? numeral(x).format() : '-';
    },
  },
  chgActual: {
    Header: 'Last Month Change',
    accessor: 'chgActual',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'Last Month Change:',
      description: 'Last Month on Month change in Value of Property',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
  chgForecast: {
    Header: 'One Year Forecast Change',
    accessor: 'chgForecast',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'One Year Forecast Change:',
      description: 'Forward year Estimated Value Change of Property, 12th to 13th month from today',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
  chgRentActual: {
    Header: 'Last Month Monthly Rent Change',
    accessor: 'chgRentActual',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'Last Month Monthly Rent Change:',
      description: 'Last Month on Month change in Monthly Rent of Property',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
  chgRentForecast: {
    Header: 'One Year Monthly Rent Forecast Change',
    accessor: 'chgRentForecast',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'One Year Monthly Rent Forecast Change:',
      description: 'Forward year Estimated Monthly Rent Change of Property, 12th to 13th month from today',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
};

export const columnsMetadataProperty = {
  address: {
    Header: 'Property Address',
    accessor: 'address',
    headerStyle: { minWidth: '270px' },
    filterable: true,
    filterClassName: 'px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs w-72',
    filterStyle: { border: '1px solid #F0F0F0' },
    filterPlaceholder: 'Address',
    filterTitle: 'Address',
    style: {
      cursor: 'pointer',
      textDecoration: 'underline',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: '10px',
      width: '300px',
      maxWidth: '300px',
    },
    renderer: (cell) => {
      return (
        <Link
          className="items-center mt-2 mb-2 truncate w-100 block"
          to={`/property-analytics/${cell?.row?.original?.id}`}
        >
          {cell.value}
        </Link>
      );
    },
  },
  property_type: {
    Header: 'Property Type',
    id: 'property_type',
    accessor: (d) => {
      return propertySVGs[d?.property_type]?.name ?? '';
    },
    headerStyle: { width: '101px' },
    style: { color: '#000000' },
    filterable: true,
    filterPlaceholder: 'Property Type',
    Filter: SelectTypeColumnFilter,
    filterClassName: 'px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs w-52',
    filterStyle: { border: '1px solid #F0F0F0' },
    filter: 'includes',
  },
  num_rooms: {
    Header: 'Number Of Bedrooms',
    id: 'num_rooms',
    accessor: (d) => {
      return d?.num_rooms ? (d?.num_rooms).toString() : '';
    },
    headerStyle: { width: '101px' },
    style: {},
    filterable: true,
    filterPlaceholder: 'Number of Bedrooms',
    Filter: SelectBedroomsColumnFilter,
    filterClassName: 'px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs w-52',
    filterStyle: { border: '1px solid #F0F0F0' },
    filter: 'includes',
  },
  uprn: {
    Header: 'UPRN',
    accessor: 'uprn',
    headerStyle: { width: '171px' },
    style: {},
  },
  price: {
    Header: 'Value',
    accessor: 'price',
    headerStyle: { width: '151px' },
    style: { color: '#000000' },
    formatter: (x) => {
      return numeral(x).format();
    },
  },
  rentPrice: {
    Header: 'Monthly Rent',
    accessor: 'rentPrice',
    headerStyle: { width: '151px' },
    style: { color: '#000000' },
    formatter: (x) => {
      return x ? numeral(x).format() : '-';
    },
  },
  chgActual: {
    Header: 'Last Month Price Change',
    accessor: 'chgActual',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'Last Month Change:',
      description: 'Last Month on Month change in Value of Property',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
  chgForecast: {
    Header: 'One Year Price Forecast Change',
    accessor: 'chgForecast',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'One Year Forecast Change:',
      description: 'Forward year Estimated Value Change of Property, 12th to 13th month from today',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
  chgRentActual: {
    Header: 'Last Month Monthly Rent Change',
    accessor: 'chgRentActual',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'Last Month Monthly Rent Change:',
      description: 'Last Month on Month change in Monthly Rent of Property',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
  chgRentForecast: {
    Header: 'One Year Monthly Rent Forecast Change',
    accessor: 'chgRentForecast',
    headerStyle: { width: '158px' },
    style: {},
    hintData: {
      title: 'One Year Monthly Rent Forecast Change:',
      description: 'Forward year Estimated Monthly Rent Change of Property, 12th to 13th month from today',
    },
    formatter: (x) => {
      return parseFloat(parseFloat(x) * 100).toFixed(2);
    },
    renderer: (cell) => {
      return <PillRenderer value={cell.value} />;
    },
    sortType: compareNumericString,
  },
};
