import Table from '../../Dashboard/Tables/BaseTable';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import moment from 'moment';
import { propertySVGs } from '../../../../utils/helpers';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const columnsMetadata = {
  address: {
    Header: 'Address',
    accessor: 'address',
    headerStyle: {
      minWidth: '270px',
    },
    style: {
      color: '#000000',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '210px',
      paddingRight: '10px',
      cursor: 'pointer',
    },
  },
  created_at: {
    Header: 'Date added',
    headerStyle: {
      width: '120px',
      minWidth: '120px',
    },
    accessor: 'created_at',
    style: {
      width: '120px',
      minWidth: '120px',
    },
  },
  post_town: {
    Header: 'City',
    id: 'post_town',
    headerStyle: { width: '140px' },
    accessor: (d) => {
      return d?.property_index_data?.post_town ?? '-';
    },
  },
  district: {
    Header: 'District',
    id: 'district_code',
    headerStyle: { width: '140px' },
    accessor: (d) => {
      return d?.property_index_data?.district_code ?? '-';
    },
  },
  last_transaction_date: {
    Header: 'Last Transaction Date',
    headerStyle: { width: '140px' },
    id: 'last_transaction_date',
    accessor: (d) => {
      return moment(d?.last_transaction_date || d?.property_index_data?.last_transaction_date).isBefore(moment())
        ? d?.last_transaction_date || d?.property_index_data?.last_transaction_date?.split(/-/)?.join('-')
        : '-';
    },
  },
  last_transaction_price: {
    Header: 'Last Transaction Price',
    headerStyle: { width: '140px' },
    id: 'last_transaction_price',
    accessor: (d) => {
      return (d?.last_transaction_price || d?.property_index_data?.last_transaction_price) > 0
        ? numeral(d?.last_transaction_price || d?.property_index_data?.last_transaction_price).format()
        : '-';
    },
    style: {
      color: '#000000',
    },
  },
  property_type: {
    Header: 'Property Type',
    id: 'property_type',
    accessor: (d) => {
      return d?.property_index_data?.property_type
        ? propertySVGs[d?.property_index_data?.property_type.charAt(0).toUpperCase()]?.name
        : '-';
    },
    style: { width: '140px' },
    headerStyle: { width: '140px' },
  },
  num_rooms: {
    Header: 'Number Of Bedrooms',
    id: 'number_of_bedrooms',
    accessor: (d) => {
      return d?.property_index_data?.number_of_bedrooms ?? '-';
    },
    formatter: (x) => {
      return x?.property_index_data?.number_of_bedrooms ? x?.property_index_data?.number_of_bedrooms : '-';
    },
    headerStyle: { width: '130px' },
    style: {
      width: '90px',
    },
  },
  price: {
    Header: 'Index AVM Value',
    headerStyle: { width: '140px' },
    id: 'price',
    accessor: (d) => {
      return d?.price ? numeral(d?.price).format() : '-';
    },
    style: {
      color: '#000000',
    },
  },
  occupancy: {
    Header: 'Occupancy',
    headerStyle: {
      width: '140px',
    },
    style: {
      color: '#000000',
      width: '130px',
    },
    accessor: 'occupancy',
    formatter: (x) => {
      return numeral(x / 100).format('%');
    },
  },
};

const PropertiesListTable = ({
  properties,
  onSelectedRowsChange,
  controlledSelectedRows,
  pageCount,
  pageIndex,
  fetchData,
  loading,
}) => {
  return (
    <div>
      <Table
        columnsMetadata={columnsMetadata}
        sampleTableData={properties}
        onSelectedRowsChange={onSelectedRowsChange}
        controlledSelectedRows={controlledSelectedRows}
        filtersActive={false}
        paginationActive={true}
        staticData={false}
        title=""
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </div>
  );
};
export default PropertiesListTable;
