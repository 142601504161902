import Table from '../../Dashboard/Tables/BaseTable';
import { ReactComponent as ImagesIcon } from 'icons/custom/images-icon.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as HeartSolid } from 'icons/custom/heart-solid.svg';
import { ReactComponent as HeartRegular } from 'icons/custom/heart-regular.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import ImageGalleryModal from './ImageGallery';
import { useMemo, useState } from 'react';
import InfoAsterisk from '../../Dashboard/Components/InfoAsterisk';
import { KILOMETERS_TO_MILES } from '../../../../utils/constants';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const ListView = ({
  comparables,
  onSelectedRowsChange,
  toggleFavourite,
  controlledSelectedRows,
  pageCount,
  pageIndex,
  fetchData,
  loading,
  optimalComparables,
}) => {
  const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(false);

  const columnsMetadata = useMemo(
    () => {
      return {
        index: {
          Header: '#',
          filterable: false,
          accessor: (_row, i) => i + 1,
          disableSortBy: true,
          hideSort: true,
        },
        optimal_comparables: {
          Header: '',
          headerStyle: { width: '40px' },
          filterable: false,
          hideSort: true,
          disableSortBy: true,
          id: 'optimal_comparables',
          accessor: (d) => {
            const isOptimal = optimalComparables.find((opt) => opt.id === d.id);
            return (
              <div
                className="flex align-center justify-between"
                style={{ opacity: optimalComparables.length < 5 ? 1 : 0.7 }}
              >
                <div className="cursor-pointer" onClick={() => toggleFavourite(d)}>
                  {isOptimal ? (
                    <InfoAsterisk
                      color="text-white"
                      placement="top"
                      text={
                        <div className="text-gray-500 py-1 text-left">
                          <div className="text-black text-base"> Remove from favourites </div>
                        </div>
                      }
                      icon={<HeartSolid className="w-5" />}
                    />
                  ) : (
                    <InfoAsterisk
                      color="text-white"
                      placement="top"
                      text={
                        optimalComparables.length < 5 ? (
                          <div className="text-gray-500 py-1 text-left">
                            <div className="text-black text-base"> Add to Favorites </div>
                            <div className="text-xs">
                              You can edit them by clicking the favorite heart icon next to each of the properties.
                            </div>
                          </div>
                        ) : (
                          <div className="text-gray-500 py-1 text-left">
                            <div className="text-black text-base"> Favorites Limit Reached </div>
                            <div className="text-xs">
                              Remove a comparable from your favourites before adding another
                            </div>
                          </div>
                        )
                      }
                      icon={<HeartRegular className="w-5" />}
                    />
                  )}
                </div>
              </div>
            );
          },
          formatter: (x) => (x ? x : '-'),
        },

        address: {
          Header: 'Address',
          id: 'address',
          accessor: (d) => {
            return (
              <div className="flex align-center justify-between">
                <p className="overflow-ellipsis overflow-hidden whitespace-nowrap">{d?.address}</p>
                {d?.floor_plans?.length || d?.property_images?.length ? (
                  <OverlayTrigger
                    bsPrefix="range-popover"
                    trigger="click"
                    rootClose={true}
                    key="1"
                    placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body className="w-64">
                          <div>
                            <img
                              src={
                                d?.property_images?.length
                                  ? d?.property_images[0]
                                  : d?.floor_plans?.length
                                    ? d?.floor_plans[0]
                                    : ''
                              }
                              alt=""
                            />
                          </div>
                          <div className="flex flex-end mt-2">
                            <PrimaryButton
                              className="ml-auto px-3 py-2 w-auto h-auto"
                              onClick={() => viewGalleryClick(d)}
                            >
                              Open Gallery
                            </PrimaryButton>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <div className={`ml-2`}>
                      <ImagesIcon className="w-6 cursor-pointer" />
                    </div>
                  </OverlayTrigger>
                ) : (
                  ''
                )}
              </div>
            );
          },
          headerStyle: {
            minWidth: '270px',
          },
          style: {
            color: '#000000',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '210px',
            paddingRight: '10px',
          },
          filterable: false,
        },

        size: {
          Header: 'Property Size (sq ft)',
          headerStyle: { width: '140px' },
          filterable: false,
          accessor: 'size',
          formatter: (x) => (x ? x : '-'),
        },
        price: {
          Header: 'Price (£)',
          headerStyle: { width: '140px' },
          style: { color: '#000' },
          filterable: false,
          accessor: 'price',
          formatter: (x) => (x ? numeral(x).format() : '-'),
        },
        date: {
          Header: 'Date',
          headerStyle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
          style: { width: '120px', maxWidth: '120px', minWidth: '120px' },
          filterable: false,
          accessor: 'date',
          formatter: (x) => (x ? x : '-'),
        },
        dataset: {
          Header: 'Type',
          headerStyle: { width: '140px' },
          style: { color: '#000' },
          filterable: false,
          accessor: 'dataset',
          formatter: (x) => (x === 'LS' ? 'Listing' : x === 'LR' ? 'Transaction' : '-'),
        },
        old_new_lr: {
          Header: 'New Build',
          headerStyle: { width: '140px' },
          filterable: false,
          accessor: 'old_new_lr',
          formatter: (x) => (x ? (x === 'N' ? 'No' : 'Yes') : '-'),
        },
        bathrooms: {
          Header: 'Bathrooms',
          headerStyle: { width: '140px' },
          style: { color: '#000' },
          filterable: false,
          accessor: 'bathrooms',
          formatter: (x) => (x ? parseInt(x) : '-'),
        },
        freehold_leasehold: {
          Header: 'Tenure',
          headerStyle: { width: '140px' },
          filterable: false,
          accessor: 'freehold_leasehold',
          formatter: (x) => (x ? (x === 'F' ? 'Freehold' : 'Leasehold') : '-'),
        },
        current_energy_rating_epc: {
          Header: 'EPC rating',
          headerStyle: { width: '140px' },
          style: { color: '#000' },
          filterable: false,
          accessor: 'current_energy_rating_epc',
          formatter: (x) => (x && x !== 'None' ? x : '-'),
        },
        distance_from: {
          Header: 'Distance Radius',
          headerStyle: { width: '140px' },
          style: { color: '#000' },
          filterable: false,
          accessor: 'distance_from',
          formatter: (x) => (x ? numeral(x * KILOMETERS_TO_MILES).format('0.00') : '-'),
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optimalComparables, optimalComparables.length, toggleFavourite],
  );

  const viewGalleryClick = (item) => {
    setSelectedProperty(item);
    setShowImageGalleryModal(true);
  };

  return (
    <>
      <ImageGalleryModal
        setShowModal={setShowImageGalleryModal}
        showModal={showImageGalleryModal}
        property={selectedProperty}
      ></ImageGalleryModal>

      <Table
        columnsMetadata={columnsMetadata}
        sampleTableData={comparables}
        onSelectedRowsChange={onSelectedRowsChange}
        controlledSelectedRows={controlledSelectedRows}
        filtersActive={false}
        paginationActive={true}
        staticData={false}
        title=""
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </>
  );
};

export default ListView;
