import { formatRentalYieldData, seriesObjectToArray } from '../../../../utils/helpers';
import ItemVsIndexChart from '../../Dashboard/Charts/ItemVsIndexChart';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

const RentalCharts = ({ selectedIndex1, selectedIndex2, targetTime }) => {
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3 justify-start">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0">
                Rental Index: {selectedIndex1?.label}
                {!Object.keys(selectedIndex1?.rental_index_forecast || {}).length && (
                  <span> (Data not available) </span>
                )}
                <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                  vs
                </span>
                {selectedIndex2?.label}
                {!Object.keys(selectedIndex2?.rental_index_forecast || {}).length && (
                  <span> (Data not available) </span>
                )}
              </h4>
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {selectedIndex1?.rental_index_forecast ? (
                  <ItemVsIndexChart
                    address={selectedIndex1?.label}
                    index={selectedIndex2?.label}
                    itemGraphSeriesData={seriesObjectToArray(selectedIndex1?.rental_index_forecast, targetTime)}
                    benchmarkGraphSeriesData={seriesObjectToArray(selectedIndex2?.rental_index_forecast, targetTime)}
                    secondaryAxisButtonEnabled={true}
                  />
                ) : (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0">
                Rental Yield: {selectedIndex1?.label}
                {!Object.keys(selectedIndex1?.rental_index_forecast || {}).length && (
                  <span> (Data not available) </span>
                )}
                <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                  vs
                </span>
                {selectedIndex2?.label}
                {!Object.keys(selectedIndex2?.rental_index_forecast || {}).length && (
                  <span> (Data not available) </span>
                )}
              </h4>
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {selectedIndex1?.rental_index_forecast ? (
                  <ItemVsIndexChart
                    dataType={'rate'}
                    address={selectedIndex1?.label}
                    index={selectedIndex2?.label}
                    itemGraphSeriesData={formatRentalYieldData(
                      selectedIndex1?.rental_index_forecast,
                      selectedIndex1?.forecast,
                      targetTime,
                    )}
                    benchmarkGraphSeriesData={formatRentalYieldData(
                      selectedIndex2?.rental_index_forecast,
                      selectedIndex2?.forecast,
                      targetTime,
                    )}
                    secondaryAxisButtonEnabled={true}
                  />
                ) : (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalCharts;
