import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as IncreaseSVG } from 'icons/custom/increase.svg';
import { ReactComponent as DecreaseSVG } from 'icons/custom/decrease.svg';
import { ReactComponent as UnchangedSVG } from 'icons/custom/unchanged.svg';

import { apiSearchCityExactIndex } from '../../../../api/cityIndex';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const calculateParams = (forecast, targetTime) => {
  const targetDate = moment(targetTime).format('YYYY-MM-DD');
  const formattedLastMonth = moment(targetTime).subtract(1, 'months').format('YYYY-MM-DD');
  const formattedLastYear = moment(targetTime).subtract(12, 'months').format('YYYY-MM-DD');
  const targetValue = forecast[targetDate];
  const changeMom = targetValue / forecast[formattedLastMonth] - 1;
  const changeYoy = targetValue / forecast[formattedLastYear] - 1;

  return { targetValue, changeMom, changeYoy };
};

const InfoIndicator = ({ value, text }) => {
  return (
    <div className="d-flex align-items-center">
      {value > 0 ? (
        <IncreaseSVG className="ml-5 mr-3 mt-3" />
      ) : value < 0 ? (
        <DecreaseSVG className="ml-5 mr-3 mt-3" />
      ) : (
        <UnchangedSVG className="ml-5 mr-3 mt-3" />
      )}
      <div className="mt-1.5">
        <div
          className="text-lg	font-semibold	"
          style={{ color: value > 0 ? '#32D16D' : value < 0 ? '#FF4015' : '#FFA500' }}
        >
          {numeral(value).format('0.00%')}
        </div>
        <div className="font-normal	text-xs text-gray-500">{text}</div>
      </div>
    </div>
  );
};

const Card = ({ targetTime, index }) => {
  const {
    data: indexData = [],
    isSuccess,
    isLoading,
  } = useQuery([index], ({ signal }) => apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: index }, signal));

  const formattedIndexData = useMemo(() => {
    if (isSuccess) {
      return calculateParams(indexData?.forecast, targetTime);
    } else {
      return {};
    }
  }, [isSuccess, indexData, targetTime]);

  return (
    <div className="card">
      <div className="card-body d-flex align-items-center justify-between py-3">
        <div className="card-data flex">
          <div>
            <h5 className="mb-2 font-medium fs-16" style={{ color: '#747B8A' }}>
              {index}
            </h5>

            <div className="text-2xl font-semibold text-black">
              {isLoading && !formattedIndexData?.targetValue ? (
                <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
              ) : isSuccess ? (
                <div> {numeral(formattedIndexData?.targetValue).format()} </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        {!isLoading && formattedIndexData?.changeMom ? (
          <div>
            <InfoIndicator value={formattedIndexData?.changeMom} text={'Month on month'} />
            <InfoIndicator value={formattedIndexData?.changeYoy} text={'Year on year'} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Card;
