import React from 'react';
import { ReactComponent as Error } from 'icons/custom/error.svg';

const ErrorScreen = () => {
  return (
    <div className="card-body justify-center">
      <div className="items-center flex flex-column justify-center">
        <Error />
        <div className="text-lg text-black font-semibold text-center my-2"> An error has occurred </div>
        <div className="text-xs font-normal w-80 text-center">Please try again later</div>
      </div>
    </div>
  );
};

export default ErrorScreen;
