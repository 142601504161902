import Metismenu from 'metismenujs';
import React, { Component, useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import useScrollPosition from 'use-scroll-position';
import { ThemeContext } from '../../../context/ThemeContext';
import { ReactComponent as DashboardNav } from 'icons/custom/dashboard-nav.svg';
import { ReactComponent as DashboardNavSelected } from 'icons/custom/dashboard-nav-selected.svg';
import { ReactComponent as PortfoliosNav } from 'icons/custom/portfolios-nav.svg';
import { ReactComponent as PortfoliosNavSelected } from 'icons/custom/portfolios-nav-selected.svg';
import { ReactComponent as PropertiesNav } from 'icons/custom/properties-nav.svg';
import { ReactComponent as PropertiesNavSelected } from 'icons/custom/properties-nav-selected.svg';
import { ReactComponent as AlertsNav } from 'icons/custom/alerts-nav.svg';
import { ReactComponent as AlertsNavSelected } from 'icons/custom/alerts-nav-selected.svg';
import { ReactComponent as InsightsNav } from 'icons/custom/insights-nav.svg';
import { ReactComponent as InsightsNavSelected } from 'icons/custom/insights-nav-selected.svg';
import { ReactComponent as LocationSearchNav } from 'icons/custom/location-search-nav.svg';
import { ReactComponent as LocationSearchNavSelected } from 'icons/custom/location-search-nav-selected.svg';
import { ReactComponent as AppraisalNav } from 'icons/custom/appraisal.svg';
import { ReactComponent as AppraisalNavSelected } from 'icons/custom/appraisal-selected.svg';
import { ReactComponent as BankSVG } from 'icons/custom/bank.svg';
import { ReactComponent as BankGray } from 'icons/custom/bank-gray.svg';
import { ReactComponent as LogoutSelected } from 'icons/custom/logout-selected.svg';
import { ReactComponent as LogoutSVG } from 'icons/custom/logout.svg';
import { logout } from '../../../store/actions/AuthActions';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getUser } from '../../../store/selectors/AuthSelectors';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import profile from '../../../images/profile.png';
import PrimaryButton from '../../components/Dashboard/Buttons/PrimaryButton';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper h-full">
        <ul className="metismenu h-full" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const [customRoutes, setCustomRoutes] = useState([]);

  const [currentPath, setCurrentPath] = useState('');
  const location = useLocation();
  const scrollPosition = useScrollPosition();

  const { openMenuToggle } = useContext(ThemeContext);
  const searchParams = new URLSearchParams(location.search);

  const handleClick = () => {
    const screenSize = window.innerWidth;
    if (screenSize < 768) {
      openMenuToggle({ toggle: false });
    }
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    dispatch(logout(history));
  };

  useEffect(() => {
    const companySearchAccessData = process.env.REACT_APP_COMPANY_SEARCH_ACCESS;
    let companySearchAccess;

    try {
      companySearchAccess = companySearchAccessData ? JSON.parse(companySearchAccessData) : null;
    } catch (error) {
      console.log(error);
    }

    if (companySearchAccess?.company_id?.includes(user.company_id) || companySearchAccess?.user_id?.includes(user.id)) {
      setCustomRoutes((prevRoutes) => [...prevRoutes, 'company-search']);
    }

    const lhaAccessData = process.env.REACT_APP_LHA_ACCESS;
    let lhaAccess;

    try {
      lhaAccess = lhaAccessData ? JSON.parse(lhaAccessData) : null;
    } catch (error) {
      console.log(error);
    }

    if (lhaAccess?.company_id?.includes(user.company_id) || lhaAccess?.user_id?.includes(user.id)) {
      setCustomRoutes((prevRoutes) => [...prevRoutes, 'lha']);
    }

    const appraisalAccessData = process.env.REACT_APP_APPRAISAL_ACCESS;
    let appraisalAccess;

    try {
      appraisalAccess = appraisalAccessData ? JSON.parse(appraisalAccessData) : null;
    } catch (error) {
      console.log(error);
    }

    if (appraisalAccess?.company_id?.includes(user.company_id) || appraisalAccess?.user_id?.includes(user.id)) {
      setCustomRoutes((prevRoutes) => [...prevRoutes, 'appraisal']);
      setCustomRoutes((prevRoutes) => [...prevRoutes, 'appraisal-properties']);
      setCustomRoutes((prevRoutes) => [...prevRoutes, 'appraisal-portfolios']);
    }
  }, [user]);

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === 'fixed' && sidebarLayout.value === 'horizontal' && headerposition.value === 'static'
          ? scrollPosition > 120
            ? 'fixed'
            : ''
          : ''
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li>
            <Link onClick={handleClick} className="flex flex-row" to="/dashboard">
              <i>
                {currentPath === '/dashboard' ? (
                  <DashboardNavSelected className="w-6" />
                ) : (
                  <DashboardNav className="w-6" />
                )}
              </i>
              <span className="nav-text" style={{ color: `${currentPath === '/dashboard' ? '#3571E5' : ''}` }}>
                Dashboard
              </span>
            </Link>
          </li>

          <li>
            <Link onClick={handleClick} className="ai-icon" to="/portfolios">
              <i>
                {currentPath === '/portfolios' && !searchParams.has('appraisal') ? (
                  <PortfoliosNavSelected className="w-6" />
                ) : (
                  <PortfoliosNav className="w-6" />
                )}
              </i>
              <span
                className="nav-text"
                style={{ color: `${currentPath === '/portfolios' && !searchParams.has('appraisal') ? '#3571E5' : ''}` }}
              >
                Portfolios
              </span>
            </Link>
          </li>

          <li>
            <Link onClick={handleClick} className="ai-icon" to="/properties">
              <i>
                {currentPath === '/properties' && !searchParams.has('appraisal') ? (
                  <PropertiesNavSelected className="w-6" />
                ) : (
                  <PropertiesNav className="w-6" />
                )}
              </i>
              <span
                className="nav-text"
                style={{ color: `${currentPath === '/properties' && !searchParams.has('appraisal') ? '#3571E5' : ''}` }}
              >
                Properties
              </span>
            </Link>
          </li>

          <li>
            <Link onClick={handleClick} className="ai-icon" to="/alerts">
              <i>{currentPath === '/alerts' ? <AlertsNavSelected className="w-6" /> : <AlertsNav className="w-6" />}</i>
              <span className="nav-text" style={{ color: `${currentPath === '/alerts' ? '#3571E5' : ''}` }}>
                Alerts
              </span>
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} className="ai-icon" to="/insights">
              <i>
                {currentPath === '/insights' ? (
                  <InsightsNavSelected className="w-6" />
                ) : (
                  <InsightsNav className="w-6" />
                )}
              </i>
              <span className="nav-text" style={{ color: `${currentPath === '/insights' ? '#3571E5' : ''}` }}>
                Market Intelligence
              </span>
            </Link>
          </li>
          <li>
            <Link onClick={handleClick} className="ai-icon" to="/location-search">
              <i>
                {currentPath === '/location-search' ? (
                  <LocationSearchNavSelected className="w-6" />
                ) : (
                  <LocationSearchNav className="w-6" />
                )}
              </i>
              <span
                className="nav-text"
                style={{
                  color: `${currentPath === '/location-search' ? '#3571E5' : ''}`,
                }}
              >
                Investment Search AI
              </span>
            </Link>
          </li>

          {customRoutes.includes('appraisal') ? (
            <li>
              <Link onClick={handleClick} className="ai-icon" to="/appraisal">
                <i>
                  {currentPath === '/appraisal' ? (
                    <AppraisalNavSelected className="w-6" />
                  ) : (
                    <AppraisalNav className="w-6" />
                  )}
                </i>
                <span className="nav-text" style={{ color: `${currentPath === '/appraisal' ? '#3571E5' : ''}` }}>
                  Appraisal
                </span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {customRoutes.includes('appraisal-properties') ? (
            <li>
              <Link className="ai-icon" to="/properties?appraisal=true">
                <i>
                  {currentPath === '/properties' && searchParams.get('appraisal') === 'true' ? (
                    <PropertiesNavSelected className="w-6" />
                  ) : (
                    <PropertiesNav className="w-6" />
                  )}
                </i>
                <span
                  className="nav-text"
                  style={{
                    color: `${currentPath === '/properties' && searchParams.get('appraisal') === 'true' ? '#3571E5' : ''}`,
                  }}
                >
                  Appraisal Properties
                </span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {customRoutes.includes('appraisal-portfolios') ? (
            <li>
              <Link className="ai-icon" to="/portfolios?appraisal=true">
                <i>
                  {currentPath === '/portfolios' && searchParams.get('appraisal') === 'true' ? (
                    <PortfoliosNavSelected className="w-6" />
                  ) : (
                    <PortfoliosNav className="w-6" />
                  )}
                </i>
                <span
                  className="nav-text"
                  style={{
                    color: `${currentPath === '/portfolios' && searchParams.get('appraisal') === 'true' ? '#3571E5' : ''}`,
                  }}
                >
                  Appraisal Portfolios
                </span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {customRoutes.includes('lha') ? (
            <li>
              <Link onClick={handleClick} className="ai-icon" to="/lha">
                <i>
                  {currentPath === '/lha' ? <DashboardNavSelected className="w-6" /> : <DashboardNav className="w-6" />}
                </i>
                <span className="nav-text" style={{ color: `${currentPath === '/lha' ? '#3571E5' : ''}` }}>
                  LHA
                </span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {customRoutes.includes('company-search') ? (
            <li>
              <Link onClick={handleClick} className="ai-icon" to="/company-search">
                <i>{currentPath === '/company-search' ? <BankSVG className="w-6" /> : <BankGray className="w-6" />}</i>
                <span
                  className="nav-text"
                  style={{
                    color: `${currentPath === '/company-search' ? '#3571E5' : ''}`,
                  }}
                >
                  Company Search
                </span>
              </Link>
            </li>
          ) : (
            ''
          )}

          <div className="mt-auto mb-6 block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden px-2">
            <div className="bg-white p-3 mt-4 rounded-tr-lg rounded-br-lg rounded-bl-lg">
              <div className="flex justify-start items-center px-2">
                <img src={profile} alt="" className="w-10 h-10" />
                <div className="ml-3">
                  <div>
                    <div className="fs-14 font-w400 mb-1"> {user?.email} </div>
                    <div className="fs-18 font-w500"> {user?.company_name} </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 pt-4 border-t">
                <Link to="/login" onClick={handleLogout} className="p-0 flex justify-start items-center">
                  <PrimaryButton>
                    <LogoutSelected className="w-4 h-4 mr-2" />
                    Log out
                  </PrimaryButton>
                </Link>
              </div>
            </div>
          </div>

          <li className="mt-auto mb-6">
            <Link
              className="ai-icon hidden sm:hidden md:block lg:block xl:block 2xl:block"
              to="/login"
              onClick={handleLogout}
            >
              <i>
                <LogoutSVG />
              </i>
              <span className="nav-text">Logout</span>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default connect((state) => ({
  user: getUser(state),
}))(SideBar);
