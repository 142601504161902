import ReactApexChart from 'react-apexcharts';
import numeral from 'numeral';
import 'numeral/locales/en-gb';
import React, { useMemo } from 'react';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const RegionalChart = ({ regionIndexData }) => {
  const chartParams = useMemo(() => {
    if (regionIndexData?.length) {
      return {
        series: regionIndexData.map((item) => item?.currentPrice),
        categories: regionIndexData.map((item) => item?.name),
      };
    } else {
      return {
        series: [],
        categories: [],
      };
    }
  }, [regionIndexData]);

  const series = [
    {
      data: chartParams?.series,
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        horizontal: true,
        barHeight: '40%',
      },
    },
    colors: ['#7A9FE6'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartParams?.categories,
      labels: {
        formatter: function (val) {
          return numeral(val).format();
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numeral(val).format();
        },
        title: {
          formatter: function (seriesName) {
            return '';
          },
        },
      },
    },
  };

  return (
    <>
      <div className="card d-flex align-center justify-center p-4">
        <div id="bar-chart">
          <ReactApexChart type="bar" options={options} series={series} height={350} />
        </div>
      </div>
    </>
  );
};

export default React.memo(RegionalChart);
