import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import SecondaryButton from '../Buttons/SecondaryButton';
import { Link } from 'react-router-dom';
import { ReactComponent as RedirectBlueSvg } from 'icons/custom/redirect-blue.svg';
import { useQuery } from '@tanstack/react-query';
import { getPaginatedUserProperties } from '../../../../api/property';
import emptyImg from 'icons/custom/empty-properties.png';
import PillRenderer from './PillRenderer';

const DashboardProperties = ({ favourites }) => {
  const { data: propertiesData, isLoading } = useQuery(['paginated-properties', favourites], ({ signal }) =>
    getPaginatedUserProperties(
      {
        order_by: 'created_at',
        ascending: false,
        offset: 0,
        limit: 10,
        favourite: favourites,
      },
      signal,
    ),
  );

  return (
    <div className="card">
      <div className="card-body d-flex align-items-center p-4">
        <div className="card-data w-100 flex flex-column h-100 justify-between">
          <div className="flex justify-between items-center w-100 mb-2">
            <div className="text-base font-normal text-black">
              {favourites ? 'My favourite properties ' : ' My  properties '}
            </div>
            <div className="text-xs"> Total {propertiesData?.total_count}</div>
          </div>
          {isLoading ? (
            <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
          ) : propertiesData?.total_count > 0 ? (
            <div className="flex flex-column h-100 justify-between">
              <div>
                <div className="ml-auto cursor-pointer flex justify-between items-center my-3 text-xs">
                  <div> Address </div>
                  <div className="flex justify-between items-center">
                    Last Month <br />
                    Price Change
                  </div>
                </div>
                {propertiesData.data.map((property, index) => (
                  <Link
                    key={index}
                    className="ml-auto cursor-pointer flex justify-between items-center my-3 text-black text-xs"
                    to={`/property-analytics/${property.id}`}
                  >
                    <div className="cursor-pointer truncate"> {property.address} </div>
                    <div className="flex justify-between items-center">
                      <PillRenderer value={parseFloat(property?.property_index_data?.chg_1m)} />
                    </div>
                  </Link>
                ))}
              </div>

              <Link className="ml-auto cursor-pointer mt-4" to={`/properties/`}>
                <SecondaryButton className="w-auto px-3 ml-auto">
                  <RedirectBlueSvg className="mr-2" />
                  See all properties
                </SecondaryButton>
              </Link>
            </div>
          ) : (
            <div className="flex mt-8 flex-column justify-center items-center">
              <img className="h-52" src={emptyImg} alt="error" />
              <div className="text-sm text-black align-center"> There’s not properties yet </div>
              <Link className="cursor-pointer" to={`/new-property`}>
                <SecondaryButton className="w-auto px-3 mt-4">
                  <RedirectBlueSvg className="mr-2" />
                  Add a Property
                </SecondaryButton>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardProperties;
