import React from 'react';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { useSortBy, useTable } from 'react-table';
import { Card } from 'react-bootstrap';
import { getKeyPerformanceMetrics } from '../../Property/Helpers/Helpers';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const KeyPerformanceMetricsTable = ({ itemPM, benchmarkPM, type = 'Property' }) => {
  const columnsKeyPerformanceMetrics = React.useMemo(
    () => [
      ...Object.values({
        metric: {
          Header: 'Metric',
          accessor: 'metric',
          style: {},
          disableSortBy: true,
        },
        itemName: {
          Header: type,
          accessor: 'itemName',
          style: { color: '#000000' },
          disableSortBy: true,
        },
        benchmark: {
          Header: 'Benchmark',
          accessor: 'benchmark',
          style: {},
          disableSortBy: true,
        },
      }),
    ],
    [type],
  );

  const tableKeyPerformanceMetrics = React.useMemo(() => {
    return getKeyPerformanceMetrics(itemPM, benchmarkPM);
  }, [benchmarkPM, itemPM]);

  const {
    getTableProps: getTablePropsKeyPerformanceMetrics,
    getTableBodyProps: getTableBodyPropsKeyPerformanceMetrics,
    headerGroups: headerGroupsKeyPerformanceMetrics,
    prepareRow: prepareRowKeyPerformanceMetrics,
    rows: rowsKeyPerformanceMetrics,
  } = useTable({ columns: columnsKeyPerformanceMetrics, data: tableKeyPerformanceMetrics }, useSortBy);

  const headerGeneralStyle = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '18px',
    borderRight: '1px solid #F0F0F0',
    paddingRight: '20px',
    paddingLeft: '16px',
    width: '30%',
    color: '#000',
    paddingBottom: '10px',
  };

  const cellGeneralStyle = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#747B8A',
    height: '36px',
    borderRight: '1px solid #F0F0F0',
    paddingLeft: '16px',
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <Card>
          <Card.Header>
            <Card.Title>Key Performance Metrics</Card.Title>
          </Card.Header>
          <Card.Body>
            <table {...getTablePropsKeyPerformanceMetrics()} style={{ border: 'none' }} className="w-full text-sm">
              <thead>
                {headerGroupsKeyPerformanceMetrics.map((headerGroup, idx) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                    {headerGroup.headers.map((column, idx) => {
                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{
                            ...headerGeneralStyle,
                          }}
                          className="text-base"
                          key={idx}
                        >
                          <div className="flex justify-start items-center w-full select-none h-full">
                            <div className="w-full">{column.render('Header')}</div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyPropsKeyPerformanceMetrics()} style={{ width: '100%' }}>
                {rowsKeyPerformanceMetrics.map((row, idx) => {
                  prepareRowKeyPerformanceMetrics(row);
                  return (
                    <tr {...row.getRowProps()} key={idx}>
                      {row.cells.map((cell, idx) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              ...cellGeneralStyle,
                            }}
                            key={idx}
                          >
                            {cell.value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default KeyPerformanceMetricsTable;
