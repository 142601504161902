import Select from 'react-select';
import { calculateMonthlyChange, calculateYearlyChange, seriesObjectToArray } from '../../../../utils/helpers';
import ItemVsIndexChart from '../../Dashboard/Charts/ItemVsIndexChart';
import { searchCityIndices } from '../../../../api/cityIndex';
import { useCallback, useLayoutEffect, useState } from 'react';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import { ReactComponent as FilterSvg } from 'icons/custom/filter.svg';
import DetailedCityIndexSearchModal from '../../Dashboard/Modals/DetailedCityIndexSearchModal';
import BaseBarChart from '../../Dashboard/Charts/BaseBarChart';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

function CityIndiciesChart({ targetTime }) {
  const [selectedIndexA, setSelectedCityIndexA] = useState(null);
  const [indexDropdownA, setIndexDropdownA] = useState([]);

  const [selectedIndexB, setSelectedCityIndexB] = useState(null);
  const [indexDropdownB, setIndexDropdownB] = useState([]);

  const [selectedIndexC, setSelectedCityIndexC] = useState(null);
  const [indexDropdownC, setIndexDropdownC] = useState([]);

  const [selectedDropdownType, setSelectedDropdownType] = useState(null);
  const [detailedSearchModal, setDetailedSearchModal] = useState(false);

  const getIndexData = async (query, dropdownSetter, indexSetter, includeNationwide = false) => {
    const indexResponse = await searchCityIndices({ offset: 0, limit: 24, ...query });
    let responseData = indexResponse?.data?.data;
    if (includeNationwide) {
      const nationwideIndexResponse = await searchCityIndices({ index_exact: 'UK', offset: 0, limit: 1 });
      const nationwideIndex = nationwideIndexResponse?.data?.data[0];
      responseData.unshift(nationwideIndex);
    }
    setIndxData(responseData, dropdownSetter, indexSetter, includeNationwide);
  };

  const getIndexDataWrapper = useCallback(getIndexData, []);

  const setIndxData = (data, dropdownSetter, indexSetter, includeNationwide) => {
    const dropdownData = data.map((searchResult, index) => {
      return {
        label:
          (searchResult?.post_town && searchResult.post_town !== 'nan' ? `${searchResult.post_town}` : `UK`) +
          (searchResult?.property_type && searchResult.property_type !== 'nan'
            ? `, Type ${searchResult.property_type}`
            : '') +
          (searchResult?.number_of_bedrooms && searchResult.number_of_bedrooms !== 'NaN'
            ? `, Rooms: ${searchResult.number_of_bedrooms}`
            : ''),
        value: searchResult.index_name,
        id: index,
        forecast: searchResult.forecast,
      };
    });

    dropdownData.sort((a, b) => a.label.localeCompare(b.label));
    dropdownSetter(dropdownData);

    if (!includeNationwide) {
      const index = dropdownData.findIndex((item) => item.value.includes(' T 3'));
      indexSetter(dropdownData[index] || dropdownData[0]);
    } else {
      indexSetter(dropdownData[0]);
    }
  };

  const handleUpdateDropdown = (data) => {
    if (selectedDropdownType === 'A') {
      setIndxData(data, setIndexDropdownA, setSelectedCityIndexA);
    } else {
      setIndxData(data, setIndexDropdownB, setSelectedCityIndexB);
    }
  };

  const openDetailedSearchModal = (type) => {
    setDetailedSearchModal(true);
    setSelectedDropdownType(type);
  };

  useLayoutEffect(() => {
    getIndexDataWrapper({ post_town: 'LONDON' }, setIndexDropdownA, setSelectedCityIndexA);
    getIndexDataWrapper({ post_town: 'MANCHESTER' }, setIndexDropdownB, setSelectedCityIndexB);
    getIndexDataWrapper({ index: 'UK ' }, setIndexDropdownC, setSelectedCityIndexC, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DetailedCityIndexSearchModal
        setShowModal={setDetailedSearchModal}
        showModal={detailedSearchModal}
        handleSuccess={handleUpdateDropdown}
      />

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="pb-0 m-3 flex justify-start items-center">
              <Select
                className="w-1/3 mx-2"
                key="selectedIndexA"
                value={selectedIndexA}
                onChange={(item) => setSelectedCityIndexA(item)}
                options={indexDropdownA}
                isSearchable={true}
              />

              <PrimaryButton className="mx-1.5 w-52" onClick={() => openDetailedSearchModal('A')}>
                <FilterSvg className="mr-1.5" />
                Search Filters
              </PrimaryButton>
              <div className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                vs
              </div>
              <Select
                className="w-1/3 mx-2"
                key="selectedIndexB"
                value={selectedIndexB}
                onChange={(item) => setSelectedCityIndexB(item)}
                options={indexDropdownB}
                isSearchable={true}
              />
              <PrimaryButton className="mx-1.5 w-52" onClick={() => openDetailedSearchModal('B')}>
                <FilterSvg className="mr-1.5" />
                Search Filters
              </PrimaryButton>
              <div className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                vs
              </div>
              <Select
                className="w-1/3 mx-2"
                key="selectedIndexC"
                value={selectedIndexC}
                onChange={(item) => setSelectedCityIndexC(item)}
                options={indexDropdownC}
                isSearchable={true}
              />
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {selectedIndexA?.forecast ? (
                  <ItemVsIndexChart
                    address={selectedIndexA?.label}
                    index={selectedIndexB?.label}
                    index2={selectedIndexC?.label}
                    itemGraphSeriesData={seriesObjectToArray(selectedIndexA?.forecast || {}, targetTime)}
                    benchmarkGraphSeriesData={seriesObjectToArray(selectedIndexB?.forecast || {}, targetTime)}
                    benchmark2GraphSeriesData={seriesObjectToArray(selectedIndexC?.forecast || {}, targetTime)}
                    secondaryAxisButtonEnabled={true}
                  />
                ) : (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3 flex justify-between">
              <h4 className="fs-20 mb-0"> Year on year price change </h4>
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                <BaseBarChart
                  address={selectedIndexA?.label}
                  index={selectedIndexB?.label}
                  index2={selectedIndexC?.label}
                  itemGraphSeriesData={calculateYearlyChange(selectedIndexA?.forecast || {}, targetTime)}
                  benchmarkGraphSeriesData={calculateYearlyChange(selectedIndexB?.forecast || {}, targetTime)}
                  benchmark2GraphSeriesData={calculateYearlyChange(selectedIndexC?.forecast || {}, targetTime)}
                  dataType={'rate'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3 flex justify-between">
              <h4 className="fs-20 mb-0"> Month on month price change </h4>
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                <BaseBarChart
                  address={selectedIndexA?.label}
                  index={selectedIndexB?.label}
                  index2={selectedIndexC?.label}
                  itemGraphSeriesData={calculateMonthlyChange(selectedIndexA?.forecast || {}, targetTime)}
                  benchmarkGraphSeriesData={calculateMonthlyChange(selectedIndexB?.forecast || {}, targetTime)}
                  benchmark2GraphSeriesData={calculateMonthlyChange(selectedIndexC?.forecast || {}, targetTime)}
                  dataType={'rate'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CityIndiciesChart;
