import axios from 'axios';
import swal from 'sweetalert';
import { loginConfirmedAction, logout, setActiveUserAction } from '../store/actions/AuthActions';
import Cookies from 'js-cookie';
import { getUserData } from '../api/auth';

export function signUp(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData,
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData,
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case 'EMAIL_EXISTS':
      swal('Oops', 'Email already exists', 'error');
      break;
    case 'EMAIL_NOT_FOUND':
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
      break;
    case 'INVALID_PASSWORD':
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + (tokenDetails?.expiresIn ? tokenDetails?.expiresIn : 30 * 24 * 3600 * 1000),
  );
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function setAuthCookie(token, expires = 30) {
  Cookies.set('REalysepmt-token', token, {
    domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.realyse.com',
    expires: expires,
    secure: true,
  });
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  checkAutoLoginWithCookies(dispatch, history);
  const tokenDetailsString = localStorage.getItem('userDetails');

  let tokenDetails = '';
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);

  dispatch(loginConfirmedAction(tokenDetails));
}

export function checkAutoLoginWithCookies(dispatch, history) {
  const accessToken = Cookies.get('REalysepmt-token');

  if (!accessToken) {
    dispatch(logout(history));
    return;
  }

  getUserData(accessToken)
    .then((response) => {
      const responseUserData = response?.data?.data;
      dispatch(setActiveUserAction(responseUserData));
    })
    .catch((error) => {});
}
