import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PortfolioAlerts from './Components/PortfolioAlerts';
import PropertyAlerts from './Components/PropertyAlerts';

import { getTargetTime } from '../../../api/propertyIndex';

const Alerts = () => {
  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));

  return (
    <>
      <PortfolioAlerts targetTime={targetTime} />
      <PropertyAlerts targetTime={targetTime} />
    </>
  );
};
export default Alerts;
