import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const handleStyle = {
  borderColor: '#3571E5',
  borderWidth: 4,
  borderStyle: 'solid',
  opacity: 1,
  marginTop: -5,
  boxShadow: 'none',
  zIndex: 0,
  height: 14,
  width: 14,
};

const trackStyle = {
  backgroundColor: '#3571E5',
  height: 5,
};

const railStyle = {
  backgroundColor: '#E1EAFB',
  height: 5,
};

const RangeSliderItem = ({ title, min, max, step, range, updateRange, unit, unitPlacement = 'left' }) => {
  const [rangeValue, setRangeValue] = useState(range);
  const [rangeInputValue, setRangeInputValue] = useState(range);

  const changeRange = (event, updatePos) => {
    const value = event.target.value;
    const rangeInputTemp = rangeInputValue;
    updatePos ? setRangeInputValue([rangeInputTemp[0], value]) : setRangeInputValue([value, rangeInputTemp[1]]);
  };

  const onRangeInputBlur = (event, updatePos) => {
    const value = event.target.value;
    const { min, max } = event.target;
    const resultValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    const rangeInputTemp = rangeInputValue;
    if (updatePos) {
      setRangeInputValue([rangeInputTemp[0], resultValue]);
      setRangeValue([rangeInputTemp[0], resultValue]);
      updateRange([rangeInputTemp[0], resultValue]);
    } else {
      setRangeInputValue([resultValue, rangeInputTemp[1]]);
      setRangeValue([resultValue, rangeInputTemp[1]]);
      updateRange([resultValue, rangeInputTemp[1]]);
    }
  };

  const onRangeChange = (value) => {
    setRangeValue(value);
    updateRange(value);
    setRangeInputValue([value[0], value[1]]);
  };

  useEffect(() => {
    setRangeValue(range);
    setRangeInputValue(range);
  }, [range]);

  return (
    <div>
      <div className="text-xs mb-2"> {title} </div>
      <Slider
        range
        min={min}
        max={max}
        step={step}
        allowCross={false}
        value={rangeValue}
        onChange={(value) => onRangeChange(value)}
        handleStyle={handleStyle}
        trackStyle={trackStyle}
        railStyle={railStyle}
      />
      <div className="w-100 flex justify-between mb-4 mt-2">
        <div className={`flex items-center ${unitPlacement === 'right' ? 'flex-row-reverse' : 'flex-row'}`}>
          <span className="inline-block mx-2"> {unit} </span>
          <input
            className="w-20 h-6 px-2 bg-white rounded-lg border text-xs"
            placeholder="Min"
            type="number"
            onChange={(event) => changeRange(event, 0)}
            onBlur={(event) => onRangeInputBlur(event, 0)}
            value={rangeInputValue[0]}
            name="min"
            min={min}
            max={rangeValue[1]}
          />
        </div>
        <div className={`flex items-center ${unitPlacement === 'right' ? 'flex-row-reverse' : 'flex-row'}`}>
          <span className="inline-block mx-2"> {unit} </span>
          <input
            className="w-20 h-6 px-2 bg-white rounded-lg border text-xs"
            placeholder="Max"
            type="number"
            onChange={(event) => changeRange(event, 1)}
            onBlur={(event) => onRangeInputBlur(event, 1)}
            value={rangeInputValue[1]}
            name="max"
            min={rangeValue[0]}
            max={max}
          />
        </div>
      </div>
    </div>
  );
};
export default RangeSliderItem;
