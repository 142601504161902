import { addMonths, format, subMonths } from 'date-fns';
import { ISODateValuePairs } from './types';

export const getForecastPercentageChange = (forecast: ISODateValuePairs, targetTime: string, months: number) => {
  const getMovingAverage = (date: string): number | undefined => {
    const currentDate = new Date(date);
    const monthOffsets = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6];
    const alpha = 0.2;

    const values = monthOffsets
      .map((offset) => {
        const offsetDate = format(addMonths(currentDate, offset), 'yyyy-MM-dd');
        return forecast?.[offsetDate];
      })
      .filter((val): val is number => val !== undefined);

    if (values.length === 0) return undefined;

    let ema = values[0];
    for (let i = 1; i < values.length; i++) {
      ema = alpha * values[i] + (1 - alpha) * ema;
    }

    return ema;
  };

  const currentValue = getMovingAverage(targetTime);
  const prevValue = getMovingAverage(format(subMonths(new Date(targetTime), months), 'yyyy-MM-dd'));

  return currentValue !== undefined && prevValue !== undefined ? ((currentValue - prevValue) / prevValue) * 100 : null;
};
