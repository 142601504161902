import { StyleSheet, View, Text } from '@react-pdf/renderer';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  table: {
    width: '100%',
    border: 'none',
  },
  header: {
    fontSize: 12,
    marginBottom: 8,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    borderBottomStyle: 'solid',
  },
  cell: {
    flex: 1,
    padding: 4,
    fontSize: 8,
  },
  address: {
    flex: 2,
    padding: 4,
    fontSize: 8,
  },
});
const PdfListingsSection = ({ listingsData }) => {
  return (
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.cell}>#</Text>
        <Text style={styles.address}>Address</Text>
        <Text style={styles.cell}>Property Size (sq ft)</Text>
        <Text style={styles.cell}>Price (£)</Text>
        <Text style={styles.cell}>Listing Date</Text>
        <Text style={styles.cell}>New Build</Text>
        <Text style={styles.cell}>Bathrooms</Text>
        <Text style={styles.cell}>Tenure</Text>
        <Text style={styles.cell}>EPC rating</Text>
        <Text style={styles.cell}>Distance Radius</Text>
        <Text style={styles.cell}>Rental Yield</Text>
      </View>
      {listingsData.data.map((row, index) => (
        <View key={index} style={styles.row}>
          <Text style={styles.cell}>{(index + 10).toString(36).toUpperCase()}</Text>
          <Text style={styles.address}>{row.address}</Text>
          <Text style={styles.cell}>{row.size ? row.size : '-'}</Text>
          <Text style={styles.cell}>{row.price_lr ? numeral(row.price_lr).format() : '-'}</Text>
          <Text style={styles.cell}>{row.date ? row.date : '-'}</Text>
          <Text style={styles.cell}>{row.old_new_lr ? (row.old_new_lr === 'N' ? 'No' : 'Yes') : '-'}</Text>
          <Text style={styles.cell}>{row.bathrooms ? parseInt(row.bathrooms) : '-'}</Text>
          <Text style={styles.cell}>
            {row.freehold_leasehold ? (row.freehold_leasehold === 'F' ? 'Freehold' : 'Leasehold') : '-'}
          </Text>
          <Text style={styles.cell}>
            {row.current_energy_rating_epc && row.current_energy_rating_epc !== 'None'
              ? row.current_energy_rating_epc
              : '-'}
          </Text>
          <Text style={styles.cell}>{row.distance ? numeral(row.distance).format('0.00') : '-'}</Text>
          <Text style={styles.cell}>{row.rental_yield ? numeral(row.rental_yield).format('0.00%') : '-'}</Text>
        </View>
      ))}
    </View>
  );
};

export default PdfListingsSection;
