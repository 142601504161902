import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as ChevronLeft } from 'icons/custom/chevron-left.svg';
import PrimaryButton from '../Buttons/PrimaryButton';

const ImageCarouselModal = ({ showModal, currentIndex, setShowModal, images }) => {
  const getCarouselProps = () => ({
    showArrows: true,
    showThumbs: true,
    infiniteLoop: true,
    dynamicHeight: true,
    showStatus: false,
    showIndicators: false,
    swipeable: true,
    emulateTouch: true,
    renderArrowPrev: (clickHandler, hasPrev) => {
      return (
        <div
          className={`${
            hasPrev ? 'absolute' : 'hidden'
          } inset-y-1/2 left-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-20 m-3 mt-0 h-10 w-10`}
          onClick={clickHandler}
        >
          <ChevronLeft className="w-6 h-6" />
        </div>
      );
    },
    renderArrowNext: (clickHandler, hasNext) => {
      return (
        <div
          className={`${
            hasNext ? 'absolute' : 'hidden'
          } inset-y-1/2 right-0 flex justify-center items-center p-3 rounded-lg bg-white shadow-lg hover:opacity-80 cursor-pointer z-20 m-3 mt-0 h-10 w-10`}
          onClick={clickHandler}
        >
          <ChevronLeft className="w-6 h-6" style={{ transform: 'rotate(180deg)' }} />
        </div>
      );
    },
  });

  return (
    <Modal className="modal fade" size="lg" show={showModal} onHide={() => setShowModal(false)} enforceFocus={false}>
      <div className="flex w-full justify-between px-4 py-3">
        <div></div>
        <CloseSVG className="cursor-pointer mt-3" onClick={() => setShowModal(false)} />
      </div>
      <div className="px-3">
        <Carousel {...getCarouselProps()} selectedItem={currentIndex}>
          {images?.map((image, index) => {
            return (
              <div key={index}>
                <img
                  src={image}
                  alt="carousel"
                  className="h-100 object-contain"
                  style={{ maxHeight: 'calc(100vh - 100px)' }}
                />
              </div>
            );
          })}
        </Carousel>
      </div>

      <hr className="w-full" style={{ backgroundColor: 'gray' }} />
      <div className="flex w-full items-center justify-end px-3 py-3">
        <PrimaryButton className="ml-auto px-3 py-2 w-auto h-auto" onClick={() => setShowModal(false)}>
          Close
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default ImageCarouselModal;
