import React from 'react';
import { compareNumericString, propertySVGs } from '../../../../utils/helpers';
import Table from './BaseTable';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import SelectBedroomsColumnFilter from '../Components/SelectBedroomsColumnFilter';
import SelectTypeColumnFilter from '../Components/SelectTypeColumnFilter';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const BenchmarkTable = ({
  sampleTableData,
  children,
  filtersActive = true,
  title,
  pageCount,
  pageIndex,
  fetchData,
  staticData,
  loading,
  customActionButton,
}) => {
  const columnsMetadata = {
    post_town: {
      Header: 'Location',
      accessor: 'post_town',
      headerStyle: {
        minWidth: '170px',
      },
      style: {
        color: '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '113px',
        paddingRight: '10px',
      },
      filterable: false,
    },
    Chg_5Y: {
      Header: 'Last 5yr Return',
      accessor: 'Chg_5Y',
      headerStyle: { width: '130px' },
      sortType: compareNumericString,
      filterable: false,
      isSortedDesc: true,
    },
    number_of_bedrooms: {
      Header: 'Number Of Bedrooms',
      accessor: 'number_of_bedrooms',
      headerStyle: { width: '106px' },
      style: {
        width: '90px',
      },
      filterClassName: 'xl:w-52	lg:w-36 md:w-28	h-10 px-2.5 py-2 bg-white rounded-lg mr-3 text-xs',
      filterStyle: { border: '1px solid #F0F0F0' },
      filterable: true,
      filterPlaceholder: 'Number of Bedrooms',
      Filter: SelectBedroomsColumnFilter,
    },
    property_type: {
      Header: 'Property Type',
      id: 'property_type',
      accessor: (d) => {
        return d?.property_type ? propertySVGs[d?.property_type]?.name : '';
      },
      headerStyle: { width: '94px' },
      style: {
        width: '90px',
      },
      filterable: true,
      filterPlaceholder: 'Property Type',
      Filter: SelectTypeColumnFilter,
      filterClassName: 'xl:w-52	lg:w-36 md:w-28	h-9	px-2.5 py-2 bg-white rounded-lg mr-3',
      filter: 'includes',
    },
    area_code: {
      Header: 'Area',
      accessor: 'area_code',
      headerStyle: { width: '130px' },
      style: {
        color: '#000000',
        width: '130px',
      },
      filterable: false,
      filterClassName: 'xl:w-52	lg:w-36 md:w-28	h-9	px-2.5 py-2 bg-white rounded-lg mr-3 ml-3',
      filterStyle: { border: '1px solid #F0F0F0' },
      filterPlaceholder: 'Area',
    },
    district_code: {
      Header: 'District code',
      accessor: 'district',
      headerStyle: { width: '130px' },
      style: {
        width: '130px',
      },
      filterable: true,
      filterClassName: 'xl:w-52	lg:w-36 md:w-28	h-9	px-2.5 py-2 bg-white rounded-lg mr-3',
      filterStyle: { border: '1px solid #F0F0F0' },
      filterPlaceholder: 'District Code',
    },
    est_chg_fy1y: {
      Header: 'Estimated price change for the next 12 months',
      accessor: 'est_chg_fy1y',
      headerStyle: {
        width: '130px',
      },
      style: {
        color: '#000000',
        width: '130px',
      },
      sortType: compareNumericString,
      filterable: false,
    },
    est_chg_fy2y: {
      Header: 'Estimated price change for the next 24 months',
      accessor: 'est_chg_fy2y',
      headerStyle: {
        width: '130px',
      },
      style: {
        width: '130px',
      },
      sortType: compareNumericString,
      filterable: false,
    },
    price: {
      Header: 'Price',
      accessor: 'price',
      headerStyle: {
        width: '130px',
      },
      style: {
        width: '130px',
      },
      sortType: compareNumericString,
      filterable: false,
      formatter: (x) => {
        return x ? numeral(x).format() : '-';
      },
    },
    rental_price: {
      Header: 'Monthly Rent Price',
      accessor: 'rental_price',
      headerStyle: {
        width: '130px',
      },
      style: {
        width: '130px',
      },
      sortType: compareNumericString,
      filterable: false,
      formatter: (x) => {
        return x ? numeral(x).format() : '-';
      },
    },
    rental_yield: {
      Header: 'Rental Yield',
      id: 'rental_price/price',
      headerStyle: {
        borderRight: 'none',
        width: '130px',
      },
      style: {
        borderRight: 'none',
        width: '130px',
      },
      sortType: compareNumericString,
      filterable: false,
      accessor: (x) => {
        return x?.rental_price ? numeral((x?.rental_price / x?.price) * 12).format('0.000%') : '-';
      },
    },
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <div className="card">
          {title ? (
            <div className="card-header border-0">
              <h4 className="mb-0 fs-20 text-black">{title}</h4>
            </div>
          ) : (
            ' '
          )}
          {children}
          {sampleTableData.length ? (
            <Table
              columnsMetadata={columnsMetadata}
              sampleTableData={sampleTableData}
              filtersActive={filtersActive}
              paginationActive={true}
              fetchData={fetchData}
              pageCount={pageCount}
              pageIndex={pageIndex}
              staticData={staticData}
              loading={loading}
              customActionButton={customActionButton}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default BenchmarkTable;
