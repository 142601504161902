import { useQuery } from '@tanstack/react-query';
import NationwideVsMacrosChart from './NationwideVsMacrosChart';
import {
  apiGetBankRateMonthlyData,
  apiGetCPIHPMonthlyData,
  apiGetFTSEMonthlyData,
  apiGetUnemploymentMonthlyData,
} from '../../../../api/avm';
import React from 'react';

function NationwideVsMacrosList({ nationwideIndexSeries, targetTime }) {
  const { data: CPIHPData = [] } = useQuery(['CPIHPData'], ({ signal }) => apiGetCPIHPMonthlyData(signal));
  const { data: bankRateData = [] } = useQuery(['bankRateData'], ({ signal }) => apiGetBankRateMonthlyData(signal));
  const { data: FTSEData = [] } = useQuery(['FTSEData'], ({ signal }) => apiGetFTSEMonthlyData(signal));
  const { data: unemploymentData = [] } = useQuery(['unemploymentData'], ({ signal }) =>
    apiGetUnemploymentMonthlyData(signal),
  );

  return (
    <>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Macro economics </h4>

      <NationwideVsMacrosChart
        indexGraphSeriesData={CPIHPData}
        nationwideIndexSeries={nationwideIndexSeries}
        index="Consumer Price Index"
        targetTime={targetTime}
      ></NationwideVsMacrosChart>

      <NationwideVsMacrosChart
        indexGraphSeriesData={bankRateData}
        nationwideIndexSeries={nationwideIndexSeries}
        index="Bank Rate index"
        targetTime={targetTime}
      ></NationwideVsMacrosChart>

      <NationwideVsMacrosChart
        indexGraphSeriesData={FTSEData}
        nationwideIndexSeries={nationwideIndexSeries}
        index="FTSE100 index"
        targetTime={targetTime}
      ></NationwideVsMacrosChart>

      <NationwideVsMacrosChart
        indexGraphSeriesData={unemploymentData}
        nationwideIndexSeries={nationwideIndexSeries}
        index="Unemployment rate Changes"
        targetTime={targetTime}
      ></NationwideVsMacrosChart>
    </>
  );
}

export default React.memo(NationwideVsMacrosList);
