import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const handleStyle = {
  borderColor: '#3571E5',
  borderWidth: 4,
  borderStyle: 'solid',
  opacity: 1,
  marginTop: -5,
  boxShadow: 'none',
  zIndex: 0,
  height: 14,
  width: 14,
};

const trackStyle = {
  backgroundColor: '#3571E5',
  height: 5,
};

const railStyle = {
  backgroundColor: '#E1EAFB',
  height: 5,
};

const CustomSlider = ({ title, min, max, step, value, updateValue, unit, unitPlacement = 'left' }) => {
  const [sliderValue, setSliderValue] = useState(value);

  const changeValue = (event) => {
    const value = event.target.value;
    onRangeChange(value);
  };

  const onRangeChange = (value) => {
    setSliderValue(value);
    updateValue(value);
  };

  useEffect(() => {
    onRangeChange(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="text-xs mb-2"> {title} </div>
      <Slider
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={(value) => onRangeChange(value)}
        handleStyle={handleStyle}
        trackStyle={trackStyle}
        railStyle={railStyle}
      />
      <div className="w-100 flex justify-end mb-8 mt-2">
        <div className={`flex items-center ${unitPlacement === 'right' ? 'flex-row-reverse' : 'flex-row'}`}>
          <span className="inline-block mx-2"> {unit} </span>
          <input
            className="w-20 h-6 px-2 bg-white rounded-lg border text-xs"
            placeholder="Max"
            type="number"
            onChange={(event) => changeValue(event)}
            value={sliderValue}
            name="max"
            min={min}
            max={max}
          />
        </div>
      </div>
    </div>
  );
};
export default CustomSlider;
