import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { useEffect, useState } from 'react';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const calculatePV = (rate, periods, paymentPerYear, fv) => {
  let x = Math.pow(1 + rate, -periods);
  let y = Math.pow(1 + rate, periods);
  return (x * (fv * rate - paymentPerYear + y * paymentPerYear)) / rate;
};

const calculateFV = (rate, periods, paymentPerYear) => {
  var fv = paymentPerYear * Math.pow(1 + rate, periods);
  return fv;
};

const LeaseAssumptions = ({ values }) => {
  const [leaseAssumptionValues, setLeaseAssumptionValues] = useState({
    sellerDiscountRate: 15.0,
    buyerDiscountRate: 15.0,
    sellerGrowthRate: 2.5,
    buyerGrowthRate: 2.5,
    sellerPeriods: 15,
    buyerPeriods: 15,
  });

  const handleChange = (e) => {
    const { name, type, min, max } = e.target;
    let { value } = e.target;

    if (type === 'number' && (min || max)) {
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    const payment = values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52;

    setLeaseAssumptionValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };

      let sellerFutureValue = calculateFV(updatedValues.sellerGrowthRate / 100, updatedValues.sellerPeriods, payment);
      let sellerPresentValue = calculatePV(
        updatedValues.sellerDiscountRate / 100,
        updatedValues.sellerPeriods,
        payment,
        sellerFutureValue,
      );
      let buyerFutureValue = calculateFV(updatedValues.buyerGrowthRate / 100, updatedValues.buyerPeriods, payment);
      let buyerPresentValue = calculatePV(
        updatedValues.buyerDiscountRate / 100,
        updatedValues.buyerPeriods,
        payment,
        buyerFutureValue,
      );

      return { ...updatedValues, sellerFutureValue, buyerFutureValue, sellerPresentValue, buyerPresentValue };
    });
  };

  useEffect(() => {
    if (values) {
      const payment = values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52;

      setLeaseAssumptionValues((prevValues) => {
        let sellerFutureValue = calculateFV(prevValues.sellerGrowthRate / 100, prevValues.sellerPeriods, payment);
        let sellerPresentValue = calculatePV(
          prevValues.sellerDiscountRate / 100,
          prevValues.sellerPeriods,
          payment,
          sellerFutureValue,
        );
        let buyerFutureValue = calculateFV(prevValues.buyerGrowthRate / 100, prevValues.buyerPeriods, payment);
        let buyerPresentValue = calculatePV(
          prevValues.buyerDiscountRate / 100,
          prevValues.buyerPeriods,
          payment,
          buyerFutureValue,
        );

        return { ...prevValues, sellerFutureValue, buyerFutureValue, sellerPresentValue, buyerPresentValue };
      });
    }
  }, [values]);

  return (
    <div className="row mt-4">
      <div className="col-12">
        <h4 className="my-2 fs-16 text-black"> Lease Assumptions </h4>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> </div>
        <div className="col-4">
          <h4 className="my-2 fs-14 text-black"> For Seller </h4>
        </div>
        <div className="col-4">
          <h4 className="my-2 fs-14 text-black"> For Buyer </h4>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> GTM4 AVM VALUE </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(values.gtmPrice).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(values.gtmPrice).format()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> PV Lease </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(leaseAssumptionValues.sellerPresentValue).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(leaseAssumptionValues.buyerPresentValue).format()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> Discount Rate (%) </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <input
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-60"
              name="sellerDiscountRate"
              value={leaseAssumptionValues.sellerDiscountRate}
              type="number"
              onChange={handleChange}
              min={0}
              max={100}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <input
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-60"
              name="buyerDiscountRate"
              value={leaseAssumptionValues.buyerDiscountRate}
              type="number"
              onChange={handleChange}
              min={0}
              max={100}
            />
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> Growth Rate (%) </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <input
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-60"
              name="sellerGrowthRate"
              value={leaseAssumptionValues.sellerGrowthRate}
              type="number"
              onChange={handleChange}
              min={0}
              max={100}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <input
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-60"
              name="buyerGrowthRate"
              value={leaseAssumptionValues.buyerGrowthRate}
              type="number"
              onChange={handleChange}
              min={0}
              max={100}
            />
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> Periods </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <input
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-60"
              name="sellerPeriods"
              value={leaseAssumptionValues.sellerPeriods}
              type="number"
              onChange={handleChange}
              min={0}
              max={1000}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <input
              className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-60"
              name="buyerPeriods"
              value={leaseAssumptionValues.buyerPeriods}
              type="number"
              onChange={handleChange}
              min={0}
              max={1000}
            />
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> Payment (Yr 1 Rent) </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52).format()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> Future Value </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(leaseAssumptionValues.sellerFutureValue).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(leaseAssumptionValues.buyerFutureValue).format()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row items-center py-2">
        <div className="col-3"> MVST </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(values.gtmPrice + leaseAssumptionValues.sellerPresentValue).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="flex">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-xs" style={{ color: '#747B8A' }}></span>
              <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                {numeral(values.gtmPrice + leaseAssumptionValues.buyerPresentValue).format()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaseAssumptions;
