import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { getUser } from '../../../store/selectors/AuthSelectors';
import { connect } from 'react-redux';
import profile from '../../../images/profile.png';
import { ThemeContext } from '../../../context/ThemeContext';
import { Link } from 'react-router-dom';
import { ReactComponent as RealyseIcon } from 'icons/custom/logo.svg';
import { ReactComponent as ChevronLeft } from 'icons/custom/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'icons/custom/chevron-right.svg';
import { ReactComponent as RealyseIconMinimized } from 'icons/custom/logo-minimized.svg';
import { ReactComponent as Menu } from 'icons/custom/menu.svg';

const Header = ({ onNote, user }) => {
  const { openMenuToggle, menuToggle } = useContext(ThemeContext);
  const screenSize = window.innerWidth;
  const rightStyle = screenSize >= 1280 && !menuToggle ? { right: '8px' } : {};

  const handleToggleClick = () => {
    openMenuToggle({ toggle: !menuToggle });
  };

  return (
    <div className="header">
      <div className="nav-header hidden sm:hidden md:flex lg:flex xl:flex 2xl:flex">
        <Link to="/dashboard" className="brand-logo overflow-hidden">
          {!menuToggle ? <RealyseIcon className="mx-3.5" /> : <RealyseIconMinimized className="w-10" />}
        </Link>

        <div className="nav-control" onClick={() => handleToggleClick()} style={rightStyle}>
          {!menuToggle ? (
            <div
              className="w-8 h-8 bg-white flex items-center justify-center rounded-full"
              style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))', marginRight: '-30px' }}
            >
              <ChevronLeft className="mr-0.5" />
            </div>
          ) : (
            <div
              className="w-8 h-8 bg-white flex items-center justify-center rounded-full"
              style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))', marginLeft: '-125%' }}
            >
              <ChevronRight />
            </div>
          )}
        </div>
      </div>

      <div className="nav-header w-100 flex sm:flex md:hidden lg:hidden xl:hidden 2xl:hidden">
        <Link to="/dashboard" className="brand-logo flex sm:flex md:hidden lg:hidden xl:hidden 2xl:hidden">
          <RealyseIcon className="mx-3.5" />
        </Link>

        <div className="nav-control" onClick={() => handleToggleClick()} style={rightStyle}>
          {!menuToggle ? (
            <div
              className="w-8 h-8 bg-white flex items-center justify-center rounded-full"
              style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
            >
              <Menu className="h-5 w-5" />
            </div>
          ) : (
            <div
              className="w-8 h-8 bg-white flex items-center justify-center rounded-full"
              style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
            >
              <ChevronLeft />
            </div>
          )}
        </div>
      </div>

      <div className="header-content z-20 hidden sm:hidden md:block lg:block xl:block 2xl:block">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <ul className="navbar-nav header-right main-notification ml-auto">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                  <div className="header-info me-3">
                    <span className="fs-18 font-w500 text-end"> {user?.company_name} </span>
                    <small className="text-end fs-14 font-w400"> {user?.email} </small>
                  </div>
                  <img src={profile} alt="" />
                </Dropdown.Toggle>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: getUser(state),
}))(Header);
