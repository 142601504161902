import PropertyVsCPIHChart from '../../Dashboard/Charts/PropertyVsCPIHChart';

function NationwideVsMacrosChart({ index, indexGraphSeriesData, nationwideIndexSeries, targetTime }) {
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header pb-0 border-0 mb-3">
            <h4 className="fs-20 mb-0">
              {'UK Sales'} vs {index}
            </h4>
          </div>
          <div className="card-body py-0 px-sm-3 px-0">
            <div id="BarCharts" className="bar-chart">
              <PropertyVsCPIHChart
                scaled={false}
                indexGraphSeriesData={indexGraphSeriesData}
                propertyGraphSeriesData={nationwideIndexSeries}
                address={'UK'}
                index={index}
                targetTime={targetTime}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NationwideVsMacrosChart;
