import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export function searchEpcData(data, cancelToken = {}) {
  return axiosInstance.post(`/epccoefficients/`, data, {
    ...cancelToken,
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export const searchEpcDataCSV = async (data, cancelToken = {}) => {
  try {
    const response = await axiosInstance.post(`/epccoefficients/`, data, {
      ...cancelToken,
      headers: {
        Authorization: `Bearer ${Cookies.get('REalysepmt-token')}`,
        Accept: 'text/csv',
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get super index CSV failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
