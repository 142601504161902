import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const apiGetPropertyRentalIndex = async (uprn, signal) => {
  try {
    const response = await axiosInstance.post(
      `propertyrentalindex`,
      { uprns: [uprn] },
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get property analytics failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGenerateRentalIndex = async (params, cancelToken = {}) => {
  try {
    const response = await axiosInstance.post(`/property/rental_index`, params, {
      ...cancelToken,
      headers: {
        Authorization: `Bearer ${Cookies.get('REalysepmt-token')}`,
        Accept: 'text/csv',
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('rental index generation failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
