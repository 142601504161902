import React from 'react';
import ReactApexChart from 'react-apexcharts';
const colors = ['#0A2E6C', '#3571E5', '#3571e599', '#747B8A'];
const legendClassName = 'not-italic font-medium text-sm tracking-tight text-black';
const legendDotClassName = 'w-2.5 h-2.5 mr-5 rounded-full';

const PieChartAnalytics = (props) => {
  const series = props?.pieChartData.map((item, index) => {
    return parseInt(item.value);
  });

  const options = {
    chart: {
      height: 250,
      type: 'donut',
    },
    labels: props?.pieChartData.map((item) => {
      return item.name;
    }),
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + '%';
      },
      style: {
        colors: ['#FFFFFF'],
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 200,
      },
    },
    stroke: {
      width: 0,
    },
    colors,
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
  };

  return (
    <div id="chart" className="w-full flex">
      <ReactApexChart options={options} series={series} type="donut" height={250} />
      <div className="h-full w-px ml-28	mr-16" style={{ backgroundColor: '#F0F0F0', height: '200px' }}></div>
      <div className="flex flex-col">
        {props?.pieChartData.map((item, index) => (
          <div className="mb-7 flex flex-row items-center" key={index}>
            <div className={legendDotClassName} style={{ backgroundColor: colors[index] }}></div>
            <div className={legendClassName}>{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChartAnalytics;
