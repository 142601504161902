import React from 'react';
import { useTable, usePagination, useSortBy, useFilters, useRowSelect } from 'react-table';
import { ReactComponent as ArrowSortDownSvg } from 'icons/custom/arrow-sort-down.svg';
import { ReactComponent as ArrowSortUpSvg } from 'icons/custom/arrow-sort-up.svg';
import { ReactComponent as ResetFiltersSvg } from 'icons/custom/resetfilters.svg';
import SecondaryButton from '../Buttons/SecondaryButton';
import emptyImg from 'icons/custom/empty.png';
import InfoPopover from '../Components/InfoPopover';

const Table = ({
  columnsMetadata,
  sampleTableData = [],
  title,
  filtersActive = false,
  paginationActive = false,
  fetchData,
  staticData = false,
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex,
  controlledSelectedRows,
  onSelectedRowsChange,
  hiddenColumns,
  loading = false,
  customActionButton,
  stickyHeader = false,
  stripePattern = false,
}) => {
  const columns = React.useMemo(() => [...Object.values(columnsMetadata)], [columnsMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  const tableData = sampleTableData ? sampleTableData : [];
  const DefaultColumnFilter = ({ column }) => {
    if (!columnsMetadata[column.id]?.filterable) return null;
    return (
      <div className="flex flex-column ml-3">
        <label className="font-medium text-black text-xs"> {columnsMetadata[column.id].filterTitle} </label>
        <input
          value={column.filterValue || ''}
          onChange={(e) => {
            column.setFilter(e.target.value || undefined);
          }}
          className={columnsMetadata[column.id].filterClassName}
          style={columnsMetadata[column.id].filterStyle}
          placeholder={columnsMetadata[column.id].filterPlaceholder}
        />
      </div>
    );
  };

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  });

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setAllFilters,
    state: { pageIndex, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      manualSortBy: !staticData,
      manualPagination: !staticData,
      pageCount: !staticData ? controlledPageCount : Math.ceil(sampleTableData.length / 10),
      initialState: {
        pageIndex: !staticData ? controlledPageIndex : 0,
        pageSize: paginationActive ? 10 : sampleTableData.length,
        selectedRowIds: controlledSelectedRows ? controlledSelectedRows : {},
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) =>
      onSelectedRowsChange
        ? hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              hideSort: true,
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div className="mb-1">
                  <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ])
        : {},
  );

  const paginationExtensionLimit = 5;
  const paginationButtonsToShow = 7;
  const paginationButtonClassName = 'min-w-8 h-8 rounded-lg	flex justify-center items-center box-border p-2 ml-2';
  const paginationButtonStyle = {
    boxShadow: '3px 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid #F0F0F0',
  };
  const headerGeneralStyle = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    color: '#000000',
    lineHeight: '18px',
    borderRight: '1px solid #F0F0F0',
    paddingRight: '16px',
    paddingLeft: '16px',
    paddingBottom: '10px',
    width: onSelectedRowsChange ? '' : '130px',
  };

  const cellGeneralStyle = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#747B8A',
    height: '36px',
    borderRight: '1px solid #F0F0F0',
    paddingLeft: '16px',
    paddingRight: '5px',
  };

  const resetFilters = () => {
    setAllFilters([]);
  };

  React.useEffect(() => {
    !staticData && fetchData && fetchData({ pageIndex, sortBy });
  }, [pageIndex, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange({ selectedRowIds });
  }, [selectedRowIds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card-body">
      <div>
        {filtersActive && (
          <div className="flex mb-6">
            <div className="flex flex-wrap gap-y-3">
              {headerGroups
                .map((headerGroup, idxHeaderGroup) => {
                  return headerGroup.headers.map((column, idxHeader) => {
                    return (
                      <div key={'filter' + idxHeaderGroup + idxHeader}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    );
                  });
                })
                .flat()}
            </div>

            <div>{customActionButton}</div>

            <SecondaryButton className="ml-auto w-36 mt-6" onClick={resetFilters}>
              <ResetFiltersSvg className="mr-1.5" />
              Reset Filters
            </SecondaryButton>
          </div>
        )}
        {title && <div className="fs-18 text-black ml-4 mb-1">{title}</div>}
        <div className="overflow-x-auto relative" style={{ maxHeight: stickyHeader ? 500 : 'auto' }}>
          <table {...getTableProps()} style={{ border: 'none' }} className={'w-full'}>
            {page.length > 0 && (
              <thead className={stickyHeader ? 'sticky bg-white top-0' : ''}>
                {headerGroups.map((headerGroup, idxHeaderGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={idxHeaderGroup}>
                    {headerGroup.headers.map((column, idxHeader) => {
                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{
                            ...headerGeneralStyle,
                            ...(columnsMetadata[column.id]?.headerStyle || {}),
                          }}
                          key={'header' + idxHeaderGroup + idxHeader}
                        >
                          <div className="flex justify-start items-center w-full select-none h-full">
                            <div className="w-full">{column.render('Header')}</div>
                            {column?.hintData && (
                              <InfoPopover title={column.hintData.title} description={column.hintData.description} />
                            )}
                            <div className="pl-3" style={{ width: '10px' }}>
                              {!column.hideSort ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowSortDownSvg style={{ width: '10px' }} />
                                  ) : (
                                    <ArrowSortUpSvg />
                                  )
                                ) : (
                                  <>
                                    <ArrowSortUpSvg />
                                    <ArrowSortDownSvg />
                                  </>
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()} style={{ width: '100%' }}>
              {page.map((row, idxRow) => {
                prepareRow(row);
                const { key, role } = row.getRowProps();
                return (
                  <tr key={key} role={role} className={stripePattern && idxRow % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                    {row.cells.map((cell, idxCol) => {
                      const formatter = columnsMetadata[cell.column.id]?.formatter;
                      const renderer = columnsMetadata[cell.column.id]?.renderer;
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            ...cellGeneralStyle,
                            ...columnsMetadata[cell.column.id]?.style,
                          }}
                          key={idxCol}
                          title={columnsMetadata[cell.column.id]?.showTitle ? cell.value : ''}
                          onClick={
                            columnsMetadata[cell.column.id]?.handleClick
                              ? () => columnsMetadata[cell.column.id]?.handleClick(cell)
                              : undefined
                          }
                        >
                          {renderer && renderer(cell)}
                          {!renderer && (!formatter ? cell.render('Cell') : formatter(cell.value))}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {!loading && !page.length && (
            <div className="items-center flex flex-column justify-center my-5">
              <img className="h-52" src={emptyImg} alt="error" />
              <div className="text-lg text-black font-semibold text-center my-2"> No Results Found </div>
              <div className="text-xs font-normal w-80 text-center">
                Nothing was found based on your filter settings. Please change the filter settings.
              </div>
            </div>
          )}
        </div>
        {!!pageOptions.length && paginationActive && (
          <div className="flex justify-between w-full pt-3">
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <div className="flex justify-between text-black	">
              {pageCount > 1 && (
                <button
                  type="button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className={paginationButtonClassName}
                  style={paginationButtonStyle}
                >
                  {'<'}
                </button>
              )}
              {pageCount <= 10 && pageCount > 1 && (
                <>
                  {Array.apply(null, Array(pageCount))
                    .map((_, idx1) => idx1 + 1)
                    .map((val, idx2) => {
                      return (
                        <button
                          type="button"
                          onClick={() => gotoPage(val - 1)}
                          className={paginationButtonClassName}
                          style={{
                            ...paginationButtonStyle,
                            backgroundColor: pageIndex === val - 1 ? '#3571E5' : '#FFFFFF',
                            color: pageIndex === val - 1 ? 'white' : 'black',
                          }}
                          key={idx2}
                        >
                          {val}
                        </button>
                      );
                    })}
                </>
              )}
              {pageCount > 10 && pageIndex + 1 <= paginationExtensionLimit && (
                <>
                  {Array.apply(null, Array(paginationButtonsToShow))
                    .map((_, idx1) => idx1 + 1)
                    .map((val, idx2) => {
                      return (
                        <button
                          type="button"
                          onClick={() => gotoPage(val - 1)}
                          className={paginationButtonClassName}
                          style={{
                            ...paginationButtonStyle,
                            backgroundColor: pageIndex === val - 1 ? '#3571E5' : '#FFFFFF',
                            color: pageIndex === val - 1 ? 'white' : 'black',
                          }}
                          key={idx2}
                        >
                          {val}
                        </button>
                      );
                    })}
                  <button className={paginationButtonClassName} style={paginationButtonStyle}>
                    ...
                  </button>
                  <button
                    type="button"
                    onClick={() => gotoPage(pageCount - 1)}
                    className={paginationButtonClassName}
                    style={paginationButtonStyle}
                  >
                    {pageCount}
                  </button>
                </>
              )}
              {pageCount > 10 && pageIndex + 1 > pageCount - paginationExtensionLimit && (
                <>
                  <button
                    type="button"
                    onClick={() => gotoPage(0)}
                    className={paginationButtonClassName}
                    style={paginationButtonStyle}
                  >
                    1
                  </button>
                  <button className={paginationButtonClassName} style={paginationButtonStyle}>
                    ...
                  </button>
                  {Array.apply(null, Array(paginationButtonsToShow))
                    .map((_, idx1) => idx1 + 1 + pageCount - paginationButtonsToShow)
                    .map((val, idx2) => {
                      return (
                        <button
                          type="button"
                          onClick={() => gotoPage(val - 1)}
                          className={paginationButtonClassName}
                          style={{
                            ...paginationButtonStyle,
                            backgroundColor: pageIndex === val - 1 ? '#3571E5' : '#FFFFFF',
                            color: pageIndex === val - 1 ? 'white' : 'black',
                          }}
                          key={idx2}
                        >
                          {val}
                        </button>
                      );
                    })}
                </>
              )}
              {pageCount > 10 &&
                !(pageIndex + 1 <= paginationExtensionLimit) &&
                !(pageIndex + 1 > pageCount - paginationExtensionLimit) && (
                  <>
                    <button
                      type="button"
                      onClick={() => gotoPage(0)}
                      className={paginationButtonClassName}
                      style={paginationButtonStyle}
                    >
                      1
                    </button>
                    <button type="button" className={paginationButtonClassName} style={paginationButtonStyle}>
                      ...
                    </button>
                    {Array.apply(null, Array(paginationButtonsToShow))
                      .map((_, idx1) => pageIndex + 1 + idx1 - (paginationButtonsToShow - 1) / 2)
                      .map((val, idx2) => {
                        return (
                          <button
                            type="button"
                            onClick={() => gotoPage(val - 1)}
                            className={paginationButtonClassName}
                            style={{
                              ...paginationButtonStyle,
                              backgroundColor: pageIndex === val - 1 ? '#3571E5' : '#FFFFFF',
                              color: pageIndex === val - 1 ? 'white' : 'black',
                            }}
                            key={idx2}
                          >
                            {val}
                          </button>
                        );
                      })}
                    <button type="button" className={paginationButtonClassName} style={paginationButtonStyle}>
                      ...
                    </button>
                    <button
                      type="button"
                      onClick={() => gotoPage(pageCount - 1)}
                      className={paginationButtonClassName}
                      style={paginationButtonStyle}
                    >
                      {pageCount}
                    </button>
                  </>
                )}
              {pageCount > 1 && (
                <button
                  type="button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className={paginationButtonClassName}
                  style={paginationButtonStyle}
                >
                  {'>'}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Table;
