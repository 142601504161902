import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

const Card = ({ title, value, loading, info = '' }) => {
  return (
    <div className="card">
      <div className="card-body d-flex align-items-center">
        <div className="card-data flex">
          <div>
            <h5> {title} </h5>
            <div className="text-2xl font-semibold text-black">
              {loading ? <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" /> : value}
            </div>
          </div>
        </div>
        {info}
      </div>
    </div>
  );
};

export default Card;
