import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthWrapper from '../layouts/AuthWrapper';
import { requestPasswordReset } from '../../api/auth';
import swal from 'sweetalert';

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  let errorsObj = { required: '', format: '', accountExists: '' };
  const [errors, setErrors] = useState(errorsObj);

  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.required = 'Email is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    requestPasswordReset(email)
      .then((response) => {
        swal('Good job!', 'Please, Check Your Email', 'success');
      })
      .catch((error) => {
        console.log(error);
        swal('Oops', 'Invalid Email', 'error', { button: 'Try Again!' });
      });
  };

  return (
    <AuthWrapper>
      <div className="auth-form-1">
        <div className="mb-4">
          <div className="relative mb-2">
            <Link className="text-primary flex items-center" to="./login">
              {/* TODO: come up with the uniform solution for icons */}
              <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.6761 3.99236C13.6761 3.62436 13.3774 3.32569 13.0094 3.32569H3.6761V0.659027L0.342773 3.99236L3.6761 7.32569V4.65902H13.0094C13.3774 4.65902 13.6761 4.36036 13.6761 3.99236Z"
                  fill="#747B8A"
                />
              </svg>
              <span className="pl-2 text-gray-500"> Back </span>
            </Link>
          </div>
          <h3 className="dz-title mb-1" style={{ color: '#454B59' }}>
            Request Password Reset
          </h3>
          <p>
            Enter the email associated with your account and we’ll send an email with instructions to reset your
            password.
          </p>
        </div>

        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label className="mb-2 ">
              <strong>Email</strong>
            </label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email Address"
            />
            {errors.required && <div className="text-danger fs-12">{errors.required}</div>}
            {errors.accountExists && <div className="text-danger fs-12">{errors.accountExists}</div>}
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary btn-block">
              Submit
            </button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
}

export default ForgotPassword;
