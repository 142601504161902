function DataItem({ label, value, info }) {
  return (
    <div>
      <div className="font-medium text-xs my-1.5" style={{ color: '#747B8A' }}>
        {label}
      </div>
      <div className="font-medium text-xs text-black my-1.5 pb-3" style={{ borderBottom: '1px solid #F0F0F0' }}>
        {value} {info}
      </div>
    </div>
  );
}

export default DataItem;
