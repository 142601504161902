import { useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { apiGetPropertyAnalytics } from '../../../api/property';
import { ReactComponent as ArrowDown } from 'icons/custom/arrow-blue.svg';
import { ReactComponent as BackSVG } from 'icons/custom/arrow-back.svg';

import Listings from './Components/Listings';
import Comparables from './Components/Comparables';
import { useQuery } from '@tanstack/react-query';
import { propertySVGs } from '../../../utils/helpers';

const PropertyComparables = () => {
  const { propertyId } = useParams();
  const [isListingsOpen, setIsListingsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsListingsOpen((isListingsOpen) => !isListingsOpen);
  };

  const { data: property } = useQuery(['property-analytics', propertyId], ({ signal }) =>
    apiGetPropertyAnalytics(propertyId, signal),
  );

  return (
    <>
      <div className="flex justify-between">
        <div className="w-96">
          <div className="flex flex-wrap items-center justify-start">
            <Link className="text-primary flex items-center mb-2" to={`/property-analytics/${property?.id}`}>
              <BackSVG className="mr-2 h-4 w-4" />
              <span className="pl-2 text-gray-500 fs-14"> Back to Property analytics </span>
            </Link>
          </div>
          <h4 className="fs-20"> Refine comparables </h4>
        </div>

        <div className="mb-4 ml-8">
          <div className="mb-2">
            {property?.address && <div className="text-black text-xl font-medium mr-2">{property.address}</div>}
          </div>
          <div className="flex flex-wrap items-center justify-end">
            {property?.property_index_data ? (
              <>
                <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                  District: {property.property_index_data?.district_code}
                </div>
                <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                  Type: {propertySVGs[property.property_index_data?.property_type]?.name}
                </div>
                <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                  Rooms: {property.property_index_data?.number_of_bedrooms}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {property && property.id && (
        <div className="card p-0 mt-4">
          <div className="p-4">
            <Comparables property={property} />
          </div>
          <div onClick={toggleAccordion} className="flex items-center cursor-pointer px-4 pb-3">
            <ArrowDown className={`w-5 mr-2 ${isListingsOpen ? 'fa-rotate-0' : 'fa-rotate-180'}`} />
            <h4 className="text-base text-black"> Listings </h4>
          </div>

          {isListingsOpen && (
            <div className="p-4" style={{ backgroundColor: '# ' }}>
              <Listings property={property}></Listings>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PropertyComparables;
