import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const apiGetPremiumValue = async (group, signal) => {
  try {
    const response = await axiosInstance.get(`premiumvalue/${group}`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0] ? response.data.data[0] : {};
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
