import React from 'react';
import logo from '../../images/auth-logo.svg';

function AuthWrapper({ children }) {
  return (
    <div className="login-main-page relative h-full bg-cover bg-center d-flex items-center">
      <div className="login-wrapper w-100">
        <div className="login-aside-left">
          <div className="login-logo hidden sm:hidden md:hidden lg:hidden xl:block 2xl:block">
            <img className="mb-4 ml-auto lg:mr-auto lg:ml-0 xl:mr-auto xl:ml-0" src={logo} alt="Logo" />
            <div className="text-white fs-16 max-w-xs hidden sm:hidden md:hidden lg:hidden xl:block 2xl:block">
              Track and manage your Real Estate portfolios and properties with ease under one roof
            </div>
          </div>
        </div>
        <div className="login-aside-right sm:p-14 md:p-14 lg:p-14">
          <div className="relative row m-0 justify-content-center h-100 align-items-center">
            <img
              className="mb-4 sm:text-center sm:ml-0 md:ml-auto lg:ml-auto max-w-sm block sm:block md:block lg:block xl:hidden 2xl:hidden"
              src={logo}
              alt="Logo"
            />
            <div className="p-5 bg-white max-w-lg shadow-xl rounded-lg">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthWrapper;
