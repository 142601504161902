import { useCallback, useEffect, useState } from 'react';
import PlacesAutocomplete from '../Components/PlacesAutocomplete';

import debounce from 'lodash.debounce';
import { GoogleMap, Marker } from '@react-google-maps/api';

const defaultCenter = {
  lat: 51.5067621,
  lng: -0.1259233,
};
const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '20px',
};

const LocationMap = ({ setSelectedLocation }) => {
  const [center, setCenter] = useState(defaultCenter);
  const [selected, setSelected] = useState(defaultCenter);
  const [mapref, setMapRef] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const geocodeData = (selected) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: selected }).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let post_town, post_code;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'postal_code':
                post_code = response.results[0].address_components[i].long_name;
                break;
              case 'postal_town':
                post_town = response.results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }
        const { lat: latitude, lng: longitude } = selected;
        post_code = post_code ? post_code : '';
        const district_code = post_code ? post_code.match(/^([A-Za-z][A-Ha-hJ-Yj-y]?\d[A-Za-z\d]?)\s?/)[1] : '';

        setSelectedLocation({ address, post_town, post_code, district_code, latitude, longitude });
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const debouncedGetGeocodeData = useCallback(debounce(geocodeData, 1000), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    debouncedGetGeocodeData(selected);
  }, [debouncedGetGeocodeData, selected]);

  useEffect(() => setIsMounted(true), []);

  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      setSelected({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  const setNewCenter = (center) => {
    setCenter(center);
  };

  return (
    <>
      <div className="places-container mb-8">
        <PlacesAutocomplete setSelected={setNewCenter} />
      </div>
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
        mapContainerStyle={containerStyle}
        onCenterChanged={handleCenterChanged}
        onLoad={handleOnLoad}
      >
        {isMounted && <Marker position={selected} />}
      </GoogleMap>
    </>
  );
};

export default LocationMap;
