import {
  QueriesObserver,
  type QueryObserverOptions,
} from '@tanstack/react-query';
import { fromCallback } from 'xstate';
import { SUPER_INDEX_QUERY_KEY, getSuperIndex } from '.';
import type { SuperIndexRecord, SuperIndexRequestOptions } from './types';
import queryClient from 'utils/queryClient';

export type SuperIndicesDataEvent =
  | {
      type: 'SUPER_INDICES.DATA.UPDATE';
      superIndices: { data: SuperIndexRecord[] }[];
    }
  | { type: 'SUPER_INDICES.DATA.FETCHING' }
  | { type: 'SUPER_INDICES.DATA.ERROR'; error: Error }
  | { type: 'SUPER_INDICES.REFETCH'; options?: SuperIndexRequestOptions[] };

export const getSuperIndicesActor = fromCallback<
  SuperIndicesDataEvent,
  SuperIndexRequestOptions[]
>(({ input, sendBack, receive }) => {
  if (input.length === 0) {
    sendBack({ type: 'SUPER_INDICES.DATA.FETCHING' });
    sendBack({ type: 'SUPER_INDICES.DATA.UPDATE', superIndices: [] });
    return () => {};
  }

  const generateQuery = (
    options: SuperIndexRequestOptions,
  ): QueryObserverOptions => ({
    queryKey: [...SUPER_INDEX_QUERY_KEY, options],
    queryFn: async ({ signal }) => await getSuperIndex(options, signal),
    retry: (failureCount) => failureCount < 1,
  });

  let queries = input.map((options) => generateQuery(options));

  const observer = new QueriesObserver(queryClient, queries);

  observer.subscribe((results) => {
    const isFetching = results.some((result) => result.isFetching);
    const isSuccess = results.some((result) => result.isSuccess);
    const isError = results.some((result) => result.isError);

    if (isSuccess) {
      const data = results
        .filter((result) => result.isSuccess)
        .map((result) => result.data);
      sendBack({ type: 'SUPER_INDICES.DATA.UPDATE', superIndices: data });
    } else if (isFetching) {
      sendBack({ type: 'SUPER_INDICES.DATA.FETCHING' });
    } else if (isError) {
      const error = results.find((result) => result.isError)?.error;
      sendBack({ type: 'SUPER_INDICES.DATA.ERROR', error });
    }
  });

  const currentResults = observer.getCurrentResult() || [];
  const initialData = currentResults.map((result) => result.data);

  if (initialData.length > 0) {
    sendBack({ type: 'SUPER_INDICES.DATA.FETCHING' });
    sendBack({
      type: 'SUPER_INDICES.DATA.UPDATE',
      superIndices: initialData,
    });
  }

  receive((event) => {
    if (event.type === 'SUPER_INDICES.REFETCH') {
      if (event.options) {
        queries = event.options.map((options) => generateQuery(options));
        observer.setQueries(queries);
        observer.getObservers().forEach((observer) => observer.updateResult());
      } else {
        observer.getObservers().forEach((observer) => observer.refetch());
      }
    }
  });

  return () => {
    observer.destroy();
  };
});