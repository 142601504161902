import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

const PlacesAutocomplete = ({ setSelected }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'uk' },
    },
    cache: false,
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleSelect =
    ({ description }) =>
    async () => {
      setValue(description, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address: description });
        const { lat, lng } = getLatLng(results[0]);
        setSelected({ lat, lng });
      } catch (error) {
        console.log(error);
      }
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="flex items-center cursor-pointer pt-1.5 pb-1.5 pl-3 pr-3 text-black hover:bg-gray-200"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <span className="font-medium pr-1.5">{main_text}</span> <span>{secondary_text}</span>
        </li>
      );
    });

  return (
    <div ref={ref} className="relative text-xs w-100" style={{ height: 30, zIndex: 1 }}>
      <div className="absolute z-10 w-100 rounded-lg bg-white location-search" style={{ border: '1px solid #DFE1E5' }}>
        <input
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder="Search for an Address"
          className="w-100 rounded-lg bg-white py-2.5 px-3"
        />
        {status === 'OK' && (
          <>
            <div className="border-t mr-3 ml-3"></div>
            <ul className="bg-white pt-1.5 pb-2 rounded-lg">{renderSuggestions()}</ul>
          </>
        )}
      </div>
    </div>
  );
};

export default PlacesAutocomplete;
