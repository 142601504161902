import React, { useState } from 'react';
import BenchmarkTable from '../../Dashboard/Tables/BenchmarkTable';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';

const TablesByDistrictData = ({ property, searchTableData }) => {
  const [tableData, setTableData] = useState(searchTableData);
  const [worstFilterActive, setWorstFilterActive] = useState(false);

  const toggleCustomSort = (filterType) => {
    if (filterType) {
      setTableData(searchTableData.sort((a, b) => parseFloat(a.Chg_5Y) - parseFloat(b.Chg_5Y)).slice(0, 5));
    } else {
      setTableData(searchTableData.sort((a, b) => parseFloat(b.Chg_5Y) - parseFloat(a.Chg_5Y)));
    }
    setWorstFilterActive(filterType);
  };

  return (
    <>
      <BenchmarkTable
        sampleTableData={tableData.length ? tableData : searchTableData}
        title={`Expected Returns for [${
          property?.property_index_data ? property?.property_index_data?.district_code : ''
        }] Properties`}
        staticData={true}
        customActionButton={
          <SecondaryButton
            className={'mt-4 mx-3 h-11 rounded-md w-auto px-3'}
            onClick={() => toggleCustomSort(!worstFilterActive)}
          >
            {worstFilterActive ? 'Show all property types' : 'Show 5 worst-performing property types'}
          </SecondaryButton>
        }
      />
    </>
  );
};

export default React.memo(TablesByDistrictData);
