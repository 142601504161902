import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export function searchCompany(data, cancelToken = {}) {
  return axiosInstance.post(`land/company`, data, {
    ...cancelToken,
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

const lookupQuery = {
  geometry: {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [0.8794795016245628, 53.316923447670185],
          [-1.530210308234218, 56.445040202974226],
          [-0.9072854792339663, 57.80516880881289],
          [-2.7209162987181714, 58.06123992721038],
          [-0.1012422018419124, 60.48914297431226],
          [-0.3605285806478683, 61.03871475492648],
          [-1.1110525734915846, 61.10217113737986],
          [-2.246391069871384, 60.5044381143331],
          [-3.9481339631328005, 59.071081758402414],
          [-6.812908186658339, 58.88924686567622],
          [-7.949098959976753, 58.08867951086618],
          [-8.338334925171672, 56.92306443048373],
          [-7.431324950311663, 56.430173306389904],
          [-7.0498261995590115, 55.73644686374032],
          [-6.013036963079161, 55.344821776103274],
          [-5.116899269199543, 54.554434241932015],
          [-4.511107871854082, 54.50516038470729],
          [-3.713654685175388, 54.35888279735477],
          [-4.720124554784172, 53.659015968103766],
          [-5.669624206755715, 52.446869335919914],
          [-6.8903403250678785, 49.59123593696372],
          [-1.1541451187900866, 50.25573837880067],
          [1.7925984096324246, 51.07782675819643],
          [2.2008233520059943, 52.998785454302734],
          [0.8794795016245628, 53.316923447670185],
        ],
      ],
    },
    properties: {
      uuid: '2e52a83e-c0cb-4b4c-96be-c9d859d66e4f',
      visible: false,
      placeType: 'drawnShape',
      prettyName: 'Whole of GB',
      polygonType: 'polygon',
    },
  },
  limit: 20,
  offset: null,
  order_by: null,
  order_dir: 'desc',
};

export const searchPropertiesByCompany = async (data, signal) => {
  try {
    const response = await axiosInstance.post(
      `land/search`,
      { ...lookupQuery, ...data },
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
