import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PropertyOutput = ({ values }) => {
  return (
    <>
      <div className="row p-2 border my-4">
        <div className="col-12">
          <h4 className="my-2 fs-16 text-black"> Cost Summary </h4>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> </div>
          <div className="col-4">
            <h4 className="my-2 fs-14 text-black"> For Seller </h4>
          </div>
          <div className="col-4">
            <h4 className="my-2 fs-14 text-black"> For Buyer </h4>
          </div>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> Acquisition Building </div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield,
                ).format()}
              </span>
            </div>
          </div>
          <div className="col-2"></div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield,
                ).format()}
              </span>
            </div>
          </div>
        </div>

        <div className="row items-center py-2">
          <div className="col-4"> Acquisition Costs </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.sellerTotalAcquisitionCosts).format()}
              </span>
            </div>
          </div>

          <div className="col-2"></div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.buyerTotalAcquisitionCosts).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="row items-center py-2">
          <div className="col-4"> Reserve Integration </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.sellerTotalRefurbishmentCosts).format()}
              </span>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.buyerTotalRefurbishmentCosts).format()}
              </span>
            </div>
          </div>
        </div>
        <div className="row items-center py-2">
          <div className="col-4"> Other Costs </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.sellerTotalSalesCosts).format()}
              </span>
            </div>
          </div>
          <div className="col-2"></div>

          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(values.buyerTotalSalesCosts).format()}
              </span>
            </div>
          </div>
        </div>

        <div className="row items-center py-2">
          <div className="col-4 text-black"> Market Value (MV) </div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield +
                    values.sellerTotalAcquisitionCosts +
                    values.sellerTotalRefurbishmentCosts +
                    values.sellerTotalSalesCosts,
                ).format()}
              </span>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-2">
            <div className="w-28 mr-4"></div>
            <div
              className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-full"
              style={{ backgroundColor: '#F3F6FB' }}
            >
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                £
              </span>
              <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                {numeral(
                  (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) / values.acquisitionYield +
                    values.buyerTotalAcquisitionCosts +
                    values.buyerTotalRefurbishmentCosts +
                    values.buyerTotalSalesCosts,
                ).format()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyOutput;
