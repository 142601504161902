import Table from '../../Dashboard/Tables/BaseTable';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { propertySVGs } from '../../../../utils/helpers';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const columnsMetadata = {
  district_code: {
    Header: 'District Code',
    headerStyle: { width: '140px', minWidth: '120px' },
    filterable: false,
    accessor: 'district_code',
    formatter: (x) => (x ? x : '-'),
  },
  property_type: {
    Header: 'Property Type',
    headerStyle: { width: '140px', minWidth: '120px' },
    filterable: false,
    id: 'property_type',
    accessor: (d) => {
      return d?.property_type ? propertySVGs[d?.property_type]?.name : '';
    },
    formatter: (x) => (x ? x : '-'),
  },
  number_of_bedrooms: {
    Header: 'Number of Bedrooms',
    headerStyle: { width: '140px', minWidth: '120px' },
    style: { color: '#000' },
    filterable: false,
    accessor: 'number_of_bedrooms',
    formatter: (x) => (x ? x : '-'),
  },
  tenants: {
    Header: 'Number of Tenants',
    headerStyle: { width: '140px', minWidth: '120px' },
    filterable: false,
    accessor: 'tenants',
    formatter: (x) => (x ? x : '-'),
  },
  achieved_rent: {
    Header: 'Achieved Rent',
    headerStyle: { width: '140px', minWidth: '120px' },
    style: { color: '#000' },
    filterable: false,
    accessor: 'achieved_rent',
    formatter: (x) => (x ? numeral(x).format() : '-'),
  },
  asking_rent: {
    Header: 'Asking Rent',
    headerStyle: { width: '140px', minWidth: '120px' },
    filterable: false,
    accessor: 'asking_rent',
    formatter: (x) => (x ? numeral(x).format() : '-'),
  },
  lha: {
    Header: 'LHA',
    headerStyle: { width: '140px', minWidth: '120px' },
    filterable: false,
    accessor: 'lha',
    formatter: (x) => (x ? numeral(x).format() : '-'),
  },
  rental_price: {
    Header: 'Rental Price',
    headerStyle: { width: '140px', minWidth: '120px' },
    style: { color: '#000' },
    filterable: false,
    accessor: 'rental_price',
    formatter: (x) => (x ? numeral(x).format() : '-'),
  },
  lha_percentage_diff: {
    Header: '% Difference',
    headerStyle: { width: '140px', minWidth: '120px' },
    style: { color: '#000' },
    filterable: false,
    accessor: 'lha_percentage_diff',
    formatter: (x) => (x ? numeral(x).format('0.00%') : '-'),
  },
  brma_name: {
    Header: 'BRMA',
    headerStyle: { width: '140px', minWidth: '120px' },
    filterable: false,
    accessor: 'brma_name',
    formatter: (x) => (x ? x : '-'),
  },
};

const LhaData = ({ data, pageCount, pageIndex, fetchData, loading }) => {
  return (
    <>
      <Table
        columnsMetadata={columnsMetadata}
        sampleTableData={data}
        filtersActive={false}
        paginationActive={true}
        staticData={false}
        title=""
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </>
  );
};

export default LhaData;
