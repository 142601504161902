import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

const TransactionChart = ({ seriesData, label, loading }) => {
  const series = [
    {
      name: label,
      type: 'area',
      data: seriesData.length
        ? seriesData.map((item) => {
            let timestamp = new Date(item.timestamp);
            let value = parseInt(item.value);
            return [timestamp, value];
          })
        : [],
    },
  ];

  const options = {
    chart: {
      height: 350,
      toolbar: {
        show: true,
      },

      animations: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      enabled: true,
      y: {
        formatter: function (value) {
          if (value) {
            return value;
          }
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      colors: ['#3571E5', '#ff4560', '#705fd3'],
      strokeColors: ['#3571E5', '#ff4560', '#705fd3'],
      strokeOpacity: 1,
      fillOpacity: 1,
      size: [0.1, 0.1, 0.1, 0.1],
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 30,
      labels: {
        formatter: function (val, timestamp) {
          return moment(timestamp).format('MMM YYYY');
        },
        rotate: -90,
        rotateAlways: true,
        show: !loading,
      },
      lines: {
        show: false,
      },
    },
    yaxis: [
      {
        showAlways: true,
        labels: {
          formatter: function (val, index) {
            return val === undefined || val === null ? null : val;
          },
        },
      },
    ],
    colors: ['#3571E5', '#ff4560', '#705fd3'],
  };

  return (
    <>
      <div id="chart2" className="bar-chart pb-3">
        <ReactApexChart options={options} type="area" series={series} height={350} />
      </div>
    </>
  );
};

export default TransactionChart;
