import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

export const createAppraisal = async (propertyId, params, cancelToken = {}) => {
  try {
    const response = await axiosInstance.post(`property/${propertyId}/appraisal`, params, {
      ...cancelToken,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAppraisal = async (propertyId, params, cancelToken = {}) => {
  try {
    const response = await axiosInstance.patch(`property/${propertyId}/appraisal`, params, {
      ...cancelToken,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('get user properties failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPropertyAppraisal = async (id, signal) => {
  try {
    const response = await axiosInstance.get(`property/${id}/appraisal`, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response.data.data[0];
    } else {
      console.error('get property appraisal failed');
      return undefined;
    }
  } catch (error) {
    console.error('get property appraisal failed');
    return undefined;
  }
};
