import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Papa from 'papaparse';
import { createPropertiesFromCSV } from '../../../api/property';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ReactComponent as BackSVG } from 'icons/custom/arrow-back.svg';
import InvalidCSVModal from '../Dashboard/Modals/InvalidCSVModal';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ReactComponent as ExclamationSVG } from 'icons/custom/exclamation-triangle.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as Info } from 'icons/custom/info-icon.svg';

import { useQueryClient } from '@tanstack/react-query';
import InfoAsterisk from '../Dashboard/Components/InfoAsterisk';

const columns = [
  {
    header: 'Property Number or Name',
    required: true,
    info: 'Unique identifier for each flat or property',
    type: 'text',
  },
  { header: 'Address', required: true, info: 'Full address of the property', type: 'text' },
  { header: 'Post Code', required: true, info: 'Postal code for the property', type: 'text' },
  {
    header: 'Property Type',
    required: false,
    info: 'Type of property, this field should be one of the values: Flat, Terraced, Detached, or Semi Detached',
    type: 'dropdown',
    options: ['Flat', 'Terraced', 'Detached', 'Semi Detached'],
  },
  {
    header: 'Number of Bedrooms',
    required: false,
    info: 'Total number of bedrooms in the property',
    type: 'dropdown',
    options: [1, 2, 3, 4, 5],
  },
  { header: 'UPRN', required: false, info: 'Unique Property Reference Number', type: 'text' },
  {
    header: 'Last Transaction / Valuation Date',
    required: false,
    info: 'Date of last transaction or valuation',
    type: 'date',
  },
  {
    header: 'Last Transaction or Valuation Price (£)',
    required: false,
    info: 'Price of last transaction or valuation in GBP',
    type: 'number',
  },
  { header: 'Coordinates Lat', required: false, info: 'Latitude coordinate of the property', type: 'number' },
  { header: 'Coordinates Long', required: false, info: 'Longitude coordinate of the property', type: 'number' },
];

const titleMappings = {
  'Property Number or Name': 'Flat Number',
  Address: 'Address',
  'Post Code': 'Postcode',
  'Property Type': 'Property Type',
  'Number of Bedrooms': 'Number of Rooms',
  UPRN: 'Uprn',
  'Last Transaction / Valuation Date': 'Last Transaction Date',
  'Last Transaction or Valuation Price (£)': 'Last Transaction Price',
  'Coordinates Lat': 'Coordinates Lat',
  'Coordinates Long': 'Coordinates Long',
};

const CsvPropertiesReview = () => {
  const location = useLocation();
  const initialCsvData = location.state.csvData;
  const errorResponse = location.state.errors;
  const errorResponseLength = Object.values(errorResponse).reduce((sum, item) => sum + (item.invalid?.length ?? 0), 0);

  const [csvData, setCsvData] = useState(initialCsvData);
  const [editCell, setEditCell] = useState({ row: null, col: null });
  const [modifiedCells, setModifiedCells] = useState({});
  const [showInvalidSCVModal, setShowInvalidSCVModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();
  const today = new Date().toISOString().split('T')[0];

  const handleInputChange = (e, rowIndex, colKey) => {
    const updatedData = [...csvData];
    updatedData[rowIndex][colKey] = e.target.value;
    setCsvData(updatedData);

    setModifiedCells((prev) => ({
      ...prev,
      [`${rowIndex}-${colKey}`]: true,
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasError = useCallback((rowIndex, colKey) => {
    const rowErrors = errorResponse?.[rowIndex];
    const mappedColKey = titleMappings[colKey] || colKey;

    if (rowErrors && rowErrors.invalid) {
      const lowerCaseErrors = rowErrors.invalid.map((error) => error.toLowerCase());

      return lowerCaseErrors.includes(mappedColKey.toLowerCase());
    }

    return false;
  });

  const hasErrors = useMemo(() => {
    return csvData.some((row, rowIndex) =>
      columns.some((col) => {
        const isModified = modifiedCells[`${rowIndex}-${col.header}`];
        return hasError(rowIndex, col.header) && !isModified;
      }),
    );
  }, [csvData, hasError, modifiedCells]);

  const handleCsvSubmit = async () => {
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const theFile = new File([blob], 'updated_properties.csv', {
      type: 'text/csv',
    });
    try {
      setLoading(true);
      const response = await createPropertiesFromCSV({ attachment: theFile });
      const csvResponse = response.data.data;
      setLoading(false);

      if (Array.isArray(csvResponse)) {
        queryClient.invalidateQueries(['dashboardData']);
        queryClient.invalidateQueries(['rawProperties']);
        queryClient.invalidateQueries(['rawProperties', 'in-progress']);
        const queryState = queryClient.getQueryState(['properties']);
        if (queryState) {
          queryClient.refetchQueries(['properties']);
        }
        const propertyIds = csvResponse.map((property) => property.id);
        history.push('/properties', { csvPropertyIds: propertyIds });
      } else if (typeof csvResponse === 'object') {
        setShowInvalidSCVModal(true);
        setModifiedCells([]);

        Papa.parse(theFile, {
          header: true,
          complete: (results) => {
            const csvData = results.data;
            history.replace('/csv-properties-review', { csvData, errors: csvResponse });
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
          },
        });
      }
    } catch (error) {
      setShowInvalidSCVModal(true);
      setLoading(false);
      console.error(error);
    }
  };

  const renderCell = (rowIndex, colKey, value) => {
    const error = hasError(rowIndex, colKey);
    const isModified = modifiedCells[`${rowIndex}-${colKey}`];
    const isInEditMode = editCell.row === rowIndex && editCell.col === colKey;

    const column = columns.find((col) => col.header === colKey);
    const inputType = column?.type || 'text'; // Default to 'text' if no type is found
    const options = column?.options || null;

    let errorMessage = '';
    if (column?.type === 'number') {
      errorMessage = 'This field should be a number';
    } else if (column?.type === 'date') {
      errorMessage = 'This field should be in the format: yyyy-mm-dd';
    } else if (error) {
      errorMessage = 'This is a mandatory field';
    }

    const cellStyle = {
      border: error && !isInEditMode && !isModified ? '1px solid #CD453F' : '1px solid transparent',
      backgroundColor: isModified || isInEditMode ? 'transparent' : error ? '#FAB6B680' : 'transparent',
    };

    if (isInEditMode) {
      return (
        <div className="h-full flex p-1" style={cellStyle}>
          {options ? (
            <select
              value={value}
              onChange={(e) => handleInputChange(e, rowIndex, colKey)}
              onBlur={() => setEditCell({ row: null, col: null })}
              autoFocus
              className="text-xs w-full h-100 rounded-md py-1 px-2"
              style={{
                border: '1px solid #3571E5',
              }}
            >
              <option value="" disabled>
                Select {colKey}
              </option>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={inputType}
              value={value}
              onChange={(e) => handleInputChange(e, rowIndex, colKey)}
              onBlur={() => setEditCell({ row: null, col: null })}
              autoFocus
              max={inputType === 'date' ? today : undefined}
              className="text-xs w-full h-100 rounded-md py-1 px-2"
              style={{
                border: '1px solid #3571E5',
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="flex p-1 h-10" style={cellStyle}>
          {error && !isModified ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="text-white">
                  <div className="text-left flex justify-center items-center">
                    <div>
                      <ExclamationSVG className="mr-2 h-7 w-7" />
                    </div>
                    <div>
                      <div className="font-medium text-sm" style={{ color: '#CD453F' }}>
                        Invalid format
                      </div>
                      <div className="py-1 font-normal" style={{ fontSize: '12px', color: '#747B8A' }}>
                        {errorMessage}
                      </div>
                    </div>
                  </div>
                </Tooltip>
              }
            >
              <div
                onClick={() => setEditCell({ row: rowIndex, col: colKey })}
                className="block h-100 cursor-pointer w-full h-full px-1 py-1.5 truncate text-xs"
                style={{
                  color: error && !isModified ? '#CD453F' : '#000000',
                }}
              >
                {value}
              </div>
            </OverlayTrigger>
          ) : (
            <div
              onClick={() => setEditCell({ row: rowIndex, col: colKey })}
              className="block h-100 cursor-pointer w-full h-full px-1 py-1.5 truncate text-xs"
              style={{
                color: error && !isModified ? '#CD453F' : '#000000',
              }}
            >
              {value}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <InvalidCSVModal setShowModal={setShowInvalidSCVModal} showModal={showInvalidSCVModal} errors={[]} />

      <div>
        <Link className="text-primary flex items-center mb-3" to={`/properties`}>
          <BackSVG className="mr-2 h-4 w-4" />
          <span className="pl-2 text-gray-500 fs-14"> Back to properties </span>
        </Link>

        <div className="card p-3">
          <h1 className="mb-2.5 ml-2 fs-20 text-black"> Review Upload </h1>
          <div
            className="my-2 p-2 mb-4 bg-white flex width-auto justify-start items-center rounded-md font-normal text-sm"
            style={{ boxShadow: ' 0px 4px 6px -1px #0000001A', border: '1px solid #F0F0F0' }}
          >
            <ExclamationSVG className="mr-2 h-7 w-7" />

            <div>
              We found
              <span className="font-medium">
                {' '}
                {csvData.length} {csvData.length === 1 ? 'property' : 'properties'}{' '}
              </span>
              in your CSV with{' '}
              <span className="font-semibold" style={{ color: '#CD453F' }}>
                {errorResponseLength} {errorResponseLength === 1 ? 'issue' : 'issues'}{' '}
              </span>
              {errorResponseLength === 1 ? 'that needs' : 'that need'} to be reviewed. Please update the highlighted
              fields and click <span className="font-semibold"> Continue </span>
            </div>
          </div>
          <div className="w-100 overflow-y-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      className="w-48 "
                      style={{
                        border: '1px solid #D9DEE3',
                        textAlign: 'left',
                        padding: '10px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <div className="flex items-center	justify-center">
                        <span className="text-xs"> {column.header} </span>
                        {column.required && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}

                        <div className="h-6 w-6 ml-1">
                          <InfoAsterisk
                            text={
                              <span className="text-black">
                                {column.required ? (
                                  <div className="inline-block">
                                    <ExclamationSVG className="h-5 w-5 inline-block" /> Required Field.
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div> {column.info}</div>
                              </span>
                            }
                            placement="top"
                            color="text-white"
                            icon={<Info className="w-4 opacity-100 group-hover:opacity-0 transition-opacity" />}
                          />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((col, colIndex) => (
                      <td
                        key={colIndex}
                        className="text-left bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
                        style={{
                          border: '1px solid #D9DEE3',
                        }}
                      >
                        {renderCell(rowIndex, col.header, row[col.header] || '')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end items-center mt-4 mb-2">
            {loading ? <LoaderSvg className="animate-spin h-6 w-6 mx-2" /> : ''}
            <PrimaryButton onClick={handleCsvSubmit} style={{ marginTop: '20px' }} disabled={loading || hasErrors}>
              Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default CsvPropertiesReview;
