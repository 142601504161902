import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { Carousel } from 'react-responsive-carousel';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import { getCarouselProps } from '../../../../utils/helpers';

const ImageGalleryModal = ({ showModal, setShowModal, property }) => {
  const images = property ? [...(property?.property_images || []), ...(property?.floor_plans || [])] : [];

  return (
    <Modal className="modal fade" size="lg" show={showModal} onHide={() => setShowModal(false)} enforceFocus={false}>
      <div className="flex w-full justify-between px-4 py-3">
        <div>
          <div className="text-lg text-black mb-1"> Images & floorplans for: </div>
          <div className="px-2 py-1.5" style={{ backgroundColor: '#F2F6FB' }}>
            {property?.address && (
              <div className="text-black text-sm font-medium mr-2 capitalize">{property.address}</div>
            )}
          </div>
        </div>
        <CloseSVG className="cursor-pointer mt-3" onClick={() => setShowModal(false)} />
      </div>
      <div className="px-3">
        <Carousel {...getCarouselProps()}>
          {images?.map((image, index) => {
            return (
              <div key={index}>
                <img
                  src={image}
                  alt="carousel"
                  className="h-100 object-contain"
                  style={{ maxHeight: 'calc(100vh - 100px)' }}
                />
              </div>
            );
          })}
        </Carousel>
      </div>

      <hr className="w-full" style={{ backgroundColor: 'gray' }} />
      <div className="flex w-full items-center justify-end px-3 py-3">
        <PrimaryButton className="ml-auto px-3 py-2 w-auto h-auto" onClick={() => setShowModal(false)}>
          Close
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default ImageGalleryModal;
