import { useEffect, useMemo, useState } from 'react';
import { GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api';
import schoolMarker from 'icons/custom/schools.svg';
import publicTransportMarker from 'icons/custom/public-transport.svg';
import foodAndDrinkMarker from 'icons/custom/food-drink.svg';
import mainHomeIcon from 'icons/custom/home-marker.svg';
import parkIcon from 'icons/custom/park.svg';
import { apiSearchSchoolData } from 'api/avm';
import { useQuery } from '@tanstack/react-query';
import AmenitiesTable from './AmenitiesTable';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

const radius = 1000;

const amenityConfig = {
  publicTransport: { title: 'Public Transport', icon: publicTransportMarker },
  foodAndDrink: { title: 'Food and Drink', icon: foodAndDrinkMarker },
  parks: { title: 'Parks', icon: parkIcon },
};

const calculateDistance = (coord1, coord2) => {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const R = 3958.8;
  const lat1 = coord1.lat;
  const lng1 = coord1.lng;
  const lat2 = typeof coord2.lat === 'function' ? coord2.lat() : coord2.lat;
  const lng2 = typeof coord2.lng === 'function' ? coord2.lng() : coord2.lng;

  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

const categories = {
  publicTransport: [
    { type: 'bus_station', subtype: 'Bus Station' },
    { type: 'subway_station', subtype: 'Subway Station' },
    { type: 'train_station', subtype: 'Train Station' },
  ],
  foodAndDrink: [
    { type: 'restaurant', subtype: 'Restaurant' },
    { type: 'cafe', subtype: 'Cafe' },
    { type: 'bar', subtype: 'Bar' },
  ],
  parks: [{ type: 'park', subtype: 'Park' }],
};

const subtypeMapping = {
  first_primary_and_infant_schools: 'First, Primary & Infant Schools',
  broad_age_range_and_secondary_state_schools: 'Broad Age Range & Secondary Schools',
};

const NearbyAmenities = ({ property }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amenities, setAmenities] = useState({});

  const {
    data: schools = [],
    isLoading: schoolDataLoading,
    isSuccess: schoolDataLoaded,
  } = useQuery(['schoolData', property.original_uprn ? property.original_uprn : property.uprn], ({ signal }) =>
    apiSearchSchoolData({ uprn: [Number(property.original_uprn ? property.original_uprn : property.uprn)] }, signal),
  );

  const schoolsData = useMemo(() => {
    if (schoolDataLoaded) {
      const modifiedResults = schools

        .map((school) => ({
          ...school,
          location: { lat: school.latitude, lng: school.longitude },
          distance_label: school.distance_in_miles.toFixed(2),
          subtype: subtypeMapping[school.low_level_category] || school.low_level_category,
          rating: school.oftsed_rating,
          high_level_category: 'Schools',
        }))
        .sort((a, b) => a.distance_in_miles - b.distance_in_miles)
        .slice(0, 3);

      return modifiedResults;
    } else {
      return [];
    }
  }, [schools, schoolDataLoaded]);

  useEffect(() => {
    if (!window.google || !property) return;

    setLoading(true);
    const propertyCoordinates = { lat: property.latitude, lng: property.longitude };
    const map = new window.google.maps.Map(document.createElement('div'), {
      center: propertyCoordinates,
      zoom: 15,
    });
    const service = new window.google.maps.places.PlacesService(map);

    const newAmenities = {};

    const fetchAmenities = async () => {
      await Promise.all(
        Object.entries(categories).map(async ([category, subtypes]) => {
          newAmenities[category] = [];
          await Promise.all(
            subtypes.map(
              ({ type, subtype }) =>
                new Promise((resolve) => {
                  service.nearbySearch({ location: propertyCoordinates, radius, type }, (results, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                      let transformedResults = results
                        .filter(
                          (place) =>
                            place.business_status === 'OPERATIONAL' &&
                            (category !== 'publicTransport' && category !== 'foodAndDrink'
                              ? true
                              : place.user_ratings_total > 0),
                        )
                        .map((place) => ({
                          ...place,
                          location: place.geometry.location,
                          category,
                          subtype,
                          high_level_category: amenityConfig[category].title,
                          rating: place.rating || '-',
                          distance_label: calculateDistance(propertyCoordinates, place.geometry.location).toFixed(2),
                          distance_in_miles: calculateDistance(propertyCoordinates, place.geometry.location),
                        }));

                      newAmenities[category] = [...newAmenities[category], ...transformedResults];
                    }
                    resolve();
                  });
                }),
            ),
          );
        }),
      );

      const processedAmenities = {};

      Object.entries(newAmenities).forEach(([category, results]) => {
        const groupedBySubtype = {};
        results.forEach((result) => {
          if (!groupedBySubtype[result.subtype]) {
            groupedBySubtype[result.subtype] = [];
          }
          groupedBySubtype[result.subtype].push(result);
        });

        const distinctSubtypeResults = [];
        Object.keys(groupedBySubtype).forEach((subtype) => {
          const bestItem = groupedBySubtype[subtype].sort((a, b) => {
            if (b.rating !== a.rating) return b.rating - a.rating;
            return a.distance_in_miles - b.distance_in_miles;
          })[0];
          if (distinctSubtypeResults.length < 3) {
            distinctSubtypeResults.push(bestItem);
          }
        });

        if (distinctSubtypeResults.length < 3) {
          const remaining = results
            .filter((item) => !distinctSubtypeResults.includes(item))
            .sort((a, b) => {
              if (b.rating !== a.rating) return b.rating - a.rating;
              return a.distance_in_miles - b.distance_in_miles;
            });
          distinctSubtypeResults.push(...remaining.slice(0, 3 - distinctSubtypeResults.length));
        }

        processedAmenities[category] = distinctSubtypeResults.sort((a, b) => a.distance_in_miles - b.distance_in_miles);
      });

      setAmenities(processedAmenities);
      setLoading(false);
    };

    fetchAmenities();
  }, [property]);

  return (
    <>
      <h4 className="mb-2.5 ml-2 fs-20 text-black">Distance to Amenities</h4>
      <div className="card p-4">
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ height: '400px', width: '100%' }}>
            <GoogleMap
              center={{ lat: property.latitude, lng: property.longitude }}
              zoom={15}
              mapContainerStyle={{ width: '100%', height: '100%' }}
            >
              <MarkerF
                key={`main-property-marker-${property.latitude}-${property.longitude}`}
                position={{ lat: property.latitude, lng: property.longitude }}
                icon={mainHomeIcon}
              ></MarkerF>

              {schoolsData.map((place, index) =>
                place.name ? (
                  <MarkerF
                    key={`${place.name}-${place.category}-${place.subtype || index}`}
                    position={place.location}
                    icon={schoolMarker}
                    onClick={() => setSelectedPlace(place)}
                  />
                ) : (
                  ''
                ),
              )}

              {Object.values(amenities)
                .flat()
                .map((place, index) =>
                  place.place_id ? (
                    <MarkerF
                      key={`${place.place_id}-${place.category}-${place.subtype || index}`}
                      position={place.location}
                      icon={amenityConfig[place.category].icon}
                      onClick={() => setSelectedPlace(place)}
                    />
                  ) : (
                    ''
                  ),
                )}

              {selectedPlace && selectedPlace.name && (
                <InfoWindowF position={selectedPlace.location} onCloseClick={() => setSelectedPlace(null)}>
                  <div>
                    <h3>{selectedPlace.name}</h3>
                    <p>Type: {selectedPlace.subtype}</p>
                    {selectedPlace.vicinity && <p>Address: {selectedPlace.vicinity}</p>}
                    {selectedPlace.rating && <p>Rating: {selectedPlace.rating}⭐</p>}
                  </div>
                </InfoWindowF>
              )}
            </GoogleMap>
          </div>

          {schoolsData.length ? (
            <div className="flex flex-col sm:flex-row items-start">
              <div className="flex items-center w-60 mt-4">
                <img src={schoolMarker} className="w-9 mr-2" alt="Schools" />
                <div
                  className="py-1 px-3 text-md"
                  style={{ backgroundColor: '#F2F6FC', borderRadius: '20px', color: '#737B8B' }}
                >
                  Schools
                </div>
              </div>
              {schoolDataLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <div className="overflow-x-auto w-100">
                  <div style={{ minWidth: '800px' }}>
                    <AmenitiesTable data={schoolsData} loading={schoolDataLoading} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            ''
          )}

          {loading ? (
            <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
          ) : (
            Object.entries(amenities).map(([key, data]) => {
              const config = amenityConfig[key];
              if (!config || !data) return null;

              return data.length ? (
                <div key={key} className="flex flex-col sm:flex-row items-start ">
                  <div className="flex items-center w-60 mt-4">
                    <img src={config.icon} className="w-9 mr-2" alt={config.title} />
                    <div
                      className="py-1 px-3 text-md"
                      style={{ backgroundColor: '#F2F6FC', borderRadius: '20px', color: '#737B8B' }}
                    >
                      {config.title}
                    </div>
                  </div>

                  <div className="overflow-x-auto w-100">
                    <div style={{ minWidth: '800px' }}>
                      <AmenitiesTable data={data} loading={loading} />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default NearbyAmenities;
