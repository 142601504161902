import { ReactComponent as IncreaseSVG } from 'icons/custom/increase.svg';
import { ReactComponent as DecreaseSVG } from 'icons/custom/decrease.svg';
import { ReactComponent as UnchangedSVG } from 'icons/custom/unchanged.svg';

import numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const InfoIndicator = ({ value, label }) => {
  return (
    <div className="d-flex align-items-center">
      {value > 0 ? (
        <IncreaseSVG className="ml-2 mr-1 h-5 " />
      ) : value < 0 ? (
        <DecreaseSVG className="ml-2 mr-1 h-5" />
      ) : (
        <UnchangedSVG className="ml-2 mr-1 h-5" />
      )}
      <div className="mt-1.5">
        <div
          className="font-16 font-medium"
          style={{ color: value > 0 ? '#32D16D' : value < 0 ? '#FF4015' : '#FFA500' }}
        >
          {numeral(value).format('0.00%')}
        </div>
        <div className="font-normal text-xs text-right text-gray-500">{label}</div>
      </div>
    </div>
  );
};

const RegionCard = ({ regionData }) => {
  return (
    <div className="card">
      <div className="card-body d-flex align-items-center justify-between py-3 px-3">
        <div className="card-data flex">
          <div>
            <h5 className="mb-2 font-medium text-md w-full text-gray-300">{regionData.name}</h5>
            <div className="text-xl font-semibold text-black">{numeral(regionData?.currentPrice).format()}</div>
          </div>
        </div>
        <div>
          <InfoIndicator label={'Year on year'} value={regionData?.changeYoy} />
          <InfoIndicator label={'Month on month'} value={regionData?.changeMom} />
        </div>
      </div>
    </div>
  );
};

export default RegionCard;
