import { getUserData, login } from '../../api/auth';
import Cookies from 'js-cookie';
import swal from 'sweetalert';

import {
  formatError,
  runLogoutTimer,
  saveTokenInLocalStorage,
  setAuthCookie,
  signUp,
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const PASSWORD_RESET_CONFIRMED = 'Password reset confirmed';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const SET_USER_DATA_ACTION = '[login action] set user data';

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem('userDetails');
  Cookies.remove('REalysepmt-token');
  Cookies.remove('REalysepmt-token', { domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.realyse.com' });
  history.push('/login');
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        const token = response?.data?.access_token;

        const responseData = { idToken: token, email: email };
        localStorage.setItem('usertoken', email);
        runLogoutTimer(dispatch, response?.data?.expiresIn || 3600 * 1000, history);
        saveTokenInLocalStorage(responseData);

        setAuthCookie(response?.data?.access_token);
        dispatch(loginConfirmedAction(responseData));

        getUserData(token)
          .then((response) => {
            const responseUserData = response?.data?.data;
            dispatch(setActiveUserAction(responseUserData));
            history.push('/dashboard');
          })
          .catch((error) => {
            dispatch(loginFailedAction(error.message));
            history.push('/login');
          });
      })
      .catch((error) => {
        swal('Oops', 'Invalid Email or Password', 'error', { button: 'Try Again!' });
        dispatch(loginFailedAction(error.message));
        history.push('/login');
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function setActiveUserAction(payload) {
  return {
    type: SET_USER_DATA_ACTION,
    payload,
  };
}
