import Table from '../../Dashboard/Tables/BaseTable';
import { useQueries, useQuery } from '@tanstack/react-query';
import { apiGetPortfolioAnalyticsById, apiGetUserPortfolios } from '../../../../api/portfolio';
import { useMemo } from 'react';
import { columnsMetadataPortfolio } from '../../../../utils/alertsTableColumns';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import moment from 'moment';
import ErrorScreen from '../../SharedComponents/ErrorScreen';

function PortfolioAlerts({ targetTime }) {
  const {
    data: portfolios = [],
    isSuccess: portfoliosLoaded,
    isError,
  } = useQuery(['portfolios'], ({ signal }) => apiGetUserPortfolios(signal));

  const portfolioAnalyticsQueries = useQueries({
    queries: portfolios.map((portfolio) => {
      return {
        queryKey: ['portfolioAnalyticsData', portfolio.id],
        queryFn: ({ signal }) => apiGetPortfolioAnalyticsById(portfolio.id, signal),
        enabled: portfoliosLoaded,
      };
    }),
  });

  const portfolioAnalyticsQueriesFinished = useMemo(
    () => portfolioAnalyticsQueries.every((query) => query.isSuccess),
    [portfolioAnalyticsQueries],
  );

  const portfoliosData = useMemo(() => {
    if (portfoliosLoaded && portfolioAnalyticsQueriesFinished) {
      const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
      const formattedPrevDate = moment(targetTime).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const formattedTwelfthDate = moment(targetTime).add(12, 'months').startOf('month').format('YYYY-MM-DD');
      const formattedThirteenthDate = moment(targetTime).add(13, 'months').startOf('month').format('YYYY-MM-DD');

      return portfolioAnalyticsQueries.map((query, index) => {
        let portfolioRentPrice = 0;
        let portfolioPrevRentPrice = 0;
        let portfolioPrevRentPrice12th = 0;
        let portfolioPrevRentPrice13th = 0;

        if (query?.data.property_analytics && query?.data.property_analytics.length) {
          for (const property of query?.data.property_analytics) {
            const rentPrice = property.rental_index_data?.forecast
              ? property.rental_index_data?.forecast[formattedCurrentDate]
              : 0;
            const prevRentPrice = property.rental_index_data?.forecast
              ? property.rental_index_data?.forecast[formattedPrevDate]
              : 0;
            const prevRentPrice12th = property.rental_index_data?.forecast
              ? property.rental_index_data?.forecast[formattedTwelfthDate]
              : 0;
            const prevRentPrice13th = property.rental_index_data?.forecast
              ? property.rental_index_data?.forecast[formattedThirteenthDate]
              : 0;

            portfolioRentPrice = portfolioRentPrice + rentPrice;
            portfolioPrevRentPrice = portfolioPrevRentPrice + prevRentPrice;
            portfolioPrevRentPrice12th = portfolioPrevRentPrice12th + prevRentPrice12th;
            portfolioPrevRentPrice13th = portfolioPrevRentPrice13th + prevRentPrice13th;
          }
        }

        return {
          id: query?.data.id,
          portfolioName: query?.data.name,
          value: query?.data?.portfolio_value,
          numberOfProperties: query?.data.properties ? query?.data.properties.length : 0,
          chgActual: !isNaN(parseFloat(query?.data?.chg_1m)) ? parseFloat(query?.data?.chg_1m) : 0,
          chgForecast: !isNaN(parseFloat(query?.data?.est_chg_fy1y_1m))
            ? (parseFloat(query?.data?.est_chg_fy1y_1m) * 100).toFixed(2)
            : 0,
          rentPrice: portfolioRentPrice,
          chgRentActual: portfolioPrevRentPrice
            ? ((portfolioRentPrice - portfolioPrevRentPrice) / portfolioPrevRentPrice) * 100
            : undefined,
          chgRentForecast: portfolioPrevRentPrice12th
            ? ((portfolioPrevRentPrice13th - portfolioPrevRentPrice12th) / portfolioPrevRentPrice12th) * 100
            : undefined,
        };
      });
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioAnalyticsQueriesFinished, targetTime]);

  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        {isError ? (
          <div className="card h-auto">
            <ErrorScreen />
          </div>
        ) : (
          <div className="card">
            <div className="card-header border-0">
              <h4 className="mb-0 fs-20 text-black">Portfolio change notifications</h4>
            </div>
            {portfoliosData && portfoliosData?.length ? (
              <Table
                columnsMetadata={columnsMetadataPortfolio}
                sampleTableData={portfoliosData}
                filtersActive={true}
                paginationActive={false}
                staticData={true}
                stickyHeader={true}
                stripePattern={true}
              />
            ) : (
              <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PortfolioAlerts;
