import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from 'lucide-react';
import type { FC } from 'react';

interface ChangeIconProps {
  value?: number;
  overrideColor?: string;
}

const ChangeIcon: FC<ChangeIconProps> = ({ value, overrideColor }) => {
  return (
    <div>
      {value === null || value === undefined ? (
        <div
          className="bg-gray-400 flex h-7 items-center justify-center rounded-full text-white w-7"
          style={{ backgroundColor: overrideColor }}
        >
          ?
        </div>
      ) : value === 0 ? (
        <ArrowRightIcon
          className="bg-gray-500 h-7 p-1 rounded-full text-white w-7"
          style={{ backgroundColor: overrideColor }}
        />
      ) : value > 0 ? (
        <ArrowUpIcon
          className="bg-green-500 h-7 p-1 rounded-full text-white w-7"
          style={{ backgroundColor: overrideColor }}
        />
      ) : (
        <ArrowDownIcon
          className="bg-red-500 h-7 p-1 rounded-full text-white w-7"
          style={{ backgroundColor: overrideColor }}
        />
      )}
    </div>
  );
};

export default ChangeIcon;
