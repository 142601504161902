import Table from '../../Dashboard/Tables/BaseTable';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const SearchResultsTable = ({ items, pageCount, pageIndex, fetchData, loading }) => {
  const columnsMetadata = {
    property_address: {
      accessor: (x) => {
        return x?.property_address[0] ? x?.property_address[0] : '-';
      },
      Header: 'Address',
      id: 'property_address',
      headerStyle: {
        minWidth: '270px',
      },
      style: {
        color: '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '210px',
        paddingRight: '10px',
      },
      filterable: false,
    },
    tenure: {
      Header: 'Tenure',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'tenure',
      formatter: (x) => (x ? x : '-'),
    },
    area_sqft: {
      Header: 'Area sqft',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'area_sqft',
      formatter: (x) => (x ? x?.toFixed(2) : '-'),
    },
    area_buildings_sqft: {
      Header: 'Area Buildings sqft',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'area_buildings_sqft',
      formatter: (x) => (x ? x?.toFixed(2) : '-'),
    },
    ownership: {
      Header: 'Ownership',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'ownership',
      formatter: (x) => (x ? x : '-'),
    },
  };

  return (
    <>
      <div className="card">
        <Table
          columnsMetadata={columnsMetadata}
          sampleTableData={items}
          filtersActive={false}
          paginationActive={true}
          staticData={false}
          title=""
          pageCount={pageCount}
          pageIndex={pageIndex}
          fetchData={fetchData}
          loading={loading}
        />
      </div>
    </>
  );
};

export default SearchResultsTable;
