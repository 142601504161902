import React from 'react';
import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const BarChart = ({ categories, districtData, areaData, property }) => {
  const series = [
    {
      name: `District (${property?.property_index_data?.district_code})`,
      data: Object.values(districtData)?.length ? Object.values(districtData).map((val) => val * 100) : [],
    },
    {
      name: `Area (${property?.property_index_data?.area_code})`,
      data: Object.values(areaData)?.length ? Object.values(areaData).map((val) => val * 100) : [],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      type: 'solid',
      colors: ['#C3D4F7', '#FFE2E7'],
    },
    forecastDataPoints: {
      count: 0,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    stroke: {
      width: 1,
      colors: ['#3571E5', '#FF4560'],
      show: true,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'text',
      tickAmount: 30,
      categories: categories,
      labels: {
        rotate: -90,
        rotateAlways: true,
      },
      lines: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return numeral(val).format('0') + '%';
        },
      },
      forceNiceScale: true,
    },
    colors: ['#3571E5', '#FF4560'],
  };

  return (
    <>
      <div id="chart2" className="bar-chart base-bar-chart pb-3">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </>
  );
};

export default BarChart;
