import React from 'react';
import { ReactComponent as Error404SVG } from 'icons/custom/error404.svg';

const Error404: React.FC = () => {
  return (
    <div className="w-full h-screen	m-auto flex flex-column justify-center items-center bg-white rounded-b-3xl" style={{borderRadius: '20px', height: 'calc(100vh - 215px)'}}>
      <Error404SVG style={{marginTop: '-70px', height: '50%', width: 'auto'}}/>
      <div className='text-3xl font-medium mt-14' style={{color: '#3571E5'}}>Ooops! Page not found!</div>
    </div>
  );
};

export default Error404;
