import { area, booleanContains, booleanIntersects, center, distance, point, transformScale } from '@turf/turf';
import { type GeometryDataEvent, getGeometryActor } from 'api/geometry/actors';
import type { GeometryRecord } from 'api/geometry/types';
import type { PropertyTypeKey } from 'api/properties/types';
import { type SuperIndicesDataEvent, getSuperIndicesActor } from 'api/super-index/actors';
import type { SuperIndexRecord, SuperIndexRequestOptions } from 'api/super-index/types';
import type { ChangeData } from 'api/types';
import type { ValueLabelPair } from 'utils/types';
import { and, assertEvent, assign, sendTo, setup } from 'xstate';

export const MAX_BOUNDS_AREA = 1000000000;

export const MAX_GEOMETRY_COUNT = 100;

export const PERIOD_OPTIONS: ValueLabelPair<keyof ChangeData, string>[] = [
  {
    value: 'chg_1m',
    label: 'Last month',
  },
  {
    value: 'chg_3m',
    label: 'Last quarter',
  },
  {
    value: 'chg_1y',
    label: 'Last year',
  },
  {
    value: 'est_chg_1y',
    label: 'Next year',
  },
  {
    value: 'est_chg_2y',
    label: 'Next 2 years',
  },
];

export type PriceChangeMapMode = 'sales' | 'rents';

type PriceChangeMapContext = {
  mode: PriceChangeMapMode;
  bounds?: GeoJSON.Feature<GeoJSON.Polygon>;
  geometry?: GeometryRecord[];
  data?: Pick<SuperIndexRecord, 'district' | 'price' | 'rental_price' | 'rental_index' | 'forecast' | 'target_time'>[];
  period: ValueLabelPair<keyof ChangeData, string>;
  propertyType: PropertyTypeKey;
  numberOfBedrooms: number;
  center?: google.maps.LatLngLiteral;
  districtSelected?: string;
};

type PriceChangeMapEvent =
  | GeometryDataEvent
  | SuperIndicesDataEvent
  | {
      type: 'MODE.UPDATED';
      mode: PriceChangeMapMode;
    }
  | {
      type: 'BOUNDS.CHANGED';
      bounds: google.maps.LatLngBounds;
    }
  | {
      type: 'DISTRICTS.UPDATED';
      districts: string[];
    }
  | {
      type: 'PERIOD.UPDATED';
      period: ValueLabelPair<keyof ChangeData, string>;
    }
  | {
      type: 'PROPERTY_TYPE.UPDATED';
      propertyType: PropertyTypeKey;
    }
  | {
      type: 'NUMBER_OF_BEDROOMS.UPDATED';
      numberOfBedrooms: number;
    }
  | {
      type: 'DISTRICT.SELECTED';
      district?: string;
    };

type PriceChangeMapInput = {
  propertyType?: PropertyTypeKey;
  numberOfBedrooms?: number;
  center?: google.maps.LatLngLiteral;
  districtSelected?: string;
};

const convertBoundsToGeoJSON = (bounds: google.maps.LatLngBounds) => {
  const { east, north, south, west } = bounds.toJSON();
  let geojson = {
    type: 'Feature' as const,
    properties: {},
    geometry: {
      type: 'Polygon' as const,
      coordinates: [
        [
          [west, north],
          [east, north],
          [east, south],
          [west, south],
          [west, north],
        ],
      ],
    },
  };
  const boundsArea = area(geojson);
  if (boundsArea > MAX_BOUNDS_AREA) {
    geojson = transformScale(geojson, MAX_BOUNDS_AREA / boundsArea);
  }
  return geojson;
};

const getSuperIndexRequestOptions = (context: PriceChangeMapContext): SuperIndexRequestOptions[] => {
  return context
    .geometry!.filter((geometry) => booleanIntersects(context.bounds, geometry))
    .map<SuperIndexRequestOptions>(({ properties }) => ({
      districtExact: properties.districtCode,
      propertyTypes: [context.propertyType],
      numberOfBedrooms: [context.numberOfBedrooms],
      pagination: {
        pageIndex: 0,
        pageSize: 1,
      },
    }));
};

const priceChangeMapMachine = setup({
  types: {
    context: {} as PriceChangeMapContext,
    events: {} as PriceChangeMapEvent,
    input: {} as PriceChangeMapInput,
  },
  actions: {
    setBounds: assign(({ event }) => {
      assertEvent(event, 'BOUNDS.CHANGED');
      return {
        bounds: convertBoundsToGeoJSON(event.bounds),
      };
    }),
    setGeometry: assign(({ context, event }) => {
      assertEvent(event, 'GEOMETRY.DATA.UPDATE');
      const boundsCenter = center(context.bounds);
      const geometry = [...event.geometry]
        .sort((a, b) => {
          const centerA = a.properties.center;
          const centerB = b.properties.center;
          const pointA = point([centerA.lng, centerA.lat]);
          const pointB = point([centerB.lng, centerB.lat]);
          const distanceA = distance(pointA, boundsCenter);
          const distanceB = distance(pointB, boundsCenter);
          return distanceA - distanceB;
        })
        .slice(0, MAX_GEOMETRY_COUNT);
      return {
        geometry,
      };
    }),
    setData: assign(({ event }) => {
      assertEvent(event, 'SUPER_INDICES.DATA.UPDATE');
      return {
        data: event.superIndices
          .filter((superIndex) => !!superIndex?.data && superIndex.data.length > 0)
          .map((superIndex) => {
            const { district, price, rental_price, rental_index, forecast, target_time } = superIndex.data[0];
            return {
              district,
              price,
              rental_price,
              rental_index,
              forecast,
              target_time,
            };
          }),
      };
    }),
    setPeriod: assign(({ event }) => {
      assertEvent(event, 'PERIOD.UPDATED');
      return {
        period: event.period,
      };
    }),
    setPropertyType: assign(({ event }) => {
      assertEvent(event, 'PROPERTY_TYPE.UPDATED');
      return {
        propertyType: event.propertyType,
      };
    }),
    setNumberOfBedrooms: assign(({ event }) => {
      assertEvent(event, 'NUMBER_OF_BEDROOMS.UPDATED');
      return {
        numberOfBedrooms: event.numberOfBedrooms,
      };
    }),
    refetchSuperIndexData: sendTo('getSuperIndicesActor', ({ context }) => {
      return {
        type: 'SUPER_INDICES.REFETCH',
        options: getSuperIndexRequestOptions(context),
      };
    }),
    refetchGeometryData: sendTo('getGeometryActor', ({ context }) => ({
      type: 'GEOMETRY.REFETCH',
      options: {
        geometry: context.bounds!,
        lod: [2],
      },
    })),
    setDistrictSelected: assign(({ event }) => {
      assertEvent(event, 'DISTRICT.SELECTED');
      return {
        districtSelected: event.district,
      };
    }),
    clearDistrictSelected: assign(() => {
      return {
        districtSelected: undefined,
      };
    }),
    setMode: assign(({ event }) => {
      assertEvent(event, 'MODE.UPDATED');
      return {
        mode: event.mode,
      };
    }),
  },
  actors: {
    getGeometryActor,
    getSuperIndicesActor,
  },
  guards: {
    isGeometryDataUpdate: ({ event }) => event.type === 'GEOMETRY.DATA.UPDATE',
    geometryNeedsUpdating: ({ context, event }) => {
      assertEvent(event, 'BOUNDS.CHANGED');
      return !context.bounds || !booleanContains(context.bounds, convertBoundsToGeoJSON(event.bounds));
    },
    hasOptions: ({ context }) => {
      return !!context.geometry && context.geometry.length > 0 && !!context.propertyType && !!context.numberOfBedrooms;
    },
    hasData: ({ context }) => !!context.data && context.data.length > 0,
    hasDistrictSelected: ({ context, event }) => {
      if (event.type === 'DISTRICT.SELECTED') {
        assertEvent(event, 'DISTRICT.SELECTED');
        return !!event.district;
      }
      return !!context.districtSelected;
    },
  },
}).createMachine({
  id: 'price-change-map',
  context: ({ input }) => ({
    mode: 'sales',
    period: PERIOD_OPTIONS[2],
    propertyType: input.propertyType ?? 'T',
    numberOfBedrooms: input.numberOfBedrooms ?? 3,
    center: input.center,
    districtSelected: input.districtSelected,
  }),
  type: 'parallel',
  states: {
    geometry: {
      initial: 'default',
      states: {
        default: {
          on: {
            'BOUNDS.CHANGED': {
              actions: 'setBounds',
              target: 'hasBounds',
            },
          },
        },
        hasBounds: {
          invoke: {
            id: 'getGeometryActor',
            src: 'getGeometryActor',
            input: ({ context }) => ({
              geometry: context.bounds!,
              lod: [2],
            }),
          },
          on: {
            'BOUNDS.CHANGED': [
              {
                actions: ['setBounds', 'refetchGeometryData'],
                guard: 'geometryNeedsUpdating',
              },
              {
                actions: 'setBounds',
              },
            ],
            'GEOMETRY.DATA.FETCHING': {
              target: '.fetching',
            },
            'GEOMETRY.DATA.UPDATE': {
              actions: 'setGeometry',
              target: '.success',
            },
          },
          initial: 'default',
          states: {
            default: {},
            fetching: {
              on: {
                'GEOMETRY.DATA.ERROR': {
                  target: 'error',
                },
              },
            },
            success: {
              always: [
                {
                  actions: 'refetchSuperIndexData',
                  guard: and(['hasData', 'isGeometryDataUpdate']),
                },
                {
                  target: '#superIndex.hasOptions',
                  guard: 'isGeometryDataUpdate',
                },
              ],
            },
            error: {},
          },
        },
      },
    },
    superIndex: {
      id: 'superIndex',
      on: {
        'PERIOD.UPDATED': {
          actions: 'setPeriod',
        },
      },
      initial: 'default',
      states: {
        default: {
          on: {
            'PROPERTY_TYPE.UPDATED': {
              actions: 'setPropertyType',
            },
            'NUMBER_OF_BEDROOMS.UPDATED': {
              actions: 'setNumberOfBedrooms',
            },
          },
        },
        hasOptions: {
          invoke: {
            id: 'getSuperIndicesActor',
            src: 'getSuperIndicesActor',
            input: ({ context }) => getSuperIndexRequestOptions(context),
          },
          on: {
            'SUPER_INDICES.DATA.FETCHING': {
              target: '.fetching',
            },
            'SUPER_INDICES.DATA.UPDATE': {
              actions: 'setData',
              target: '.success',
            },
            'SUPER_INDICES.DATA.ERROR': {
              target: '.error',
            },
          },
          initial: 'default',
          states: {
            default: {},
            fetching: {},
            success: {},
            error: {},
          },
        },
      },
    },
    filters: {
      on: {
        'PROPERTY_TYPE.UPDATED': {
          actions: 'setPropertyType',
          target: '.updated',
        },
        'NUMBER_OF_BEDROOMS.UPDATED': {
          actions: 'setNumberOfBedrooms',
          target: '.updated',
        },
      },
      initial: 'default',
      states: {
        default: {},
        updated: {
          after: {
            100: 'refetching',
          },
        },
        refetching: {
          entry: 'refetchSuperIndexData',
        },
      },
    },
    card: {
      on: {
        'DISTRICT.SELECTED': [
          {
            actions: 'setDistrictSelected',
            target: '.opening',
            guard: 'hasDistrictSelected',
          },
          {
            target: '.closing',
          },
        ],
      },
      initial: 'conductor',
      states: {
        conductor: {
          always: [
            {
              target: 'open',
              guard: 'hasDistrictSelected',
            },
            {
              target: 'closed',
            },
          ],
        },
        opening: {
          after: {
            400: 'open',
          },
        },
        open: {},
        closing: {
          after: {
            400: {
              actions: 'clearDistrictSelected',
              target: 'closed',
            },
          },
        },
        closed: {},
      },
    },
    mode: {
      on: {
        'MODE.UPDATED': {
          actions: 'setMode',
        },
      },
    },
  },
});

export default priceChangeMapMachine;
