import React, { useMemo, useState } from 'react';

import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import PageHead from '../additionalModules/PageHead';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';
import { apiGetRawUserProperties, apiGetPropertyAnalytics } from '../../../api/property';
import { customSelectStyles } from '../../../utils/constants';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { apiGetRentalIndexByParams } from '../../../api/rentalIndex';
import moment from 'moment';
import LeaseAssumptions from './Components/LeaseAssumptions';
import Summary from './Components/Summary';
import SecondaryButton from '../Dashboard/Buttons/SecondaryButton';
import axios from 'axios';
import { createAppraisal, getPropertyAppraisal, updateAppraisal } from '../../../api/appraisal';
import StatusModal from '../Dashboard/Modals/StatusModal';
import PropertyOutput from './Components/PropertyOutput';
import LeaseOutput from './Components/LeaseOutput';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const roomsMapping = {
  1: 2,
  2: 3,
  3: 5,
  4: 6,
  5: 6,
};

const Appraisal = () => {
  const {
    data: rawProperties = [],
    isLoading: rawPropertiesLoading,
    isSuccess: rawPropertiesLoaded,
  } = useQuery(['rawProperties'], ({ signal }) => apiGetRawUserProperties(signal, false, true));

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [appraisalData, setAppraisalData] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
    title: '',
  });

  const [values, setValues] = useState({
    numberOfRentalUnits: 0,
    rentPerUnitPerWeek: 0,
    acquisitionYield: 7,
    vatRate: 20,

    sellerDevelopmentCosts: 15000,
    sellerSdlt: 10000,

    sellerPricePerUnit: 0,
    sellerFurniturePack: 5000,
    sellerLegalFees: 5000,
    sellerLegalCosts: 15000,
    sellerSurveys: 5000,
    sellerFee: 7000,
    sellerProfessionalFees: 5000,

    sellerTotalAcquisitionCosts: 0,

    buyerDevelopmentCosts: 15000,
    buyerSdlt: 10000,

    buyerPricePerUnit: 0,
    buyerFurniturePack: 5000,
    buyerLegalFees: 5000,
    buyerLegalCosts: 15000,
    buyerSurveys: 5000,
    buyerFee: 7000,
    buyerProfessionalFees: 5000,

    communalWorksEnabled: false,
    constructionBillsEnabled: false,

    sellerCommunalWorks: 0,
    buyerCommunalWorks: 0,
    sellerBillsDuringConstruction: 0,
    buyerBillsDuringConstruction: 0,
  });

  const handleChange = (e) => {
    const { name, type, min, max, checked } = e.target;
    let { value } = e.target;

    if (type === 'number' && (min || max)) {
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    setValues((prevValues) => {
      const updatedValues =
        name !== 'communalWorksEnabled' && name !== 'constructionBillsEnabled'
          ? { ...prevValues, [name]: Math.trunc(numeral(value).value()) }
          : { ...prevValues, [name]: checked };

      if (name === 'communalWorksEnabled' || name === 'constructionBillsEnabled') {
        if (updatedValues.communalWorksEnabled) {
          updatedValues.sellerCommunalWorks = updatedValues.sellerCommunalWorks
            ? updatedValues.sellerCommunalWorks
            : updatedValues.sellerDevelopmentCosts / 2;
          updatedValues.buyerCommunalWorks = updatedValues.buyerCommunalWorks
            ? updatedValues.buyerCommunalWorks
            : updatedValues.buyerDevelopmentCosts / 2;
        } else {
          updatedValues.sellerCommunalWorks = 0;
          updatedValues.buyerCommunalWorks = 0;
        }

        if (updatedValues.constructionBillsEnabled) {
          updatedValues.sellerBillsDuringConstruction = updatedValues.sellerBillsDuringConstruction
            ? updatedValues.sellerBillsDuringConstruction
            : 1000;
          updatedValues.buyerBillsDuringConstruction = updatedValues.buyerBillsDuringConstruction
            ? updatedValues.buyerBillsDuringConstruction
            : 1000;
        } else {
          updatedValues.sellerBillsDuringConstruction = 0;
          updatedValues.buyerBillsDuringConstruction = 0;
        }
      }
      const totals = calculateTotals(updatedValues);

      return { ...updatedValues, ...totals };
    });
  };

  const handlePropertyChange = async (selectedOption) => {
    try {
      const propertyAnalyticsData = await apiGetPropertyAnalytics(selectedOption.value);

      const propertyRentalData = await apiGetRentalIndexByParams({
        districtCode: propertyAnalyticsData?.property_index_data?.district_code,
        propertyType: propertyAnalyticsData?.property_index_data?.property_type,
        numBedrooms: propertyAnalyticsData?.property_index_data?.number_of_bedrooms,
      });

      const appraisalData = await getPropertyAppraisal(selectedOption.value);
      setAppraisalData(appraisalData);

      const targetDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const rentalValue = propertyRentalData?.forecast ? propertyRentalData?.forecast[targetDate] : undefined;
      setSelectedProperty({ ...selectedOption });

      setValues((prevValues) => {
        const updatedValues = {
          ...prevValues,
          numberOfRentalUnits: roomsMapping[propertyAnalyticsData?.property_index_data?.number_of_bedrooms],
          rentPerUnitPerWeek: rentalValue / 4.2,
          gtmPrice: propertyAnalyticsData?.price,
          sellerPricePerUnit: propertyAnalyticsData?.price,
          buyerPricePerUnit: propertyAnalyticsData?.price,
          buyerLegalCosts: propertyAnalyticsData?.price * 0.0121,
          buyerSurveys: propertyAnalyticsData?.price * 0.0085,
          buyerProfessionalFees: propertyAnalyticsData?.price * 0.0126,
          buyerDevelopmentCosts: 7000 * roomsMapping[propertyAnalyticsData?.property_index_data?.number_of_bedrooms],
          buyerFurniturePack: propertyAnalyticsData?.price * 0.0021,
          buyerLegalFees: propertyAnalyticsData?.price * 0.0126,

          ...appraisalData?.metadata,
        };

        const totals = calculateTotals(updatedValues);

        return { ...updatedValues, ...totals };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotals = (updatedValues) => {
    const buyerTotalAcquisitionVAT =
      (updatedValues.vatRate / 100) *
      (updatedValues.buyerProfessionalFees +
        updatedValues.buyerPricePerUnit * 0.01 +
        updatedValues.buyerSurveys +
        updatedValues.buyerLegalCosts +
        updatedValues.buyerPricePerUnit * 0.02);

    const buyerTotalAcquisitionCosts =
      buyerTotalAcquisitionVAT +
      (updatedValues.buyerProfessionalFees +
        updatedValues.buyerPricePerUnit * 1.06 +
        updatedValues.buyerSurveys +
        updatedValues.buyerLegalCosts);

    const buyerTotalRefurbishmentCosts =
      1.05 *
      updatedValues.numberOfRentalUnits *
      (updatedValues.buyerDevelopmentCosts +
        updatedValues.buyerFurniturePack +
        updatedValues.buyerCommunalWorks +
        updatedValues.buyerBillsDuringConstruction);

    const buyerTotalSalesCosts =
      updatedValues.buyerSdlt +
      updatedValues.numberOfRentalUnits * updatedValues.rentPerUnitPerWeek * 52 * 2 +
      updatedValues.buyerLegalFees;

    const sellerTotalAcquisitionVAT =
      (updatedValues.vatRate / 100) *
      (updatedValues.sellerProfessionalFees +
        updatedValues.sellerPricePerUnit * 0.01 +
        updatedValues.sellerSurveys +
        updatedValues.sellerLegalCosts +
        updatedValues.sellerPricePerUnit * 0.02);

    const sellerTotalAcquisitionCosts =
      sellerTotalAcquisitionVAT +
      (updatedValues.sellerProfessionalFees +
        updatedValues.sellerPricePerUnit * 1.06 +
        updatedValues.sellerSurveys +
        updatedValues.sellerLegalCosts);

    const sellerTotalRefurbishmentCosts =
      1.05 *
      updatedValues.numberOfRentalUnits *
      (updatedValues.sellerDevelopmentCosts +
        updatedValues.sellerFurniturePack +
        updatedValues.sellerCommunalWorks +
        updatedValues.sellerBillsDuringConstruction);

    const sellerTotalSalesCosts =
      updatedValues.sellerSdlt +
      updatedValues.numberOfRentalUnits * updatedValues.rentPerUnitPerWeek * 52 * 2 +
      updatedValues.sellerLegalFees;

    return {
      buyerTotalAcquisitionVAT,
      buyerTotalAcquisitionCosts,
      buyerTotalRefurbishmentCosts,
      buyerTotalSalesCosts,
      sellerTotalAcquisitionVAT,
      sellerTotalAcquisitionCosts,
      sellerTotalRefurbishmentCosts,
      sellerTotalSalesCosts,
    };
  };

  const formattedOptions = useMemo(() => {
    if (rawProperties && rawPropertiesLoaded) {
      return rawProperties.map((property) => {
        return { value: property.id, label: property.address };
      });
    } else {
      return [];
    }
  }, [rawProperties, rawPropertiesLoaded]);

  let cancelToken;
  const saveChanges = async () => {
    setLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      if (appraisalData) {
        await updateAppraisal(selectedProperty.value, { metadata: { ...values } }, { cancelToken: cancelToken.token });
      } else {
        const result = await createAppraisal(
          selectedProperty.value,
          { metadata: { ...values } },
          { cancelToken: cancelToken.token },
        );
        if (result?.data[0]) {
          setAppraisalData(result.data[0].metadata);
        }
      }

      setStatusModalContent({
        show: true,
        type: 'success',
        description: `Property appraisal ${appraisalData ? 'updated' : 'created'} successfully`,
      });

      setLoading(false);
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <PageHead activePage="Property Appraisal" />

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card p-5">
            <div className="row">
              <div className="pb-2">
                <label className="fs-16 text-black"> Select Property </label>
                <Select
                  className="h-9 text-sm"
                  styles={customSelectStyles}
                  isSearchable={true}
                  onChange={handlePropertyChange}
                  value={selectedProperty}
                  options={formattedOptions}
                />
              </div>
              <div className="w-12 mt-7">
                {rawPropertiesLoading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 className="my-2 fs-16 text-black"> Income/Sales </h4>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Number of rentable units: </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="numberOfRentalUnits"
                      value={values.numberOfRentalUnits}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Core Rent Uplift:
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.rentPerUnitPerWeek).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Core Rent per unit per week: </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="rentPerUnitPerWeek"
                      value={numeral(values.rentPerUnitPerWeek).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Annual Income:
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Acquisition Yield: </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="acquisitionYield"
                      value={values.acquisitionYield}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Sale Price of Asset with the benefit of Lease: </div>
                <div className="col-4 flex">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                      Total:
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                      {numeral(
                        (values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52 * 100) /
                          (values.acquisitionYield ? values.acquisitionYield : 1),
                      ).format()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 className="my-2 fs-16 text-black"> Acquisition Costs </h4>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> </div>
                <div className="col-4">
                  <h4 className="my-2 fs-14 text-black"> For Seller </h4>
                </div>
                <div className="col-4">
                  <h4 className="my-2 fs-14 text-black"> For Buyer </h4>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Property Purchase </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerPricePerUnit"
                      value={numeral(values.sellerPricePerUnit).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Stamp Duty on purchase
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.sellerPricePerUnit * 0.03).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerPricePerUnit"
                      value={numeral(values.buyerPricePerUnit).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Stamp Duty on purchase
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.buyerPricePerUnit * 0.03).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Legal Costs (Solicitor) </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerLegalCosts"
                      value={numeral(values.sellerLegalCosts).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Agent/Referral/ Introduction Fees
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.sellerPricePerUnit * 0.02).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerLegalCosts"
                      value={numeral(values.buyerLegalCosts).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Agent/Referral/ Introduction Fees
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.buyerPricePerUnit * 0.02).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Professional Fees (Surveys) </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerSurveys"
                      value={numeral(values.sellerSurveys).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Fee
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.sellerPricePerUnit * 0.01).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerSurveys"
                      value={numeral(values.buyerSurveys).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Fee
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.buyerPricePerUnit * 0.01).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3">Professional Fees</div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerProfessionalFees"
                      value={numeral(values.sellerProfessionalFees).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.sellerProfessionalFees).format()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerProfessionalFees"
                      value={numeral(values.buyerProfessionalFees).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.buyerProfessionalFees).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3">VAT on Acquisition Costs</div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="vatRate"
                      value={values.vatRate}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.sellerTotalAcquisitionVAT).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.buyerTotalAcquisitionVAT).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2 mb-4">
                <div className="col-3"> Total </div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        £
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        {numeral(values.sellerTotalAcquisitionCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        £
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        {numeral(values.buyerTotalAcquisitionCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 className="my-2 fs-16 text-black"> Refurbishment/Build Costs </h4>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> </div>
                <div className="col-4">
                  <h4 className="my-2 fs-14 text-black"> For Seller </h4>
                </div>
                <div className="col-4">
                  <h4 className="my-2 fs-14 text-black"> For Buyer </h4>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Development Costs (Total Build Costs) </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerDevelopmentCosts"
                      value={numeral(values.sellerDevelopmentCosts).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.sellerDevelopmentCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerDevelopmentCosts"
                      value={numeral(values.buyerDevelopmentCosts).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.buyerDevelopmentCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3">
                  <div className="row items-center">
                    <div className="col-10">Communal, External and structural works</div>
                    <div className="col-2">
                      <input
                        type="checkbox"
                        id="communalWorks"
                        name="communalWorksEnabled"
                        value={values.communalWorksEnabled}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerCommunalWorks"
                      value={numeral(values.sellerCommunalWorks).format()}
                      disabled={!values.communalWorksEnabled}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.sellerCommunalWorks).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerCommunalWorks"
                      value={numeral(values.buyerCommunalWorks).format()}
                      disabled={!values.communalWorksEnabled}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.buyerCommunalWorks).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3">
                  <div className="row items-center">
                    <div className="col-10">Bills during construction (Gas, Water, Electric, Council Tax, etc.)</div>
                    <div className="col-2">
                      <input
                        type="checkbox"
                        id="constructionBillsEnabled"
                        name="constructionBillsEnabled"
                        value={values.constructionBillsEnabled}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerBillsDuringConstruction"
                      value={numeral(values.sellerBillsDuringConstruction).format()}
                      disabled={!values.constructionBillsEnabled}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.sellerBillsDuringConstruction).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerBillsDuringConstruction"
                      value={numeral(values.buyerBillsDuringConstruction).format()}
                      disabled={!values.constructionBillsEnabled}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.buyerBillsDuringConstruction).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> Furniture pack </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="sellerFurniturePack"
                      value={numeral(values.sellerFurniturePack).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.sellerFurniturePack).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <input
                      className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                      name="buyerFurniturePack"
                      value={numeral(values.buyerFurniturePack).format()}
                      onChange={handleChange}
                    />
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(values.numberOfRentalUnits * values.buyerFurniturePack).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-3"> VAT on building Works (5%)</div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(
                          0.05 *
                            values.numberOfRentalUnits *
                            (values.sellerDevelopmentCosts +
                              values.sellerFurniturePack +
                              values.sellerCommunalWorks +
                              values.sellerBillsDuringConstruction),
                        ).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-xs" style={{ color: '#747B8A' }}>
                        Total
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                        {numeral(
                          0.05 *
                            values.numberOfRentalUnits *
                            (values.buyerDevelopmentCosts +
                              values.buyerFurniturePack +
                              values.buyerCommunalWorks +
                              values.buyerBillsDuringConstruction),
                        ).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2 mb-4">
                <div className="col-3"> Total </div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        £
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        {numeral(values.sellerTotalRefurbishmentCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <div className="w-28 mr-4"></div>
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        £
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        {numeral(values.buyerTotalRefurbishmentCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 className="my-2 fs-16 text-black"> Sales Costs & RP Fees </h4>
              </div>

              <div className="row items-center py-2">
                <div className="col-4"> </div>
                <div className="col-4">
                  <h4 className="my-2 fs-14 text-black"> For Seller </h4>
                </div>
                <div className="col-4">
                  <h4 className="my-2 fs-14 text-black"> For Buyer </h4>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-4"> Legal Fees for provider </div>
                <div className="col-4">
                  <input
                    className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                    name="sellerLegalFees"
                    value={numeral(values.sellerLegalFees).format()}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-4">
                  <input
                    className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                    name="buyerLegalFees"
                    value={numeral(values.buyerLegalFees).format()}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-4"> Provider initial cost contribution </div>
                <div className="col-4">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      £
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      {numeral((values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) / 2).format()}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      £
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      {numeral((values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) / 2).format()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-4"> 6 months rent built in day 1 during work refurb </div>
                <div className="col-4">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      £
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      {numeral((values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) / 2).format()}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      £
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      {numeral((values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52) / 2).format()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-4"> Bond 12 month Reserve </div>
                <div className="col-4">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      £
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      {numeral(values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52).format()}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="w-28 mr-4"></div>
                  <div
                    className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                    style={{ backgroundColor: '#F3F6FB' }}
                  >
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      £
                    </span>
                    <span className="pl-2 text-lg" style={{ color: '#7B8FB7' }}>
                      {numeral(values.numberOfRentalUnits * values.rentPerUnitPerWeek * 52).format()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row items-center py-2">
                <div className="col-4"> Stamp Duty Land Tax </div>
                <div className="col-4">
                  <input
                    className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                    name="sellerSdlt"
                    value={values.sellerSdlt}
                    onChange={handleChange}
                    type="number"
                    min={0}
                    max={10000000}
                  />
                </div>
                <div className="col-4">
                  <input
                    className="px-2.5 h-10 py-2 bg-white rounded-md mr-4 border text-sm w-28"
                    name="buyerSdlt"
                    value={values.buyerSdlt}
                    onChange={handleChange}
                    type="number"
                    min={0}
                    max={10000000}
                  />
                </div>
              </div>

              <div className="row items-center py-2 mb-4">
                <div className="col-4"> Total </div>
                <div className="col-4">
                  <div className="flex">
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        £
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        {numeral(values.sellerTotalSalesCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="flex">
                    <div
                      className="flex h-10 justify-between items-center rounded-md px-2 py-1 w-60"
                      style={{ backgroundColor: '#F3F6FB' }}
                    >
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        £
                      </span>
                      <span className="pl-2 text-lg" style={{ color: '#3571E5' }}>
                        {numeral(values.buyerTotalSalesCosts).format()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Summary values={values} />
            <LeaseAssumptions values={values} />
            <PropertyOutput values={values} />
            <LeaseOutput values={values} />

            <div className="flex justify-end">
              {loading ? <LoaderSvg className="animate-spin h-8 w-8 text-white" /> : ''}
              <SecondaryButton onClick={() => saveChanges()}> Save Changes </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appraisal;
