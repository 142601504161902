import ItemVsIndexChart from '../../Dashboard/Charts/ItemVsIndexChart';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import emptyImg from 'icons/custom/emptyChart.png';
import { useEffect, useMemo, useState } from 'react';
import { formatRentalYieldData, seriesObjectToArray } from '../../../../utils/helpers';
import { apiGetRentalIndexByParams } from '../../../../api/rentalIndex';
import { useQuery } from '@tanstack/react-query';

function RentCharts({ address, selectedIndex, rentsByDistrict, targetTime, propertyAnalytics, portfolioForecast }) {
  const [loading, setLoading] = useState(true);

  const { data: rentalIndex } = useQuery({
    queryKey: [
      'rentalIndex',
      selectedIndex?.district_code,
      selectedIndex?.property_type,
      selectedIndex?.number_of_bedrooms,
    ],
    queryFn: ({ signal }) =>
      apiGetRentalIndexByParams({
        districtCode: selectedIndex?.district_code,
        propertyType: selectedIndex?.property_type,
        numBedrooms: selectedIndex?.number_of_bedrooms,
        signal,
      }),
    enabled: !!selectedIndex?.district_code,
  });

  const rentalBenchmark = useMemo(() => {
    if (selectedIndex?.area_code && selectedIndex?.property_type && selectedIndex?.number_of_bedrooms) {
      return (
        'Area:' +
        selectedIndex.area_code +
        ', Property Type: ' +
        selectedIndex.property_type +
        ', Number Of Bedrooms: ' +
        selectedIndex.number_of_bedrooms
      );
    } else {
      return '';
    }
  }, [selectedIndex]);

  const chartData = useMemo(() => {
    const aggregatePropertiesForecast = {};
    const aggregateOverriddenRents = {};

    const containsRentsData = propertyAnalytics?.some(
      (element) => Object.keys(element.override_rents || {}).length > 0,
    );

    for (const rentData of rentsByDistrict) {
      const resultData = rentData?.forecast || {};
      for (const [key, value] of Object.entries(resultData)) {
        const overrideRent = rentData.override_rents && rentData.override_rents[key];
        const newValue = overrideRent ? overrideRent : value;

        if (aggregatePropertiesForecast.hasOwnProperty(key)) {
          aggregatePropertiesForecast[key] += value;
        } else {
          aggregatePropertiesForecast[key] = value;
        }

        if (containsRentsData) {
          if (aggregateOverriddenRents.hasOwnProperty(key)) {
            aggregateOverriddenRents[key] += newValue;
          } else {
            aggregateOverriddenRents[key] = newValue;
          }
        }
      }
    }

    const stateUpdates = {
      rentalIndexGraphSeriesData: seriesObjectToArray(aggregatePropertiesForecast, targetTime),
      rentalYieldGraphSeriesData: formatRentalYieldData(aggregatePropertiesForecast, portfolioForecast, targetTime),
      overriddenRentalGraphSeriesData: seriesObjectToArray(aggregateOverriddenRents, targetTime),
      overriddenRentalYieldGraphSeriesData: formatRentalYieldData(
        aggregateOverriddenRents,
        portfolioForecast,
        targetTime,
      ),
    };

    return stateUpdates;
  }, [portfolioForecast, propertyAnalytics, rentsByDistrict, targetTime]);

  useEffect(() => {
    if (chartData?.rentalIndexGraphSeriesData?.length) {
      setLoading(false);
    }
  }, [chartData]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate"> Rental Index: {address} </h4>
              {rentalBenchmark && (
                <>
                  <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                    vs
                  </span>
                  <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate">{rentalBenchmark}</h4>
                </>
              )}
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {!loading && chartData?.rentalIndexGraphSeriesData.length ? (
                  <ItemVsIndexChart
                    itemGraphSeriesData={chartData?.rentalIndexGraphSeriesData}
                    address={address}
                    index={rentalBenchmark}
                    benchmarkGraphSeriesData={
                      rentalIndex?.forecast ? seriesObjectToArray(rentalIndex.forecast, targetTime) : []
                    }
                    secondaryAxisButtonEnabled={!!rentalBenchmark}
                    index2={chartData?.overriddenRentalGraphSeriesData.length ? 'Overridden Rents' : ''}
                    benchmark2GraphSeriesData={chartData?.overriddenRentalGraphSeriesData}
                  />
                ) : loading ? (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                ) : (
                  <div className="items-center flex flex-column justify-center my-5">
                    <img className="h-44" src={emptyImg} alt="error" />
                    <div className="text-lg text-black font-semibold text-center my-2"> No Data </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-16 mb-0 mx-2 my-0 py-0"> Rental Yield: {address} </h4>
              {rentalBenchmark && (
                <>
                  <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                    vs
                  </span>
                  <h4 className="fs-16 mb-0 mx-2 my-0 py-0 w-1/2 truncate">{rentalBenchmark}</h4>
                </>
              )}
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {!loading && chartData?.rentalYieldGraphSeriesData.length ? (
                  <ItemVsIndexChart
                    itemGraphSeriesData={chartData?.rentalYieldGraphSeriesData}
                    address={address}
                    index={rentalBenchmark}
                    benchmarkGraphSeriesData={
                      rentalIndex?.forecast
                        ? formatRentalYieldData(rentalIndex.forecast, portfolioForecast, targetTime)
                        : []
                    }
                    dataType={'rate'}
                    secondaryAxisButtonEnabled={true}
                    index2={chartData?.overriddenRentalYieldGraphSeriesData.length ? 'Overridden Rents' : ''}
                    benchmark2GraphSeriesData={chartData?.overriddenRentalYieldGraphSeriesData}
                  />
                ) : loading ? (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                ) : (
                  <div className="items-center flex flex-column justify-center my-5">
                    <img className="h-44" src={emptyImg} alt="error" />
                    <div className="text-lg text-black font-semibold text-center my-2"> No Data </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RentCharts;
