import { envelope, multiPoint, pointsWithinPolygon, polygon } from '@turf/turf';
import type { GeometryRecord, GeometryResponse } from './types';

const filterCoordinates = (coordinates: GeoJSON.Position[][]) => {
  const largestPolygon = coordinates.reduce((largest, group) => {
    return group.length > largest.length ? group : largest;
  }, []);

  return coordinates.filter((points) => {
    return (
      JSON.stringify(points) === JSON.stringify(largestPolygon) ||
      !(pointsWithinPolygon(multiPoint(points), polygon([largestPolygon])).features.length > 0 && points.length <= 9)
    );
  });
};

export const geometryResponseAdaptor = (response: GeometryResponse[]): GeometryRecord[] =>
  response.map((response) => {
    const districtCode = response.name;
    const name = response.area_name.replace(districtCode, '').trim();

    if (response.geometry.geometry.type === 'MultiPolygon') {
      const coordinates = response.geometry.geometry.coordinates.flatMap(filterCoordinates);

      response.geometry.geometry = {
        ...response.geometry.geometry,
        type: 'Polygon',
        coordinates,
      };
    } else {
      response.geometry.geometry = {
        ...response.geometry.geometry,
        coordinates: filterCoordinates(response.geometry.geometry.coordinates),
      };
    }

    const [lng, lat] = response.center.geometry.coordinates;

    const feature: GeoJSON.Feature<
      GeoJSON.Polygon | GeoJSON.MultiPolygon,
      { name: string; districtCode: string; center: { lat: number; lng: number } }
    > = {
      type: 'Feature',
      geometry: response.geometry.geometry,
      properties: {
        name,
        districtCode,
        center: { lat, lng },
      },
    };

    return {
      ...feature,
      bbox: envelope(feature).bbox,
    };
  });
