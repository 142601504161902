import React, { type FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { APIProvider, Map, type MapCameraChangedEvent, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useSelector } from '@xstate/react';
import priceChangeMapMachine, { PERIOD_OPTIONS } from './PriceChangeMapMachine';
import { createActor } from 'xstate';
import { getNumberOfBedroomsOptions, getPropertyTypeOptions } from 'api/properties';
import { cn } from '@/lib/utils';
import { GeometryRecord } from 'api/geometry/types';
import { Polygon } from '../../../atoms/polygon/Polygon';
import { debounce } from 'lodash';
import { useMapZoomInteraction, useMousePosition, useOriginalValue } from 'utils/hooks';
import { getForecastPercentageChange } from 'utils/data';
import SecondaryButton from '../../../Dashboard/Buttons/SecondaryButton';
import {ReactComponent as House } from 'icons/custom/house.svg';
import { round } from 'mathjs';
import ChangeIcon from '../../../atoms/change-icon/ChangeIcon';
import { XIcon } from 'lucide-react';
import { format, subMonths, subYears } from 'date-fns';
import { formatMoney } from 'accounting';
import { PropertyTypeMap } from 'api/properties';
import { PropertyTypeKey, PropertyTypeOption } from 'api/properties/types';
import Select from 'react-select';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import HomeMapMarker from 'jsx/components/atoms/home-map-marker/HomeMapMarker';

const DEFAULT_CENTER = {
  lat: 51.5067621,
  lng: -0.1259233,
};

const DEFAULT_ZOOM = 13;

const MIN_MAX_CHANGE = 10;

const COLOR_STOPS = [
  { pos: -1.0, color: { r: 220, g: 40, b: 40 } },
  { pos: -0.6, color: { r: 255, g: 100, b: 40 } },
  { pos: -0.2, color: { r: 255, g: 160, b: 40 } },
  { pos: 0.0, color: { r: 255, g: 220, b: 40 } },
  { pos: 0.2, color: { r: 200, g: 230, b: 40 } },
  { pos: 0.6, color: { r: 130, g: 220, b: 50 } },
  { pos: 1.0, color: { r: 40, g: 180, b: 40 } },
];

const getGradientColor = (value: number) => {
  let lower = COLOR_STOPS[0];
  let upper = COLOR_STOPS[COLOR_STOPS.length - 1];

  if (value < COLOR_STOPS[0].pos) {
    lower = COLOR_STOPS[0];
    upper = COLOR_STOPS[1];
  } else if (value > COLOR_STOPS[COLOR_STOPS.length - 1].pos) {
    lower = COLOR_STOPS[COLOR_STOPS.length - 2];
    upper = COLOR_STOPS[COLOR_STOPS.length - 1];
  } else {
    for (let i = 0; i < COLOR_STOPS.length - 1; i++) {
      if (value >= COLOR_STOPS[i].pos && value <= COLOR_STOPS[i + 1].pos) {
        lower = COLOR_STOPS[i];
        upper = COLOR_STOPS[i + 1];
        break;
      }
    }
  }

  const range = upper.pos - lower.pos;
  const factor = (value - lower.pos) / range;

  const r = Math.round(lower.color.r + (upper.color.r - lower.color.r) * factor);
  const g = Math.round(lower.color.g + (upper.color.g - lower.color.g) * factor);
  const b = Math.round(lower.color.b + (upper.color.b - lower.color.b) * factor);

  return `rgb(${Math.max(0, Math.min(255, r))}, ${Math.max(0, Math.min(255, g))}, ${Math.max(0, Math.min(255, b))})`;
};

const getColor = (value: number) => {
  return getGradientColor(Math.max(Math.min(value / MIN_MAX_CHANGE, 1), -1));
};

type DistrictPolygon = {
  name: string;
  description: string;
  paths: google.maps.LatLngLiteral[][];
  bounds: google.maps.LatLngBounds;
};

interface PriceChangeMapProps {
  geometry?: GeometryRecord[];
  data: {
    district: string;
    change: number | null;
    price: number;
    targetTime: string;
  }[];
  periodLabel: string;
  center?: google.maps.LatLngLiteral;
  districtSelected?: string;
  onBoundsChange: (bounds: google.maps.LatLngBounds) => void;
  onDistrictSelected: (district?: string) => void;
}

const PriceChangeMap: FC<PriceChangeMapProps> = ({
  geometry = [],
  data,
  periodLabel,
  center,
  districtSelected,
  onBoundsChange,
  onDistrictSelected,
}) => {
  const maps = useMapsLibrary('core');

  const polygons = useMemo<DistrictPolygon[]>(() => {
    if (!maps) return [];

    return geometry.map((feature) => {
      return {
        name: feature.properties.districtCode,
        description: feature.properties.name,
        paths: feature.geometry.coordinates.map((coordinates) => {
          return coordinates.map(([lng, lat]) => ({
            lat: lat as number,
            lng: lng as number,
          }));
        }),
        bounds: new maps.LatLngBounds(
          {
            lat: feature.bbox![1],
            lng: feature.bbox![0],
          },
          { lat: feature.bbox![3], lng: feature.bbox![2] },
        ),
      };
    });
  }, [maps, geometry]);

  const debouncedOnBoundsChange = useMemo(
    () =>
      debounce((bounds: google.maps.LatLngBounds) => {
        onBoundsChange(bounds);
      }, 1000),
    [onBoundsChange],
  );

  const map = useMap();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | undefined>(map?.getBounds());

  useEffect(() => {
    if (bounds) {
      debouncedOnBoundsChange(bounds);
    }
  }, [bounds]); // eslint-disable-line react-hooks/exhaustive-deps

  const [hover, setHover] = useState<string | undefined>(districtSelected);
  const mousePosition = useMousePosition();
  const change = data?.find(({ district }) => district === hover)?.change;

  useLayoutEffect(() => {
    if (districtSelected) {
      if (map) {
        const bounds = polygons.find(({ name }) => name === districtSelected)?.bounds;

        if (maps && bounds) {
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();
          const diffLng = ne.lng() - sw.lng();
          const extendTo = new maps.LatLng({
            lat: sw.lat(),
            lng: sw.lng() - diffLng / 2,
          });

          const newBounds = new maps.LatLngBounds(extendTo, ne);
          map.fitBounds(newBounds);
        }
      }
    }
  }, [map, maps, polygons, districtSelected]);

  const originalDistrictSelected = useOriginalValue(districtSelected);

  const resetMap = useCallback(() => {
    if (map) {
      map.setCenter(center ?? DEFAULT_CENTER);
      map.setZoom(DEFAULT_ZOOM);
      onDistrictSelected(originalDistrictSelected);
    }
  }, [map, center, onDistrictSelected, originalDistrictSelected]);

  const { zoomStarted } = useMapZoomInteraction(map);

  useEffect(() => {
    if (zoomStarted) {
      onDistrictSelected(undefined);
    }
  }, [zoomStarted, onDistrictSelected]);

  const [mapLoaded, setMapLoaded] = useState(false);

  return (
    <div className="h-full w-full" onMouseOut={() => setHover(undefined)} onBlur={() => setHover(undefined)}>
      <Map
        mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
        defaultCenter={center ?? DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        className="h-full w-full"
        onBoundsChanged={(event: MapCameraChangedEvent) => {
          const bounds = event.map.getBounds();
          if (bounds) {
            setBounds(bounds);
          }
        }}
        onDragstart={() => {
          onDistrictSelected(undefined);
        }}
        onTilesLoaded={() => {
          if (!mapLoaded) {
            setMapLoaded(true);
            resetMap();
          }
        }}
        fullscreenControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        gestureHandling="greedy"
      >
        {polygons
          .filter(({ name }) => {
            const change = data?.find(({ district }) => district === name)?.change;
            return change !== null && change !== undefined;
          })
          .map(({ name, paths }) => {
            const change = data?.find(({ district }) => district === name)?.change!;
            if (change === undefined) return null;
            const fillColor = getColor(change);
            const isHover = hover === name;
            const isSelected = districtSelected === name;
            const strokeWeight = districtSelected === undefined && (isHover || isSelected) ? 4 : 2;
            const strokeOpacity = districtSelected === undefined || isSelected ? 1 : 0.25;
            const fillOpacity = districtSelected === undefined || isSelected ? 0.75 : 0.25;
            return (
              <Polygon
                key={`${name}-${change}-${fillColor}-${fillOpacity}-${strokeWeight}-${strokeOpacity}`}
                zIndex={isHover || isSelected ? polygons.length : undefined}
                paths={paths}
                fillColor={fillColor}
                fillOpacity={fillOpacity}
                strokeWeight={strokeWeight}
                strokeOpacity={strokeOpacity}
                strokeColor={'#ffffff'}
                onMouseOver={() => {
                  if (hover !== name) {
                    setHover(name);
                  }
                }}
                onMouseOut={() => {
                  if (hover !== undefined) {
                    setHover(undefined);
                  }
                }}
                onClick={() => {
                  onDistrictSelected(districtSelected ? undefined : name);
                }}
              />
            );
          })}
        {center && <HomeMapMarker latitude={center.lat} longitude={center.lng} />}
      </Map>
      {districtSelected === undefined &&
        change !== null &&
        hover &&
        mousePosition.x !== null &&
        mousePosition.y !== null && (
          <div
            className="bg-white fixed pointer-events-none rounded-md shadow-lg z-10"
            style={{ top: mousePosition.y + 10, left: mousePosition.x + 10 }}
          >
            <div className="px-4 py-2">
              <div className="flex gap-x-3 items-center">
                <div className="font-semibold text-md">{hover}</div>
                <div className="flex flex-none items-center">
                  <ChangeIcon value={change} overrideColor={change !== undefined ? getColor(change) : undefined} />
                </div>
                <div className="flex flex-col flex-grow justify-between">
                  <div
                    className="font-semibold"
                    style={{
                      color: change !== undefined ? getColor(change) : undefined,
                    }}
                  >
                    {change !== undefined ? `${round(change, 2)}%` : '-'}
                  </div>
                  <div className="text-slate-500 text-xs">{periodLabel}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      <SecondaryButton className="absolute bg-white bottom-32 2xl:bottom-28 px-2.5 right-2.5 w-auto" onClick={resetMap} styles={{}}>
        <House className="2xl:w-5 2xl:h-5" />
      </SecondaryButton>
    </div>
  );
};

interface PriceChangeMapViewProps {
  propertyType: PropertyTypeKey;
  numberOfBedrooms: number;
  districtSelected: string;
  center: google.maps.LatLngLiteral;
}

const PriceChangeMapView: FC<PriceChangeMapViewProps> = ({
  propertyType,
  numberOfBedrooms,
  districtSelected,
  center,
}) => {
  const priceChangeMapActor = useMemo(() => {
    const actor = createActor(priceChangeMapMachine, {
      input: { propertyType, numberOfBedrooms, center, districtSelected },
    });
    actor.start();
    return actor;
  }, [propertyType, numberOfBedrooms, districtSelected, center]);

  const context = useSelector(priceChangeMapActor, (state) => state.context);

  const { geometry, mode, period } = context;

  const { inFetching, inCardOpening, inCardOpen } = useSelector(priceChangeMapActor, (state) => ({
    inFetching:
      state.matches({ geometry: { hasBounds: 'fetching' } }) ||
      state.matches({ superIndex: { hasOptions: 'fetching' } }),
    inCardOpening: state.matches({ card: 'opening' }),
    inCardOpen: state.matches({ card: 'open' }),
  }));

  const data = useMemo(() => {
    return (
      context.data?.map((superIndex) => {
        const isSales = mode === 'sales';
        
        let months: number;
        switch (period.value) {
          case 'chg_1m':
            months = 1;
            break;
          case 'chg_3m':
            months = 3;
            break;
          case 'chg_1y':
            months = 12;
            break;
          case 'est_chg_1y':
            months = -12;
            break;
          case 'est_chg_2y':
            months = -24;
            break;
        }

        return {
          district: superIndex.district,
          change: getForecastPercentageChange(
            isSales
              ? superIndex.forecast
              : superIndex.rental_index[0]?.forecast,
            superIndex.target_time,
            months,
          ),
          price: isSales ? superIndex.price : superIndex.rental_price,
          targetTime: superIndex.target_time,
        };
      }) ?? []
    );
  }, [context.data, mode, period]);

  const propertyTypeOptions = useMemo(() => getPropertyTypeOptions(), []);

  const numberOfBedroomsOptions = useMemo(() => getNumberOfBedroomsOptions().map((option) => ({
    ...option,
    label: `${option.label} bedroom${option.label > 1 ? 's' : ''}`,
  })), []);

  const { change, price, targetTime } = useMemo(() => {
    return (
      data?.find(({ district }) => district === context.districtSelected) ?? {
        change: undefined,
        price: undefined,
        targetTime: undefined,
      }
    );
  }, [data, context.districtSelected]);

  const geometrySelected = useMemo(
    () => geometry?.find(({ properties }) => properties.districtCode === context.districtSelected),
    [geometry, context.districtSelected],
  );

  return (
    <>
      <h4 className="fs-20 mb-2.5 ml-2 text-black">Price change map</h4>
      <div className="row">
        <div className="col-xl-12">
          <div className="card overflow-hidden">
            <div className="border-0 p-0" style={{ height: '500px' }}>
              <div className="h-full relative w-full">
                <div className={cn('h-full w-full', inFetching && 'opacity-80')}>
                  <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
                    <PriceChangeMap
                      geometry={geometry}
                      data={data}
                      periodLabel={period.label}
                      center={context.center}
                      districtSelected={context.districtSelected}
                      onBoundsChange={(bounds) => {
                        priceChangeMapActor.send({ type: 'BOUNDS.CHANGED', bounds });
                      }}
                      onDistrictSelected={(district) => {
                        priceChangeMapActor.send({ type: 'DISTRICT.SELECTED', district });
                      }}
                    />
                  </APIProvider>
                </div>
                <div className="absolute flex gap-4 items-start justify-between left-0 p-6 pointer-events-none top-0 w-full">
                  <div className="flex flex-wrap gap-2 items-center pointer-events-auto">
                    <ul className="bg-white nav nav-pills p-1 rounded-md shadow-lg">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${mode === 'sales' ? 'active' : ''} px-2 py-1`}
                          onClick={() => priceChangeMapActor.send({ type: 'MODE.UPDATED', mode: 'sales' })}
                          style={{ borderRadius: '0.25rem' }}
                        >
                          Sales
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${mode === 'rents' ? 'active' : ''} px-2 py-1`}
                          onClick={() => priceChangeMapActor.send({ type: 'MODE.UPDATED', mode: 'rents' })}
                          style={{ borderRadius: '0.25rem' }}
                        >
                          Rental
                        </button>
                      </li>
                    </ul>
                    <Select<PropertyTypeOption>
                      options={propertyTypeOptions || []}
                      value={propertyTypeOptions?.find((option) => option.value === context.propertyType)}
                      onChange={(option) => {
                        if (option) {
                          priceChangeMapActor.send({ type: 'PROPERTY_TYPE.UPDATED', propertyType: option.value });
                        }
                      }}
                      isSearchable={true}
                      className="shadow-lg w-36"
                    />
                    <Select<{ label: string; value: number }>
                      options={numberOfBedroomsOptions || []}
                      value={numberOfBedroomsOptions?.find((option) => option.value === context.numberOfBedrooms)}
                      onChange={(option) => {
                        if (option) {
                          priceChangeMapActor.send({ type: 'NUMBER_OF_BEDROOMS.UPDATED', numberOfBedrooms: option.value });
                        }
                      }}
                      isSearchable={true}
                      className="shadow-lg w-40"
                    />
                  </div>
                  <div className="flex flex-wrap gap-2 items-center justify-end pointer-events-auto">
                    <ul className="bg-white nav nav-pills p-1 rounded-md shadow-lg">
                      {PERIOD_OPTIONS.map(({ value, label }) => (
                        <li key={value} className="nav-item">
                          <button
                            className={`nav-link ${value === period.value ? 'active' : ''} px-2 py-1`}
                            onClick={() => {
                              priceChangeMapActor.send({ type: 'PERIOD.UPDATED', period: { value, label } });
                            }}
                            style={{ borderRadius: '0.25rem' }}
                          >
                            {label}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {context.numberOfBedrooms && context.propertyType && price && targetTime && (
                  <div
                    className={cn(
                      'absolute duration-400 left-0 top-14 transition-opacity w-1/4',
                      inCardOpening || inCardOpen ? 'opacity-100' : 'opacity-0 pointer-events-none',
                    )}
                  >
                    <div className="bg-white m-6 mr-0 p-4 relative rounded-md shadow-lg">
                      <XIcon
                        className="absolute cursor-pointer h-4 right-4 top-4 w-4"
                        onClick={() => {
                          priceChangeMapActor.send({ type: 'DISTRICT.SELECTED' });
                        }}
                      />
                      <div className="pb-2">
                        <div className="font-semibold text-lg">{geometrySelected?.properties.name}</div>
                        <div className="mt-0">{geometrySelected?.properties.districtCode}</div>
                      </div>
                      <div className="text-slate-500 text-sm">
                        {change !== null ? (
                          <div>
                            The average <strong>{mode === 'sales' ? 'sale' : 'rental'} price</strong> of a{' '}
                            <strong>
                              {context.numberOfBedrooms}-bedroom {PropertyTypeMap[context.propertyType].toLowerCase()}
                              {context.propertyType === 'F' ? '' : ' house'}
                            </strong>{' '}
                            in {format(new Date(targetTime), 'MMMM yyyy')} was{' '}
                            <strong>{formatMoney(price, '£', 0)}</strong>
                            {period.value.includes('est') ? (
                              <>
                                {' '}
                                and is forecast to
                                {change > 0 ? ' increase by' : ' decrease by'}{' '}
                                <strong>{`${round(Math.abs(change), 2)}%`}</strong> in the next{' '}
                                {period.value === 'est_chg_1y' ? 'year' : '2 years'}
                              </>
                            ) : (
                              <>
                                {', '}
                                {change > 0
                                  ? 'increasing by'
                                  : change < 0
                                    ? 'decreasing by'
                                    : 'remaining the same as'}{' '}
                                {change !== 0 ? (
                                  <>
                                    <strong>{`${round(Math.abs(change), 2)}%`}</strong> since{' '}
                                  </>
                                ) : (
                                  ' '
                                )}
                                {period.value === 'chg_1m'
                                  ? format(subMonths(new Date(targetTime), 1), 'MMMM yyyy')
                                  : period.value === 'chg_3m'
                                    ? format(subMonths(new Date(targetTime), 3), 'MMMM yyyy')
                                    : format(subYears(new Date(targetTime), 1), 'MMMM yyyy')}
                              </>
                            )}
                            .
                          </div>
                        ) : (
                          <div>
                            Oops! We don't have data for{' '}
                            <strong>
                              {numberOfBedrooms}-bedroom {PropertyTypeMap[propertyType].toLowerCase()}
                              {propertyType === 'F' ? 's' : ' houses'}
                            </strong>{' '}
                            in this area.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="absolute bottom-0 left-0 pb-6 pl-6 pr-16 w-full" style={{ maxWidth: '24rem' }}>
                  <div className="bg-white rounded-lg shadow-lg w-full">
                    <div className="flex gap-x-2 items-center px-2 py-1.5 text-xs">
                      <div className="flex flex-col flex-none items-center justify-center">
                        <div className="font-semibold -mb-1">{`-${MIN_MAX_CHANGE}%`}</div>
                        <div>or less</div>
                      </div>
                      <div
                        className="flex-grow h-6 rounded-md w-full"
                        style={{
                          background: `linear-gradient(to right, ${[-1, -0.5, 0, 0.5, 1].map((value) => getGradientColor(value)).join(',')})`,
                        }}
                      />
                      <div className="flex flex-col flex-none items-center justify-center">
                        <div className="font-semibold -mb-1">{`+${MIN_MAX_CHANGE}%`}</div>
                        <div>or more</div>
                      </div>
                    </div>
                  </div>
                </div>
                {inFetching && (
                  <div className="absolute flex h-full items-center justify-center left-0 top-0 w-full z-20">
                    <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceChangeMapView;
