import React, { useMemo } from 'react';

import PageHead from '../additionalModules/PageHead';
import { apiSearchCityExactIndex } from '../../../api/cityIndex';
import { seriesObjectToArray } from '../../../utils/helpers';
import CityIndiciesChart from './Components/CityIndiciesChart';
import NationwideVsMacrosList from './Components/NationwideVsMacrosList';
import CitiesByTypePriceChartWrapper from './Components/CityByTypePriceChartWrapper';
import { useQuery } from '@tanstack/react-query';
import { getTargetTime } from '../../../api/propertyIndex';
import Dashboard from './Components/Dashboard';
import RegionalData from './Components/RegionalData';
import TransactionCount from './Components/TransactionCount';
import RegionalRentData from './Components/RegionalRentData';

const Insights = () => {
  const { data: nationwideIndex = [], isSuccess: ukDataLoaded } = useQuery(['UK'], ({ signal }) =>
    apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: `UK` }, signal),
  );

  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));

  const nationwideIndexSeries = useMemo(() => {
    if (ukDataLoaded) {
      return seriesObjectToArray(nationwideIndex?.forecast || {}, targetTime);
    } else {
      return [];
    }
  }, [ukDataLoaded, nationwideIndex, targetTime]);

  return (
    <>
      <PageHead activePage="UK Market Intelligence" />
      <Dashboard targetTime={targetTime} />
      <RegionalData targetTime={targetTime} />
      <TransactionCount />
      <CityIndiciesChart targetTime={targetTime} />
      <CitiesByTypePriceChartWrapper targetTime={targetTime} />
      <RegionalRentData targetTime={targetTime} />
      <NationwideVsMacrosList nationwideIndexSeries={nationwideIndexSeries} targetTime={targetTime} />
    </>
  );
};

export default Insights;
