import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { calculateCenterPoint } from '../../../../utils/helpers';

const defaultCenter = {
  lat: 52.306754,
  lng: -2.2541214,
};

const PropertiesMap = ({ properties }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState(defaultCenter);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    const centerByCoordinates = calculateCenterPoint(properties);
    setCenter(centerByCoordinates);
  }, [properties]);

  return (
    <>
      {properties?.length ? (
        <div className="card">
          <div className="card-body d-flex align-items-center">
            <GoogleMap
              zoom={7}
              center={center}
              mapContainerClassName="map-container w-100"
              mapContainerStyle={{
                height: '550px',
              }}
            >
              {properties?.length &&
                properties.map((property) =>
                  property?.latitude && property?.longitude ? (
                    <MarkerF
                      key={property.id}
                      position={{ lat: property?.latitude, lng: property?.longitude }}
                      onClick={() => handleActiveMarker(property.id)}
                    >
                      {activeMarker === property.id && (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          {
                            <Link
                              to={`/property-analytics/${property.id}`}
                              target="_blank"
                              className="inline-block ml-auto"
                            >
                              <h3>{property.address}</h3>
                            </Link>
                          }
                        </InfoWindowF>
                      )}
                    </MarkerF>
                  ) : (
                    ''
                  ),
                )}
            </GoogleMap>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PropertiesMap;
