import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import PortfolioModal from './Components/PortfolioModal';
import PrimaryButton from '../Dashboard/Buttons/PrimaryButton';
import PortfolioCard from './Components/PortfolioCard';
import { apiGetUserPortfolios } from '../../../api/portfolio';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { useLocation } from 'react-router-dom';

const Portfolios = () => {
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [portfolioToModify, setPortfolioToModify] = useState({ name: '', description: '', properties: [] });
  const queryClient = useQueryClient();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appraisal = queryParams.get('appraisal');

  const {
    data: portfolios = [],
    isLoading: portfoliosLoading,
    refetch: refetchPortfolios,
  } = useQuery(['portfolios', appraisal], ({ signal }) => apiGetUserPortfolios(signal, appraisal));

  const handleSuccess = () => {
    setPortfolioToModify({ name: '', description: '', properties: [] });
    handleRefetchData();
  };

  const handleCreteForm = () => {
    setPortfolioToModify({ name: '', description: '', properties: [] });
    setShowPortfolioModal(true);
  };

  const handleRefetchData = () => {
    queryClient.invalidateQueries(['dashboardData']);
    refetchPortfolios();
  };

  return (
    <>
      <PortfolioModal
        showModal={showPortfolioModal}
        setShowModal={setShowPortfolioModal}
        handleSuccess={handleSuccess}
        portfolio={portfolioToModify}
        appraisal={appraisal}
      />
      <div className="mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head">
        <PrimaryButton className={'px-3 w-auto'} onClick={() => handleCreteForm()}>
          + New Portfolio
        </PrimaryButton>
      </div>
      <div className="row">
        {portfoliosLoading ? (
          <LoaderSvg className="animate-spin h-16 w-16 mx-auto mt-16 text-white" />
        ) : (
          portfolios?.map((portfolio, index) => (
            <PortfolioCard
              key={index}
              portfolio={portfolio}
              handlePortfolioDeleted={() => handleRefetchData()}
              handleSuccess={handleSuccess}
            />
          ))
        )}
      </div>
    </>
  );
};

export default Portfolios;
