import type {
  SuperIndexRecord,
  SuperIndexRequestConfig,
  SuperIndexRequestOptions,
  SuperIndexResponse,
} from './types';

export const superIndexRequestAdaptor = (
  options?: SuperIndexRequestOptions,
): SuperIndexRequestConfig => {
  const pageIndex = options?.pagination?.pageIndex ?? 0;
  const pageSize = options?.pagination?.pageSize ?? 10;
  return {
    offset: pageIndex * pageSize,
    limit: pageSize,
    order_by: options?.sorting?.[0]?.id ?? 'Chg_5Y',
    ascending: !options?.sorting?.[0]?.desc,
    num_of_bedrooms: options?.numberOfBedrooms ?? null,
    property_type: options?.propertyTypes?.length
      ? options.propertyTypes
      : null,
    district_exact: options?.districtExact ?? null,
    district: options?.districtCode?.toUpperCase() ?? null,
    area_code: options?.areaCode?.toUpperCase() ?? null,
    post_town: options?.postTown ? options.postTown.toUpperCase() : null,
    min_price: options?.indexPriceRange?.[0] ?? null,
    max_price: options?.indexPriceRange?.[1] ?? null,
    min_rental_price: options?.rentalPriceRange?.[0] ?? null,
    max_rental_price: options?.rentalPriceRange?.[1] ?? null,
    min_rental_yield: options?.rentalYieldRange?.[0]
      ? options.rentalYieldRange[0] / 100
      : null,
    max_rental_yield: options?.rentalYieldRange?.[1]
      ? options.rentalYieldRange[1] / 100
      : null,
  };
};

export const superIndexResponseAdaptor = (
  response: SuperIndexResponse[],
): SuperIndexRecord[] => {
  return response.map((record) => {
    return {
      ...record,
      chg_1m: record['%_chg_1m'],
      chg_3m: record['%_chg_3m'],
      chg_1y: record['%_chg_1y'],
      est_chg_1y: record.est_chg_fy1y,
      est_chg_2y: record.est_chg_fy2y,
    };
  });
};
