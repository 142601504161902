import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import PropertyTransactions from '../Components/PropertyTransactions';
import { propertySVGs } from '../../../../utils/helpers';

const TransactionsModal = ({ showModal, transactions, setShowModal, property }) => {
  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center	justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-xl font-medium">Transaction History</div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>

        <div className="w-full mt-2">
          <div className="px-2 py-2 flex items-center  mx-3" style={{ backgroundColor: '#F2F6FB' }}>
            {property?.address && <div className="text-black text-sm font-medium mr-2">{property.address}</div>}
            <div className="flex items-center gap-2">
              <div className="bg-white py-1 px-2 rounded-xl">
                District: {property?.property_index_data?.district_code}
              </div>
              <div className="bg-white py-1 px-2 rounded-xl">Type: {propertySVGs[property.property_type]?.name}</div>
              <div className="bg-white py-1 px-2 rounded-xl">Bedrooms: {property.num_rooms}</div>
            </div>
          </div>
        </div>

        <div className="py-8">
          <PropertyTransactions property={property} transactions={transactions} modalView={true} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
        <div className="flex flex-row w-full justify-end px-4 mb-3 mt-3">
          <PrimaryButton onClick={() => setShowModal(false)}>Close</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionsModal;
