import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as Info } from 'icons/custom/info-icon.svg';
import { ReactComponent as BedroomsSVG } from 'icons/custom/bedroom-c.svg';
import { ReactComponent as EpcSVG } from 'icons/custom/epc-c.svg';
import { ReactComponent as ValuationSVG } from 'icons/custom/valuation-c.svg';

import { Link } from 'react-router-dom';

import Select from 'react-select';
import { epcOptions, numberOfBedroomsOptionsOnly } from '../../../../utils/constants';
import moment from 'moment';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { useQuery } from '@tanstack/react-query';
import { getTargetTime } from '../../../../api/propertyIndex';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const initialStateObject = {
  changed_number_of_bedrooms: undefined,
  date_of_change: undefined,
  last_transaction_price: undefined,
  last_transaction_date: undefined,
  epc: undefined,
  changed_epc: undefined,
  user_entered_params: '1',
};

const ClonePropertyModal = ({ property, showModal, setShowModal, handleSuccess }) => {
  const currentEpc = property.changed_epc ? property.changed_epc : property.epc;
  const [updatedParameters, setUpdatedParameters] = useState({ ...initialStateObject });
  const [errors, setErrors] = useState({ ...initialStateObject });

  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));

  const minDate = useMemo(() => {
    return moment(targetTime).subtract(1, 'months').startOf('month').subtract(55, 'months').format('YYYY-MM-DD');
  }, [targetTime]);

  const maxDate = useMemo(() => {
    return moment(targetTime).subtract(1, 'months').startOf('month').subtract(5, 'months').format('YYYY-MM-DD');
  }, [targetTime]);

  const handleDropdownChange = (item, selectField) => {
    const fieldName = selectField;
    let fieldValue = item.value;
    const currentFormData = { ...updatedParameters };

    if (selectField === 'changed_number_of_bedrooms') {
      fieldValue = parseInt(item.value);
    }

    if (fieldName === 'epc') {
      if (currentFormData['epc'] > currentFormData['changed_epc']) {
        currentFormData['changed_epc'] = undefined;
      }
    }

    currentFormData[fieldName] = fieldValue;
    setErrors({ ...initialStateObject });
    setUpdatedParameters(currentFormData);
  };

  const handleFormChange = (event) => {
    event.preventDefault();
    setErrors({ ...initialStateObject });
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const formData = { ...updatedParameters };

    formData[fieldName] = fieldValue;
    if (fieldName === 'last_transaction_price') {
      formData[fieldName] = Math.trunc(numeral(formData[fieldName]).value());
    }

    setErrors({ ...initialStateObject });
    setUpdatedParameters(formData);
  };

  const submitForm = () => {
    let error = false;
    const errorObject = { ...errors };

    if (updatedParameters.changed_number_of_bedrooms && !updatedParameters.date_of_change) {
      errorObject.date_of_change = 'Date of Change is Required';
      error = true;
    } else if (
      !updatedParameters.changed_number_of_bedrooms &&
      !updatedParameters.last_transaction_price &&
      !updatedParameters.changed_epc
    ) {
      errorObject.changed_number_of_bedrooms = 'Changed number of bedrooms is Required';
      error = true;
    }

    if (updatedParameters.last_transaction_price && !updatedParameters.last_transaction_date) {
      errorObject.last_transaction_date = 'Date of valuation is Required';
      error = true;
    } else if (
      !updatedParameters.last_transaction_price &&
      !updatedParameters.changed_number_of_bedrooms &&
      !updatedParameters.changed_epc
    ) {
      errorObject.last_transaction_price = 'Latest valuation is Required';
      error = true;
    }

    if (updatedParameters.epc !== property.epc && !updatedParameters.changed_epc) {
      errorObject.changed_epc = 'EPC field is Required';
    }

    setErrors(errorObject);

    if (error) {
      return;
    }

    handleSuccess && handleSuccess(updatedParameters);
    setShowModal(false);
  };

  useEffect(() => {
    setUpdatedParameters({ ...initialStateObject, epc: currentEpc });
    setErrors({ ...initialStateObject });
  }, [currentEpc, showModal]);

  return (
    <Modal className="fade" show={showModal} onHide={setShowModal} size="lg" centered enforceFocus={false}>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20"> Clone Property </h4>
              <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              {property?.parent_id ? (
                <div
                  style={{ backgroundColor: '#E1EAFB', fontSize: 12 }}
                  className="px-3 py-2 rounded-lg inline-flex items-center justify-start mb-2"
                >
                  <div className="flex items-center justify-start">
                    <Info className="w-5 mr-1" />
                    This property has been {property?.changed_number_of_bedrooms ? 'cloned' : 'revalued'}
                  </div>
                  <Link
                    className="underline pl-3"
                    to={`/property-analytics/${property?.parent_id}`}
                    style={{ color: '#3571E5' }}
                  >
                    See original property
                  </Link>
                </div>
              ) : (
                ''
              )}

              <div className="w-100 fs-14 pt-8 text-black relative mt-2" style={{ background: '#F2F6FB' }}>
                <div className="flex w-full flex-row" style={{ background: '#F2F6FB' }}>
                  <div className="form-group mb-4 px-3 w-100 flex flex-col">
                    <label className="font-medium text-black text-md inline-flex items-center">
                      <BedroomsSVG className="h-4 mr-1" />
                      Changed Number of Bedrooms
                    </label>
                    <Select
                      className="w-100 text-xs"
                      placeholder="Number of Bedrooms"
                      options={numberOfBedroomsOptionsOnly}
                      isSearchable={true}
                      isOptionDisabled={(option) => option.value <= property.property_index_data?.number_of_bedrooms}
                      onChange={(option) => handleDropdownChange(option, 'changed_number_of_bedrooms')}
                      name="changed_number_of_bedrooms"
                    />
                    {errors.changed_number_of_bedrooms && (
                      <div className="text-danger fs-12">{errors.changed_number_of_bedrooms}</div>
                    )}
                  </div>

                  <div className="form-group mb-4 px-3 w-100 flex flex-col">
                    <label className="font-medium text-black text-md"> Date of Change </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-md border text-xs"
                      placeholder="Date of Change"
                      type="date"
                      onChange={handleFormChange}
                      name="date_of_change"
                      max={maxDate}
                      min={minDate}
                    />
                    {errors.date_of_change && <div className="text-danger fs-12">{errors.date_of_change}</div>}
                  </div>
                </div>
              </div>
              <div
                className="w-100 fs-14 pt-8 text-black relative mt-10"
                style={{ background: '#F2F6FB', borderTop: '1px solid #C5D6F7' }}
              >
                <div
                  className="inline-block px-4 py-2 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-3xl"
                  style={{ background: '#E1EAFB', color: '#3571E5' }}
                >
                  And / Or
                </div>

                <div className="flex w-full flex-row">
                  <div className="form-group mb-4 px-3 w-100 flex flex-col">
                    <label className="font-medium text-black text-md inline-flex items-center">
                      <ValuationSVG className="h-4 mr-1" /> Latest valuation
                    </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-md border text-xs"
                      placeholder="Latest valuation"
                      type="text"
                      name="last_transaction_price"
                      value={numeral(updatedParameters?.last_transaction_price).format()}
                      onChange={handleFormChange}
                    />
                    {errors.last_transaction_price && (
                      <div className="text-danger fs-12">{errors.last_transaction_price}</div>
                    )}
                  </div>

                  <div className="form-group mb-4 px-3 w-100 flex flex-col">
                    <label className="font-medium text-black text-md"> Latest valuation date </label>
                    <input
                      className="w-100 h-10 px-2.5 py-2 bg-white rounded-md border text-xs"
                      placeholder="Latest valuation date"
                      type="date"
                      onChange={handleFormChange}
                      name="last_transaction_date"
                      max={maxDate}
                      min={minDate}
                    />
                    {errors.last_transaction_date && (
                      <div className="text-danger fs-12">{errors.last_transaction_date}</div>
                    )}
                  </div>
                </div>
              </div>
              {!!property.changed_epc ? (
                ''
              ) : (
                <>
                  <div
                    className="w-100 fs-14 pt-8 text-black relative mt-10"
                    style={{ background: '#F2F6FB', borderTop: '1px solid #C5D6F7' }}
                  >
                    <div
                      className="inline-block px-4 py-2 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-3xl"
                      style={{ background: '#E1EAFB', color: '#3571E5' }}
                    >
                      And / Or
                    </div>

                    <div className="flex w-full flex-row">
                      <div className="form-group mb-4 px-3 w-100 flex flex-col">
                        <label className="font-medium text-black text-md inline-flex items-center">
                          <EpcSVG className="h-4 mr-1" />
                          Current EPC
                        </label>
                        <Select
                          className="w-100 text-xs"
                          placeholder="Current EPC"
                          options={epcOptions}
                          isSearchable={true}
                          value={updatedParameters?.epc ? epcOptions.find(({ value }) => value === currentEpc) : null}
                          isDisabled={!!currentEpc}
                          onChange={(option) => handleDropdownChange(option, 'epc')}
                          name="epc"
                        />
                      </div>

                      <div className="form-group mb-4 px-3 w-100 flex flex-col">
                        <label className="font-medium text-black text-md">New EPC</label>
                        <Select
                          className="w-100 text-xs"
                          placeholder="New EPC"
                          options={epcOptions}
                          isSearchable={true}
                          value={
                            updatedParameters?.changed_epc
                              ? epcOptions.find(({ value }) => value === updatedParameters.changed_epc)
                              : null
                          }
                          isOptionDisabled={(option) => option.value >= updatedParameters.epc}
                          onChange={(option) => handleDropdownChange(option, 'changed_epc')}
                          name="changed_epc"
                        />
                        {errors.changed_epc && <div className="text-danger fs-12">{errors.changed_epc}</div>}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="flex w-100 justify-end">
                <SecondaryButton className="m-1" onClick={() => setShowModal(false)} type="button">
                  Cancel
                </SecondaryButton>
                <PrimaryButton className="m-1" type="button" onClick={() => submitForm()}>
                  Submit
                </PrimaryButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ClonePropertyModal;
