import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import SecondaryButton from '../Buttons/SecondaryButton';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const BaseBarChart = (props) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  const forecastDataA = props.itemGraphSeriesData || {};
  const forecastDataB = props.benchmarkGraphSeriesData || {};
  const forecastDataC = props.benchmark2GraphSeriesData || {};
  const dataType = props?.dataType || '';

  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [yaxisConfig, setYaxisConfig] = useState([]);
  const [showPartialData, setShowPartialData] = useState(true);

  const chartRef = useRef(null);

  useEffect(() => {
    const series = [
      {
        name: props.address,
        data: forecastDataA?.length
          ? showPartialData
            ? forecastDataA
                .slice(forecastDataA?.length - 48, forecastDataA?.length - 12)
                .map((item) => (dataType === 'rate' ? item.value : parseInt(item.value)))
            : forecastDataA.map((item) => (dataType === 'rate' ? item.value : parseInt(item.value)))
          : [],
      },
    ];

    props.index &&
      series.push({
        name: props.index,
        data: forecastDataB?.length
          ? showPartialData
            ? forecastDataB
                .slice(forecastDataB?.length - 48, forecastDataB?.length - 12)
                .map((item) => (dataType === 'rate' ? item.value : parseInt(item.value)))
            : forecastDataB.map((item) => (dataType === 'rate' ? item.value : parseInt(item.value)))
          : [],
      });

    props.index2 &&
      series.push({
        name: props.index2,
        data: forecastDataC?.length
          ? showPartialData
            ? forecastDataC
                .slice(forecastDataC?.length - 48, forecastDataC?.length - 12)
                .map((item) => (dataType === 'rate' ? item.value : parseInt(item.value)))
            : forecastDataC.map((item) => (dataType === 'rate' ? item.value : parseInt(item.value)))
          : [],
      });

    setSeries(series);

    const categories = forecastDataB?.length
      ? showPartialData
        ? forecastDataB.slice(forecastDataB?.length - 48, forecastDataB?.length - 12).map((item) => item.timestamp)
        : forecastDataB.map((item) => item.timestamp)
      : forecastDataA?.length
        ? showPartialData
          ? forecastDataA.slice(forecastDataA?.length - 48, forecastDataA?.length - 12).map((item) => item.timestamp)
          : forecastDataA.map((item) => item.timestamp)
        : [];

    setCategories(categories);

    const yConfig = {
      labels: {
        formatter: function (val) {
          const formattedVal = parseFloat(val.toFixed(10));
          return dataType === 'rate' ? numeral(formattedVal).format('0.00%') : numeral(formattedVal).format();
        },
      },
      forceNiceScale: true,
    };

    setYaxisConfig(yConfig);
  }, [
    forecastDataC,
    forecastDataB,
    forecastDataA,
    props.address,
    props.index,
    props.index2,
    dataType,
    showPartialData,
  ]);

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      type: 'solid',
      colors: ['#3571E5', '#ff4560d9', '#705fd3'],
    },
    forecastDataPoints: {
      count: showPartialData ? 11 : 23,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'text',
      tickAmount: 30,
      categories: categories,
      labels: {
        rotate: -90,
        rotateAlways: true,
      },
      lines: {
        show: false,
      },
    },
    yaxis: yaxisConfig,
    colors: ['#3571E5', '#ff4560d9', '#705fd3'],
  };

  return (
    <>
      <div id="chart2" className="bar-chart base-bar-chart pb-3">
        <ReactApexChart ref={chartRef} options={options} series={series} type="bar" height={350} />
      </div>

      <div className="flex justify-end px-2">
        <SecondaryButton
          className="mx-1 mb-3 -m-16 px-3 w-auto"
          styles={{ zIndex: '2' }}
          onClick={() => setShowPartialData((showPartialData) => !showPartialData)}
        >
          {showPartialData ? 'Show Full Data' : 'Show Partial Data'}
        </SecondaryButton>
      </div>
    </>
  );
};

export default BaseBarChart;
