let Usersnap;

export const usersnap = (user) => {
  const { id: userId, email } = user;
  if (Usersnap) {
    Usersnap.init({
      user: {
        userId,
        email,
      },
    });
  } else {
    try {
      window.onUsersnapCXLoad = function (api) {
        api.init({
          user: {
            userId,
            email,
          },
        });
        Usersnap = api;
      };
      const script = document.createElement('script');
      script.defer = true;
      script.src =
        'https://widget.usersnap.com/global/load/f035dd9d-742c-4c14-b165-7ce0286485b4?onload=onUsersnapCXLoad';
      document.getElementsByTagName('head')[0].appendChild(script);
    } catch {
      console.warn('Failed to initialise Usersnap');
    }
  }
};

export const initPendo = (user) => {
  try {
    (function (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })('8eb64abc-220c-4e7f-5036-faf3c44d26f2');

    const { id: userId, email, team_id, team_name, company_id, company_name, plan, cs_owner, created_at } = user;
    window.pendo.initialize({
      visitor: {
        id: email,
        email,
        role: plan,
        userId,
        created_at,
        company_name,
      },
      account: {
        id: `${company_name} - ${team_name}`,
        name: `${company_name} - ${team_name}`,
        teamId: team_id,
        teamName: team_name,
        companyId: company_id,
        companyName: company_name,
        planLevel: plan,
        csOwner: cs_owner,
      },
    });
  } catch (e) {
    console.log('Failed to initialise Pendo', e);
  }
};

export const initGTM = () => {
  try {
    const gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-9FK457NSYR';
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    gtagScript.onload = () => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // @ts-ignore
        window.dataLayer.push(arguments);
      }
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', 'G-9FK457NSYR');
    };
  } catch (e) {
    console.log('Failed to initialise Google Tag Manager', e);
  }
};

export const initHotjar = () => {
  if (process.env.NODE_ENV === 'production') {
    try {
      const hotjarScript = document.createElement('script');
      hotjarScript.innerHTML = `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3673298,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
      document.head.appendChild(hotjarScript);
    } catch (e) {
      console.log('Failed to initialise Hotjar', e);
    }
  }
};

export const startHotjar = (user) => {
  if (process.env.NODE_ENV === 'production' && user.company_id !== 2) {
    try {
      window.hj('identify', user.id, {
        Email: user.email,
        'Company Name': user.company_name,
        'Created At': user.created_at,
      });
      window.hj('event', 'start_hotjar');
    } catch (e) {
      console.log('Failed to start Hotjar', e);
    }
  }
};
