import React from 'react';

const PageHead = ({ activePage, pageName }) => {
  return (
    <div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
      <h2 className="mb-3 me-auto">{activePage}</h2>
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">{pageName}</li>
        </ol>
      </div>
    </div>
  );
};
export default PageHead;
