import queryClient from '../../utils/queryClient';
import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  SET_USER_DATA_ACTION,
} from '../actions/AuthActions';

const initialState = {
  auth: {
    email: '',
    idToken: '',
    localId: '',
    expiresIn: '',
    refreshToken: '',
  },
  user: {},
  errorMessage: '',
  successMessage: '',
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Signup Successfully Completed',
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Login Successfully Completed',
      showLoading: false,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    queryClient.clear();
    return {
      ...state,
      errorMessage: '',
      successMessage: '',

      // TODO: temporary solution for mock data, needs modification
      auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
      },
      user: {},
    };
  }
  if (action.type === SET_USER_DATA_ACTION) {
    return {
      ...state,
      user: action.payload,
      errorMessage: '',
      successMessage: 'Current User Successfully Set',
      showLoading: false,
    };
  }

  if (action.type === SIGNUP_FAILED_ACTION || action.type === LOGIN_FAILED_ACTION) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: '',
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
