import React, { useMemo } from 'react';
import PageHead from '../additionalModules/PageHead';
import { apiGetDashboardData } from '../../../api/dashboard';
import { currencyFormatter, percentFormatter } from '../../../utils/formatters';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as HomeSvg } from 'icons/custom/home-icon.svg';
import { ReactComponent as RedirectSvg } from 'icons/custom/redirect.svg';
import { ReactComponent as AnalyticsIcon } from 'icons/custom/analytics.svg';
import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getTargetTime } from '../../../api/propertyIndex';
import MarketInsightCard from './Components/MarketInsightCard';
import { apiSearchCityExactIndex } from '../../../api/cityIndex';
import { apiGetRentalIndexByIndexName } from '../../../api/rentalIndex';
import MarketChartCard from './Components/MarketChartCard';
import SecondaryButton from './Buttons/SecondaryButton';
import DashboardPortfolios from './Components/DashboardPortfolios';
import DashboardProperties from './Components/DashboardProperties';

const Home = () => {
  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));

  const { data: ukSalesPriceData = [], isLoading: ukSalesPriceLoading } = useQuery(
    ['ukSalesPriceData', 'UK'],
    ({ signal }) => apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: 'UK' }, signal),
  );

  const { data: ukRentalIndex = [], isLoading: ukRentPriceLoading } = useQuery(
    ['rentalIndexQueries', 'UK'],
    ({ signal }) => apiGetRentalIndexByIndexName({ indexName: 'UK' }, signal),
  );

  const { data: londonSalesPriceData = [], isLoading: londonSalesPriceLoading } = useQuery(
    ['londonSalesPriceData', 'LONDON'],
    ({ signal }) => apiSearchCityExactIndex({ offset: 0, limit: 1, index_exact: 'LONDON' }, signal),
  );

  const { data: londonRentalIndex = [], isLoading: londonRentPriceLoading } = useQuery(
    ['londonRentalIndex', 'LONDON'],
    ({ signal }) => apiGetRentalIndexByIndexName({ indexName: 'LONDON' }, signal),
  );

  const {
    data: dashboardData = {},
    isLoading: dashboardLoading,
    isSuccess: dashboardLoaded,
    isFetching: dashboardRefetching,
  } = useQuery(['dashboardData'], ({ signal }) => apiGetDashboardData(signal));

  const dashboardParams = useMemo(() => {
    if (dashboardLoaded) {
      return [
        {
          title: 'My Portfolios',
          icon: <RedirectSvg className="h-6" />,
          value: dashboardData?.number_of_portfolios,
        },
        {
          title: 'My Properties',
          icon: <HomeSvg className="h-6" />,
          value: dashboardData?.number_of_properties,
        },
        {
          title: 'Portfolio Value',
          value: currencyFormatter.format(dashboardData?.effective_portfolio_value),
        },
        {
          title: 'Annualised Operating Income',
          value: currencyFormatter.format(dashboardData?.effective_operating_income * 0.6),
        },
        {
          title: 'Annualised Rent',
          value: currencyFormatter.format(dashboardData?.effective_operating_income),
        },
        {
          title: 'Gross Yield',
          value: percentFormatter.format(
            dashboardData?.effective_operating_income / (dashboardData?.effective_portfolio_value || 1),
          ),
        },
        {
          title: 'Cap Rate',
          value: percentFormatter.format(
            (dashboardData?.effective_operating_income * 0.6) / (dashboardData?.effective_portfolio_value || 1),
          ),
        },
        {
          title: 'Aggregate Asset Return',
          value: percentFormatter.format(dashboardData?.agg_asset_return),
        },
      ];
    } else {
      return [];
    }
  }, [dashboardData, dashboardLoaded]);

  return (
    <>
      <PageHead activePage="Dashboard" />

      <div>
        <h2 className="mb-3 text-2xl me-auto"> UK Market insights </h2>
      </div>

      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="row">
            <div className="col-12 col-md-6">
              <MarketInsightCard
                targetTime={targetTime}
                title={'Avg Sales price UK'}
                isLoading={ukSalesPriceLoading}
                indexData={ukSalesPriceData}
              />
            </div>
            <div className="col-12 col-md-6">
              <MarketInsightCard
                targetTime={targetTime}
                title={'Avg Rent price UK'}
                isLoading={ukRentPriceLoading}
                indexData={ukRentalIndex}
              />
            </div>
          </div>
          <div className="flex gap-2 flex-wrap mb-4">
            <Link className="cursor-pointer" to={`/insights/`}>
              <SecondaryButton className={'w-auto px-3 bg-white text-xs'}>
                <AnalyticsIcon className="mr-2" />
                Year on year rent price change
              </SecondaryButton>
            </Link>
            <Link className="cursor-pointer" to={`/insights/`}>
              <SecondaryButton className={'w-auto px-3 bg-white text-xs'}>
                <AnalyticsIcon className="mr-2" /> Property price changes by property type
              </SecondaryButton>
            </Link>
            <Link className="cursor-pointer" to={`/insights/`}>
              <SecondaryButton className={'w-auto px-3 bg-white text-xs'}>
                <AnalyticsIcon className="mr-2" /> See more charts
              </SecondaryButton>
            </Link>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="row">
            <div className="col-12 col-md-6">
              <MarketChartCard
                targetTime={targetTime}
                title={'Avg Sales price London'}
                isLoading={londonSalesPriceLoading}
                indexData={londonSalesPriceData}
              />
            </div>
            <div className="col-12 col-md-6">
              <MarketChartCard
                targetTime={targetTime}
                title={'Avg Rent price London'}
                isLoading={londonRentPriceLoading}
                indexData={londonRentalIndex}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <h2 className="mb-3 text-2xl me-auto"> My insights </h2>
      </div>
      <div className="card p-3">
        {dashboardLoading || dashboardRefetching ? (
          <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-8 gap-4">
            {dashboardParams.map((param, index) => (
              <div key={index} className="d-flex align-items-center h-full">
                <div className="card-data flex h-full flex-col justify-between p-2">
                  <h5 className="pb-2 text-xs">{param.title}</h5>
                  <div className="text-xl font-semibold text-black">
                    {dashboardLoading || dashboardRefetching ? (
                      <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
                    ) : param?.value ? (
                      <div className="flex items-center">
                        {param.icon}
                        {param.value}
                      </div>
                    ) : (
                      <span className="italic opacity-50"> - </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 col-xl-4">
          <DashboardPortfolios targetTime={targetTime} />
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
          <DashboardProperties favourites={true} targetTime={targetTime} />
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
          <DashboardProperties favourites={false} targetTime={targetTime} />
        </div>
      </div>
    </>
  );
};
export default Home;
