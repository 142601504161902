import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as DeleteSVG } from 'icons/custom/delete-property.svg';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { deletePropertyByID } from 'api/property';

import 'numeral/locales/en-gb';
import StatusModal from './StatusModal';

const DeletePropertyModal = ({ showModal, setShowModal, handleSuccess, propertyId }) => {
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
    title: '',
  });

  const handleClickDelete = async () => {
    try {
      await deletePropertyByID(propertyId);
      handleSuccess && handleSuccess(propertyId);
      setShowModal(false);

      setStatusModalContent({
        show: true,
        type: 'success',
        description: 'Property deleted successfully',
      });
    } catch (error) {
      setShowModal(false);

      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
      console.error(error);
    }
  };

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />

      <Modal
        className="modal fade"
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        centered
        enforceFocus={false}
      >
        <div className="flex flex-column items-center	justify-center mt-4">
          <div className="flex w-full items-center justify-between px-4">
            <div className="text-black text-2xl font-medium">Delete</div>
            <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
          </div>
          <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
          <div
            className="w-12	h-12 rounded-full flex items-center	justify-center mb-4 mt-4"
            style={{ background: '#F5F8FE' }}
          >
            <DeleteSVG />
          </div>
          <div className="text-black text-base	font-medium	mb-8">
            Are you sure you want to delete this Property from the list?
          </div>
          <div className="flex flex-row w-full justify-between px-4 mb-4 ">
            <PrimaryButton onClick={handleClickDelete}>Confirm</PrimaryButton>
            <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeletePropertyModal;
