import { QueryObserver } from '@tanstack/react-query';
import { fromCallback } from 'xstate';
import { GEOMETRY_QUERY_KEY, getGeometry } from '.';
import type { GeometryRecord, GeometryRequestOptions } from './types';
import queryClient from 'utils/queryClient';

export type GeometryDataEvent =
  | { type: 'GEOMETRY.DATA.FETCHING' }
  | { type: 'GEOMETRY.DATA.UPDATE'; geometry: GeometryRecord[] }
  | { type: 'GEOMETRY.DATA.ERROR'; error: Error }
  | { type: 'GEOMETRY.REFETCH'; options?: GeometryRequestOptions };

export const getGeometryActor = fromCallback<
  GeometryDataEvent,
  GeometryRequestOptions
>(({ input, sendBack, receive }) => {
  let options = input;
  let queryKey = [...GEOMETRY_QUERY_KEY, options];

  const observer = new QueryObserver(queryClient, {
    queryKey,
    queryFn: async ({ signal }) => await getGeometry(options, signal),
    retry: (failureCount) => failureCount < 1,
  });

  observer.subscribe(({ isFetching, isSuccess, isError, data, error }) => {
    if (isFetching) {
      sendBack({ type: 'GEOMETRY.DATA.FETCHING' });
    } else if (isSuccess) {
      sendBack({ type: 'GEOMETRY.DATA.UPDATE', geometry: data });
    } else if (isError) {
      sendBack({ type: 'GEOMETRY.DATA.ERROR', error });
    }
  });

  const { data } = observer.getCurrentResult() || {};

  if (data) {
    sendBack({ type: 'GEOMETRY.DATA.FETCHING' });
    sendBack({ type: 'GEOMETRY.DATA.UPDATE', geometry: data });
  }

  receive((event) => {
    if (event.type === 'GEOMETRY.REFETCH') {
      if (event.options) {
        options = event.options;
        queryKey = [...GEOMETRY_QUERY_KEY, options];
      }
      observer.setOptions({
        queryKey,
        queryFn: async ({ signal }) => await getGeometry(options, signal),
        retry: observer.options.retry,
      });
      observer.updateResult();
    } else {
      observer.refetch();
    }
  });

  return () => {
    observer.destroy();
  };
});
