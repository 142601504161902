import axiosInstance from '../services/AxiosInstance';

export function login(email, password) {
  return axiosInstance.post(`/auth/`, {
    username: email,
    password,
  });
}

export function getUserData(token) {
  return axiosInstance.get('/user', {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function requestPasswordReset(email) {
  return axiosInstance.put('/user/password/reset', { email });
}

export function completePasswordReset(token, password) {
  return axiosInstance.post('/user/password/reset', { token, password });
}

export function completePasswordCreate(token, password) {
  return axiosInstance.post('/user/password/create', { token, password });
}
