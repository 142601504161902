import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { getKeyPerformanceMetrics } from '../Helpers/Helpers';

const styles = StyleSheet.create({
  table: {
    width: '100%',
    border: 'none',
  },
  header: {
    fontSize: 12,
    marginBottom: 8,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    borderBottomStyle: 'solid',
  },
  cell: {
    flex: 1,
    padding: 8,
    fontSize: 10,
  },
});
const PdfKeyPerformanceSection = ({ itemPM, benchmarkPM }) => {
  const data = getKeyPerformanceMetrics(itemPM, benchmarkPM);

  return (
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.cell}>Metric</Text>
        <Text style={styles.cell}>Property</Text>
        <Text style={styles.cell}>Benchmark</Text>
      </View>
      {data.map((row, index) => (
        <View key={index} style={styles.row}>
          <Text style={styles.cell}>{row.metric}</Text>
          <Text style={styles.cell}>{row.itemName}</Text>
          <Text style={styles.cell}>{row.benchmark}</Text>
        </View>
      ))}
    </View>
  );
};

export default PdfKeyPerformanceSection;
