import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as SuccessSVG } from 'icons/custom/circle-check-regular.svg';
import { ReactComponent as ExclamationRedSVG } from 'icons/custom/exclamation-red.svg';

import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import SecondaryButton from '../Buttons/SecondaryButton';

const StatusModal = ({ showModal, setShowModal, content }) => {
  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center	justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-2xl font-medium"></div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
        <div className="p-2">
          {content.type &&
            (content.type === 'success' ? (
              <div
                className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
                style={{ background: '#F5F8FE' }}
              >
                <SuccessSVG className="h-16 w-16 text-white" />
              </div>
            ) : (
              <div
                className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
                style={{ background: '#ff00331a' }}
              >
                <ExclamationRedSVG className="h-6 w-6" />
              </div>
            ))}
        </div>

        <div className="text-black text-center font-medium mb-12 px-4">{content.description}</div>

        <div className="flex flex-row w-full justify-between px-4 mb-4">
          <div>{content.action ? content.action : ''}</div>
          <SecondaryButton onClick={() => setShowModal(false)}>Close</SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default StatusModal;
