import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { ReactComponent as MenuDots } from 'icons/custom/menu-dots.svg';
import { ReactComponent as DeleteSVG } from 'icons/custom/delete.svg';
import { ReactComponent as UpdateSVG } from 'icons/custom/edit.svg';
import { ReactComponent as AnalyticsIcon } from 'icons/custom/analytics.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import DeletePortfolioModal from './DeletePortfolioModal';
import PortfolioModal from './PortfolioModal';

import { apiGetPortfolioAnalyticsById } from '../../../../api/portfolio';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PortfolioCard = ({ portfolio, handlePortfolioDeleted, handleSuccess }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [portfolioToDelete, setPortfolioToDelete] = useState('');
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [portfolioToModify, setPortfolioToModify] = useState({ name: '', description: '', properties: [] });

  const handleClickDelete = (portfolioId) => {
    setShowDeleteModal(true);
    setPortfolioToDelete(portfolioId);
  };

  const handleClickUpdate = (portfolio) => {
    setPortfolioToModify(portfolio);
    setShowPortfolioModal(true);
  };

  const { data: analyticsData = {}, isLoading: loading } = useQuery(
    ['portfolioAnalyticsData', portfolio.id],
    ({ signal }) => apiGetPortfolioAnalyticsById(portfolio.id, signal),
  );

  return (
    <>
      <DeletePortfolioModal
        setShowModal={setShowDeleteModal}
        showModal={showDeleteModal}
        portfolioId={portfolioToDelete}
        handleSuccess={handlePortfolioDeleted}
      />
      <PortfolioModal
        showModal={showPortfolioModal}
        setShowModal={setShowPortfolioModal}
        handleSuccess={handleSuccess}
        portfolio={portfolioToModify}
      />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={portfolio.id}>
        <div className="card project-boxed">
          <div className="card-header align-items-start">
            <div className="w-100">
              <h6 className="fs-18 font-w500 mb-3 text-black truncate">{portfolio.name}</h6>
              <div className="fs-14 truncate h-6" style={{ color: '#747B8A' }}>
                {portfolio?.description}
              </div>
            </div>

            <div className="absolute right-0 -mt-2.5 mr-3.5">
              <Menu
                align={'end'}
                menuButton={
                  <MenuButton className="h-6 flex items-center justify-end">
                    <MenuDots />
                  </MenuButton>
                }
                transition
              >
                <MenuItem
                  className="flex items-center justify-start pl-3 text-xs font-semibold"
                  style={{ color: '#747B8A' }}
                  onClick={() => handleClickDelete(portfolio.id)}
                >
                  <DeleteSVG className="mr-2" /> <span className="mt-0.5">Delete</span>
                </MenuItem>

                <MenuItem
                  className="flex items-center justify-start pl-3 text-xs font-semibold"
                  style={{ color: '#747B8A' }}
                  onClick={() => handleClickUpdate(portfolio)}
                >
                  <UpdateSVG className="mr-2" /> <span className="mt-0.5">Update</span>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="card-body p-0 pb-3 flex flex-col">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <span className="mb-0 title">Portfolio Value</span> :
                <span className="text-black ms-2">
                  {loading ? (
                    <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
                  ) : analyticsData?.portfolio_value ? (
                    numeral(analyticsData?.portfolio_value).format()
                  ) : (
                    '-'
                  )}
                </span>
              </li>
              <li className="list-group-item">
                <span className="mb-0 title">Total number of Properties</span> :
                <span className="text-black ms-2">{portfolio?.properties?.length || 0}</span>
              </li>
              <li className="list-group-item">
                <span className="mb-0 title">Annualised Rent</span> :
                <span className="text-black ms-2">
                  {loading ? (
                    <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
                  ) : analyticsData?.opertaing_income ? (
                    numeral(analyticsData?.opertaing_income).format()
                  ) : (
                    '-'
                  )}
                </span>
              </li>
              <li className="list-group-item">
                <span className="mb-0 title">Gross Yield</span> :
                <span className="text-black desc-text ms-2">
                  {loading ? (
                    <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
                  ) : analyticsData?.portfolio_value ? (
                    numeral(analyticsData?.opertaing_income / (analyticsData?.portfolio_value || 1)).format('0.00%')
                  ) : (
                    '-'
                  )}
                </span>
              </li>
              <li className="list-group-item">
                <span className="mb-0 title">Cap Rate</span> :
                <span className="text-black ms-2">
                  {loading ? (
                    <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
                  ) : analyticsData?.opertaing_income ? (
                    numeral((analyticsData?.opertaing_income * 0.6) / (analyticsData?.portfolio_value || 1)).format(
                      '0.00%',
                    )
                  ) : (
                    '-'
                  )}
                </span>
              </li>
              <li className="list-group-item">
                <span className="mb-0 title">Effective Debt</span> :
                <span className="text-black desc-text ms-2">
                  {loading ? (
                    <LoaderSvg className="animate-spin h-4 w-4 inline-block text-white" />
                  ) : analyticsData?.debt ? (
                    numeral(analyticsData?.debt).format()
                  ) : (
                    '-'
                  )}
                </span>
              </li>
            </ul>
            <div className="w-full flex px-3 mt-auto justify-end">
              <Link to={`/portfolio-analytics/${portfolio?.id}`}>
                <SecondaryButton className="w-auto px-3">
                  <AnalyticsIcon />
                  <span className="pl-2.5">Analytics</span>
                </SecondaryButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioCard;
