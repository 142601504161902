import Table from '../../Dashboard/Tables/BaseTable';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AmenitiesTable = ({ data, loading = false }) => {
  const columnsMetadata = {
    subtype: {
      Header: 'Type',
      accessor: 'subtype',
      headerStyle: {
        fontSize: '14px',
      },
      style: {
        paddingBottom: '5px',
        fontWeight: 400,
      },
      filterable: false,
      hideSort: true,
    },
    name: {
      Header: 'Name',
      accessor: 'name',
      headerStyle: {
        fontSize: '14px',
      },
      style: {
        paddingBottom: '5px',
        fontWeight: 400,
      },
      filterable: false,
      hideSort: true,
    },
    distance_label: {
      Header: 'Distance (miles)',
      accessor: 'distance_label',
      headerStyle: {
        fontSize: '14px',
      },
      style: {
        paddingBottom: '5px',
        fontWeight: 400,
      },
      filterable: false,
      hideSort: true,
    },
    rating: {
      Header: 'Ratings',
      accessor: 'rating',
      headerStyle: {
        fontSize: '14px',
      },
      style: {
        paddingBottom: '5px',
        fontWeight: 400,
        textTransform: 'capitalize',
      },
      filterable: false,
      hideSort: true,
    },
  };

  return (
    <>
      {data?.length ? (
        <Table
          columnsMetadata={columnsMetadata}
          sampleTableData={data}
          filtersActive={false}
          paginationActive={false}
          staticData={true}
          loading={loading}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default AmenitiesTable;
